import { gql } from 'react-apollo';

import {
  BLOG_FETCH_ERROR,
  BLOG_FETCH_START,
  BLOG_FETCH_SUCCESS,
  CHOSE_TO_CURRENCY_ERROR,
  CHOSE_TO_CURRENCY_START,
  CHOSE_TO_CURRENCY_SUCCESS,
  CURRENCY_RATES_FETCH_ERROR,
  CURRENCY_RATES_FETCH_START,
  CURRENCY_RATES_FETCH_SUCCESS,
} from '../constants';

const query = gql`
  {
    Currency {
      base
      rates
    }
  }
`;

const getBlog = gql`
  query getEnabledBlog {
    getEnabledBlog {
      id
      metaTitle
      metaDescription
      pageUrl
      pageTitle
      content
      footerCategory
      isEnable
      createdAt
      access
    }
  }
`;

export function getCurrencyRates(toCurrency) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: CURRENCY_RATES_FETCH_START,
    });

    try {
      let base;
      let currencyRates;

      // Send request to fetch Currency Rates
      const { data } = await client.query({ query });
      if (data && data.Currency) {
        base = data.Currency.base;
        if (data.Currency.rates) {
          currencyRates = JSON.parse(data.Currency.rates);
        }
        dispatch({
          type: CURRENCY_RATES_FETCH_SUCCESS,
          payload: {
            base,
            to: toCurrency,
            rates: currencyRates,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: CURRENCY_RATES_FETCH_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }

    return true;
  };
}

export function choseToCurrency(toCurrency) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: CHOSE_TO_CURRENCY_START,
    });

    try {
      dispatch({
        type: CHOSE_TO_CURRENCY_SUCCESS,
        payload: {
          to: toCurrency,
        },
      });

      // remember locale for every new request
      if (process.env.BROWSER) {
        const maxAge = 3650 * 24 * 3600; // 10 years in seconds
        document.cookie = `currency=${toCurrency};path=/;max-age=${maxAge}`;
      }
    } catch (error) {
      dispatch({
        type: CHOSE_TO_CURRENCY_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }

    return true;
  };
}

export function getBlogData() {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: BLOG_FETCH_START,
    });

    try {
      // Get Blog Data
      const { data } = await client.query({ query: getBlog });
      if (data && data.getEnabledBlog) {
        dispatch({
          type: BLOG_FETCH_SUCCESS,
          payload: {
            getBlog: data.getEnabledBlog,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: BLOG_FETCH_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }

    return true;
  };
}
