import { UPDATE_BOOKING_GOSOLO, CLEAR_BOOKING_GOSOLO } from '../constants';

export default function bookGoSolo(state = {}, action) {
  switch (action.type) {
    case UPDATE_BOOKING_GOSOLO:
      return {
        data: action.payload.data,
        selectedGoSoloTrip: action.payload.selectedGoSoloTrip,
      };

    case CLEAR_BOOKING_GOSOLO:
      return {
        data: null,
        selectedGoSoloTrip: null,
      };

    default:
      return state;
  }
}
