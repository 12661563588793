import React from 'react';

import PropTypes from 'prop-types';
import { NavItem } from 'react-bootstrap';
import { connect } from 'react-redux';

import { toggleClose } from '../../actions/Menu/toggleControl';
import history from '../../core/history';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class NavLink extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node,
    onClick: PropTypes.func,
    noLink: PropTypes.bool,
    className: PropTypes.string,
    toggleClose: PropTypes.func.isRequired,
  };

  handleClick = (event) => {
    const { noLink, toggleClose } = this.props;
    toggleClose();
    document.body.classList.remove('menu-open');

    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();

    if (!noLink) {
      history.push(this.props.to);
    }
  };

  render() {
    const { to, children, className } = this.props;
    return (
      <NavItem className={className} href={to} onClick={this.handleClick}>
        {children}
      </NavItem>
    );
  }
}

const mapState = (state) => ({});

const mapDispatch = {
  toggleClose,
};

export default connect(mapState, mapDispatch)(NavLink);
