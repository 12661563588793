import React from 'react';

import cx from 'classnames';
import { IoArrowBack } from 'react-icons/io5';

import s from './mobileBackButton.module.css';

const breakpoint = 1199;

export default function MobileBackButton() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [visible, setVisible] = React.useState(false);

  const goBack = () => window.history.back();

  React.useEffect(() => {
    if (window.location.pathname !== '/') {
      setVisible(true);
    }
    return () => {
      setVisible(false);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const renderHandler = () => {
    if (window.location.pathname.includes('rooms')) {
      return (
        <div className={cx(s.buttonContainer)}>
          <button className={cx(s.buttonRooms)} onClick={goBack} type="button">
            <IoArrowBack />
          </button>
        </div>
      );
    }

    return (
      <button className={cx(s.button)} onClick={goBack} type="button">
        <IoArrowBack />
      </button>
    );
  };

  const renderMobileBackButton = () => {
    if (width <= breakpoint && visible) {
      return renderHandler();
    }
    return null;
  };

  return renderMobileBackButton();
}
