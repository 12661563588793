import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class RemainderAdminBookingBefore extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      userName: PropTypes.string.isRequired,
      reporterName: PropTypes.string.isRequired,
      reportType: PropTypes.string.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const {
      content: {
        guestFirstName,
        guestProfileId,
        checkIn,
        checkOut,
        hostFirstName,
        hostProfileId,
      },
    } = this.props;
    const {
      content: { listId, title, reservationId },
    } = this.props;
    const hostProfile = `${url}/users/show/${hostProfileId}`;
    const guestProfile = `${url}/users/show/${guestProfileId}`;
    const listURL = `${url}/rooms/${listId}`;
    const checkInDate = checkIn
      ? moment(checkIn).format('ddd, MMM Do, YYYY')
      : '';
    const checkOutDate = checkOut
      ? moment(checkOut).format('ddd, MMM Do, YYYY')
      : '';
    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          <div>
            This is a reminder for an upcoming reservation, the following are
            the details:
          </div>
          <EmptySpace height={20} />
          <div>Reservation Id: {Number(reservationId) + 1000}</div>
          <div>
            Listing's name:{' '}
            <a href={listURL} style={linkText}>
              {title}
            </a>
          </div>
          <div>
            Host's name:{' '}
            <a href={hostProfile} style={linkText}>
              {hostFirstName}
            </a>
          </div>
          <div>
            Guest's name:{' '}
            <a href={guestProfile} style={linkText}>
              {guestFirstName}
            </a>
          </div>
          <div>Check-in: {checkInDate}</div>
          <div>Check-out: {checkOutDate}</div>
          <EmptySpace height={20} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default RemainderAdminBookingBefore;
