/* eslint-disable import/no-cycle */
import { initialize, reset } from 'redux-form';

import {
  CONFIRM_DUBLICATE_LISTING_CLOSE,
  CONFIRM_DUBLICATE_LISTING_START,
  DECLINE_REASON_MODAL_CLOSE,
  DECLINE_REASON_MODAL_OPEN,
  HOST_DECLINE_MODAL_START,
  HOST_DECLINE_MODAL_SUCCESS,
  HOST_MODAL_START,
  HOST_MODAL_SUCCESS,
  PHOTOS_CONFIRM_DUBLICATE_LISTING_CLOSE,
  PHOTOS_CONFIRM_DUBLICATE_LISTING_START,
  SUBSCRIPTION_MODAL_CLOSE,
  SUBSCRIPTION_MODAL_START,
  VERIFICATION_MODAL_START,
  VERIFICATION_MODAL_SUCCESS,
} from '../../constants';
import { loadAccount } from '../account';

export function openVerifyModal() {
  return (dispatch) => {
    dispatch({
      type: VERIFICATION_MODAL_START,
      verificationData: true,
    });
    dispatch(loadAccount());
  };
}

export function closeVerifyModal() {
  return (dispatch) => {
    dispatch({
      type: VERIFICATION_MODAL_SUCCESS,
      verificationData: false,
    });
    dispatch(loadAccount());
  };
}

export function openConfirmListingModal(initialValues) {
  return (dispatch) => {
    dispatch({
      type: CONFIRM_DUBLICATE_LISTING_START,
      isConfirmation: true,
    });
    dispatch(initialize('DublicateListingModal', initialValues));
  };
}

export function closeConfirmListingModal() {
  return (dispatch) => {
    dispatch({
      type: CONFIRM_DUBLICATE_LISTING_CLOSE,
      isConfirmation: false,
    });
  };
}

export function openPhotoConfirmListingModal() {
  return (dispatch) => {
    dispatch({
      type: PHOTOS_CONFIRM_DUBLICATE_LISTING_START,
      isPhotosConfirm: true,
      isConfirmation: false,
    });
  };
}

export function closePhotoConfirmListingModal() {
  return (dispatch) => {
    dispatch({
      type: PHOTOS_CONFIRM_DUBLICATE_LISTING_CLOSE,
      isConfirmation: false,
    });
  };
}

export function openDeclineModal() {
  return (dispatch) => {
    dispatch({
      type: HOST_DECLINE_MODAL_START,
      declineStatus: true,
      isDeclineStatus: false,
    });
  };
}

export function closeDeclineModal() {
  return (dispatch) => {
    dispatch({
      type: HOST_DECLINE_MODAL_SUCCESS,
      declineStatus: false,
    });
  };
}

export function openDecline() {
  return (dispatch) => {
    dispatch({
      type: HOST_MODAL_START,
      isDeclineStatus: true,
    });
  };
}

export function closeDecline() {
  return (dispatch) => {
    dispatch({
      type: HOST_MODAL_SUCCESS,
      isDeclineStatus: false,
    });
  };
}

export function openSubscriptionModal() {
  return (dispatch) => {
    dispatch({
      type: SUBSCRIPTION_MODAL_START,
      subscriptionStatus: true,
    });
  };
}

export function closeSubscriptionModal() {
  return (dispatch) => {
    dispatch(reset('SubscriptionModal'));
    dispatch({
      type: SUBSCRIPTION_MODAL_CLOSE,
      subscriptionStatus: false,
    });
  };
}

export function openDeclineReasonModal() {
  return (dispatch) => {
    dispatch({
      type: DECLINE_REASON_MODAL_OPEN,
      declineReasonModal: true,
    });
  };
}

export function closeDeclineReasonModal() {
  return (dispatch) => {
    dispatch({
      type: DECLINE_REASON_MODAL_CLOSE,
      declineReasonModal: false,
    });
  };
}
