import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import CurrencyView from '../modules/CurrencyView';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class ConciergeRequestAdmin extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const {
      content: {
        email,
        name,
        date,
        detailedMessage,
        location,
        requestorName,
        requestorProfileId,
      },
    } = this.props;
    const {
      content: {
        totalTravellers,
        totalRooms,
        datesFlexible,
        budget,
        currency,
        startDate,
        endDate,
      },
    } = this.props;

    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    let actionURL = `${url}/users/show/${requestorProfileId}`;
    let dayDifference = 0;

    if (startDate && endDate) {
      const startDateValue = moment(startDate);
      const endDateValue = moment(endDate);
      dayDifference = endDateValue.diff(startDateValue, 'days');
    }

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          <div>
            User {name} ({email}) has requested for the concierge service for
            the dates {date}.
          </div>
          {requestorName && <EmptySpace height={20} />}
          {requestorName && (
            <div>
              Requested user name:{' '}
              <a href={actionURL} style={linkText}>
                {requestorName}
                {email ? ` (${email})` : ''}
              </a>
            </div>
          )}
          {location && <EmptySpace height={20} />}
          {location && <div>Location: {location}</div>}
          {totalTravellers && <EmptySpace height={20} />}
          {totalTravellers && <div>No. of travellers: {totalTravellers}</div>}
          {totalRooms && <EmptySpace height={20} />}
          {totalRooms && <div>No. of rooms: {totalRooms}</div>}
          {budget && <EmptySpace height={20} />}
          {budget && (
            <div>
              {dayDifference >= 30 ? 'Budget per month' : 'Budget'}:{' '}
              <CurrencyView amount={Number(budget)} currency={currency} />
            </div>
          )}
          {detailedMessage && <EmptySpace height={20} />}
          {detailedMessage && <div>Message: {detailedMessage}</div>}
          {datesFlexible && <EmptySpace height={20} />}
          {datesFlexible && <div>Are the dates flexible: {datesFlexible}</div>}

          <EmptySpace height={30} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default ConciergeRequestAdmin;
