import {
  EDIT_LISTING_CANCELLATION_START,
  EDIT_LISTING_CANCELLATION_SUCCESS,
  EDIT_LISTING_CANCELLATION_ERROR,
  GET_EDIT_LISTING_CANCELLATION_START,
  GET_EDIT_LISTING_CANCELLATION_SUCCESS,
} from '../constants';

export default function editListingCancellation(state = {}, action) {
  switch (action.type) {
    case EDIT_LISTING_CANCELLATION_START:
      return {
        ...state,
        loading: true,
      };
    case EDIT_LISTING_CANCELLATION_SUCCESS:
      return {
        ...state,
        loading: false,
        cancellation: action.payload,
      };
    case GET_EDIT_LISTING_CANCELLATION_START:
      return {
        ...state,
        loading: true,
      };
    case GET_EDIT_LISTING_CANCELLATION_SUCCESS:
      return {
        ...state,
        loading: false,
        cancellation: action.payload,
      };
    default:
      return state;
  }
}
