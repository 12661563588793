import React, { Component } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import CurrencyConverter from '../../CurrencyConverter';
import Link from '../../Link';
import Payouts from '../Payouts';
import s from '../Transaction.module.css';

class FutureTransactionItem extends Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    formatMessage: PropTypes.func,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      guestServiceFee: PropTypes.number.isRequired,
      hostServiceFee: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      payoutId: PropTypes.number,
      listData: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      guestData: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
      }).isRequired,
    }),
  };

  render() {
    const { className, data } = this.props;
    const date = data.checkIn
      ? moment(data.checkIn)
          .add(1, 'days')
          .format('DD-MM-YYYY')
      : 'Pending';
    const checkInDate = data.checkIn
      ? moment(data.checkIn).format('MMM DD, YYYY')
      : '';
    const checkOutDate = data.checkOut
      ? moment(data.checkOut).format('MMM DD, YYYY')
      : '';
    const totalAmount =
      Math.ceil(data.total) - Math.ceil(data.hostServiceFee.toFixed(2));
    const securityFee = Number(data.securityFee);

    return (
      <tr>
        <td className={className}>{date}</td>
        <td className={className}>Reservation</td>
        <td className={className}>
          <ul className={s.listLayout}>
            <li>
              {checkInDate} - {checkOutDate}{' '}
              <Link to={`/users/trips/receipt/${data.id}`}>
                {data.confirmationCode}
              </Link>
            </li>
            <li>{data.guestData ? data.guestData.firstName : ''}</li>
            <li>{data.listData ? data.listData.title : ''}</li>
          </ul>
        </td>
        <td className={className}>
          <Payouts
            className={cx(s.formWidth, s.formControlSelect)}
            defaultLabel="Default"
            defaultValue={data.payoutId}
            enableAddPayout
            reservationId={data.id}
            type="change"
          />
        </td>
        <td className={className}>
          <CurrencyConverter
            amount={totalAmount}
            className={s.currencyColor}
            from={data.currency}
          />
        </td>
        <td className={className}>
          <CurrencyConverter
            amount={securityFee}
            className={s.currencyColor}
            from={data.currency}
          />
        </td>
      </tr>
    );
  }
}

export default FutureTransactionItem;
