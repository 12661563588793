import React from 'react';

import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';

import { choseToCurrency } from '../../actions/getCurrencyRates';

import s from './CurrencySwitcher.module.css';

class CurrencySwitcher extends React.Component {
  static propTypes = {
    currencies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        symbol: PropTypes.string.isRequired,
        isEnable: PropTypes.bool.isRequired,
        isBaseCurrency: PropTypes.bool,
      })
    ).isRequired,
    baseCurrency: PropTypes.string.isRequired,
    toCurrency: PropTypes.string,
    choseToCurrency: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { choseToCurrency } = this.props;
    const { value } = event.target;
    choseToCurrency(value);
  }

  render() {
    const { currencies, baseCurrency, toCurrency } = this.props;
    let targetCurrency;
    if (toCurrency) {
      targetCurrency = toCurrency;
    } else {
      targetCurrency = baseCurrency;
    }
    return (
      <FormControl
        className={s.formControlSelect}
        componentClass="select"
        onChange={this.handleChange}
        value={targetCurrency}
      >
        {currencies.map((currency, key) => {
          if (currency.isEnable === true) {
            return (
              <option key={key} value={currency.symbol}>
                {currency.symbol}
              </option>
            );
          }
        })}
      </FormControl>
    );
  }
}

const mapState = (state) => ({
  currencies: state.currency.availableCurrencies,
  baseCurrency: state.currency.base,
  toCurrency: state.currency.to,
});

const mapDispatch = {
  choseToCurrency,
};

export default connect(mapState, mapDispatch)(CurrencySwitcher);
