import React, { Component } from 'react';

import cx from 'classnames';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { Col } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import ListCoverPhoto from '../../ListCoverPhoto';
import Loader from '../../Loader';

import s from './ListDetails.module.css';

const ListingDataQuery = loader('./getListingData.graphql');

class ListDetails extends Component {
  static propTypes = {
    formatMessage: PropTypes.func,
    listId: PropTypes.number,
    getListingData: PropTypes.shape({
      loading: PropTypes.bool,
      UserListing: PropTypes.object,
    }),
  };

  static defaultProps = {
    data: {
      loading: true,
    },
  };

  render() {
    const { error, handleSubmit, submitting, dispatch, id, title } = this.props;
    const { formatMessage } = this.props.intl;
    const {
      getListingData: { loading, UserListing },
      listId,
    } = this.props;

    return (
      <div>
        {loading && (
          <Col lg={12} md={12} sm={12} xs={12}>
            <Loader type="text" />
          </Col>
        )}
        {!loading && UserListing && (
          <div>
            <div className={cx(s.imgContainer)}>
              <ListCoverPhoto
                bgImage
                className={cx(s.imageContent)}
                coverPhoto={UserListing.coverPhoto}
                listPhotos={UserListing.listPhotos}
                photoType="x_small"
              />
            </div>
            <div className={s.textContainer}>
              <div className={cx(s.textBottom, s.listTitleText)}>
                <div className={s.textOverflow}>{UserListing.title}</div>
              </div>
              <div className={cx(s.textBottom, s.subTexContainer)}>
                <div className={s.textOverflow}>
                  {UserListing.city}
                  {', '}
                  {UserListing.state}
                  {', '}
                  {UserListing.countryName}
                </div>
              </div>
              <span className={cx(s.reviewText, s.pullRight)}>
                {/* &nbsp; {UserListing.reviewsCount} {UserListing.reviewsCount === 1 ? formatMessage(messages.review) : formatMessage(messages.reviews)} */}
              </span>
              <span className={cx(s.reviewStar, s.pullRight)}>
                {/* <StarRating value={UserListing.reviewsStarRating} name={'review'} /> */}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state) => ({
  listId: state.modalStatus.listId,
});

const mapDispatch = {};

export default compose(
  injectIntl,

  connect(mapState, mapDispatch),
  graphql(ListingDataQuery, {
    name: 'getListingData',
    options: (props) => ({
      variables: {
        listId: props.listId,
      },
      fetchPolicy: 'network-only',
    }),
  })
)(ListDetails);
