import { toastr } from 'react-redux-toastr';

import { loadAccount } from '../../actions/account';
import { setLoaderComplete, setLoaderStart } from '../../actions/loader/loader';
import {
  emailContentMaleUser,
  resendEmailVerification,
  sendEmailofFemaleUser,
} from '../../actions/manageUserVerification';
import { closeSignupModal } from '../../actions/modalActions';
import { setRuntimeVariable } from '../../actions/runtime';
import { backendUrl, toastrOptions } from '../../config';
import fetch from '../../core/fetch';
import PopulateData from '../../helpers/populateData';

async function submit(values, dispatch) {
  if (values.password !== values.confirmPassword) {
    toastr.error(
      'Password',
      'Confirm Password does not match. Please try again.'
    );
    return;
  }
  if (!values.gender) {
    toastr.error('Gender', 'Please indicate Gender.');
    return false;
  }
  const isMonth =
    values.month !== null &&
    values.month !== undefined &&
    values.month.toString().trim() !== '';
  if (!isMonth || !values.day || !values.year) {
    toastr.error(
      'Date of Birth',
      'Please enter your date of birth to continue.'
    );
    return false;
  }
  if (values.year) {
    const now = new Date();
    const currentYear = now.getFullYear();
    const difference = currentYear - values.year;
    if (difference < 21) {
      toastr.error('Date of Birth', 'Sorry. You must be 21 or older to join.');
      return false;
    }
  }

  if (values.year && values.month && values.day) {
    if (!PopulateData.isValidDate(values.year, values.month, values.day)) {
      toastr.error(
        'Date of Birth',
        'Invaild birthday. Please fill in the correct date.'
      );
      return false;
    }
  }

  if (!values.intend) {
    toastr.error('Intend To', 'Please indicate your intend to.');
    return false;
  }

  dispatch(setLoaderStart('homeLoading'));

  const queries =
    'query ($referralKey: String, $email: String) {         getValidReferralKey(referralKey: $referralKey, email: $email) {           userId           profileId           firstName           lastName           referralKey           uniqueReferralKey           status         }       }';
  /*  For Query Variable */ const variables = {
    referralKey: values.loginReferralKey,
  };
  const respReferral = await fetch(`${backendUrl}/graphql`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query: queries, variables }),
    credentials: 'include',
  });

  const { data: referalData } = await respReferral.json();

  if (
    !referalData ||
    !referalData.getValidReferralKey ||
    referalData.getValidReferralKey.status === 'cancelled' ||
    (!referalData.getValidReferralKey.userId &&
      !referalData.getValidReferralKey.referralKey &&
      !referalData.getValidReferralKey.profileId)
  ) {
    dispatch(setLoaderComplete('homeLoading'));
    toastr.error('Sorry', 'Invalid Referral code.');
    return '';
  }
  // if (!values.phoneNumber) {
  //   toastr.error("Phone Number", "Phone Number is required.");
  // } else {
  //   if (isNaN(values.phoneNumber)) {
  //     toastr.error("Phone Number", "Invalid Phone Number.");
  //   }
  // }

  const query = `query (
    $firstName:String,
    $lastName:String,
    $email: String!,
    $password: String!,
    $dateOfBirth: String,
    $referralKey: String,
    $loginKey: String,
    $gender: String,
    $intend: String,
    $socialMediaUrl: String,
    $linkedinUrl: String,
    $identityAppId: String,
    $identityStatus: String,
    $zipCode: String,
    $phoneNumber: String,
    $countryCode: String,
    $dialCode: String,
    $accept: String,

  ) {
      userRegister (
        firstName:$firstName,
        lastName:$lastName,
        email: $email,
        password: $password,
        dateOfBirth: $dateOfBirth,
        referralKey: $referralKey,
        loginKey: $loginKey,
        gender: $gender,
        intend: $intend,
        socialMediaUrl: $socialMediaUrl,
        linkedinUrl: $linkedinUrl,
        identityAppId: $identityAppId,
        identityStatus: $identityStatus,
        zipCode: $zipCode,
        phoneNumber: $phoneNumber,
        countryCode: $countryCode,
        dialCode: $dialCode,
        accept: $accept,

      ) {
        emailToken
        status
      }
    }`;

  const { year, month, day } = values;
  const dateOfBirth = `${Number(month) + 1}-${year}-${day}`;
  const loginKeyValue = values.loginReferralKey;
  const phoneNumberValue = values.phoneNumber ? values.phoneNumber.trim() : '';
  let kycData;

  // if (!values.accept || values.accept !== '1'){
  //   const resp2 = await fetch(backendUrl + '/addUserData', {
  //     method: 'post',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       accountName: values.firstName,
  //       email: values.email,
  //       firstName: values.firstName,
  //       lastName: values.lastName,
  //       zipCode: values.zipCode,
  //       phoneNumber: phoneNumberValue,
  //     }),
  //     credentials: 'include'
  //   });

  //   kycData = await resp2.json();
  // };

  const params = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    password: values.password,
    dateOfBirth,
    referralKey: values.referralKey,
    loginKey: loginKeyValue,
    gender: values.gender,
    intend: values.intend,
    socialMediaUrl: values.socialMediaUrl,
    linkedinUrl: values.linkedinUrl,
    identityAppId: '',
    identityStatus: '',
    zipCode: values.zipCode,
    phoneNumber: phoneNumberValue,
    countryCode: values.countryCode,
    dialCode: values.dialCode,
    accept: values.accept,
  };

  const resp = await fetch(`${backendUrl}/graphql`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables: params,
    }),
    credentials: 'include',
  });

  const { data } = await resp.json();

  if (data.userRegister.status === 'success') {
    dispatch(closeSignupModal());
    const registerScreen = true;
    const genderUpdated = values.gender;
    let refer;
    await dispatch(loadAccount());
    if (genderUpdated === 'male') {
      refer = 'unsuccessful';
      dispatch(emailContentMaleUser());
    } else {
      dispatch(sendEmailofFemaleUser());
      dispatch(resendEmailVerification());
    }
    dispatch(loadAccount(registerScreen, refer, true));
  } else if (data.userRegister.status === 'email') {
    toastr.error('Sorry', 'That email address is already registered.');
  }
  // else if (data.userRegister.status === "notVerified") {
  //   toastr.success("Sign-up application submitted successfully", "Thank you for signing up to Golightly. You have completed your sign-up process and you will receive an Email when your user account is granted access to the site.");
  //   let content = {
  //     name: values.firstName,
  //     email: values.email
  //   };
  //   sendEmail(values.email, 'signUpPending',content);
  //   dispatch(closeSignupModal());
  //   history.push('/')
  // }
  else if (data.userRegister.status === 'loggedIn') {
    dispatch(loadAccount());
    toastr.error('Sorry', 'You are already logged in.');
  } else if (data.userRegister.status === 'adminLoggedIn') {
    toastr.error(
      'Sorry',
      'You are logged in as admin, you can`t perform this action!'
    );
  } else if (data.userRegister.status === 'invalidReferralEmail') {
    toastr.error(
      'Sorry',
      'Please use the email address where you received your invitation to join.'
    );
  } else if (data.userRegister.status === 'invalidReferral') {
    toastr.error(
      'Sorry',
      'Your invite code has expired. Please contact kat@wegolightly.com for a new one',
      toastrOptions
    );
  } else {
    toastr.error('Sorry', 'Something went wrong. Please try again');
  }
  await dispatch(setLoaderComplete('homeLoading'));
}

export default submit;
