import React, { Component } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Col, FormControl, FormGroup } from 'react-bootstrap';
import * as FontAwesome from 'react-icons/fa';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

// Internal Helpers
import messages from '../../../locale/messages';

import s from './ForgotPasswordForm.module.css';
import submit from './submit';
import validate from './validate';

class ForgotPasswordForm extends Component {
  static propTypes = {
    formatMessage: PropTypes.func,
  };

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
        <FormControl
          {...input}
          className={className}
          placeholder={label}
          type={type}
        />
      </div>
    );
  };

  render() {
    const { error, handleSubmit, submitting, dispatch } = this.props;
    const { formatMessage } = this.props.intl;
    const { openLoginModal, isPass } = this.props;
    return (
      <div className={s.container}>
        <form onSubmit={handleSubmit(submit)}>
          {error && (
            <span className={s.errorMessage}>{formatMessage(error)}</span>
          )}

          <FormGroup className={s.formGroup}>
            <p>
              <FormattedMessage {...messages.forgotPasswordInfo} />
            </p>
          </FormGroup>

          <FormGroup className={s.formGroup}>
            <Field
              className={s.formControlInput}
              component={this.renderFormControl}
              label={formatMessage(messages.email)}
              name="email"
              type="text"
            />
          </FormGroup>

          <hr className={s.horizontalLineThrough} />
          <div className={cx(s.formGroup, s.formSection)}>
            <Col
              className={cx(s.noPadding, s.textAlignLeft)}
              lg={7}
              md={7}
              sm={7}
              xs={12}
            >
              <span>
                <FontAwesome.FaChevronLeft className={s.leftICon} />
              </span>
              <a
                className={cx(s.modalCaptionLink, s.modalCaptionLinkLarge)}
                href="#"
                onClick={openLoginModal}
              >
                <FormattedMessage {...messages.backToLogin} />
              </a>
            </Col>
            <Col
              className={cx(s.noPadding, s.textAlignRight, s.smSpace)}
              lg={5}
              md={5}
              sm={5}
              xs={12}
            >
              <Button
                className={cx(s.button, s.btnPrimary)}
                disabled={submitting}
                type="submit"
              >
                <FormattedMessage {...messages.sendLink} />
              </Button>
            </Col>
          </div>
        </form>
      </div>
    );
  }
}

const forgotPasswordForm = reduxForm({
  form: 'ForgotPasswordForm', // a unique name for this form
  validate,
  destroyOnUnmount: false,
})(ForgotPasswordForm);

export default injectIntl(forgotPasswordForm);
