import {
  BLOGIMAGE_UPLOAD_ERROR,
  BLOGIMAGE_UPLOAD_LOADER_START,
  BLOGIMAGE_UPLOAD_SUCCESS,
  REMOVE_BLOGIMAGE_ERROR,
  REMOVE_BLOGIMAGE_SUCCESS,
} from '../../constants';

export default function blogDetails(state = {}, action) {
  switch (action.type) {
    case BLOGIMAGE_UPLOAD_LOADER_START:
      return {
        ...state,
        blogImageUploaderLoading:
          action.payload && action.payload.blogImageUploaderLoading,
      };

    case BLOGIMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        blogImageUploaderLoading:
          action.payload && action.payload.blogImageUploaderLoading,
      };

    case BLOGIMAGE_UPLOAD_ERROR:
      return {
        ...state,
        blogImageUploaderLoading:
          action.payload && action.payload.blogImageUploaderLoading,
      };

    case REMOVE_BLOGIMAGE_SUCCESS:
      return {
        ...state,
        blogImageUploaderLoading:
          action.payload && action.payload.blogImageUploaderLoading,
      };

    case REMOVE_BLOGIMAGE_ERROR:
      return {
        ...state,
        blogImageUploaderLoading:
          action.payload && action.payload.blogImageUploaderLoading,
      };

    default:
      return state;
  }
}
