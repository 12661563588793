import {
  CLOSE_EDIT_POST_MODAL,
  CLOSE_POST_MODAL,
  OPEN_EDIT_POST_MODAL,
  OPEN_POST_MODAL,
} from '../constants';

export default function blogPostModal(state = {}, action) {
  switch (action.type) {
    case OPEN_POST_MODAL:
      return {
        ...state,
        isPostModalOpen: action.isPostModalOpen,
      };

    case CLOSE_POST_MODAL:
      return {
        ...state,
        isPostModalOpen: action.isPostModalOpen,
      };

    case OPEN_EDIT_POST_MODAL:
      return {
        ...state,
        isPostModalOpen: action.isPostModalOpen,
        data: action.data,
        id: action.id,
      };

    case CLOSE_EDIT_POST_MODAL:
      return {
        ...state,
        isPostModalOpen: action.isPostModalOpen,
      };

    default:
      return state;
  }
}
