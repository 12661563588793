import React from 'react';

import ReduxToastr from 'react-redux-toastr';

class Toaster extends React.Component {
  static propTypes = {};

  render() {
    return (
      <ReduxToastr
        newestOnTop={false}
        position="top-right"
        preventDuplicates
        timeOut={7000}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
      />
    );
  }
}

export default Toaster;
