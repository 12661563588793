import React from 'react';

import moment from 'moment';

import { url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class BookingButtonMail extends React.Component {
  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const {
      content: {
        guestFirstName,
        guestProfileId,
        guestEmail,
        listId,
        listTitle,
        startDate,
        endDate,
        guests,
      },
    } = this.props;

    let checkIn;
    let checkOut;

    if (startDate) checkIn = moment(startDate).format('ddd, MMM Do, YYYY');
    if (endDate) checkOut = moment(endDate).format('ddd, MMM Do, YYYY');

    let guestProfile = `${url}/users/show/${guestProfileId}`;
    let listURL = `${url}/rooms/${listId}`;

    let isBookingDetails = startDate && endDate;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          <div>
            <a href={guestProfile} style={linkText}>
              {guestFirstName}
              {guestEmail ? ` (${guestEmail})` : ''}
            </a>{' '}
            has clicked the book button on the listing{' '}
            <span>
              <a href={listURL}>{listTitle}</a>
            </span>
          </div>
          <EmptySpace height={15} />
          {isBookingDetails && (
            <div>
              <span>Booking details:</span>
              <EmptySpace height={10} />
              {startDate && endDate && (
                <span>
                  Reservation dates: {checkIn} to {checkOut}
                </span>
              )}
              <EmptySpace height={10} />
              {guests && <span>Guest: {guests}</span>}
              <EmptySpace height={10} />
              <span>
                Listing name: <a href={listURL}>{listTitle}</a>
              </span>
            </div>
          )}
          <EmptySpace height={20} />
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default BookingButtonMail;
