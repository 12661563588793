import {
  LISTING_OVERRIDE_START,
  LISTING_OVERRIDE_SUCCESS,
  LISTING_OVERRIDE_ERROR,
} from '../constants';

export default function listingOverrides(state = {}, action) {
  switch (action.type) {
    case LISTING_OVERRIDE_START:
      return {
        ...state,
        loading: true,
      };
    case LISTING_OVERRIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
      };
    case LISTING_OVERRIDE_ERROR:
      return {
        ...state,
        loading: false,
        status: action.payload,
      };
    default:
      return state;
  }
}
