import {
  LISTING_UPDATE_TYPE_START,
  LISTING_UPDATE_TYPE_SUCCESS,
  LISTING_UPDATE_TYPE_ERROR,
} from '../constants';

export default function listingUpdateType(state = {}, action) {
  switch (action.type) {
    case LISTING_UPDATE_TYPE_START:
      return {
        ...state,
        loading: true,
      };
    case LISTING_UPDATE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
      };
    case LISTING_UPDATE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        status: action.payload,
      };
    default:
      return state;
  }
}
