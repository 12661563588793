import React, { Component } from 'react';

import moment from 'moment';
import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class CancelledByHostAdmin extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      listTitle: PropTypes.string.isRequired,
      refundToGuest: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    content: {
      refundToGuest: 0,
    },
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const {
      content: {
        guestName,
        hostName,
        confirmationCode,
        checkIn,
        listTitle,
        refundToGuest,
        currency,
        message,
        hostProfileId,
        hostEmail,
      },
    } = this.props;
    const checkInDate = checkIn
      ? moment(checkIn).format('ddd, MMM Do, YYYY')
      : '';
    const momentStartDate = moment(checkIn).startOf('day');
    const today = moment();
    const interval = momentStartDate.diff(today, 'days');
    let isPastDay = false;
    if (interval < 0) {
      isPastDay = true;
    }
    const guestProfileURL = `${url}/users/show/${hostProfileId}`;
    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };
    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi Admin,</div>
                  <EmptySpace height={20} />
                  <div>
                    <a href={guestProfileURL} style={linkText}>
                      {hostName}
                      {hostEmail ? ` (${hostEmail})` : ''}
                    </a>{' '}
                    has cancelled your reservation {listTitle}{' '}
                    {isPastDay ? 'started' : 'starting'} on {checkInDate}.
                  </div>
                  <EmptySpace height={20} />
                  {message && (
                    <div>
                      <span>Message: {message}</span>
                      <EmptySpace height={20} />
                    </div>
                  )}
                  <EmptySpace height={40} />
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default CancelledByHostAdmin;
