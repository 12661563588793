import {
  ADD_PAYOUT_ERROR,
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  GET_PAYOUT_DETAILS_SUCCESS,
  GET_PAYOUT_ERROR,
  GET_PAYOUT_START,
  GET_PAYOUT_SUCCESS,
} from '../constants';

export default function payout(state = {}, action) {
  switch (action.type) {
    case GET_PAYOUT_DETAILS_SUCCESS:
      return {
        ...state,
        payoutDetails: action.payload.payout,
      };

    case ADD_PAYOUT_START:
      return {
        ...state,
        addPayoutLoading: action.payload.addPayoutLoading,
      };

    case ADD_PAYOUT_SUCCESS:
      return {
        ...state,
        addPayoutLoading: action.payload.addPayoutLoading,
      };

    case ADD_PAYOUT_ERROR:
      return {
        ...state,
        addPayoutLoading: action.payload.addPayoutLoading,
      };
    case GET_PAYOUT_START:
      return {
        ...state,
        getPayoutLoading: action.payload.getPayoutLoading,
      };

    case GET_PAYOUT_SUCCESS:
      return {
        ...state,
        data: action.payload.payoutData,
        getPayoutLoading: action.payload.getPayoutLoading,
      };

    case GET_PAYOUT_ERROR:
      return {
        ...state,
        getPayoutLoading: action.payload.getPayoutLoading,
      };

    default:
      return state;
  }
}
