import {
  GET_HOSTFULLY_BOOKINGS,
  GET_HOSTFULLY_BOOKINGS_BY_DATES_START,
  GET_HOSTFULLY_BOOKINGS_BY_DATES_SUCCESS,
} from '../../constants';

const initialState = {
  loading: false,
  bookings: [],
};

export default function hostfully(state = initialState, action) {
  switch (action.type) {
    case GET_HOSTFULLY_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
        loading: false,
      };
    case GET_HOSTFULLY_BOOKINGS_BY_DATES_START:
      return {
        ...state,
        loading: true,
      };
    case GET_HOSTFULLY_BOOKINGS_BY_DATES_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
