import { INDUSTRY_SUCCESS } from '../constants';

export default function industry(state = {}, action) {
  switch (action.type) {
    case INDUSTRY_SUCCESS:
      return {
        ...state,
        industry: action.payload.industry,
      };

    default:
      return state;
  }
}
