import {
  GET_HOSTFULLY_PRICING_RULES_START,
  GET_HOSTFULLY_PRICING_RULES_SUCCESS,
} from '../constants';

const initialState = {
  loading: false,
  pricingRules: null,
};

export default function hostFullyData(state = initialState, action) {
  switch (action.type) {
    case GET_HOSTFULLY_PRICING_RULES_START:
      return {
        ...state,
        loading: true,
        pricingRules: null,
      };
    case GET_HOSTFULLY_PRICING_RULES_SUCCESS:
      return {
        ...state,
        loading: false,
        pricingRules: action.payload,
      };
    default:
      return state;
  }
}
