import React from 'react';

import { Img } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class DiamondSucess extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      verificationStatus: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const linkColor = {
      color: '#77d1da',
    };

    const daimondBg = {
      backgroundColor: '#fff',
      padding: '15px',
      textAlign: 'center',
    };

    const daimondImageSection = {
      display: 'inline-block',
      verticalAlign: 'middle',
    };

    const daimondImage = {
      transform: 'rotate(-54deg)',
      width: '100%',
      maxWidth: '80px',
      display: 'inline-block',
      verticalAlign: 'middle',
    };

    const daimondText = {
      color: '#77d1da',
      fontSize: '30px',
      marginLeft: '8px',
      verticalAlign: 'middle',
    };

    const {
      content: { firstName },
    } = this.props;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div style={daimondBg}>
          <span style={daimondImageSection}>
            <Img
              alt="diamond"
              src={`${url}/email/daimond.png`}
              style={daimondImage}
            />
          </span>
          <span style={daimondText}>Congrats you Diamond!</span>
        </div>
        <Body textStyle={textStyle}>
          <div>Hi {firstName},</div>
          <EmptySpace height={20} />
          We're so excited to welcome you as a Golightly Diamond Member!
          <EmptySpace height={20} />
          With{' '}
          <a href="https://wegolightly.com/membership-info" style={linkColor}>
            Diamond Membership
          </a>{' '}
          you'll find benefits and savings such as:
          <ul>
            <li>No Booking, Listing or Hosting Fees!</li>
            <li>
              Earn up to $250 in Travel Credit with Extra Invites & Credit Per
              Friend
            </li>
            <li>A Special Welcome Gift</li>
            <li>Early Access & 20% Discount on all GoSolo Trips</li>
            <li>Exclusive Golightly Concierge Use</li>
            <li>Plus Partner Perks and Events</li>
          </ul>
          In addition, you'll have 24/7 access to our Customer Service:
          <a href="mailto:kat@wegolightly.com" style={linkColor}>
            kat@wegolightly.com
          </a>
          <EmptySpace height={20} />
          Thanks again for being a Golightly Diamond Member and please reach out
          with any questions, suggestions or travel needs.
          <EmptySpace height={30} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default DiamondSucess;
