import React, { Component } from 'react';

import moment from 'moment';
import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { backendUrl, sitename, url } from '../../../config';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter';
import { phoneFormat } from '../../../helpers/phoneFormat';
import Layout from '../layouts/Layout';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class BookingConfirmationGuest extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      listTitle: PropTypes.string.isRequired,
      listCity: PropTypes.string.isRequired,
      threadId: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const textCenter = {
      textAlign: 'center',
    };

    const listPic = {
      borderRadius: '999px',
      margin: '0',
      padding: '0',
      lineHeight: '150%',
      borderSpacing: '0',
      width: '125px',
    };

    const {
      content: {
        guestName,
        hostName,
        listTitle,
        listCity,
        threadId,
        guestPhoneNumber,
        guestDialCode,
      },
    } = this.props;
    const {
      content: {
        hostPhoneNumber,
        hostDialCode,
        listId,
        checkIn,
        checkOut,
        hostListPic,
      },
    } = this.props;
    const {
      content: { listStreet, listState, listCountry, listZipcode, photoType },
    } = this.props;
    const contactURL = `${url}/message/${threadId}/guest`;
    let imageURL;
    const checkInDate = checkIn
      ? moment(checkIn).format('ddd, MMM Do, YYYY')
      : '';
    const checkOutDate = checkOut
      ? moment(checkOut).format('ddd, MMM Do, YYYY')
      : '';
    const listURL = `${url}/rooms/${listId}`;
    if (hostListPic) {
      imageURL =
        photoType === 'Golightly'
          ? hostListPic
          : `${backendUrl}/upload/x_medium_${hostListPic}`;
    }
    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi {guestName},</div>
                  <EmptySpace height={20} />
                  <div>
                    Pack your bags - you are going to{' '}
                    {capitalizeFirstLetter(listCity)}.
                  </div>
                  <EmptySpace height={20} />
                  <a href={listURL} style={linkText}>
                    <div style={textCenter}>
                      {hostListPic && (
                        <img height={125} src={imageURL} style={listPic} />
                      )}
                      <div style={textCenter}>{listTitle}</div>
                    </div>
                  </a>
                  <EmptySpace height={20} />
                  <div>
                    {hostName} has confirmed your request at {listTitle}. Please
                    review the details of your trip and{' '}
                    <a href={contactURL} style={linkText}>
                      contact your host
                    </a>{' '}
                    to coordinate check-in time and key exchange.
                  </div>
                  {/* <EmptySpace height={20} />
									{
										hostPhoneNumber && <div>Host phone number: {phoneFormat(hostDialCode, hostPhoneNumber)}
										</div>
									} */}
                  {
                    // hostPhoneNumber && <div>Host phone number: {hostDialCode}{' '}{hostPhoneNumber} </div>
                  }
                  {/* {
										hostPhoneNumber && <EmptySpace height={20} />
									} */}
                  {(listCity || listCountry || listState || listStreet) && (
                    <div>
                      Property address: {listStreet}
                      {listStreet && <span>{', '}</span>}
                      {listCity}
                      {listCity && <span>{', '}</span>}
                      {listState}
                      {listState && <span>{', '}</span>}
                      {listCountry}
                      {listCountry && <span>{', '}</span>}
                      {listZipcode}
                    </div>
                  )}
                  {(listCity || listCountry || listState || listStreet) && (
                    <EmptySpace height={20} />
                  )}
                  <EmptySpace height={20} />
                  {checkInDate && checkOutDate && (
                    <div>
                      Date: {checkInDate} to {checkOutDate}
                    </div>
                  )}
                  <EmptySpace height={20} />
                  {hostPhoneNumber && hostDialCode && (
                    <div>
                      Host phone number:{' '}
                      {phoneFormat(hostDialCode, hostPhoneNumber)}
                    </div>
                  )}
                  {hostPhoneNumber && hostDialCode && (
                    <EmptySpace height={20} />
                  )}
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default BookingConfirmationGuest;
