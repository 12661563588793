import React from 'react';

import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class ClaimBond extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      profileId: PropTypes.number,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const {
      content: {
        listTitle,
        hostName,
        guestName,
        city,
        zipcode,
        reservationId,
        reason,
        hostProfileId,
        hostEmail,
      },
    } = this.props;
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    let actionURL = `${url}/users/show/${hostProfileId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          <div>
            <a href={actionURL} style={linkText}>
              {hostName}
              {hostEmail ? ` (${hostEmail})` : ''}
            </a>{' '}
            has claimed security deposit for reservation ReservationId:{' '}
            {Number(reservationId) + 1000} on {listTitle} in {city} {zipcode}
          </div>
          <EmptySpace height={30} />
          <div>Host Message: {reason}</div>
          <EmptySpace height={30} />

          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default ClaimBond;
