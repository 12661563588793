import moment from 'moment';
import queryString from 'query-string';

import history from '../core/history';

export function formatURL(data) {
  let convertData = null;
  if (data) {
    convertData = data;
    convertData = convertData.toLowerCase();
    convertData = convertData.replace(new RegExp(', ', 'g'), '--');
    convertData = convertData.replace(new RegExp(' ', 'g'), '-');
  }

  return convertData;
}

export function specialCharacter(data) {
  let convertData = null;
  if (data) {
    convertData = data;
    convertData = convertData.toLowerCase();
    convertData = convertData.replace(new RegExp(', ', 'g'), 'NoResults');
    convertData = convertData.replace(new RegExp(' ', 'g'), 'NoResults');
    convertData = convertData.replace(new RegExp('/', 'g'), 'NoResults');
    convertData = convertData.replace(new RegExp('#', 'g'), 'NoResults');
    convertData = convertData.replace(new RegExp('%', 'g'), 'NoResults');
    convertData = convertData.replace(/\?/g, '-');
    convertData = convertData.replace(/\\/g, '-');
  }
  return convertData;
}

export function convertUpperCase(requestData) {
  let data;
  const convertData = [];
  if (requestData) {
    data = requestData.split(' ');
    for (let x = 0; x < data.length; x += 1) {
      convertData.push(data[x].charAt(0).toUpperCase() + data[x].slice(1));
    }

    return convertData.join(' ');
  }
  return null;
}

export function convertCountryCode(requestData) {
  let data;
  const convertData = [];
  if (requestData) {
    data = requestData.split(', ');
    for (let x = 0; x < data.length; x += 1) {
      if (data[x].toLowerCase() === 'uk') {
        convertData.push(data[x].toUpperCase());
      } else if (x === data.length - 1) {
        convertData.push(data[x]);
      } else {
        convertData.push(`${data[x]}, `);
      }
    }

    return convertData.join('');
  }
  return null;
}

export function resetURL(data) {
  let convertData = null;
  if (data) {
    convertData = data;
    convertData = convertData.replace(new RegExp('--', 'g'), ', ');
    convertData = convertData.replace(new RegExp('-', 'g'), ' ');
    convertData = convertUpperCase(convertData);
    if (convertData.indexOf(', ') >= 0) {
      convertData = convertCountryCode(convertData);
    }
  }

  return convertData;
}

export function formatShortNumbers(number) {
  if (Math.abs(number) < 1.0) {
    const e = parseInt(number.toString().split('e-')[1]);
    if (e) {
      const newNumber = number * 10 ** (e - 1);
      return `0.${new Array(e).join('0')}${newNumber.toString().substring(2)}`;
    }
  } else {
    let e = parseInt(number.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      const newNumber = number / 10 ** e;
      return newNumber + new Array(e + 1).join('0');
    }
  }

  return number;
}

export function prettifyNumber(value) {
  const thousand = 1000;
  const million = 1000000;
  const billion = 1000000000;
  const trillion = 1000000000000;
  const number = formatShortNumbers(value);

  if (number < thousand) {
    return String(number);
  }

  if (number >= thousand && number <= 1000000) {
    return `${Math.round(number / thousand)}k`;
  }

  if (number >= million && number <= billion) {
    return `${Math.round(number / million)}M`;
  }

  if (number >= billion && number <= trillion) {
    return `${Math.round(number / billion)}B`;
  }

  return `${Math.round(number / trillion)}T`;
}

export function formatSearchURL(personalizedValue) {
  const defaultQuery = history && history.location && history.location.search;
  // defaultQuery = defaultQuery.pathname && defaultQuery.pathname.split('refinement_paths=')[1];
  // if (history) defaultQuery = history && history.location && history.location.search
  const startDate =
    personalizedValue.startDate &&
    moment(personalizedValue.startDate).format('YYYY-MM-DD');
  const endDate =
    personalizedValue.endDate &&
    moment(personalizedValue.endDate).format('YYYY-MM-DD');
  const defaultParams = queryString.parse(defaultQuery, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
  });
  defaultParams.bookingType = personalizedValue.bookingType || null;
  defaultParams.isLongerStay = personalizedValue.isLongerStay ? true : null;
  defaultParams.isExclusive = personalizedValue.isExclusive ? true : null;
  defaultParams.roomType =
    personalizedValue.roomType && personalizedValue.roomType.length > 0
      ? personalizedValue.roomType
      : null;
  defaultParams.tripType =
    personalizedValue.tripType && personalizedValue.tripType.length > 0
      ? personalizedValue.tripType
      : null;
  defaultParams.businessAmenities =
    personalizedValue.businessAmenities &&
    personalizedValue.businessAmenities.length > 0
      ? personalizedValue.businessAmenities
      : null;
  defaultParams.familyFriendlyAmenities =
    personalizedValue.familyFriendlyAmenities &&
    personalizedValue.familyFriendlyAmenities.length > 0
      ? personalizedValue.familyFriendlyAmenities
      : null;
  defaultParams.uniqueAmenities =
    personalizedValue.uniqueAmenities &&
    personalizedValue.uniqueAmenities.length > 0
      ? personalizedValue.uniqueAmenities
      : null;
  defaultParams.houseRules =
    personalizedValue.houseRules && personalizedValue.houseRules.length > 0
      ? personalizedValue.houseRules
      : null;
  defaultParams.amenities =
    personalizedValue.amenities && personalizedValue.amenities.length > 0
      ? personalizedValue.amenities
      : null;
  defaultParams.spaces =
    personalizedValue.spaces && personalizedValue.spaces.length > 0
      ? personalizedValue.spaces
      : null;
  defaultParams.priceRange =
    personalizedValue.priceRange && personalizedValue.priceRange.length > 0
      ? personalizedValue.priceRange
      : null;
  // defaultParams.priceRangeLabel = (personalizedValue.priceRangeLabel && personalizedValue.priceRangeLabel.length > 0) ? personalizedValue.priceRangeLabel : null;
  defaultParams.startDate = startDate || null;
  defaultParams.endDate = endDate || null;
  defaultParams.personCapacity = +personalizedValue.personCapacity
    ? personalizedValue.personCapacity
    : null;
  defaultParams.womenCount = +personalizedValue.womenCount
    ? personalizedValue.womenCount
    : null;
  defaultParams.menCount = +personalizedValue.menCount
    ? personalizedValue.menCount
    : null;
  defaultParams.childCount = +personalizedValue.childCount
    ? personalizedValue.childCount
    : null;
  defaultParams.industry = personalizedValue.industry;
  defaultParams.universityId = +personalizedValue.universityId
    ? personalizedValue.universityId
    : null;
  defaultParams.university = personalizedValue.university;
  defaultParams.beds = +personalizedValue.beds ? personalizedValue.beds : null;
  defaultParams.bedrooms = +personalizedValue.bedrooms
    ? personalizedValue.bedrooms
    : null;
  defaultParams.bathrooms = +personalizedValue.bathrooms
    ? personalizedValue.bathrooms
    : null;
  defaultParams.houseType =
    personalizedValue.houseType && personalizedValue.houseType.length > 0
      ? personalizedValue.houseType
      : null;
  defaultParams.priceSortOrder = personalizedValue.priceSortOrder || null;
  defaultParams.womenCount = +personalizedValue.womenCount
    ? personalizedValue.womenCount
    : null;
  defaultParams.otherCount = +personalizedValue.otherCount
    ? personalizedValue.otherCount
    : null;
  history.push(
    `/s/refinement_paths?${queryString.stringify(defaultParams, {
      arrayFormat: 'separator',
      arrayFormatSeparator: '|',
      skipNull: true,
    })}`
  );
}

export function formatReferURL(data) {
  let refer = data || null;
  if (!refer) return refer;
  refer = refer.replace(/\?/gi, '---');
  refer = refer.replace(/&/gi, '--');
  refer = refer.replace(/=/gi, '----');
  return refer;
}

export function resetFormatReferURL(data) {
  let refer = data || null;
  if (!refer) return refer;
  refer = refer.replace(/----/gi, '=');
  refer = refer.replace(/---/gi, '?');
  refer = refer.replace(/--/gi, '&');
  return refer;
}
