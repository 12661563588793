import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose, gql, graphql } from 'react-apollo';
import { FormControl } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { getStripeStates } from '../../actions/getStripeStates';
import messages from '../../locale/messages';

class CountryList extends Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      getCountries: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          countryCode: PropTypes.string.isRequired,
          countryName: PropTypes.string.isRequired,
          isEnable: PropTypes.bool.isRequired,
        }).isRequired
      ),
    }).isRequired,
    isEmptyFirst: PropTypes.bool,
    formatMessage: PropTypes.func,
  };

  static defaultProps = {
    data: {
      getCountries: [],
    },
    isEmptyFirst: false,
    dialCode: false,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const { selectedCountry } = this.props;
    const { getStripeStates } = this.props;
    const {
      data: { loading, getCountries },
      dialCode,
      getSelected,
      change,
      formName,
    } = this.props;
    let selectedItem = null;

    if (!loading && getCountries) {
      if (dialCode) {
        selectedItem = getCountries.find((o) => o.dialCode === selectedCountry);
      } else {
        selectedItem = getCountries.find(
          (o) => o.countryCode === selectedCountry
        );
      }

      if (
        selectedItem &&
        (selectedItem.id === 229 ||
          selectedItem.id === 13 ||
          selectedItem.id === 242)
      ) {
        getStripeStates(selectedItem.id);
        await change('PayoutForm', 'isStateEnable', true);
      }
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedCountry } = nextProps;
    const { getStripeStates } = nextProps;
    const {
      data: { loading, getCountries },
      dialCode,
      getSelected,
      change,
      formName,
    } = nextProps;
    let selectedItem = null;

    if (!loading && getCountries) {
      if (dialCode) {
        selectedItem = getCountries.find((o) => o.dialCode === selectedCountry);
      } else {
        selectedItem = getCountries.find(
          (o) => o.countryCode === selectedCountry
        );
      }
      // await change('PayoutForm', 'countryName', selectedItem.countryName);

      const isCountryName = selectedItem && selectedItem.countryName;
      await change('PayoutForm', 'countryName', isCountryName);
      if (
        selectedItem &&
        (selectedItem.id === 229 ||
          selectedItem.id === 13 ||
          selectedItem.id === 242)
      ) {
        getStripeStates(selectedItem.id);
        await change('PayoutForm', 'isStateEnable', true);
      } else {
        await change('PayoutForm', 'isStateEnable', false);
      }
    }
  }

  async handleChange(e, input) {
    const {
      data: { loading, getCountries },
      dialCode,
      getSelected,
      change,
      formName,
    } = this.props;
    let selectedItem = null;
    const currentValue = e.target.value;
    if (!loading && getCountries) {
      if (dialCode) {
        selectedItem = getCountries.find((o) => o.dialCode === currentValue);
      } else {
        selectedItem = getCountries.find((o) => o.countryCode === currentValue);
      }
    }

    const isCountryName = selectedItem && selectedItem.countryName;
    const isCountryCode = selectedItem && selectedItem.countryCode;
    await change('EditProfileForm', 'countryName', isCountryName);

    // await change('EditProfileForm', 'countryName', selectedItem.countryName);

    if (getSelected) {
      getSelected(e, selectedItem);
      // await change(formName, 'phoneCountryCode', selectedItem.countryCode);
      await change(formName, 'phoneCountryCode', isCountryCode);
    }
    return e;
  }

  render() {
    const {
      data: { loading, getCountries },
      className,
      input,
      isEmptyFirst,
      dialCode,
      onChange,
      change,
    } = this.props;
    const { payoutCountry } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <FormControl
          className={className}
          componentClass="select"
          {...input}
          onChange={(e) => {
            input.onChange(e);
            this.handleChange(e, input);
          }}
        >
          {loading && <option>{formatMessage(messages.country)}</option>}
          {/*
                    {
                        !loading && isEmptyFirst && <option value="">{formatMessage(messages.chooseCountry)}</option>
                    } */}

          {!loading && (
            <option disabled value="">
              {formatMessage(messages.chooseCountry)}
            </option>
          )}

          {!loading &&
            getCountries &&
            getCountries.length > 0 &&
            !dialCode &&
            !payoutCountry &&
            getCountries.map((item) => {
              return (
                <option key={item.id} value={item.countryCode}>
                  {item.countryName}
                </option>
              );
            })}
          {!loading &&
            getCountries &&
            getCountries.length > 0 &&
            dialCode &&
            getCountries.map((item) => {
              return (
                <option key={item.id} value={item.dialCode}>
                  {item.countryName}
                </option>
              );
            })}
          {!loading &&
            getCountries &&
            getCountries.length > 0 &&
            !dialCode &&
            payoutCountry &&
            getCountries.map((item) => {
              let countryName = item.countryCode;
              if (countryName === 'UK') countryName = 'GB';
              if (countryName === 'UAE') countryName = 'AE';
              return (
                <option key={item.id} value={countryName}>
                  {item.countryName}
                </option>
              );
            })}
        </FormControl>
      </div>
    );
  }
}

const mapState = (state) => ({});

const mapDispatch = {
  change,
  getStripeStates,
};

export default compose(
  injectIntl,
  connect(mapState, mapDispatch),
  graphql(
    gql`
      query getCountries {
        getCountries {
          id
          countryCode
          countryName
          isEnable
          dialCode
        }
      }
    `,
    { options: { ssr: false } }
  )
)(CountryList);
