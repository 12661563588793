import {
  BLOGIMAGE_UPLOAD_ERROR,
  BLOGIMAGE_UPLOAD_START,
  BLOGIMAGE_UPLOAD_SUCCESS,
  EVENT_CONFIRMATION_MODAL_CLOSE,
  EVENT_CONFIRMATION_MODAL_OPEN,
  GET_ALL_EVENT_ERROR,
  GET_ALL_EVENT_START,
  GET_ALL_EVENT_SUCCESS,
  GET_ALL_TAGS,
  GET_TITLE_BLOG_ERROR,
  GET_TITLE_BLOG_SUCCESS,
  GET_USER_NOTIFICATON_ERROR,
  GET_USER_NOTIFICATON_START,
  GET_USER_NOTIFICATON_SUCCESS,
} from '../constants';

export default function blogContentExplore(state = {}, action) {
  switch (action.type) {
    case BLOGIMAGE_UPLOAD_START:
      return {
        ...state,
      };

    case BLOGIMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
      };

    case BLOGIMAGE_UPLOAD_ERROR:
      return {
        ...state,
      };

    case GET_ALL_EVENT_START:
      return {
        ...state,
        allEvents: [],
        loading: true,
      };

    case GET_ALL_EVENT_SUCCESS:
      return {
        ...state,
        allEvents: action.data || [],
        loading: false,
      };

    case GET_ALL_EVENT_ERROR:
      return {
        ...state,
        allEvents: action.payload && action.payload.error,
        loading: false,
      };

    case EVENT_CONFIRMATION_MODAL_OPEN:
      return {
        ...state,
        eventConfirmationData: {
          isOpen: true,
          data: action.data || {},
        },
      };

    case EVENT_CONFIRMATION_MODAL_CLOSE:
      return {
        ...state,
        eventConfirmationData: {
          isOpen: false,
          data: action.data || {},
        },
      };

    case GET_ALL_TAGS:
      return {
        ...state,
        tags: action.tags,
      };

    case GET_TITLE_BLOG_SUCCESS:
      return {
        ...state,
        recentBlogTitles: action.recentBlogTitles,
      };

    case GET_TITLE_BLOG_ERROR:
      return {
        ...state,
        recentBlogTitles: action.recentBlogTitles,
      };

    case GET_USER_NOTIFICATON_START:
      return {
        ...state,
        notification: {
          loading: true,
        },
      };

    case GET_USER_NOTIFICATON_SUCCESS:
      return {
        ...state,
        notification: {
          loading: false,
          data: action.payload,
        },
      };

    case GET_USER_NOTIFICATON_ERROR:
      return {
        ...state,
        notification: {
          loading: false,
          data: null,
        },
      };

    default:
      return state;
  }
}
