import React, { Component } from 'react';

import cx from 'classnames';
import { PropTypes } from 'prop-types';
import MDSpinner from 'react-md-spinner';

import s from './Loader.module.css';

class CircleLoader extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    show: false,
  };

  render() {
    const { show, fullSpinnerColor, pdfLoader } = this.props;

    if (!show) {
      return <div />;
    }

    return (
      <div className={cx(s.textCenter, pdfLoader)}>
        <MDSpinner singleColor={fullSpinnerColor || '#77d1da'} size={48} />
      </div>
    );
  }
}

export default CircleLoader;
