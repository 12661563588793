import React, { Component } from 'react';

import moment from 'moment';
import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class InquiryDeclineAdmin extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const linkText = {
      color: '#77d1da',
      fontSize: '18px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const {
      content: {
        receiverName,
        senderName,
        title,
        listId,
        startDate,
        endDate,
        senderProfileId,
        receiverProfileId,
        message,
        userEmail,
        hostEmail,
      },
    } = this.props;
    const checkInDate = startDate
      ? moment(startDate).format('ddd, MMM Do, YYYY')
      : '';
    const checkInEndDate = endDate
      ? moment(endDate).format('ddd, MMM Do, YYYY')
      : '';
    const listTitle = `${url}/rooms/${listId}`;
    const guestProfileURL = `${url}/users/show/${senderProfileId}`;
    const hostProfileURL = `${url}/users/show/${receiverProfileId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi Admin,</div>
                  <EmptySpace height={20} />
                  <div>
                    The inquiry request from{' '}
                    <a href={guestProfileURL} style={linkText}>
                      {receiverName}
                      {hostEmail ? ` (${hostEmail})` : ''}
                    </a>{' '}
                    to{' '}
                    <a href={hostProfileURL} style={linkText}>
                      {senderName}
                      {userEmail ? ` (${userEmail})` : ''}
                    </a>{' '}
                    for the listing{' '}
                    <a href={listTitle} style={linkText}>
                      {title}
                    </a>{' '}
                    from {checkInDate} to {checkInEndDate} has been declined.
                  </div>
                  <EmptySpace height={20} />
                  {message && <div>Message: {message}</div>}
                  <EmptySpace height={40} />
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default InquiryDeclineAdmin;
