import moment from 'moment';

export default {
  generateData(start, end, order) {
    const data = [];
    if (order === 'desc') {
      for (let i = end; i >= start; i -= 1) {
        data.push(i);
      }
    } else {
      for (let i = start; i <= end; i += 1) {
        data.push(i);
      }
    }

    return data;
  },

  isValidDate(year, month, day) {
    return moment({ year, month, day }, true).isValid();
  },
};
