import React, { Component } from 'react';

import moment from 'moment';
import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url, backendUrl } from '../../../config';
import Layout from '../layouts/Layout';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class BookingDeclinedGuest extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const linkText = {
      color: '#77d1da',
      fontSize: '18px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const textCenter = {
      textAlign: 'center',
      marginTop: '6px',
    };

    const listPic = {
      borderRadius: '999px',
      margin: '0',
      padding: '0',
      lineHeight: '150%',
      borderSpacing: '0',
      width: '125px',
    };

    const imageSection = {
      display: 'table-cell',
      width: '33%',
    };

    const displatTable = {
      display: 'table',
      width: '100%',
      marginTop: '12px',
    };

    const displayRow = {
      display: 'table-row',
      width: '100%',
    };

    const {
      content: {
        guestName,
        hostName,
        confirmationCode,
        checkIn,
        listIds,
        listPhotos,
        listingTitles,
        listDetails,
        city,
        listId,
        listTitle,
      },
    } = this.props;
    const checkInDate = checkIn
      ? moment(checkIn).format('ddd, MMM Do, YYYY')
      : '';
    let listFirstURL;
    let listSecondURL;
    let listThirdURL;
    let imageURL1;
    let imageURL2;
    let imageURL3;
    let listingTitle1;
    let listingTitle2;
    let listingTitle3;
    if (listIds) {
      if (listPhotos && listPhotos.length > 0 && listIds.length > 0) {
        listFirstURL = listIds[0] ? `${url}/rooms/${listIds[0]}` : ' ';
        listingTitle1 = listingTitles[0] ? listingTitles[0] : ' ';
        imageURL1 =
          listPhotos[0] && listPhotos[0].type === 'Golightly'
            ? listPhotos[0].name
            : `${backendUrl}/upload/x_medium_${listPhotos[0].name}`;
      }
      if (listPhotos && listPhotos.length > 1 && listIds.length > 1) {
        listSecondURL = listIds[1] ? `${url}/rooms/${listIds[1]}` : ' ';
        listingTitle2 = listingTitles[1] ? listingTitles[1] : ' ';
        imageURL2 =
          listPhotos[1] && listPhotos[1].type === 'Golightly'
            ? listPhotos[1].name
            : `${backendUrl}/upload/x_medium_${listPhotos[1].name}`;
      }
      if (listPhotos && listPhotos.length > 2 && listIds.length > 2) {
        listThirdURL = listIds[2] ? `${url}/rooms/${listIds[2]}` : ' ';
        listingTitle3 = listingTitles[2] ? listingTitles[2] : ' ';
        imageURL3 =
          listPhotos[2] && listPhotos[2].type === 'Golightly'
            ? listPhotos[2].name
            : `${backendUrl}/upload/x_medium_${listPhotos[2].name}`;
      }
    }
    let listURL = `${url}/rooms/${listId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi {guestName},</div>
                  <EmptySpace height={20} />
                  <div>
                    We regret to inform you that your host {hostName} declined
                    your request {confirmationCode}{' '}
                    {listTitle && (
                      <span>
                        {listId ? (
                          <a href={listURL}>{listTitle}</a>
                        ) : (
                          <span>{listTitle}</span>
                        )}{' '}
                      </span>
                    )}
                    starting on {checkInDate}. Since your request was declined,
                    your card will not be charged.
                  </div>
                  {listIds && listIds.length > 0 && (
                    <div>
                      <EmptySpace height={20} />
                      {(listFirstURL || listSecondURL || listThirdURL) && (
                        <div>
                          Here are some other Golightly properties in {city}{' '}
                          that you might enjoy,
                        </div>
                      )}
                      <div style={displatTable}>
                        <div style={displayRow}>
                          {listFirstURL && (
                            <div style={imageSection}>
                              <a href={listFirstURL} style={linkText}>
                                <div style={textCenter}>
                                  <img
                                    height={125}
                                    src={imageURL1}
                                    style={listPic}
                                  />
                                  <div style={textCenter}>{listingTitle1}</div>
                                </div>
                              </a>
                              <EmptySpace height={20} />
                            </div>
                          )}
                          {listSecondURL && (
                            <div style={imageSection}>
                              <a href={listSecondURL} style={linkText}>
                                <div style={textCenter}>
                                  <img
                                    height={125}
                                    src={imageURL2}
                                    style={listPic}
                                  />
                                  <div style={textCenter}>{listingTitle2}</div>
                                </div>
                              </a>
                              <EmptySpace height={20} />
                            </div>
                          )}
                          {listThirdURL && (
                            <div style={imageSection}>
                              <a href={listThirdURL} style={linkText}>
                                <div style={textCenter}>
                                  <img
                                    height={125}
                                    src={imageURL3}
                                    style={listPic}
                                  />
                                  <div style={textCenter}>{listingTitle3}</div>
                                </div>
                              </a>
                              <EmptySpace height={20} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <EmptySpace height={40} />
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default BookingDeclinedGuest;
