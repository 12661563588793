import { defineMessages } from 'react-intl';

const messages = defineMessages({
  adminLogs: {
    id: 'adminLogs',
    defaultMessage: 'Logs',
    description: 'Admin Logs',
  },
  ownerrez: {
    id: 'ownerrez',
    defaultMessage: 'OwnerRez',
    description: 'OwnerRez',
  },
  nextPaxFees: {
    id: 'nextPaxFees',
    defaultMessage: 'Other Fees',
    description: 'Next Pax fees',
  },
  nextPaxTaxes: {
    id: 'nextPaxTaxes',
    defaultMessage: 'Taxes',
    description: 'Next Pax taxes',
  },
  nextPaxExtraGuestFee: {
    id: 'nextPaxExtraGuestFee',
    defaultMessage: 'Extra guests fee',
    description: 'Next Pax extra guests fee',
  },
  home: {
    id: 'rentall.home',
    defaultMessage: 'Home',
    description: 'Menu item',
  },
  becomeAHost: {
    id: 'rentall.becomeAHost',
    defaultMessage: 'Become a host',
    description: 'Menu item',
  },
  howToHost: {
    id: 'rentall.howToHost',
    defaultMessage: 'How to Host',
    description: 'Menu item',
  },
  travelOften: {
    id: 'rentall.travelOften',
    defaultMessage: 'Travel Often, Go Lightly',
    description: 'Menu item',
  },
  interested: {
    id: 'rentall.interested',
    defaultMessage: "I'm interested....",
    description: 'New member popup',
  },
  help: {
    id: 'rentall.help',
    defaultMessage: 'Help',
    description: 'Menu item',
  },
  whoCanJoin: {
    id: 'rentall.whoCanJoin',
    defaultMessage: 'Who Can Join',
    description: 'Menu item',
  },
  login: {
    id: 'rentall.login',
    defaultMessage: 'Login',
    description: 'Menu item',
  },
  shareYourTravel: {
    id: 'rentall.shareYourTravel',
    defaultMessage: 'Invite a Friend',
    description: 'Invite your friends',
  },
  requestInvitesFriend: {
    id: 'rentall.requestInvitesFriend',
    defaultMessage: 'Request Invites',
    description: 'Invite your friends',
  },
  signup: {
    id: 'rentall.signup',
    defaultMessage: 'Sign Up',
    description: 'Menu item',
  },
  formalRegistration: {
    id: 'rentall.formalRegistration',
    defaultMessage:
      'With Golightly, wherever you go is home. We are launching very soon. Sign up to be notified and for early access.',
    description: 'Formal Registration',
  },
  bookingList: {
    id: 'rentall.bookingList',
    defaultMessage: 'Book Now',
    description: 'Book List',
  },
  withoutPay: {
    id: 'rentall.withoutPay',
    defaultMessage: 'Book without payment',
    description: 'Book without payment',
  },
  host: {
    id: 'rentall.host',
    defaultMessage: 'Host',
    description: 'Menu item',
  },
  manageListing: {
    id: 'rentall.manageListing',
    defaultMessage: 'Manage Listings',
    description: 'Menu item',
  },
  listYourSpace: {
    id: 'rentall.listYourSpace',
    defaultMessage: 'List Your Space',
    description: 'Menu item',
  },
  yourReservations: {
    id: 'rentall.yourReservations',
    defaultMessage: 'Your Reservations',
    description: 'Menu item',
  },
  transactionHistory: {
    id: 'rentall.transactionHistory',
    defaultMessage: 'Transaction History',
    description: 'Menu item',
  },
  profile: {
    id: 'rentall.profile',
    defaultMessage: 'Profile',
    description: 'Menu item',
  },
  accountSettings: {
    id: 'rentall.accountSettings',
    defaultMessage: 'Account Settings',
    description: 'Menu item',
  },
  trips: {
    id: 'rentall.trips',
    defaultMessage: 'Trips',
    description: 'Menu item',
  },
  connections: {
    id: 'rentall.connections',
    defaultMessage: 'Connections',
    description: 'Menu item',
  },
  messages: {
    id: 'rentall.messages',
    defaultMessage: 'Messages',
    description: 'Menu item',
  },
  dashboard: {
    id: 'rentall.dashboard',
    defaultMessage: 'Dashboard',
    description: 'Menu item',
  },
  editProfile: {
    id: 'rentall.editProfile',
    defaultMessage: 'Edit Profile',
    description: 'Menu item',
  },
  logout: {
    id: 'rentall.logout',
    defaultMessage: 'Logout',
    description: 'Menu item',
  },
  about: {
    id: 'rentall.about',
    defaultMessage: 'About',
    description: 'Menu item',
  },
  concierge: {
    id: 'rentall.concierge',
    defaultMessage: 'Golightly Concierge',
    description: 'Menu item',
  },
  careers: {
    id: 'rentall.careers',
    defaultMessage: 'Careers',
    description: 'Menu item',
  },
  becomeAMember: {
    id: 'rentall.becomeAMember',
    defaultMessage: 'Become a member',
    description: 'Menu item',
  },
  press: {
    id: 'rentall.press',
    defaultMessage: 'Press',
    description: 'Menu item',
  },
  policies: {
    id: 'rentall.policies',
    defaultMessage: 'Policies',
    description: 'Menu item',
  },
  updatesNew: {
    id: 'rentall.updatesNew',
    defaultMessage: 'Updates',
    description: 'Menu item',
  },
  discover: {
    id: 'rentall.discover',
    defaultMessage: 'Discover',
    description: 'Menu item',
  },
  austinDiscover: {
    id: 'rentall.austinDiscover',
    defaultMessage: 'Texas',
    description: 'Menu item',
  },
  newYorkCityDiscover: {
    id: 'rentall.newYorkCityDiscover',
    defaultMessage: 'New York City',
    description: 'Menu item',
  },
  miamiDiscover: {
    id: 'rentall.miamiDiscover',
    defaultMessage: 'Florida',
    description: 'Menu item',
  },
  londonDiscover: {
    id: 'rentall.londonDiscover',
    defaultMessage: 'UK',
    description: 'Menu item',
  },
  trustSafety: {
    id: 'rentall.trustSafety',
    defaultMessage: 'Trust & Safety',
    description: 'Menu item',
  },
  travelCredit: {
    id: 'rentall.travelCredit',
    defaultMessage: 'Travel Credit',
    description: 'Menu item',
  },
  citizen: {
    id: 'rentall.citizen',
    defaultMessage: 'Citizen',
    description: 'Menu item',
  },
  businessTravel: {
    id: 'rentall.businessTravel',
    defaultMessage: 'Business Travel',
    description: 'Menu item',
  },
  guidebooks: {
    id: 'rentall.guidebooks',
    defaultMessage: 'Guidebooks',
    description: 'Menu item',
  },
  hosting: {
    id: 'rentall.hosting',
    defaultMessage: 'Hosting',
    description: 'Menu item',
  },
  approvedConnections: {
    id: 'rentall.approvedConnections',
    defaultMessage: 'Approved Connections',
    description: 'Approved Connections',
  },
  whyHost: {
    id: 'rentall.whyHost',
    defaultMessage: 'Why Host',
    description: 'Menu item',
  },
  hospitality: {
    id: 'rentall.hospitality',
    defaultMessage: 'Hospitality',
    description: 'Menu item',
  },
  responsibleHosting: {
    id: 'rentall.responsibleHosting',
    defaultMessage: 'Responsible Hosting',
    description: 'Menu item',
  },
  termsPrivacy: {
    id: 'rentall.termsPrivacy',
    defaultMessage: 'Privacy',
    description: 'Menu item',
  },
  termsService: {
    id: 'rentall.termsService',
    defaultMessage: 'Terms of Services',
    description: 'Menu item',
  },
  or: {
    id: 'rentall.or',
    defaultMessage: 'or',
    description: 'or',
  },
  signUp: {
    id: 'rentall.signUp',
    defaultMessage: 'Sign Up',
    description: 'Sign Up Button',
  },
  noAccount: {
    id: 'rentall.noAccount',
    defaultMessage: 'Don’t have an account?',
    description: 'Don’t have an account?',
  },
  industryRequired: {
    id: 'rentall.industryRequired',
    defaultMessage: 'Industry is required',
    description: 'Industry is required',
  },
  companyNameRequired: {
    id: 'rentall.companyNameRequired',
    defaultMessage: 'Company Name is required',
    description: 'Company Name is required',
  },
  currentPositionRequired: {
    id: 'rentall.currentPositionRequired',
    defaultMessage: 'Current Position is required',
    description: 'Current Position is required',
  },
  summaryRequired: {
    id: 'rentall.summaryRequired',
    defaultMessage: 'Professional Summary is required',
    description: 'Professional Summary is required',
  },
  otherUniversityRequired: {
    id: 'rentall.otherUniversityRequired',
    defaultMessage: 'Other University is required',
    description: 'Other University is required',
  },
  linkedinUrlRequired: {
    id: 'rentall.linkedinUrlRequired',
    defaultMessage: 'Linkedin URL is required',
    description: 'Linkedin URL is required',
  },
  referralCodeRequired: {
    id: 'rentall.referralCodeRequired',
    defaultMessage: 'Invitation code is required to create an account',
    description: 'Invitation code is required',
  },
  socialSignupReferralCodeRequired: {
    id: 'rentall.socialSignupReferralCodeRequired',
    defaultMessage: 'Valid invitation code is required to create an account',
    description: 'Valid invitation code is required',
  },
  inviteEmailRequired: {
    id: 'rentall.inviteEmailRequired',
    defaultMessage: 'Please add at least one email id to invite',
    description: 'Invite email is required',
  },
  duplicatesEmailInvalid: {
    id: 'rentall.duplicatesEmailInvalid',
    defaultMessage: 'Duplicate/Invalid email addresses are not allowed',
    description: 'Duplicate/Invalid Email Address Not Allowed',
  },
  duplicatesEmailAddress: {
    id: 'rentall.duplicatesEmailAddress',
    defaultMessage: 'Duplicate email addresses are not allowed',
    description: 'Duplicate Email Address Not Allowed',
  },
  emailNotExists: {
    id: 'rentall.emailNotExists',
    defaultMessage:
      "No account exists for this email. Make sure it's typed in correctly, or “Sign Up” instead",
    description: 'Email Address is not exist',
  },
  referralCode: {
    id: 'rentall.referralCode',
    defaultMessage: 'Invitation Code',
    description: 'Your Invitation Code to Signup',
  },
  referralText: {
    id: 'rentall.referralText',
    defaultMessage: 'Add your invitation code',
    description: 'ReferralText',
  },
  password: {
    id: 'rentall.password',
    defaultMessage: 'Password',
    description: 'Your Password to Login',
  },
  passwordRequired: {
    id: 'rentall.passwordRequired',
    defaultMessage: 'Password is required',
    description: 'Password is required',
  },
  passwordInvalid: {
    id: 'rentall.passwordInValid',
    defaultMessage:
      'Your password must be at least 8 characters. Please try again',
    description: 'Invalid Password',
  },
  referralCodeInvalid: {
    id: 'rentall.referralCodeInvalid',
    defaultMessage: 'Enter the valid Invitation code',
    description: 'Invalid Invitation code',
  },
  referralCodeLimitExceeded: {
    id: 'rentall.referralCodeLimitExceeded',
    defaultMessage: 'Sorry this Invitation code exceeded the limit',
    description: 'Exceeded Invitation Limit',
  },
  exceededLimit: {
    id: 'rentall.exceededLimit',
    defaultMessage: 'Sorry you have exceeded the limit',
    description: 'Exceeded Limit',
  },
  passwordWrong: {
    id: 'rentall.passwordWrong',
    defaultMessage: 'Wrong Password. Please try again',
    description: 'Wrong Password',
  },
  invalidReferral: {
    id: 'rentall.invalidReferral',
    defaultMessage: 'This invitation code may be invalid. Please try again.',
    description: 'Invalid Invitation code',
  },
  invalidReferralEmail: {
    id: 'rentall.invalidReferralEmail',
    defaultMessage:
      'Please use the email address where you received your invitation to join.',
    description: 'Invalid Approved Email',
  },
  loggedIn: {
    id: 'rentall.loggedIn',
    defaultMessage: 'You are already logged in.',
    description: 'You are already Logged-in',
  },
  cantLogin: {
    id: 'rentall.cantLogin',
    defaultMessage: 'Can’t Login?',
    description: 'No se puede iniciar sesión?',
  },
  noInviteCode: {
    id: 'rentall.noInviteCode',
    defaultMessage: 'Don’t have an invitation code?',
    description: 'Don’t have an invitation code',
  },
  noReferralCode: {
    id: 'rentall.noReferralCode',
    defaultMessage: 'Don’t have a referral code?',
    description: 'Don’t have a referral code',
  },
  terms1: {
    id: 'rentall.terms1',
    defaultMessage: 'By signing up, I agree to ',
    description: 'Terms content part1',
  },
  terms2: {
    id: 'rentall.terms2',
    defaultMessage:
      'Terms of Service, Nondiscrimination Policy, Payments Terms of Service, Privacy Policy, Guest Refund Policy, and Host Guarantee Terms.',
    description: 'Terms content part1',
  },
  signupWithEmail: {
    id: 'rentall.signupWithEmail',
    defaultMessage: 'Sign Up with Email',
    description: 'Login Modal title',
  },
  facebook: {
    id: 'rentall.facebook',
    defaultMessage: 'Facebook',
    description: 'sign up with facebook',
  },
  google: {
    id: 'rentall.google',
    defaultMessage: 'Google',
    description: 'Signup with Google',
  },
  signupWith: {
    id: 'rentall.signupWith',
    defaultMessage: 'Sign Up with',
    description: 'Signup with',
  },
  alreadyHaveAccount: {
    id: 'rentall.alreadyHaveAccount',
    defaultMessage: 'Already have an account?',
    description: 'alreadyHaveAccount',
  },
  termsOfService: {
    id: 'rentall.termsOfService',
    defaultMessage: 'Terms of Service',
    description: 'Terms of Service',
  },
  nonDiscriminationPolicy: {
    id: 'rentall.nonDiscriminationPolicy',
    defaultMessage: 'NonDiscrimination Policy',
    description: 'NonDiscrimination Policy',
  },
  firstName: {
    id: 'rentall.firstName',
    defaultMessage: 'First Name',
    description: 'Your First name to Register',
  },
  loginKey: {
    id: 'rentall.loginKey',
    defaultMessage: 'Invitation Code',
    description: 'Your Login key to Register',
  },
  firstNameRequired: {
    id: 'rentall.firstNameRequired',
    defaultMessage: 'First Name is required',
    description: 'First Name Required',
  },
  loginKeyRequired: {
    id: 'rentall.loginKeyRequired',
    defaultMessage: 'Invitation code is required',
    description: 'Invitation code is required to create an account',
  },
  lastName: {
    id: 'rentall.lastName',
    defaultMessage: 'Last Name',
    description: 'Your Last name to Register',
  },
  lastNameRequired: {
    id: 'rentall.lastNameRequired',
    defaultMessage: 'Last Name is required',
    description: 'Last Name Required',
  },
  dayRequired: {
    id: 'rentall.dayRequired',
    defaultMessage: 'Day is required',
    description: 'Day is required',
  },
  monthRequired: {
    id: 'rentall.monthRequired',
    defaultMessage: 'Month is required',
    description: 'Month is required',
  },
  yearRequired: {
    id: 'rentall.yearRequired',
    defaultMessage: 'Year is required',
    description: 'Year is required',
  },
  mustBe18OrOld: {
    id: 'rentall.mustBe18OrOld',
    defaultMessage: 'Sorry. You must be 21 or older to join.',
    description: 'You must at least 21 years old',
  },
  mustBe21OrOld: {
    id: 'rentall.mustBe21OrOld',
    defaultMessage: 'Sorry. You must be 21 or older to join.',
    description: 'You must at least 21 years old',
  },
  mustBe21Old: {
    id: 'rentall.mustBe21Old',
    defaultMessage:
      'Date of birth is required to verify that all members are 21 or over. Your age and birthday will never be visible or shared.',
    description: 'mustBe21Old',
  },
  WrongDayChosen: {
    id: 'rentall.WrongDayChosen',
    defaultMessage: 'Please reenter your birth month.',
    description: 'Wrong day chosen for the month',
  },
  birthDay: {
    id: 'rentall.birthDay',
    defaultMessage: 'Birthday',
    description: 'birthday',
  },
  paymentTerms: {
    id: 'rentall.paymentTerms',
    defaultMessage: 'Payments Terms of Service',
    description: 'Payments Terms of Service',
  },
  privacyPolicy: {
    id: 'rentall.privacyPolicy',
    defaultMessage: 'Privacy Policy',
    description: 'Privacy Policy',
  },
  copyRightText: {
    id: 'rentall.copyRightText',
    defaultMessage: 'Golightly, Inc.',
    description: 'Copyright Text',
  },
  guestRefundPolicy: {
    id: 'rentall.guestRefundPolicy',
    defaultMessage: 'Guest Refund Policy',
    description: 'Guest Refund Policy',
  },
  and: {
    id: 'rentall.and',
    defaultMessage: 'and',
    description: 'And',
  },
  hostGuaranteeTerms: {
    id: 'rentall.hostGuaranteeTerms',
    defaultMessage: 'Host Guarantee Terms',
    description: 'Host Guarantee Terms',
  },
  forgotPassword: {
    id: 'rentall.forgotPassword',
    defaultMessage: 'Forgot Password',
    description: 'Forgot Password',
  },
  closeNextPaxAdminModal: {
    id: 'rentall.closeNextPaxAdminModal',
    defaultMessage: 'Close',
    description: 'Forgot Password',
  },
  forgotPasswordInfo: {
    id: 'rentall.forgotPasswordInfo',
    defaultMessage:
      'Enter the email address associated with your account, and we’ll email you a link to reset your password.',
    description: 'Forgot password info',
  },
  backToLogin: {
    id: 'rentall.backToLogin',
    defaultMessage: 'Back to Login',
    description: 'Back to login',
  },
  sendLink: {
    id: 'rentall.sendLink',
    defaultMessage: 'Send Link',
    description: 'Send Link',
  },
  inbox: {
    id: 'rentall.inbox',
    defaultMessage: 'Inbox',
    description: 'Inbox',
  },
  yourListings: {
    id: 'rentall.yourListings',
    defaultMessage: 'Your Listings',
    description: 'Your Listings',
  },
  yourConnections: {
    id: 'rentall.yourConnections',
    defaultMessage: 'Your Connections',
    description: 'Your Connections',
  },
  yourTrips: {
    id: 'rentall.yourTrips',
    defaultMessage: 'Your Trips',
    description: 'Your Trips',
  },
  account: {
    id: 'rentall.account',
    defaultMessage: 'Account',
    description: 'Account',
  },
  addListing: {
    id: 'rentall.addListing',
    defaultMessage: 'Add New Listings',
    description: ' Add New Listings Button',
  },
  createListing: {
    id: 'rentall.createListing',
    defaultMessage: 'Create Listing',
    description: ' Create Listings',
  },
  hostFullySync: {
    id: 'rentall.hostFullySync',
    defaultMessage: 'Software Sync',
    description: 'Manage Listings',
  },
  propertyManagement: {
    id: 'rentall.propertyManagement',
    defaultMessage: 'Property Management Software',
    description: 'Manage Listings',
  },
  hostfullyProperty: {
    id: 'rentall.hostfullyProperty',
    defaultMessage: 'Hostfully',
    description: 'Manage Listings',
  },
  selectProperty: {
    id: 'rentall.selectProperty',
    defaultMessage: 'Select Software',
    description: 'Manage Listings',
  },
  hostFullyPlaceholder: {
    id: 'rentall.hostFullyPlaceholder',
    defaultMessage: 'Enter hostfully agency id',
    description: 'Manage Listings',
  },
  rentalsPlaceholder: {
    id: 'rentall.rentalsPlaceholder',
    defaultMessage: 'Enter rentals united email',
    description: 'Manage Listings',
  },
  ruProperty: {
    id: 'rentall.ruProperty',
    defaultMessage: 'QuickConnect / Rentals United',
    description: 'Manage Listings',
  },
  nextPaxProperty: {
    id: 'nextPax.nextPaxProperty',
    defaultMessage: 'NextPax',
    description: 'Manage Listings',
  },
  nextPaxPlaceholder: {
    id: 'nextPax.nextPaxPlaceholder',
    defaultMessage: 'Enter Next Pax id',
    description: 'Manage Listing',
  },
  hostFullySyncSubmit: {
    id: 'rentall.hostFullySyncSubmit',
    defaultMessage: 'Sync Properties to Golightly',
    description: 'Manage Listings',
  },
  hostFullyReSyncSubmit: {
    id: 'rentall.hostFullyReSyncSubmit',
    defaultMessage: 'Resync Properties to Golightly',
    description: 'Manage Listings',
  },
  hostFullyLogoutInfo: {
    id: 'rentall.hostFullyLogoutInfo',
    defaultMessage: 'Please do not logout until the Hostfully sync completes.',
    description: 'Manage Listings',
  },
  hostFullyListings: {
    id: 'rentall.hostFullyListings',
    defaultMessage: 'No of listings added',
    description: 'Manage Listings',
  },

  profilePhoto: {
    id: 'rentall.profilePhoto',
    defaultMessage: 'Profile Photo',
    description: 'Profile Photo',
  },
  addNewProfilePhoto: {
    id: 'rentall.addNewProfilePhoto',
    defaultMessage: 'Add your photo now',
    description: 'Profile Photo',
  },
  trustAndVerification: {
    id: 'rentall.trustAndVerification',
    defaultMessage: 'Trust and Verification',
    description: 'Trust and Verification',
  },
  reviews: {
    id: 'rentall.reviews',
    defaultMessage: 'Reviews',
    description: 'Reviews',
  },
  viewProfile: {
    id: 'rentall.viewProfile',
    defaultMessage: 'View Profile',
    description: 'View Profile',
  },
  payoutPreferences: {
    id: 'rentall.payoutPreferences',
    defaultMessage: 'Payout Preferences',
    description: 'Payout Preferences',
  },
  security: {
    id: 'rentall.security',
    defaultMessage: 'Security',
    description: 'Security',
  },
  where: {
    id: 'rentall.where',
    defaultMessage: 'Where',
    description: 'Where',
  },
  when: {
    id: 'rentall.when',
    defaultMessage: 'When',
    description: 'When',
  },
  guest: {
    id: 'rentall.guest',
    defaultMessage: 'Guest',
    description: 'Guest',
  },
  search: {
    id: 'rentall.search',
    defaultMessage: 'Search',
    description: 'Search',
  },
  searchbyname: {
    id: 'rentall.searchbyname',
    defaultMessage: 'Search by name',
    description: 'Search',
  },
  homeWhere: {
    id: 'rentall.homeWhere',
    defaultMessage: 'Where are you going?',
    description: 'Destination, city, address',
  },
  checkIn: {
    id: 'rentall.checkIn',
    defaultMessage: 'Check In',
    description: 'Check In',
  },
  checkOut: {
    id: 'rentall.checkOut',
    defaultMessage: 'Check Out',
    description: 'Check Out',
  },
  recommended: {
    id: 'rentall.recommended',
    defaultMessage: 'Recommended',
    description: 'Recommended Home page Slider title',
  },
  otherRecommended: {
    id: 'rentall.otherRecommended',
    defaultMessage: 'Other Recommended List',
    description: 'Recommended Home page Slider title',
  },
  mostViewed: {
    id: 'rentall.mostViewed',
    defaultMessage: 'Most Viewed',
    description: 'Most Viewed Home page Slider title',
  },
  location: {
    id: 'rentall.location',
    defaultMessage: 'Location',
    description: 'Location',
  },
  dates: {
    id: 'rentall.dates',
    defaultMessage: 'Dates',
    description: 'Dates',
  },
  roomType: {
    id: 'rentall.roomType',
    defaultMessage: 'Room Type',
    description: 'Type of Room',
  },
  price: {
    id: 'rentall.price',
    defaultMessage: 'Price',
    description: 'price',
  },
  roomsAndBeds: {
    id: 'rentall.roomsAndBeds',
    defaultMessage: 'Rooms and Beds',
    description: 'Rooms and Beds',
  },
  priceRange: {
    id: 'rentall.priceRange',
    defaultMessage: 'Price Per Night',
    description: 'Price Range',
  },
  instantBook: {
    id: 'rentall.instantBook',
    defaultMessage: 'Instant Book',
    description: 'Instant Book',
  },
  instantBookInfo: {
    id: 'rentall.instantBookInfo',
    defaultMessage: 'Listings you can book without waiting for host approval.',
    description: 'instant Book Information',
  },
  size: {
    id: 'rentall.size',
    defaultMessage: 'Size',
    description: 'Size',
  },
  anyBed: {
    id: 'rentall.anyBed',
    defaultMessage: 'Any bedrooms',
    description: 'Any bedrooms',
  },
  anyBath: {
    id: 'rentall.anyBath',
    defaultMessage: 'Any bathrooms',
    description: 'Any bathrooms',
  },
  anyBeds: {
    id: 'rentall.anyBeds',
    defaultMessage: 'Any beds',
    description: 'Any beds',
  },
  anyIndustry: {
    id: 'rentall.anyIndustry',
    defaultMessage: 'No preference',
    description: 'No preference',
  },
  filter: {
    id: 'rentall.filter',
    defaultMessage: 'Filters',
    description: 'Filter button',
  },
  seeHomes: {
    id: 'rentall.seeHomes',
    defaultMessage: 'See Homes',
    description: 'See Homes',
  },
  rentals: {
    id: 'rentall.rentals',
    defaultMessage: 'Rentals',
    description: 'Rentals',
  },
  aminities: {
    id: 'rentall.aminities',
    defaultMessage: 'Amenities',
    description: 'Amenities',
  },
  facilities: {
    id: 'rentall.facilities',
    defaultMessage: 'Facilities',
    description: 'Facilities',
  },
  houseRules: {
    id: 'rentall.houseRules',
    defaultMessage: 'House Rules',
    description: 'House Rules',
  },
  houseTypes: {
    id: 'rentall.houseTypes',
    defaultMessage: 'Place  Type',
    description: 'Place type',
  },
  cancel: {
    id: 'rentall.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel button',
  },
  applyFilters: {
    id: 'rentall.applyFilters',
    defaultMessage: 'Apply filters',
    description: 'Apply filters button',
  },
  moreFilters: {
    id: 'rentall.moreFilters',
    defaultMessage: 'More filters',
    description: 'moreFilters',
  },
  applyNow: {
    id: 'rentall.applyNow',
    defaultMessage: 'Apply Now',
    description: 'Apply Now button',
  },
  map: {
    id: 'rentall.map',
    defaultMessage: 'Map',
    description: 'Map button for mobile',
  },
  filters: {
    id: 'rentall.filters',
    defaultMessage: 'Filters',
    description: 'Filters button for mobile',
  },
  results: {
    id: 'rentall.results',
    defaultMessage: 'Results',
    description: 'Results button for mobile',
  },
  noResultsTitle: {
    id: 'rentall.noResultsTitle',
    defaultMessage: 'No Results',
    description: 'No results message for search listing',
  },
  noResultsStaticTitle: {
    id: 'rentall.noResultsStaticTitle',
    defaultMessage: 'No Listings Found',
    description: 'No results message for static page listing',
  },
  noIndustryResultsTitle: {
    id: 'rentall.noIndustryResultsTitle',
    defaultMessage: 'No Result for',
    description: 'No results message for search listing',
  },
  noResultsSubTitle: {
    id: 'rentall.noResultsSubTitle',
    defaultMessage: 'Try adjusting your search. Here are some ideas:',
    description: 'No results message for search listing',
  },
  noIndustryResultsSubTitle: {
    id: 'rentall.noIndustryResultsSubTitle',
    defaultMessage: 'Here are some other suggestions',
    description: 'No results message for search listing',
  },
  noResultsTerms1: {
    id: 'rentall.noResultsTerms1',
    defaultMessage: 'Change your filters or dates',
    description: 'No results message for search listing',
  },
  noResultsTerms2: {
    id: 'rentall.noResultsTerms2',
    defaultMessage: 'Zoom out on the map',
    description: 'No results message for search listing',
  },
  noResultsTerms3: {
    id: 'rentall.noResultsTerms3',
    defaultMessage: 'Search for a specific city, address',
    description: 'No results message for search listing',
  },
  aboutListing: {
    id: 'rentall.aboutListing',
    defaultMessage: 'About this listing',
    description: 'View listing description',
  },
  contactHost: {
    id: 'rentall.contactHost',
    defaultMessage: 'Contact Host',
    description: 'Contact host button and link',
  },
  meetHost: {
    id: 'rentall.meetHost',
    defaultMessage: 'Meet the host',
    description: 'Meet host heading',
  },
  theSpace: {
    id: 'rentall.theSpace',
    defaultMessage: 'The space',
    description: 'The space',
  },
  accommodate: {
    id: 'rentall.accommodate',
    defaultMessage: 'Accommodate',
    description: 'The space accommodate',
  },
  accommodates: {
    id: 'rentall.accommodates',
    defaultMessage: 'Accommodates',
    description: 'The space accommodates',
  },
  bedrooms: {
    id: 'rentall.bedrooms',
    defaultMessage: 'Bedrooms',
    description: 'The space bedrooms',
  },
  bathrooms: {
    id: 'rentall.bathrooms',
    defaultMessage: 'Bathrooms',
    description: 'The space bathrooms',
  },
  beds: {
    id: 'rentall.beds',
    defaultMessage: 'Beds',
    description: 'The space beds',
  },
  propertyType: {
    id: 'rentall.propertyType',
    defaultMessage: 'Property Type',
    description: 'The space property type',
  },
  sharedSpaces: {
    id: 'rentall.sharedSpaces',
    defaultMessage: 'Shared Spaces',
    description: 'View listing shared spaces',
  },
  prices: {
    id: 'rentall.prices',
    defaultMessage: 'Prices',
    description: 'View listing prices',
  },
  extraPeople: {
    id: 'rentall.extraPeople',
    defaultMessage: 'Extra people',
    description: 'View listing extra people',
  },
  noCharge: {
    id: 'rentall.noCharge',
    defaultMessage: 'No Charge',
    description: 'Extra people no charge',
  },
  cancellations: {
    id: 'rentall.cancellations',
    defaultMessage: 'Cancellations',
    description: 'View listing cancellations',
  },
  viewDetails: {
    id: 'rentall.viewDetails',
    defaultMessage: 'View details',
    description: 'Cancellations view details',
  },
  safetyFeatures: {
    id: 'rentall.safetyFeatures',
    defaultMessage: 'Safety features',
    description: 'View listing Safety features',
  },
  availability: {
    id: 'rentall.availability',
    defaultMessage: 'Availability',
    description: 'View listing availability',
  },
  in: {
    id: 'rentall.in',
    defaultMessage: 'in',
    description: 'in',
  },
  guests: {
    id: 'rentall.guests',
    defaultMessage: 'Guests',
    description: 'Guests',
  },
  addBeds: {
    id: 'rentall.addBeds',
    defaultMessage: 'Add beds',
    description: 'Add Beds',
  },
  editBeds: {
    id: 'rentall.editBeds',
    defaultMessage: 'Edit beds',
    description: 'Edit Beds',
  },
  done: {
    id: 'rentall.done',
    defaultMessage: 'Done',
    description: 'Beds Type',
  },
  clickPhotoToCrop: {
    id: 'rentall.clickPhotoToCrop',
    defaultMessage: 'Click on Photo to Crop',
    description: 'Profile photo',
  },
  bedroom: {
    id: 'rentall.bedroom',
    defaultMessage: 'Bedroom',
    description: 'Bedroom',
  },
  bedType: {
    id: 'rentall.bedType',
    defaultMessage: 'Bed Type',
    description: 'Bed Type',
  },
  livingBedroom: {
    id: 'rentall.livingBedroom',
    defaultMessage: 'Living Room',
    description: 'Bedroom',
  },
  bed: {
    id: 'rentall.bed',
    defaultMessage: 'Bed',
    description: 'Bed',
  },
  noReviewTitle: {
    id: 'rentall.noReviewTitle',
    defaultMessage: 'This place would love your review',
    description: 'View listing no review title',
  },
  noReviewInfo: {
    id: 'rentall.noReviewInfo',
    defaultMessage:
      'When you book this place, here’s where your review will show up!',
    description: 'View listing no review info',
  },
  loadMore: {
    id: 'rentall.loadMore',
    defaultMessage: 'Load More',
    description: 'Load more button',
  },
  viewCountInfo: {
    id: 'rentall.viewCountInfo',
    defaultMessage: "This home is on people's minds.",
    description: 'View listing view count details',
  },
  viewCountTotal1: {
    id: 'rentall.viewCountTotal1',
    defaultMessage: 'It’s been viewed',
    description: 'View listing view count details',
  },
  viewCountTotal2: {
    id: 'rentall.viewCountTotal2',
    defaultMessage: 'times in the past week.',
    description: 'View listing view count details',
  },
  neighborhood: {
    id: 'rentall.neighbourhood',
    defaultMessage: 'Location',
    description: 'View listing neighbourhood',
  },
  neighborhoodInfo: {
    id: 'rentall.neighborhoodInfo',
    defaultMessage:
      'Exact location information is provided after a booking is confirmed.',
    description: 'View listing neighbourhoodInfo',
  },
  yourHost: {
    id: 'rentall.yourHost',
    defaultMessage: 'Your Host',
    description: 'View listing your host',
  },
  responseRate: {
    id: 'rentall.responseRate',
    defaultMessage: 'Response rate',
    description: 'View listing response rate',
  },
  responseTime: {
    id: 'rentall.responseTime',
    defaultMessage: 'Response time',
    description: 'View listing response time',
  },
  joinedIn: {
    id: 'rentall.joinedIn',
    defaultMessage: 'Joined in',
    description: 'View listing host joining date',
  },
  contactHostinfo1: {
    id: 'rentall.contactHostinfo1',
    defaultMessage: 'Make sure you share the following',
    description: 'Contact host instructions',
  },
  contactHostinfo2: {
    id: 'rentall.contactHostinfo2',
    defaultMessage: 'Tell',
    description: 'Contact host instructions',
  },
  contactHostinfo3: {
    id: 'rentall.contactHostinfo3',
    defaultMessage: 'a little about yourself',
    description: 'Contact host instructions',
  },
  contactHostinfo4: {
    id: 'rentall.contactHostinfo4',
    defaultMessage: 'What brings you to',
    description: 'Contact host instructions',
  },
  contactHostinfo5: {
    id: 'rentall.contactHostinfo5',
    defaultMessage: 'Who’s joining you',
    description: 'Contact host instructions',
  },
  contactHostinfo6: {
    id: 'rentall.contactHostinfo6',
    defaultMessage: 'What do you love about this listing? Mention it',
    description: 'Contact host instructions',
  },
  contactHostDate: {
    id: 'rentall.contactHostDate',
    defaultMessage: 'When are you traveling',
    description: 'Contact host dates title',
  },
  hostErrorMessage1: {
    id: 'rentall.hostErrorMessage1',
    defaultMessage: 'Please specify check in and check out',
    description: 'Contact host error message',
  },
  hostErrorMessage2: {
    id: 'rentall.hostErrorMessage2',
    defaultMessage: 'These dates are not available.',
    description: 'Contact host error message',
  },
  hostErrorMessage3: {
    id: 'rentall.hostErrorMessage3',
    defaultMessage: 'You can contact the host now',
    description: 'Contact host error message',
  },
  availMinStayError1: {
    id: 'rentall.availMinStayError1',
    defaultMessage: 'This property has',
    description: 'Booking form error',
  },
  availMinStayError2: {
    id: 'rentall.availMinStayError2',
    defaultMessage: 'nights minimum during this period.',
    description: 'Booking form error',
  },
  chooseGuests: {
    id: 'rentall.chooseGuests',
    defaultMessage: 'Choose Guests',
    description: 'Contact host choose guests dropdown',
  },
  textBoxMessage: {
    id: 'rentall.textBoxMessage',
    defaultMessage: 'Start your message',
    description: 'Contact host text box start your message',
  },
  sendMessage: {
    id: 'rentall.sendMessage',
    defaultMessage: 'Send message',
    description: 'Contact host button',
  },
  contacthostError1: {
    id: 'rentall.contacthostError1',
    defaultMessage: 'You must select number of guests',
    description: 'Contact host error message',
  },
  contacthostError2: {
    id: 'rentall.contacthostError2',
    defaultMessage: 'Message is required',
    description: 'Contact host error message',
  },
  contacthostError3: {
    id: 'rentall.contacthostError3',
    defaultMessage: 'Sorry numbers, emails and websites are restricted.',
    description: 'Contact host error message',
  },
  serviceFee: {
    id: 'rentall.serviceFee',
    defaultMessage: 'Service fee',
    description: 'Bill details',
  },
  cleaningFee: {
    id: 'rentall.cleaningFee',
    defaultMessage: 'Cleaning fee',
    description: 'Bill details',
  },
  total: {
    id: 'rentall.total',
    defaultMessage: 'Total',
    description: 'Bill details',
  },
  interhomePayNow: {
    id: 'rentall.payNow',
    defaultMessage: 'Pay now',
    description: 'InterhomeManagement pay now',
  },
  interhomePayUntil: {
    id: 'rentall.payUntil',
    defaultMessage: 'Pay until',
    description: 'InterhomeManagement pay until',
  },
  servicePaymentWaiting: {
    id: 'rentall.serviceWaitingForPay',
    defaultMessage: 'Waiting for payment to complete',
    description: 'Waiting for payment to complete',
  },
  servicePaymentWaitingDescription: {
    id: 'rentall.serviceWaitingDesc',
    defaultMessage:
      '* Please allow for up to 5 minutes for payment to go through. You can close this tab *',
    description: 'Waiting for payment description',
  },
  nights: {
    id: 'rentall.nights',
    defaultMessage: 'nights',
    description: 'Bill details',
  },
  checkAvailability: {
    id: 'rentall.checkAvailability',
    defaultMessage: 'Check Availability',
    description: 'Booking button',
  },
  viewOtherListings: {
    id: 'rentall.viewOtherListings',
    defaultMessage: 'View Other Listings',
    description: 'Booking button',
  },
  book: {
    id: 'rentall.book',
    defaultMessage: 'Book',
    description: 'Booking button',
  },
  requestToBook: {
    id: 'rentall.requestToBook',
    defaultMessage: 'Request to Book',
    description: 'Booking button',
  },
  maximumStay: {
    id: 'rentall.maximumStay',
    defaultMessage: 'Maximum stay is',
    description: 'Maximum day error',
  },
  perNight: {
    id: 'rentall.perNight',
    defaultMessage: 'Per Night',
    description: 'Per Night',
  },
  bookingInfo: {
    id: 'rentall.bookingInfo',
    defaultMessage: 'You won’t be charged yet',
    description: 'You won’t be charged yet',
  },
  viewPhotos: {
    id: 'rentall.viewPhotos',
    defaultMessage: 'View Photos',
    description: 'View photos button',
  },
  cancellationPolicies: {
    id: 'rentall.cancellationPolicies',
    defaultMessage: 'Cancellation Policies',
    description: 'Cancellation Policies',
  },
  flexible: {
    id: 'rentall.flexible',
    defaultMessage: 'Flexible',
    description: 'Flexible',
  },
  moderate: {
    id: 'rentall.moderate',
    defaultMessage: 'Moderate',
    description: 'Moderate',
  },
  strict: {
    id: 'rentall.strict',
    defaultMessage: 'Strict',
    description: 'Strict',
  },
  superStrict: {
    id: 'rentall.superStrict',
    defaultMessage: 'Super Strict 30 Days',
    description: 'Super Strict',
  },
  addProfilePhoto: {
    id: 'rentall.addProfilePhoto',
    defaultMessage: 'Add your profile photo',
    description: 'Avatar upload title',
  },
  uploadInfo: {
    id: 'rentall.uploadInfo',
    defaultMessage:
      'This helps your host recognize you when you meet, so make sure it shows your face.',
    description: 'Avatar upload info',
  },
  continue: {
    id: 'rentall.continue',
    defaultMessage: 'Continue',
    description: 'Continue button',
  },
  dropzoneUpload: {
    id: 'rentall.dropzoneUpload',
    defaultMessage: 'Upload a Photo',
    description: 'Dropzone upload info',
  },
  dropzoneUploadError: {
    id: 'rentall.dropzoneUploadError',
    defaultMessage: 'Remove the existing image and try upload again',
    description: 'Dropzone upload error message',
  },
  meetupTitle: {
    id: 'rentall.meetupTitle',
    defaultMessage: 'Get ready to book with',
    description: 'Get ready to book with',
  },
  meetupInfo1: {
    id: 'rentall.meetupInfo1',
    defaultMessage:
      'We ask everyone to confirm a few things before traveling or hosting.',
    description: 'Meetup information',
  },
  meetupInfo2: {
    id: 'rentall.meetupInfo2',
    defaultMessage: 'You’ll only have to do this once.',
    description: 'Meetup information',
  },
  next: {
    id: 'rentall.next',
    defaultMessage: 'Next',
    description: 'Next button',
  },
  checkEmail: {
    id: 'rentall.checkEmail',
    defaultMessage: 'Check your email',
    description: 'Email verification title',
  },
  checkPhoneNumber: {
    id: 'rentall.checkPhoneNumber',
    defaultMessage: 'Add your phone number',
    description: 'Phone Number verification title',
  },
  verificationInfo1: {
    id: 'rentall.verificationInfo1',
    defaultMessage: 'Congrats! You are almost a member.',
    description: 'Verification info',
  },
  verificationInfo2: {
    id: 'rentall.verificationInfo2',
    defaultMessage:
      'Confirming your email address helps us send you trip information.',
    description: 'Verification info',
  },
  verificationInfo3: {
    id: 'rentall.verificationInfo3',
    defaultMessage: 'Check your email to access your account and get started.',
    description: 'Verification info 3',
  },
  resendEmail: {
    id: 'rentall.resendEmail',
    defaultMessage: 'Resend email',
    description: 'Resend email button',
  },
  resendEmailConfirmation: {
    id: 'rentall.resendEmailConfirmation',
    defaultMessage: 'Resend confirmation email',
    description: 'Resend confirmation email button',
  },
  changeEmail: {
    id: 'rentall.changeEmail',
    defaultMessage: 'Change email address',
    description: 'Change email address',
  },
  changePhoneNumber: {
    id: 'rentall.changePhoneNumber',
    defaultMessage: 'Change phone number',
    description: 'Change phone number',
  },
  aboutYourTrip: {
    id: 'rentall.aboutYourTrip',
    defaultMessage: 'About Your Trip',
    description: 'Payment About Your Trip',
  },
  whoComing: {
    id: 'rentall.whoComing',
    defaultMessage: "Who's Coming",
    description: 'Who`s Coming',
  },
  sayHello: {
    id: 'rentall.sayHello',
    defaultMessage: 'Say hello to your host and tell them why you’re coming',
    description: 'Payment page info',
  },
  descriptionInfo: {
    id: 'rentall.descriptionInfo',
    defaultMessage:
      'Visiting family or friends? Seeing the sights? This helps your host plan for your trip.',
    description: 'Payment description information',
  },
  payment: {
    id: 'rentall.payment',
    defaultMessage: 'Payment',
    description: 'Payment',
  },
  paymentInfo: {
    id: 'rentall.paymentInfo',
    defaultMessage:
      'You’ll only be charged if your request is accepted by the host. They’ll have 24 hours to accept or decline.',
    description: 'Payment information',
  },
  paymentCurrency: {
    id: 'rentall.paymentCurrency',
    defaultMessage: 'Payment Currency',
    description: 'Payment currency',
  },
  chooseCurrency: {
    id: 'rentall.chooseCurrency',
    defaultMessage: 'Choose Currency',
    description: 'Choose Currency',
  },
  chooseIndustry: {
    id: 'rentall.chooseIndustry',
    defaultMessage: 'Choose Industry',
    description: 'Choose Industry',
  },
  searchIndustry: {
    id: 'rentall.searchIndustry',
    defaultMessage: 'Search by Host’s Industry',
    description: 'Choose Industry',
  },
  paymentType: {
    id: 'rentall.paymentType',
    defaultMessage: 'Payment Type',
    description: 'Payment Type',
  },
  paypal: {
    id: 'rentall.paypal',
    defaultMessage: 'Paypal',
    description: 'Paypal',
  },
  loginInfo: {
    id: 'rentall.loginInfo',
    defaultMessage:
      'Log into PayPal. You will be able to review your request before it is final.',
    description: 'Login information',
  },
  currencyError: {
    id: 'rentall.currencyError',
    defaultMessage: 'Choose a payment currency',
    description: 'Currency Error',
  },
  hostedBy: {
    id: 'rentall.hostedBy',
    defaultMessage: 'Hosted by',
    description: 'Hosted by',
  },
  cancellationPolicy: {
    id: 'rentall.cancellationPolicy',
    defaultMessage: 'Cancellation policy',
    description: 'Cancellation policy',
  },
  deleteListing: {
    id: 'rentall.deleteListing',
    defaultMessage: 'Delete this Listing',
    description: 'Manage listing delete this listing',
  },
  deleteListingInfo: {
    id: 'rentall.deleteListingInfo',
    defaultMessage:
      'Are you sure you want to delete this listing? You cannot reverse this action.',
    description: 'Manage listing delete listing information',
  },
  delete: {
    id: 'rentall.delete',
    defaultMessage: 'Delete',
    description: 'Manage listing Delete button',
  },
  goBack: {
    id: 'rentall.goBack',
    defaultMessage: 'Go Back',
    description: 'Manage listing Go back button',
  },
  progressBarText1: {
    id: 'rentall.progressBarText1',
    defaultMessage: 'You’re',
    description: 'Manage listing Progress bar text',
  },
  progressBarText2: {
    id: 'rentall.progressBarText2',
    defaultMessage: 'done with your listing',
    description: 'Manage listing Progress bar text',
  },
  listingUpdateInfo: {
    id: 'rentall.listingUpdateInfo',
    defaultMessage: 'Last updated on',
    description: 'Manage listing update info',
  },
  editListing: {
    id: 'rentall.editListing',
    defaultMessage: 'Edit Listing',
    description: 'Manage listing button',
  },
  finishListing: {
    id: 'rentall.finishListing',
    defaultMessage: 'Finish the Listing',
    description: 'Manage listing button',
  },
  preview: {
    id: 'rentall.preview',
    defaultMessage: 'Preview',
    description: 'Manage listing button',
  },
  listed: {
    id: 'rentall.listed',
    defaultMessage: 'List',
    description: 'Manage listing dropdown',
  },
  unListed: {
    id: 'rentall.unListed',
    defaultMessage: 'Unlist',
    description: 'Manage listing dropdown',
  },
  title: {
    id: 'rentall.title',
    defaultMessage: "You don't have any listings!",
    description: 'You don`t have any listings!',
  },
  content1: {
    id: 'rentall.content1',
    defaultMessage: 'Make money by renting out your extra space.',
    description: 'content',
  },
  content2: {
    id: 'rentall.content2',
    defaultMessage:
      "You'll also get to meet interesting travelers from around the world!",
    description: 'content',
  },
  button: {
    id: 'rentall.button',
    defaultMessage: 'Post a new listing',
    description: 'Post a new listing',
  },
  noTripTitle1: {
    id: 'rentall.noTripTitle1',
    defaultMessage: "You don't have any upcoming trips!",
    description: 'No trips title',
  },
  noTripTitle2: {
    id: 'rentall.noTripTitle2',
    defaultMessage: 'Choose your next adventure right away!',
    description: 'No trips title',
  },
  noTripsButton: {
    id: 'rentall.noTripsButton',
    defaultMessage: 'Search Listings',
    description: 'No trips button',
  },
  noReservation: {
    id: 'rentall.noReservation',
    defaultMessage: "You don't have any upcoming reservations!",
    description: 'No reservation',
  },
  status: {
    id: 'rentall.status',
    defaultMessage: 'Status',
    description: 'Status',
  },
  dateLocation: {
    id: 'rentall.dateLocation',
    defaultMessage: 'Dates and Location',
    description: 'Dates and Location',
  },
  details: {
    id: 'rentall.details',
    defaultMessage: 'Details',
    description: 'Details',
  },
  noList: {
    id: 'rentall.noList',
    defaultMessage: 'List deleted',
    description: 'No List Found',
  },
  notexist: {
    id: 'rentall.notexist',
    defaultMessage: "Guest profile doesn't exist",
    description: 'Guest profile not exist',
  },
  messageHistroy: {
    id: 'rentall.messageHistroy',
    defaultMessage: 'Message History',
    description: 'Message History',
  },
  contactSupport: {
    id: 'rentall.contactSupport',
    defaultMessage: 'Contact Support',
    description: 'Contact Support',
  },
  viewItinerary: {
    id: 'rentall.viewItinerary',
    defaultMessage: 'View Itinerary',
    description: 'View Itinerary',
  },
  viewReceipt: {
    id: 'rentall.viewReceipt',
    defaultMessage: 'View Receipt',
    description: 'View Receipt',
  },
  approve: {
    id: 'rentall.approve',
    defaultMessage: 'Approve',
    description: 'Approve',
  },
  decline: {
    id: 'rentall.decline',
    defaultMessage: 'Decline',
    description: 'Decline',
  },
  // firstName: {
  //   id: 'rentall.firstName',
  //   defaultMessage: 'First name',
  //   description: 'Your First name to Register',
  // },
  // firstNameRequired: {
  //   id: 'rentall.firstNameRequired',
  //   defaultMessage: 'First name is required',
  //   description: 'First name Required',
  // },
  // lastName: {
  //   id: 'rentall.lastName',
  //   defaultMessage: 'Last name',
  //   description: 'Your Last name to Register',
  // },
  // lastNameRequired: {
  //   id: 'rentall.lastNameRequired',
  //   defaultMessage: 'Last name is required',
  //   description: 'Last name Required',
  // },
  email: {
    id: 'rentall.email',
    defaultMessage: 'Email Address',
    description: 'Your Email Address to Login',
  },
  emailRequired: {
    id: 'rentall.emailRequired',
    defaultMessage: 'Email is required',
    description: 'Email is required',
  },
  emailInvalid: {
    id: 'rentall.emailInValid',
    defaultMessage: "Invalid Email. Please make sure it's typed in correctly.",
    description: 'Invalid Email Address',
  },
  emailAlreadyExists: {
    id: 'rentall.emailAlreadyExists',
    defaultMessage:
      'That email address is already in use. Please choose a different one!',
    description: 'Email Address is already exist',
  },
  emailAlreadyDeleted: {
    id: 'rentall.emailAlreadyDeleted',
    defaultMessage:
      'That email address is already deleted by admin. Please choose a different one!',
    description: 'Email Address is already Deleted',
  },
  iAm: {
    id: 'rentall.iam',
    defaultMessage: 'I am',
    description: 'Gender Label',
  },
  gender: {
    id: 'rentall.gender',
    defaultMessage: 'Gender',
    description: 'Gender Field',
  },
  genderMale: {
    id: 'rentall.genderMale',
    defaultMessage: 'Male',
    description: 'Gender Male',
  },
  genderFemale: {
    id: 'rentall.genderFemale',
    defaultMessage: 'Female',
    description: 'Gender Female',
  },
  genderOther: {
    id: 'rentall.genderOther',
    defaultMessage: 'Other',
    description: 'Gender Other',
  },
  genderRequired: {
    id: 'rentall.genderRequired',
    defaultMessage: 'Gender is required',
    description: 'Gender is required',
  },
  preferredLanguage: {
    id: 'rentall.preferredLanguage',
    defaultMessage: 'Preferred Language',
    description: 'Preferred Language',
  },
  preferredLanguageRequired: {
    id: 'rentall.preferredLanguageRequired',
    defaultMessage: 'Preferred Language is required',
    description: 'Preferred Language is required',
  },
  preferredCurrency: {
    id: 'rentall.preferredCurrency',
    defaultMessage: 'Preferred Currency',
    description: 'Preferred Currency',
  },
  countryName: {
    id: 'rentall.countryName',
    defaultMessage: 'Country',
    description: 'Country Name',
  },
  preferredCurrencyRequired: {
    id: 'rentall.preferredCurrencyRequired',
    defaultMessage: 'Preferred Currency is required',
    description: 'Preferred Currency is required',
  },
  phoneNumber: {
    id: 'rentall.phoneNumber',
    defaultMessage: 'Phone Number',
    description: 'Phone Number',
  },
  phoneNumberRequired: {
    id: 'rentall.phoneNumberRequired',
    defaultMessage: 'Phone Number is required',
    description: 'Phone Number is required',
  },
  phoneNumberInvalid: {
    id: 'rentall.phoneNumberInvalid',
    defaultMessage: 'Invalid Phone Number',
    description: 'Phone Number is Invalid',
  },
  liveLocation: {
    id: 'rentall.liveLocation',
    defaultMessage: 'Where you live',
    description: 'location',
  },
  searchbyloc: {
    id: 'rentall.searchbyloc',
    defaultMessage: 'Search by location',
    description: 'location',
  },
  locationRequired: {
    id: 'rentall.locationRequired',
    defaultMessage: 'Location is required',
    description: 'Location is required',
  },
  info: {
    id: 'rentall.info',
    defaultMessage: 'Describe Yourself',
    description: 'Describe Yourself',
  },
  Summaryinfo: {
    id: 'rentall.Summaryinfo',
    defaultMessage: 'Professional Summary',
    description: 'Summary',
  },
  infoRequired: {
    id: 'rentall.infoRequired',
    defaultMessage: 'Describe Yourself is required',
    description: 'Describe Yourself is required',
  },
  educationRequired: {
    id: 'rentall.educationRequired',
    defaultMessage: 'University is required',
    description: 'University is required',
  },
  dateOfBirth: {
    id: 'rentall.dateOfBirth',
    defaultMessage: 'Birthday',
    description: 'Your Birthday to Register',
  },
  dateOfBirthRequired: {
    id: 'rentall.dateOfBirthRequired',
    defaultMessage: 'Birthday is required',
    description: 'Birthday Required',
  },
  selectedDatesBlocked: {
    id: 'rentall.selectedDatesBlocked',
    defaultMessage: 'Selected dates will be blocked.',
    description: 'Selected dates will be blocked.',
  },
  selectedDatesSuccess: {
    id: 'rentall.selectedDatesSuccess',
    defaultMessage: 'Your calendar has been updated successfully.',
    description: 'Your calendar has been updated successfully.',
  },
  selectedDatesError: {
    id: 'rentall.selectedDatesError',
    defaultMessage: 'Something went wrong, please try again.',
    description: 'Something went wrong, please try again.',
  },
  save: {
    id: 'rentall.save',
    defaultMessage: 'Save',
    description: 'Save Button',
  },
  month: {
    id: 'rentall.month',
    defaultMessage: 'Month',
    description: 'Month of birthday',
  },
  year: {
    id: 'rentall.year',
    defaultMessage: 'Year',
    description: 'Year of birthday',
  },
  day: {
    id: 'rentall.day',
    defaultMessage: 'Day',
    description: 'Day of birthday',
  },
  birthDayRequired: {
    id: 'rentall.birthDayRequired',
    defaultMessage: 'Please enter your date of birth to continue.',
    description: 'birthDayRequired',
  },
  Required: {
    id: 'rentall.Required',
    defaultMessage: 'Requested Profile Info',
    description: 'Required Heading',
  },
  lastNameInfo: {
    id: 'rentall.lastNameInfo',
    defaultMessage:
      'Your profile is only visible to other Golightly members. No personal info will be shared with other members.',
    description: 'lastNameInfo',
  },
  birthDayInfo: {
    id: 'rentall.birthDayInfo',
    defaultMessage:
      'Your date of birth and age will not be displayed or shared. It is only used to verify that all members are over 21.',
    description: 'birthDayInfo',
  },
  genderInfo: {
    id: 'rentall.genderInfo',
    defaultMessage:
      'We use this data for analysis and never share it with other users.',
    description: 'genderInfo',
  },
  emailInfo: {
    id: 'rentall.emailInfo',
    defaultMessage:
      'We won’t share your private email address with other users.',
    description: 'emailInfo',
  },
  zipInfo: {
    id: 'rentall.zipInfo',
    defaultMessage:
      'This info will not be displayed or shared in your profile.',
    description: 'zipInfo',
  },
  learnMore: {
    id: 'rentall.learnMore',
    defaultMessage: 'Learn More',
    description: 'learnMore',
  },
  phoneNumberInfo: {
    id: 'rentall.phoneNumberInfo',
    defaultMessage:
      'This is only shared once you have a confirmed booking with another user. This is how we can all get in touch.',
    description: 'phoneNumberInfo',
  },
  preferredLanguageInfo: {
    id: 'rentall.preferredLanguageInfo',
    defaultMessage: 'We’ll send you messages in this language.',
    description: 'preferredLanguageInfo',
  },
  preferredCurrencyInfo: {
    id: 'rentall.preferredCurrencyInfo',
    defaultMessage: 'We’ll show you prices in this currency.',
    description: 'preferredCurrencyInfo',
  },
  info1: {
    id: 'rentall.info1',
    defaultMessage: 'Help other people get to know you.',
    description: 'info1',
  },
  info2: {
    id: 'rentall.info2',
    defaultMessage:
      'Tell them about the things you like: What are 5 things you can’t live without? Share your favorite travel destinations, books, movies, shows, music, food.',
    description: 'info2',
  },
  info3: {
    id: 'rentall.info3',
    defaultMessage:
      'Tell them what it’s like to have you as a guest or host: What’s your style of traveling? Of hosting?',
    description: 'info3',
  },
  info4: {
    id: 'rentall.info4',
    defaultMessage: 'Tell them about you: Do you have a life motto?',
    description: 'info4',
  },
  profilePhotoInfo: {
    id: 'rentall.profilePhotoInfo',
    defaultMessage:
      'Clear face photos are an important way for hosts and guests to learn about each other. It’s not much fun to host a landscape! Please upload a photo that clearly shows your face.',
    description: 'Profile photo upload information',
  },
  verifiedInfo: {
    id: 'rentall.verifiedInfo',
    defaultMessage: 'Verified information',
    description: 'Verified Info',
  },
  verifiedEmail: {
    id: 'rentall.verifiedEmail',
    defaultMessage: 'You have verified your email address',
    description: 'You have verified your email address',
  },
  verifiedPhoneNumber: {
    id: 'rentall.verifiedPhoneNumber',
    defaultMessage: 'You have verified your phone number',
    description: 'You have verified your phone number',
  },
  facebookInfo: {
    id: 'rentall.facebookInfo',
    defaultMessage:
      'Sign in with Facebook and discover your trusted connections to hosts and guests all over the world.',
    description: 'Facebook information',
  },
  disconnect: {
    id: 'rentall.disconnect',
    defaultMessage: 'Disconnect',
    description: 'Disconnect button',
  },
  removePhonenumber: {
    id: 'rentall.removePhonenumber',
    defaultMessage: 'Remove Phone Number',
    description: 'Remove Phone Number button',
  },
  googleInfo: {
    id: 'rentall.googleInfo',
    defaultMessage:
      'Connect your account to your Google account for simplicity and ease.',
    description: 'Google information',
  },
  linkedinInfo: {
    id: 'rentall.linkedinInfo',
    defaultMessage: 'Connect to your Linkedin account for simplicity and ease.',
    description: 'Linkedin information',
  },
  linkedinVerified: {
    id: 'rentall.linkedinVerified',
    defaultMessage: 'You have verified your linkedin account',
    description: 'Linkedin information',
  },
  notVerifiedDetails: {
    id: 'rentall.notVerifiedDetails',
    defaultMessage: "You haven't verified any of the below items",
    description: 'Not verified information',
  },
  notVerifiedInfo: {
    id: 'rentall.notVerifiedInfo',
    defaultMessage: 'Unverified Info',
    description: 'Not Verified Info',
  },
  pleaseVerify: {
    id: 'rentall.pleaseVerify',
    defaultMessage: 'Please verify your email address.',
    description: 'Verify your email address.',
  },
  verifyEmail: {
    id: 'rentall.verifyEmail',
    defaultMessage: 'Verify Email',
    description: 'Verify Email',
  },
  connect: {
    id: 'rentall.connect',
    defaultMessage: 'Connect',
    description: 'Connect button',
  },
  reviewTitle: {
    id: 'rentall.reviewTitle',
    defaultMessage: 'Thank you for reviewing',
    description: 'Review',
  },
  reviewTitle2: {
    id: 'rentall.reviewTitle2',
    defaultMessage:
      'Thank you for reviewing your guest. Your review will be visible on her profile.',
    description: 'Review',
  },
  finish: {
    id: 'rentall.finish',
    defaultMessage: 'Finish',
    description: 'Finish button',
  },
  reviewPageTitle: {
    id: 'rentall.reviewPageTitle',
    defaultMessage: 'Describe your experience',
    description: 'Review',
  },
  reviewPageTitle1Host: {
    id: 'rentall.reviewPageTitle1Host',
    defaultMessage: "Your review will be public on your guest's profile",
    description: 'Review',
  },
  reviewPageTitle1Guest: {
    id: 'rentall.reviewPageTitle1Guest',
    defaultMessage: "Your review will be public on your host's profile",
    description: 'Review',
  },
  reviewTextAreaHost: {
    id: 'rentall.reviewTextAreaHost',
    defaultMessage: 'How was your guest?',
    description: 'Review',
  },
  reviewTextAreaGuest: {
    id: 'rentall.reviewTextAreaGuest',
    defaultMessage: 'How was the place?',
    description: 'Review',
  },
  reviewRating: {
    id: 'rentall.reviewRating',
    defaultMessage: 'Overall Rating',
    description: 'Review',
  },
  submit: {
    id: 'rentall.submit',
    defaultMessage: 'Submit',
    description: 'Submit button',
  },
  reviewError1: {
    id: 'rentall.reviewError1',
    defaultMessage: 'Please write your review!',
    description: 'Review',
  },
  reviewError2: {
    id: 'rentall.reviewError2',
    defaultMessage: 'Please rate your review!',
    description: 'Review',
  },
  pastReviewTitle: {
    id: 'rentall.pastReviewTitle',
    defaultMessage: 'Past Reviews You’ve Written',
    description: 'Review',
  },
  noReview: {
    id: 'rentall.noReview',
    defaultMessage: 'There is no review to show!',
    description: 'Review',
  },
  response: {
    id: 'rentall.response',
    defaultMessage: 'Response',
    description: 'Review',
  },
  reviewPanelTitle1: {
    id: 'rentall.reviewPanelTitle',
    defaultMessage: 'Reviews About you',
    description: 'Review',
  },
  reviewPanelTitle2: {
    id: 'rentall.reviewPanelTitle2',
    defaultMessage: 'Reviews By you',
    description: 'Review',
  },
  reviewContent: {
    id: 'rentall.reviewContent',
    defaultMessage:
      'Nobody to review right now. Looks like it’s time for another trip!',
    description: 'Review',
  },
  submitReviewFor: {
    id: 'rentall.submitReviewFor',
    defaultMessage: 'Submit a public review for',
    description: 'Review',
  },
  writeReview: {
    id: 'rentall.writeReview',
    defaultMessage: 'Write a Review',
    description: 'Review',
  },
  viewLitneray: {
    id: 'rentall.viewLitneray',
    defaultMessage: 'View Itinerary',
    description: 'Review',
  },
  reviewWritten: {
    id: 'rentall.reviewWritten',
    defaultMessage:
      'Reviews are written at the end of a reservation. Reviews you’ve received will be visible both here and on your public profile.',
    description: 'Review',
  },
  hey: {
    id: 'rentall.hey',
    defaultMessage: "Hi, I'm",
    description: 'View profile',
  },
  reportUser: {
    id: 'rentall.reportUser',
    defaultMessage: 'Report this user',
    description: 'View profile',
  },
  reportDetailsRequired: {
    id: 'rentall.reportDetailsRequired',
    defaultMessage: 'Please select any one of the option below',
    description: 'View profile',
  },
  noVerifications: {
    id: 'rentall.noVerifications',
    defaultMessage: 'No verifications yet',
    description: 'View profile',
  },
  addVerifications: {
    id: 'rentall.addVerifications',
    defaultMessage: 'Add Verifications',
    description: 'View profile',
  },
  emailConfirmed: {
    id: 'rentall.emailConfirmed',
    defaultMessage: 'Email Confirmed',
    description: 'View profile',
  },
  fbConnected: {
    id: 'rentall.fbConnected',
    defaultMessage: 'Facebook Connected',
    description: 'View profile',
  },
  googleConnected: {
    id: 'rentall.googleConnected',
    defaultMessage: 'Google Connected',
    description: 'View profile',
  },
  linkedinConnected: {
    id: 'rentall.linkedinConnected',
    defaultMessage: 'Linkedin Connected',
    description: 'View profile',
  },
  moreVerifications: {
    id: 'rentall.moreVerifications',
    defaultMessage: 'Add More Verifications',
    description: 'View profile',
  },
  addPayout: {
    id: 'rentall.addPayout',
    defaultMessage: 'Add Payout Method',
    description: 'Payout',
  },
  address1: {
    id: 'rentall.address1',
    defaultMessage: 'Address Line 1',
    description: 'Payout Form',
  },
  address2: {
    id: 'rentall.address2',
    defaultMessage: 'Address Line 2',
    description: 'Payout Form',
  },
  city: {
    id: 'rentall.city',
    defaultMessage: 'City',
    description: 'Payout Form',
  },
  state: {
    id: 'rentall.state',
    defaultMessage: 'State / Province',
    description: 'Payout Form',
  },
  zipCode: {
    id: 'rentall.zipCode',
    defaultMessage: 'ZIP Code / Postal Code',
    description: 'Payout Form',
  },
  payoutError1: {
    id: 'rentall.payoutError1',
    defaultMessage: 'Country is required',
    description: 'Payout Form',
  },
  payoutError2: {
    id: 'rentall.payoutError2',
    defaultMessage: 'City is required',
    description: 'Payout Form',
  },
  payoutError3: {
    id: 'rentall.payoutError3',
    defaultMessage: 'State / Province is required',
    description: 'Payout Form',
  },
  payoutError4: {
    id: 'rentall.payoutError4',
    defaultMessage: 'ZIP Code / Postal Code is required',
    description: 'Payout Form',
  },
  payoutError5: {
    id: 'rentall.payoutError5',
    defaultMessage: 'Enter valid email address',
    description: 'Payout Form',
  },
  payoutError6: {
    id: 'rentall.payoutError6',
    defaultMessage: 'Currency is required',
    description: 'Payout Form',
  },
  country: {
    id: 'rentall.country',
    defaultMessage: 'Country',
    description: 'Payout Form',
  },
  chooseCountry: {
    id: 'rentall.chooseCountry',
    defaultMessage: 'Choose Country',
    description: 'Choose Country',
  },
  back: {
    id: 'rentall.back',
    defaultMessage: 'Back',
    description: 'Back button',
  },
  paypalIntro1: {
    id: 'rentall.paypalIntro1',
    defaultMessage:
      'PayPal is an online payment processing service that allows you to receive payments from',
    description: 'Paypal intro',
  },
  paypalIntro2: {
    id: 'rentall.paypalIntro2',
    defaultMessage: 'To use PayPal with',
    description: 'Paypal intro',
  },
  paypalIntro3: {
    id: 'rentall.paypalIntro3',
    defaultMessage: 'you must have an existing account with PayPal.',
    description: 'Paypal intro',
  },
  paypalIntro4: {
    id: 'rentall.paypalIntro4',
    defaultMessage: 'Learn more about PayPal',
    description: 'Paypal intro',
  },
  paypalEmail: {
    id: 'rentall.paypalEmail',
    defaultMessage:
      'Enter the email address associated with your existing PayPal account.',
    description: 'Paypal email',
  },
  paypalCurrency: {
    id: 'rentall.paypalCurrency',
    defaultMessage: 'In what currency would you like to be paid?',
    description: 'Paypal currency',
  },
  payoutIntro1: {
    id: 'rentall.payoutIntro1',
    defaultMessage:
      'Payouts for reservations are released to you the day after your guest checks in, and it takes some additional time for the money to arrive depending on your payout method.',
    description: 'Payout intro',
  },
  payoutIntro2: {
    id: 'rentall.payoutIntro2',
    defaultMessage:
      'We can send money to people in United States with these payout methods. Which do you prefer?',
    description: 'Payout intro',
  },
  isBankAccountOption1: {
    id: 'rentall.isBankAccountOption1',
    defaultMessage: 'To add your bank account in the',
    description: 'Payout footer',
  },
  isBankAccountOption2: {
    id: 'rentall.isBankAccountOption2',
    defaultMessage: 'you will need to update your currency to Euros',
    description: 'Payout footer',
  },
  payoutTitle: {
    id: 'rentall.payoutTitle',
    defaultMessage: 'Method',
    description: 'Payout header',
  },
  payoutTitle1: {
    id: 'rentall.payoutTitle1',
    defaultMessage: 'Processed in',
    description: 'Payout header',
  },
  payoutTitle2: {
    id: 'rentall.payoutTitle2',
    defaultMessage: 'Fees',
    description: 'Payout header',
  },
  payoutTitle3: {
    id: 'rentall.payoutTitle3',
    defaultMessage: 'Currency',
    description: 'Payout header',
  },
  payoutTitle4: {
    id: 'rentall.payoutTitle4',
    defaultMessage: 'Details',
    description: 'Payout header',
  },
  noPaymentFound: {
    id: 'rentall.noPaymentFound',
    defaultMessage: 'No Payment method found',
    description: 'No payment',
  },
  payoutMethod: {
    id: 'rentall.payoutMethod',
    defaultMessage: 'Payout Methods',
    description: 'Payout Method',
  },
  addPayoutMethod: {
    id: 'rentall.addPayoutMethod',
    defaultMessage: 'To get paid, you need to set up a payout method',
    description: 'Payout Method',
  },
  paymentReleaseInfo1: {
    id: 'rentall.paymentReleaseInfo1',
    defaultMessage:
      'releases payouts about 24 hours after a guest’s scheduled check-in time.',
    description: 'Payout Method',
  },
  paymentReleaseInfo2: {
    id: 'rentall.paymentReleaseInfo2',
    defaultMessage:
      'The time it takes for the funds to appear in your account depends on your payout method.',
    description: 'Payout Method',
  },
  payoutTitleBlock1: {
    id: 'rentall.payoutTitleBlock1',
    defaultMessage:
      'When you receive a payment for a reservation, we call that payment to you a “payout”. Our secure payment system supports several payout methods,which can be setup and edited here. Your available payout options and currencies differ by country.',
    description: 'Payout Method',
  },
  payoutFaq: {
    id: 'rentall.payoutFaq',
    defaultMessage: 'Visit the Payout FAQ',
    description: 'Payout Method',
  },
  options: {
    id: 'rentall.options',
    defaultMessage: 'Options',
    description: 'Payout Method',
  },
  default: {
    id: 'rentall.default',
    defaultMessage: 'Default',
    description: 'Payout Method',
  },
  ready: {
    id: 'rentall.ready',
    defaultMessage: 'Ready',
    description: 'Payout Method',
  },
  setDefault: {
    id: 'rentall.setDefault',
    defaultMessage: 'Set Default',
    description: 'Payout Method',
  },
  remove: {
    id: 'rentall.remove',
    defaultMessage: 'Remove',
    description: 'Payout Method',
  },
  directDeposit: {
    id: 'rentall.directDeposit',
    defaultMessage: 'Direct Deposit, Paypal, etc..',
    description: 'Payout Method',
  },
  transactionPayout: {
    id: 'rentall.transactionPayout',
    defaultMessage: 'Payout',
    description: 'Transaction history',
  },
  transferTo: {
    id: 'rentall.transferTo',
    defaultMessage: 'Transfer to',
    description: 'Transaction history',
  },
  transferDate: {
    id: 'rentall.transferDate',
    defaultMessage: 'Date',
    description: 'Date',
  },
  transferType: {
    id: 'rentall.transferType',
    defaultMessage: 'Type',
    description: 'Type',
  },
  transferAmount: {
    id: 'rentall.transferAmount',
    defaultMessage: 'Amount',
    description: 'Amount',
  },
  paidOut: {
    id: 'rentall.paidOut',
    defaultMessage: 'Paid Out',
    description: 'Paid Out',
  },
  reservation: {
    id: 'rentall.reservation',
    defaultMessage: 'Reservation',
    description: 'Reservation',
  },
  payTo: {
    id: 'rentall.payTo',
    defaultMessage: 'Pay to',
    description: 'Pay to',
  },
  grossEarnings: {
    id: 'rentall.grossEarnings',
    defaultMessage: 'Gross Earnings',
    description: 'Gross Earnings',
  },
  allListings: {
    id: 'rentall.allListings',
    defaultMessage: 'All listings',
    description: 'All listings',
  },
  noTransactions: {
    id: 'rentall.noTransactions',
    defaultMessage: 'No Transactions',
    description: 'No Transactions',
  },
  transactionsAddPayout: {
    id: 'rentall.transactionsAddPayout',
    defaultMessage: 'Add Payout',
    description: 'Transaction payout',
  },
  completedTransactions: {
    id: 'rentall.completedTransactions',
    defaultMessage: 'Completed Transactions',
    description: 'Completed Transactions',
  },
  futureTransactions: {
    id: 'rentall.futureTransactions',
    defaultMessage: 'Future Transactions',
    description: 'Completed Transactions',
  },
  exportCSV: {
    id: 'rentall.exportCSV',
    defaultMessage: 'Export to CSV',
    description: 'Export to CSV',
  },
  changePassword: {
    id: 'rentall.changePassword',
    defaultMessage: 'Change Your Password',
    description: 'Change Your Password',
  },
  oldPassword: {
    id: 'rentall.oldPassword',
    defaultMessage: 'Old Password',
    description: 'Old Password',
  },
  newPassword: {
    id: 'rentall.newPassword',
    defaultMessage: 'New Password',
    description: 'New Password',
  },
  confirmPassword: {
    id: 'rentall.confirmPassword',
    defaultMessage: 'Confirm Password',
    description: 'Confirm Password',
  },
  updatePassword: {
    id: 'rentall.updatePassword',
    defaultMessage: 'Update Password',
    description: 'Update Password',
  },
  passwordError1: {
    id: 'rentall.passwordError1',
    defaultMessage: 'Old Password is required',
    description: 'Password Error',
  },
  passwordError2: {
    id: 'rentall.passwordError2',
    defaultMessage: 'New Password is required',
    description: 'Password Error',
  },
  passwordError3: {
    id: 'rentall.passwordError3',
    defaultMessage: 'New Password must be minimum 8 characters',
    description: 'Password Error',
  },
  passwordError4: {
    id: 'rentall.passwordError4',
    defaultMessage: 'Confirm Password is required',
    description: 'Password Error',
  },
  passwordError5: {
    id: 'rentall.passwordError5',
    defaultMessage: 'Confirm Password must be minimum 8 characters',
    description: 'Password Error',
  },
  passwordError6: {
    id: 'rentall.passwordError6',
    defaultMessage: 'New Password must be under 20 characters',
    description: 'Password Error',
  },
  passwordError7: {
    id: 'rentall.passwordError7',
    defaultMessage: 'Only spaces are not allowed',
    description: 'Password Error',
  },
  passwordError8: {
    id: 'rentall.passwordError8',
    defaultMessage: 'Confirm Password must be under 20 characters',
    description: 'Password Error',
  },
  noMessagesTitle: {
    id: 'rentall.noMessagesTitle',
    defaultMessage: 'No Messages yet.',
    description: 'Inbox',
  },
  noConnectionTitle: {
    id: 'rentall.noConnectionTitle',
    defaultMessage: 'No Pending Connections.',
    description: 'Connection',
  },
  noConnectionPresentTitle: {
    id: 'rentall.noConnectionPresentTitle',
    defaultMessage: 'No Connections.',
    description: 'Connection',
  },
  noMessagesTitle1: {
    id: 'rentall.noMessagesTitle1',
    defaultMessage:
      'When hosts contact you or send you booking confirmations, you’ll see her messages here.',
    description: 'Inbox',
  },
  noMessagesTitle2: {
    id: 'rentall.noMessagesTitle2',
    defaultMessage:
      'When guests contact you or send you booking requests, you’ll see her messages here.',
    description: 'Inbox',
  },
  noMessagesTitle5: {
    id: 'rentall.noMessagesTitle5',
    defaultMessage:
      'When you contact one of your connections, or they send you a message, you’ll see it here.',
    description: 'Inbox',
  },
  noMessagesTitle3: {
    id: 'rentall.noMessagesTitle3',
    defaultMessage: 'These are the requests you have sent or received.',
    description: 'Connection',
  },
  noMessagesTitle4: {
    id: 'rentall.noMessagesTitle4',
    defaultMessage: 'Here, We display the Direct connections list.',
    description: 'Connection',
  },
  traveling: {
    id: 'rentall.traveling',
    defaultMessage: 'Traveling',
    description: 'Traveling',
  },
  pendingConnections: {
    id: 'rentall.pendingConnections',
    defaultMessage: 'Pending',
    description: 'Pending',
  },
  errorMessage: {
    id: 'rentall.errorMessage',
    defaultMessage: 'Oops something went wrong, refresh or check back later',
    description: 'Error message',
  },
  receipt: {
    id: 'rentall.receipt',
    defaultMessage: 'Receipt',
    description: 'Receipt',
  },
  customerReceipt: {
    id: 'rentall.customerReceipt',
    defaultMessage: 'Customer Receipt',
    description: 'Customer Receipt',
  },
  confirmationCode: {
    id: 'rentall.confirmationCode',
    defaultMessage: 'Confirmation Code',
    description: 'Confirmation Code',
  },
  name: {
    id: 'rentall."name"',
    defaultMessage: 'Name',
    description: 'Name',
  },
  travelDestination: {
    id: 'rentall.travelDestination',
    defaultMessage: 'Travel Destination',
    description: 'Travel Destination',
  },
  duration: {
    id: 'rentall.duration',
    defaultMessage: 'Duration',
    description: 'Duration',
  },
  accommodationType: {
    id: 'rentall.accommodationType',
    defaultMessage: 'Accommodation Type',
    description: 'Accommodation Type',
  },
  accommodationAddress: {
    id: 'rentall.accommodationAddress',
    defaultMessage: 'Accommodation Address',
    description: 'Accommodation Address',
  },
  accommodationHost: {
    id: 'rentall.accommodationHost',
    defaultMessage: 'Accommodation Host',
    description: 'Accommodation Host',
  },
  reservationCharges: {
    id: 'rentall.reservationCharges',
    defaultMessage: 'Reservation Charges',
    description: 'Reservation Charges',
  },
  paymentReceived: {
    id: 'rentall.paymentReceived',
    defaultMessage: 'Payment Received:',
    description: 'Payment Received:',
  },
  receiptInfo1: {
    id: 'rentall.receiptInfo1',
    defaultMessage:
      'is authorized to accept Accommodation Fees on behalf of the Host as limited agent.',
    description: 'Receipt information',
  },
  receiptInfo2: {
    id: 'rentall.receiptInfo2',
    defaultMessage:
      "This means that Guest's payment obligation to the Host is satisfied by payment to",
    description: 'Receipt information',
  },
  receiptInfo3: {
    id: 'rentall.receiptInfo3',
    defaultMessage:
      'Any disagreements by the Host regarding that payment must be settled between the Host and',
    description: 'Receipt information',
  },
  itinerayTitle: {
    id: 'rentall.itinerayTitle',
    defaultMessage: 'You’re going to',
    description: 'Itinerary',
  },
  reservationCode: {
    id: 'rentall.reservationCode',
    defaultMessage: 'Reservation code:',
    description: 'Itinerary',
  },
  address: {
    id: 'rentall.address',
    defaultMessage: 'Address',
    description: 'Itinerary',
  },
  unsubscribe: {
    id: 'rentall.unsubscribe',
    defaultMessage: 'Disconnect',
    description: 'Disconnect',
  },
  viewListing: {
    id: 'rentall.viewListing',
    defaultMessage: 'View Listing',
    description: 'View Listing',
  },
  viewListings: {
    id: 'rentall.viewListings',
    defaultMessage: 'View Listings',
    description: 'View Listings',
  },
  overrideListing: {
    id: 'rentall.overrideListing',
    defaultMessage: 'Override Listing',
    description: 'Override Listing',
  },
  messageHost: {
    id: 'rentall.messageHost',
    defaultMessage: 'Message Host',
    description: 'Itinerary',
  },
  billing: {
    id: 'rentall.billing',
    defaultMessage: 'Billing',
    description: 'Itinerary',
  },
  messageAction1: {
    id: 'rentall.messageAction1',
    defaultMessage: 'You messaged',
    description: 'View message',
  },
  messageAction2: {
    id: 'rentall.messageAction2',
    defaultMessage: 'about her listing',
    description: 'View message',
  },
  messageActionInfo: {
    id: 'rentall.messageActionInfo',
    defaultMessage:
      'Most hosts respond within 24 hours. If this listing is your top choice, enter your payment information to officially request a reservation.',
    description: 'View message',
  },
  RequestToBook: {
    id: 'rentall.RequestToBook',
    defaultMessage: 'Request to book',
    description: 'Request to book',
  },
  messageAction3: {
    id: 'rentall.messageAction3',
    defaultMessage: 'Your booking request sent to',
    description: 'View message',
  },
  messageAction4: {
    id: 'rentall.messageAction4',
    defaultMessage: 'for her listing',
    description: 'View message',
  },
  cancelInfo: {
    id: 'rentall.cancelInfo',
    defaultMessage:
      "Most hosts respond within 24 hours. If you don't want to continue with this host further, you can cancel this request.",
    description: 'View message',
  },
  cancelReservation: {
    id: 'rentall.cancelReservation',
    defaultMessage: 'Cancel Reservation',
    description: 'Cancel Reservation',
  },
  approveCancellation: {
    id: 'rentall.approveCancellation',
    defaultMessage: 'Approve Cancellation',
    description: 'Approve Cancellation',
  },
  requestDeclined: {
    id: 'rentall.requestDeclined',
    defaultMessage: 'Request declined',
    description: 'Request declined',
  },
  guestDeclinedInfo: {
    id: 'rentall.guestDeclinedInfo',
    defaultMessage:
      'Your request is declined by host. You can choose different dates or search for other listing.',
    description: 'Request declined',
  },
  requestApprovedAction1: {
    id: 'rentall.requestApprovedAction1',
    defaultMessage: 'Your booking request approved by',
    description: 'Request approved',
  },
  requestTimeInfo1: {
    id: 'rentall.requestTimeInfo1',
    defaultMessage: 'You have to book within',
    description: 'Request approved',
  },
  requestTimeInfo2: {
    id: 'rentall.requestTimeInfo2',
    defaultMessage: 'or it will expire.',
    description: 'Request approved',
  },
  bookingConfirmedInfo1: {
    id: 'rentall.bookingConfirmedInfo1',
    defaultMessage: 'Your booking is confirmed with',
    description: 'Booking confirmed',
  },
  bookingCanceledInfo: {
    id: 'rentall.bookingCanceledInfo',
    defaultMessage:
      'You can contact your host and do necessary arrangements or you can cancel your reservation.',
    description: 'Booking canceld',
  },
  bookingExpiredTitle: {
    id: 'rentall.bookingExpiredTitle',
    defaultMessage: 'Your booking request is expired',
    description: 'Booking expired',
  },
  bookingExpiredInfo: {
    id: 'rentall.bookingExpiredInfo',
    defaultMessage:
      'Try sending request again or send an inquiry to get in touch with the host',
    description: 'Booking expired',
  },
  gotoListing: {
    id: 'rentall.gotoListing',
    defaultMessage: 'Go to Listing',
    description: 'Go to Listing',
  },
  bookingRequestCancel1: {
    id: 'rentall.bookingRequestCancel',
    defaultMessage: 'Booking request is cancelled',
    description: 'Booking request is cancelled',
  },
  bookingRequestCancel2: {
    id: 'rentall.bookingRequestCance2',
    defaultMessage: 'Your booking request is cancelled.',
    description: 'BYour booking request is cancelled.',
  },
  cancellationRequest1: {
    id: 'rentall.cancellationRequest1',
    defaultMessage: 'Your cancellation request has been accepted by us.',
    description: 'Your cancellation request has been accepted by us.',
  },
  cancellationRequest2: {
    id: 'rentall.cancellationRequest2',
    defaultMessage:
      'Your cancellation request is being processed. As soon as we confirm it, you will receive notifications, as well as payments if they were in cancellation policies.',
    description:
      'Your cancellation request is being processed. As soon as we confirm it, you will receive notifications, as well as payments if they were in cancellation policies.',
  },
  cancellationRequest3: {
    id: 'rentall.cancellationRequest3',
    defaultMessage:
      'The cancellation request for this booking is still being processed.',
    description:
      'The cancellation request for this booking is still being processed.',
  },
  cancellationRequest4: {
    id: 'rentall.cancellationRequest4',
    defaultMessage:
      'Confirm the cancellation of the reservation after you have agreed with the integration.',
    description:
      'Confirm the cancellation of the reservation after you have agreed with the integration.',
  },
  hostAction1: {
    id: 'rentall.hostAction1',
    defaultMessage: 'Invite',
    description: 'Host Action Block.',
  },
  hostAction2: {
    id: 'rentall.hostAction2',
    defaultMessage: 'to book by pre-approving this trip',
    description: 'Host Action Block.',
  },
  hostAction3: {
    id: 'rentall.hostAction3',
    defaultMessage: 'Let',
    description: 'Host Action Block.',
  },
  hostAction4: {
    id: 'rentall.hostAction4',
    defaultMessage: 'know these dates are available by pre-approving them.',
    description: 'Host Action Block.',
  },
  hostResponseTime1: {
    id: 'rentall.hostResponseTime1',
    defaultMessage: 'Respond within',
    description: 'Host Action Block.',
  },
  hostResponseTime2: {
    id: 'rentall.hostResponseTime2',
    defaultMessage: 'to maintain your response rate.',
    description: 'Host Action Block.',
  },
  preApprove: {
    id: 'rentall.preApprove',
    defaultMessage: 'Pre-approve',
    description: 'Pre-approve',
  },
  guestRequest: {
    id: 'rentall.guestRequest',
    defaultMessage: 'sent a request to book your listing.',
    description: 'Guest Request',
  },
  requestApproved: {
    id: 'rentall.requestApproved',
    defaultMessage: 'Request Approved',
    description: 'Request Approved',
  },
  timeToExpire: {
    id: 'rentall.timeToExpire',
    defaultMessage: 'Your guest has 24 hours to respond before it expires.',
    description: 'Time To Expire',
  },
  declinedInfo: {
    id: 'rentall.declinedInfo',
    defaultMessage:
      'Make sure your calendar is up-to-date and that your listing page accurately reflects your house rules and requirements.',
    description: 'Declined Info',
  },
  bookingIsConfirmed: {
    id: 'rentall.bookingIsConfirmed',
    defaultMessage: 'Booking is confirmed',
    description: 'Booking is confirmed',
  },
  contactGuest: {
    id: 'rentall.contactGuest',
    defaultMessage:
      'You can contact your guest and do necessary arrangements or you can cancel.',
    description: 'Contact Guest',
  },
  bookingIsExpired: {
    id: 'rentall.bookingIsExpired',
    defaultMessage: 'Booking request is expired',
    description: 'Booking request is expired',
  },
  bookingIsExpired1: {
    id: 'rentall.bookingIsExpired1',
    defaultMessage: 'booking request is expired.',
    description: 'booking request is expired.',
  },
  bookingRequestCancel3: {
    id: 'rentall.bookingRequestCance3',
    defaultMessage: 'booking request is cancelled.',
    description: 'booking request is cancelled.',
  },
  writeMessage: {
    id: 'rentall.writeMessage',
    defaultMessage: 'Write your message...',
    description: 'Write your message',
  },
  loadMoreMsg: {
    id: 'rentall.loadMoreMsg',
    defaultMessage: 'Load More Messages',
    description: 'Load More Messages',
  },
  noItmesFound: {
    id: 'rentall.noItmesFound',
    defaultMessage: 'No items found',
    description: 'No items found',
  },
  messageStatus1: {
    id: 'rentall.messageStatus1',
    defaultMessage: 'Inquiry',
    description: 'Message Status',
  },
  earnedAmount: {
    id: 'rentall.earnedAmount',
    defaultMessage: 'Earned Amount',
    description: 'Payment details',
  },
  missedEarnings: {
    id: 'rentall.missedEarnings',
    defaultMessage: 'Missed Earnings',
    description: 'Missed Earnings',
  },
  refundAmount: {
    id: 'rentall.refundAmount',
    defaultMessage: 'Refund Amount',
    description: 'Refund Amount',
  },
  cleaningPrice: {
    id: 'rentall.cleaningPrice',
    defaultMessage: 'Cleaning Price',
    description: 'Payment details',
  },
  subTotal: {
    id: 'rentall.subTotal',
    defaultMessage: 'Subtotal',
    description: 'Payment details',
  },
  youEarn: {
    id: 'rentall.youEarn',
    defaultMessage: 'You earn',
    description: 'Payment details',
  },
  tripDetails: {
    id: 'rentall.tripDetails',
    defaultMessage: 'Trip details',
    description: 'Trip details',
  },
  noThreadFound: {
    id: 'rentall.noThreadFound',
    defaultMessage:
      'No thread found! If you think something went wrong, please refresh the page',
    description: 'View Message',
  },
  dashBoardHeader: {
    id: 'rentall.dashBoardHeader',
    defaultMessage: 'Welcome to',
    description: 'Dashboard',
  },
  dashBoardInfoFirst: {
    id: 'rentall.dashBoardInfoFirst',
    defaultMessage:
      'Because Golightly is a community based on trust and safety, we verify all members. We will never display or share any sensitive personal information (last name, age, date of birth, email address). Your phone number will only be shared when a booking or listing is confirmed to ensure safe arrival. Your property listing address will only be shared when a booking has been confirmed.',
    description: 'Dashboard',
  },
  dashBoardInfoSecond: {
    id: 'rentall.dashBoardInfoSecond',
    defaultMessage:
      'Check your messages, view upcoming trip information, and find travel inspiration all from your dashboard. Before booking your first stay, make sure to:',
    description: 'Dashboard',
  },
  dashBoardInfo1: {
    id: 'rentall.dashBoardInfo1',
    defaultMessage:
      'Upload a photo and write a short bio to help hosts get to know you before inviting you into her home.',
    description: 'Dashboard',
  },
  completeYourProfile: {
    id: 'rentall.completeYourProfile',
    defaultMessage: 'View Your Profile',
    description: 'Dashboard',
  },
  allMessages: {
    id: 'rentall.allMessages',
    defaultMessage: 'All Messages',
    description: 'Dashboard',
  },
  hi: {
    id: 'rentall.hi',
    defaultMessage: 'Hi',
    description: 'Hi',
  },
  letYouGetReady: {
    id: 'rentall.letYouGetReady',
    defaultMessage: 'Let’s get you ready to become a host',
    description: 'Let You Get Ready',
  },
  whatKindOfPlace: {
    id: 'rentall.whatKindOfPlace',
    defaultMessage: 'What kind of place do you have?',
    description: 'What Kind Of Place',
  },
  change: {
    id: 'rentall.change',
    defaultMessage: 'Change',
    description: 'Change Link',
  },
  whatKindOfPlaceListing: {
    id: 'rentall.whatKindOfPlaceListing',
    defaultMessage: 'What kind of place are you listing?',
    description: 'What Kind Of Place Listing',
  },
  whatTypeOfProperty: {
    id: 'rentall.whatTypeOfProperty',
    defaultMessage: 'What type of property is this?',
    description: 'What Type Of Property',
  },
  whatGuestHave: {
    id: 'rentall.whatGuestHave',
    defaultMessage: 'What will guests have?',
    description: 'What will guests have',
  },
  howManyRooms: {
    id: 'rentall.howManyRooms',
    defaultMessage: 'How many total rooms does your property have?',
    description: 'How many total rooms does your property have?',
  },
  isPersonalHome: {
    id: 'rentall.isPersonalHome',
    defaultMessage: 'Is this your personal home?',
    description: 'Is this your personal home?',
  },
  isPersonalHomeInfo: {
    id: 'rentall.isPersonalHomeInfo',
    defaultMessage:
      'Guests like to know if this is a place you live and keep your personal belongings in.',
    description: 'Personal Home Info',
  },
  skip: {
    id: 'rentall.skip',
    defaultMessage: 'Skip now',
    description: 'Skip Button',
  },
  howManyGuests: {
    id: 'rentall.howManyGuests',
    defaultMessage: 'How many guests can your place accommodate?',
    description: 'How many guests can your place accommodate?',
  },
  howManyBeds: {
    id: 'rentall.howManyBeds',
    defaultMessage: 'How many beds can guests use?',
    description: 'How many beds can guests use?',
  },
  howManyBedrooms: {
    id: 'rentall.howManyBedrooms',
    defaultMessage: 'How many bedrooms can guests use?',
    description: 'How many bedrooms can guests use?',
  },
  howManyGuestsStay: {
    id: 'rentall.howManyGuestsStay',
    defaultMessage: 'How many guests can stay?',
    description: 'How many guests can stay?',
  },
  howManyBathrooms: {
    id: 'rentall.howManyBathrooms',
    defaultMessage: 'How many bathrooms?',
    description: 'How many bathrooms?',
  },
  whereLocated: {
    id: 'rentall.whereLocated',
    defaultMessage: 'Where’s your place located?',
    description: 'Where’s your place located?',
  },
  bathroom: {
    id: 'rentall.bathroom',
    defaultMessage: 'Bathroom',
    description: 'Bathroom',
  },
  yourFullAddress: {
    id: 'rentall.yourFullAddress',
    defaultMessage: 'Your Full Address',
    description: 'Your Full Address',
  },
  street: {
    id: 'rentall.street',
    defaultMessage: 'Street Address',
    description: 'Street Address',
  },
  buildingName: {
    id: 'rentall.buildingName',
    defaultMessage: 'Apt, Suite, Bldg. (optional)',
    description: 'Building Name',
  },
  zipcode: {
    id: 'rentall.zipcode',
    defaultMessage: 'ZIP Code',
    description: 'ZIP Code',
  },
  countryRequired: {
    id: 'rentall.countryRequired',
    defaultMessage: 'Country is required',
    description: 'countryRequired',
  },
  stateRequired: {
    id: 'rentall.stateRequired',
    defaultMessage: 'State is required',
    description: 'stateRequired',
  },
  cityRequired: {
    id: 'rentall.cityRequired',
    defaultMessage: 'City is required',
    description: 'cityRequired',
  },
  streetRequired: {
    id: 'rentall.streetRequired',
    defaultMessage: 'Street is required',
    description: 'streetRequired',
  },
  zipcodeRequired: {
    id: 'rentall.zipcodeRequired',
    defaultMessage: 'Zip Code is required',
    description: 'zipcodeRequired',
  },
  commonAreaRequired: {
    id: 'rentall.commonAreaRequired',
    defaultMessage: 'Common area is required',
    description: 'commonAreaRequired',
  },
  houseTypeRequired: {
    id: 'rentall.houseTypeRequired',
    defaultMessage: 'House Type is required',
    description: 'House Type is required',
  },
  somethingWentWrong: {
    id: 'rentall.somethingWentWrong',
    defaultMessage: 'Sorry, something went wrong. Please try again.',
    description: 'Wrong went wrong',
  },
  notLoggedIn: {
    id: 'rentall.notLoggedIn',
    defaultMessage: 'You are not logged In. Please Login to continue!',
    description: 'Wrong went wrong',
  },
  adminLoggedIn: {
    id: 'rentall.adminLoggedIn',
    defaultMessage:
      "You are logged in as admin, you can't perform this action!",
    description: 'adminLoggedIn',
  },
  whatamenities: {
    id: 'rentall.whatamenities',
    defaultMessage: 'What amenities do you offer?',
    description: 'Amenities Headline',
  },
  safetyamenities: {
    id: 'rentall.safetyamenities',
    defaultMessage: 'Safety Amenities',
    description: 'Safety amenities Headline',
  },
  whatSpace: {
    id: 'rentall.whatSpace',
    defaultMessage: 'What spaces can guests use?',
    description: 'What spaces can guests use?',
  },
  step1Heading: {
    id: 'rentall.step1Heading',
    defaultMessage: 'Become a host',
    description: 'STEP 1 Heading',
  },
  step1TopHeading: {
    id: 'rentall.step1TopHeading',
    defaultMessage: 'step 1 | set the basics',
    description: 'STEP 1 Heading',
  },

  step2TopHeading: {
    id: 'rentall.step2TopHeading',
    defaultMessage: 'step 2 | set the scene',
    description: 'STEP 2 Heading',
  },

  step3TopHeading: {
    id: 'rentall.step3TopHeading',
    defaultMessage: 'step 3 | get ready for guests',
    description: 'STEP 3 Heading',
  },
  step1TopSubHeading: {
    id: 'rentall.step1TopSubHeading',
    defaultMessage: 'STEP 1',
    description: 'STEP 1 Heading',
  },
  step2Heading: {
    id: 'rentall.step2Heading',
    defaultMessage: 'STEP 2',
    description: 'STEP 2 Heading',
  },
  step3Heading: {
    id: 'rentall.step3Heading',
    defaultMessage: 'STEP 3',
    description: 'STEP 3 Heading',
  },
  step1SubHeading: {
    id: 'rentall.step1SubHeading',
    defaultMessage: 'Start with the basics',
    description: 'STEP 1 Sub Heading',
  },
  step2SubHeading: {
    id: 'rentall.step2SubHeading',
    defaultMessage: 'Set the scene',
    description: 'STEP 2 Sub Heading',
  },
  step3SubHeading: {
    id: 'rentall.step3SubHeading',
    defaultMessage: 'Get ready for guests',
    description: 'STEP 3 Sub Heading',
  },
  step1HeadingContent: {
    id: 'rentall.step1HeadingContent',
    defaultMessage: 'Beds, bathrooms, amenities, and more',
    description: 'STEP 1 Heading Content',
  },
  step2HeadingContent: {
    id: 'rentall.step2HeadingContent',
    defaultMessage: 'Photos, short description, title, and video.',
    description: 'STEP 2 Heading Content',
  },
  step3HeadingContent: {
    id: 'rentall.step3HeadingContent',
    defaultMessage: 'Booking settings, calendar, price',
    description: 'STEP 3 Heading Content',
  },
  mapWarning: {
    id: 'rentall.mapWarning',
    defaultMessage: 'Drag the pin to set your location.',
    description: 'Map Warning',
  },
  mapSuccess: {
    id: 'rentall.mapSuccess',
    defaultMessage: 'Great! Thank you!',
    description: 'Map Success',
  },
  photos: {
    id: 'rentall.photos',
    defaultMessage: 'Photos',
    description: 'Photos',
  },
  placeTitle: {
    id: 'rentall.placeTitle',
    defaultMessage: 'Name your place',
    description: 'Title',
  },
  titleLabel: {
    id: 'rentall.titleLabel',
    defaultMessage: 'Listing title',
    description: 'Title',
  },
  titleRequired: {
    id: 'rentall.titleRequired',
    defaultMessage: 'Oops! We need a title for your place.',
    description: 'Title is required',
  },
  description: {
    id: 'rentall.description',
    defaultMessage: 'Edit your description',
    description: 'Description',
  },
  descriptionRequired: {
    id: 'rentall.descriptionRequired',
    defaultMessage: 'Description is required',
    description: 'Description',
  },
  guestRequirementsTitle: {
    id: 'rentall.guestRequirementsTitle',
    defaultMessage: 'Please review the following',
    description: 'Guest Requirements Title',
  },
  guestRequirementsDescription: {
    id: 'rentall.guestRequirementsDescription',
    defaultMessage: 'I agree to the following:',
    description: 'Guest Requirements Description',
  },
  setHouseRules: {
    id: 'rentall.setHouseRules',
    defaultMessage: 'Set house rules for your guests',
    description: 'House Rules',
  },
  reviewGuestBookTitle: {
    id: 'rentall.reviewGuestBookTitle',
    defaultMessage: 'How guests book: Instantly',
    description: 'How guests book: Instantly',
  },
  reviewGuestBookDescription: {
    id: 'rentall.reviewGuestBookDescription',
    defaultMessage:
      'Don’t worry, prior to booking all Golightly guests will have:',
    description: 'Guests can book available',
  },
  reviewGuestBookNote: {
    id: 'rentall.reviewGuestBookNote',
    defaultMessage:
      'Guests who don’t meet your requirements must send a reservation request.',
    description: 'Review Guests Note',
  },
  advanceNoticeTitle: {
    id: 'rentall.advanceNoticeTitle',
    defaultMessage: 'How much notice do you need before a guest arrives?',
    description: 'Advance Notice Title',
  },
  advanceNoticeCheckInTitle: {
    id: 'rentall.advanceNoticeCheckInTitle',
    defaultMessage: 'When can guests check in?',
    description: 'Advance Notice Check In Title',
  },
  advanceNoticeFrom: {
    id: 'rentall.advanceNoticeFrom',
    defaultMessage: 'From:',
    description: 'From',
  },
  advanceNoticeTo: {
    id: 'rentall.advanceNoticeTo',
    defaultMessage: 'To:',
    description: 'To',
  },
  advanceNoticeError: {
    id: 'rentall.advanceNoticeError',
    defaultMessage: 'From time should be earlier than To time',
    description: 'Error Message',
  },
  advanceNoticeInvalid: {
    id: 'rentall.advanceNoticeInvalid',
    defaultMessage: 'Invalid input value',
    description: 'Error Message',
  },
  MaxDaysNoticeTitle: {
    id: 'rentall.MaxDaysNoticeTitle',
    defaultMessage: 'How far in advance can guests book?',
    description: 'How far in advance can guests book?',
  },
  MinMaxNightsTitle: {
    id: 'rentall.MinMaxNightsTitle',
    defaultMessage: 'How long can guests stay?',
    description: 'How long can guests stay?',
  },
  calendar: {
    id: 'rentall.calendar',
    defaultMessage: 'Update your calendar',
    description: 'calendar title',
  },
  calendarBlockDays: {
    id: 'rentall.calendarBlockDays',
    defaultMessage:
      "Block out the days you can't host and click on the start and end dates for",
    description: 'calendar title',
  },
  calendarBlockDays2: {
    id: 'rentall.calendarBlockDays2',
    defaultMessage: ' seasonal pricing',
    description: 'calendar title',
  },
  calendarBlockDays3: {
    id: 'rentall.calendarBlockDays3',
    defaultMessage: ' and minimum nights.',
    description: 'calendar title',
  },
  pricing: {
    id: 'rentall.pricing',
    defaultMessage: 'Pricing',
    description: 'pricing title',
  },
  pricingDescription: {
    id: 'rentall.pricingDescription',
    defaultMessage:
      'Your base price is your default nightly rate. For seasonal pricing click on start and end dates in the calendar on the next page.',
    description: 'pricing description',
  },
  basePrice: {
    id: 'rentall.basePrice',
    defaultMessage: 'Base price',
    description: 'Base price',
  },
  basePriceLabel: {
    id: 'rentall.basePriceLabel',
    defaultMessage: 'Price per night',
    description: 'Base price Label',
  },
  basePriceInvalid: {
    id: 'rentall.basePriceInvalid',
    defaultMessage: 'Invalid Base Price, only numbers(eg: 25) are allowed',
    description: 'Base price Invalid',
  },
  basePriceRequired: {
    id: 'rentall.basePriceRequired',
    defaultMessage: 'Base Price is required',
    description: 'Base Price is required',
  },
  cleaningPriceInvalid: {
    id: 'rentall.cleaningPriceInvalid',
    defaultMessage: 'Invalid Cleaning Price, only numbers(eg: 25) are allowed',
    description: 'Cleaning price Invalid',
  },
  currency: {
    id: 'rentall.currency',
    defaultMessage: 'Currency',
    description: 'Currency',
  },
  discount: {
    id: 'rentall.discount',
    defaultMessage: 'Discounts',
    description: 'Discounts',
  },
  discountLabel: {
    id: 'rentall.discountLabel',
    defaultMessage: '% off',
    description: 'Discount Label',
  },
  discountWeekly: {
    id: 'rentall.discountWeekly',
    defaultMessage: 'Weekly discount',
    description: 'Weekly discount',
  },
  discountInvalid: {
    id: 'rentall.discountInvalid',
    defaultMessage:
      'Invalid discount. The discount must be between 0% and 99%.',
    description: 'Invalid discount',
  },
  taxRateInvalidError: {
    id: 'rentall.taxRateInvalidError',
    defaultMessage:
      'Invalid tax rate. The tax rate must be between 0% and 99%.',
    description: 'Invalid discount',
  },
  discountMonthly: {
    id: 'rentall.discountMonthly',
    defaultMessage: 'Monthly discount',
    description: 'Monthly discount',
  },
  summary: {
    id: 'rentall.summary',
    defaultMessage: 'Based on your settings, here’s what you could expect',
    description: 'Summary Title',
  },
  localLaws: {
    id: 'rentall.localLaws',
    defaultMessage: 'Your local laws and taxes',
    description: 'Local Laws',
  },
  setCoverPhoto: {
    id: 'rentall.setCoverPhoto',
    defaultMessage: 'Choose a photo for cover image',
    description: 'setCoverPhoto',
  },
  photosPlaceholder: {
    id: 'rentall.photosPlaceholder',
    defaultMessage:
      'Click here to upload photos or drag them in\n Supported file types: .jpeg, .jpg, .png\n Maximum file size: 1Mb',
    description: 'Photos Placeholder',
  },
  descriptionLabel: {
    id: 'rentall.descriptionLabel',
    defaultMessage: "Describe the decor, light, what's nearby, etc...",
    description: 'Description Placeholder',
  },
  descriptionSubheading: {
    id: 'rentall.descriptionSubheading',
    defaultMessage: 'Summary',
    description: 'Description Subheading',
  },
  instantBookingTitle: {
    id: 'rentall.instantBookingTitle',
    defaultMessage: 'Increase your earnings with Instant Book',
    description: 'Instant booking',
  },
  instantBookingInfo: {
    id: 'rentall.instantBookingInfo',
    defaultMessage:
      'Instant Book can give your listing an edge. Not only do guests prefer to book instantly, we’re promoting Instant Book listings in search results.',
    description: 'Instant booking',
  },
  whoCanBook: {
    id: 'rentall.whoCanBook',
    defaultMessage: 'Who can book instantly',
    description: 'Instant booking',
  },
  whoCanBookInfo: {
    id: 'rentall.whoCanBookInfo',
    defaultMessage:
      'Choose who can book available days without requesting approval.',
    description: 'Instant booking',
  },
  whoCanBookInfo1: {
    id: 'rentall.whoCanBookInfo1',
    defaultMessage:
      'Guests who meet your requirements and agree to your rules.',
    description: 'Instant booking',
  },
  whoCanBookInfo2: {
    id: 'rentall.whoCanBookInfo2',
    defaultMessage: 'Anyone else must send a reservation request.',
    description: 'Instant booking',
  },
  whoCanBookInfo3: {
    id: 'rentall.whoCanBookInfo3',
    defaultMessage: 'No one. All guests must send reservation requests.',
    description: 'Instant booking',
  },
  maxDaysTitle: {
    id: 'rentall.maxDaysTitle',
    defaultMessage: 'Booking Window',
    description: 'Booking Window',
  },
  datesDropDown: {
    id: 'rentall.datesDropDown',
    defaultMessage: 'Dates unavailable by default',
    description: 'Booking Dropdown',
  },
  datesOption1: {
    id: 'rentall.datesOption1',
    defaultMessage: '3 months into the future',
    description: 'Booking Dropdown',
  },
  datesOption2: {
    id: 'rentall.datesOption2',
    defaultMessage: '6 months into the future',
    description: 'Booking Dropdown',
  },
  datesOption3: {
    id: 'rentall.datesOption3',
    defaultMessage: '9 months into the future',
    description: 'Booking Dropdown',
  },
  datesOption4: {
    id: 'rentall.datesOption4',
    defaultMessage: '12 months into the future',
    description: 'Booking Dropdown',
  },
  datesOption5: {
    id: 'rentall.datesOption5',
    defaultMessage: 'All future dates',
    description: 'Booking Dropdown',
  },
  syncCalendars: {
    id: 'rentall.syncCalendars',
    defaultMessage: 'Sync calendars',
    description: 'Sync calendars',
  },
  syncCalendarsInfo1: {
    id: 'rentall.syncCalendarsInfo1',
    defaultMessage: 'Note',
    description: 'Sync calendars',
  },
  syncCalendarsInfo2: {
    id: 'rentall.syncCalendarsInfo2',
    defaultMessage:
      'iCal sync is currently disabled since this listing is about to be duplicated. You may return back to this page after completing all the necessary steps to sync your iCal.',
    description: 'Sync calendars',
  },
  calendarBlockName: {
    id: 'rentall.calendarBlockName',
    defaultMessage:
      "You can import your calendars from Airbnb, HomeAway..etc and you can also use the export this listing's calendar and use it on other iCal applications",
    description: 'calendar title',
  },
  tripLength: {
    id: 'rentall.tripLength',
    defaultMessage: 'Trip length',
    description: 'Trip length',
  },
  tripLengthError1: {
    id: 'rentall.tripLengthError1',
    defaultMessage: "Minimum nights can't be higher than maximum nights",
    description: 'Trip length',
  },
  tabPlaceType: {
    id: 'rentall.tabPlaceType',
    defaultMessage: 'Place type',
    description: 'Tab bar',
  },
  baths: {
    id: 'rentall.baths',
    defaultMessage: 'Baths',
    description: 'Tab bar',
  },
  setCover: {
    id: 'rentall.setCover',
    defaultMessage: 'Set Cover Photo',
    description: 'Set Cover Photo',
  },
  tabDescription: {
    id: 'rentall.tabDescription',
    defaultMessage: 'Description',
    description: 'Tab bar',
  },
  tabTitle: {
    id: 'rentall.tabTitle',
    defaultMessage: 'Title',
    description: 'Tab bar',
  },
  guestRequirements: {
    id: 'rentall.guestRequirements',
    defaultMessage: 'Host Requirements',
    description: 'Tab bar',
  },
  reviewGuestBook: {
    id: 'rentall.reviewGuestBook',
    defaultMessage: 'Review GuestBook',
    description: 'Tab bar',
  },
  advanceNotice: {
    id: 'rentall.advanceNotice',
    defaultMessage: 'Advance Notice',
    description: 'Tab bar',
  },
  bookingWindow: {
    id: 'rentall.bookingWindow',
    defaultMessage: 'Booking window',
    description: 'Tab bar',
  },
  minMaxNights: {
    id: 'rentall.minMaxNights',
    defaultMessage: 'Min Max Nights',
    description: 'Tab bar',
  },
  tabCalendar: {
    id: 'rentall.tabCalendar',
    defaultMessage: 'Calendar',
    description: 'Tab bar',
  },
  tabPricing: {
    id: 'rentall.tabPricing',
    defaultMessage: 'Pricing',
    description: 'Tab bar',
  },
  tabDiscount: {
    id: 'rentall.tabDiscount',
    defaultMessage: 'Discount',
    description: 'Tab bar',
  },
  tabBooking: {
    id: 'rentall.tabBooking',
    defaultMessage: 'Booking',
    description: 'Tab bar',
  },
  tabLocalLaws: {
    id: 'rentall.tabLocalLaws',
    defaultMessage: 'Local Laws',
    description: 'Tab bar',
  },
  headerText: {
    id: 'rentall.headerText',
    defaultMessage: 'Step',
    description: 'Header Text',
  },
  readyToPublish: {
    id: 'rentall.readyToPublish',
    defaultMessage: 'Your Listing is ready to Publish!',
    description: 'Listing inde',
  },
  publishNow: {
    id: 'rentall.publishNow',
    defaultMessage: 'Publish Now',
    description: 'Listing index',
  },
  previewListing: {
    id: 'rentall.previewListing',
    defaultMessage: 'Preview Listing',
    description: 'Listing index',
  },
  listingPublished: {
    id: 'rentall.listingPublished',
    defaultMessage: 'Your Listing is Published!',
    description: 'Listing index',
  },
  unPublishNow: {
    id: 'rentall.unPublishNow',
    defaultMessage: 'Unpublish Now',
    description: 'Listing index',
  },
  signUpTitle: {
    id: 'rentall.signUpTitle',
    defaultMessage: 'New Member Registration',
    description: 'Signup Title',
  },
  panelReservation: {
    id: 'rentall.panelReservation',
    defaultMessage: 'Reservations',
    description: 'Reservations',
  },
  transactions: {
    id: 'rentall.transactions',
    defaultMessage: 'Transactions',
    description: 'Transactions',
  },
  notFoundTitle: {
    id: 'rentall.notFoundTitle',
    defaultMessage: 'Uh-oh!',
    description: 'Not Found',
  },
  notFoundSubTitle: {
    id: 'rentall.notFoundSubTitle',
    defaultMessage: "We can't seem to find the page you're looking for!",
    description: 'Not Found',
  },
  errorCode: {
    id: 'rentall.errorCode',
    defaultMessage: 'Error code: 404',
    description: 'Not Found',
  },
  linksTitle: {
    id: 'rentall.linksTitle',
    defaultMessage: 'Here are some helpful links instead:',
    description: 'Not Found',
  },
  hostingOn: {
    id: 'rentall.hostingOn',
    defaultMessage: 'Hosting on',
    description: 'Not Found',
  },
  pageError: {
    id: 'rentall.pageError',
    defaultMessage: 'Error',
    description: 'Page Error',
  },
  pageErrorInfo: {
    id: 'rentall.pageErrorInfo',
    defaultMessage: 'Sorry, a critical error occurred on this page.',
    description: 'Page Error',
  },
  messageStatus2: {
    id: 'rentall.messageStatus2',
    defaultMessage: 'Pre Approved',
    description: 'Message Status',
  },
  messageStatus3: {
    id: 'rentall.messageStatus3',
    defaultMessage: 'Declined',
    description: 'Message Status',
  },
  messageStatus4: {
    id: 'rentall.messageStatus4',
    defaultMessage: 'Approved',
    description: 'Message Status',
  },
  messageStatus5: {
    id: 'rentall.messageStatus5',
    defaultMessage: 'Pending',
    description: 'Message Status',
  },
  messageStatus6: {
    id: 'rentall.messageStatus6',
    defaultMessage: 'Cancelled by host',
    description: 'Message Status',
  },
  messageStatus7: {
    id: 'rentall.messageStatus7',
    defaultMessage: 'Cancelled by guest',
    description: 'Message Status',
  },
  messageStatus8: {
    id: 'rentall.messageStatus8',
    defaultMessage: 'Approved',
    description: 'Message Status',
  },
  messageStatus9: {
    id: 'rentall.messageStatus9',
    defaultMessage: 'Expired',
    description: 'Message Status',
  },
  messageStatus10: {
    id: 'rentall.messageStatus10',
    defaultMessage: 'Request to book',
    description: 'Message Status',
  },
  messageStatus20: {
    id: 'rentall.messageStatus20',
    defaultMessage: 'Cancellation requested',
    description: 'Cancellation requested',
  },
  panelHeader1: {
    id: 'rentall.panelHeader1',
    defaultMessage: 'In progress',
    description: 'Panel Header',
  },
  panelHeader2: {
    id: 'rentall.panelHeader2',
    defaultMessage: 'Completed',
    description: 'Panel Header',
  },
  panelHeader3: {
    id: 'rentall.panelHeader3',
    defaultMessage: 'Your Listings',
    description: 'Panel Header',
  },
  messageStatus11: {
    id: 'rentall.messageStatus11',
    defaultMessage: 'Cancelled',
    description: 'Message Status',
  },
  messagesNew: {
    id: 'rentall.messagesNew',
    defaultMessage: 'New',
    description: 'New',
  },
  facebookLogin: {
    id: 'rentall.facebookLogin',
    defaultMessage: 'Log in with Facebook',
    description: 'Log in with Facebook',
  },
  googleLogin: {
    id: 'rentall.googleLogin',
    defaultMessage: 'Log in with Google',
    description: 'Log in with Google',
  },
  reviewToWrite: {
    id: 'rentall.reviewToWrite',
    defaultMessage: 'Reviews to Write',
    description: 'Reviews Panel',
  },
  cancellationNote: {
    id: 'rentall.cancellationNote',
    defaultMessage: 'A personal note can help',
    description: 'Cancellation Notes',
  },
  cancellationNoteForHost: {
    id: 'rentall.cancellationNoteForHost',
    defaultMessage: "understand why you can't host them",
    description: 'Cancellation Notes',
  },
  saveAndContinue: {
    id: 'rentall.saveAndContinue',
    defaultMessage: 'Save & Continue',
    description: 'Save & Continue',
  },
  saveAndExit: {
    id: 'rentall.saveAndExit',
    defaultMessage: 'Save and Exit',
    description: 'Save and Exit',
  },
  reservationCancel: {
    id: 'rentall.reservationCancel',
    defaultMessage: 'The reservation will be cancelled immediately',
    description: 'Cancel your trip',
  },
  nonRefundable: {
    id: 'rentall.nonRefundable',
    defaultMessage: 'Non-Refundable',
    description: 'Cancel your trip',
  },
  refundable: {
    id: 'rentall.refundable',
    defaultMessage: 'Refundable',
    description: 'Cancel your trip',
  },
  refundCost: {
    id: 'rentall.refundCost',
    defaultMessage: 'You will be refunded with the above cost.',
    description: 'Cancel your trip',
  },
  keepReservation: {
    id: 'rentall.keepReservation',
    defaultMessage: 'Keep reservation',
    description: 'Keep reservation',
  },
  cancelYourReservation: {
    id: 'rentall.cancelYourReservation',
    defaultMessage: 'Cancel your reservation',
    description: 'Cancel your reservation',
  },
  cleaningMinusServiceFee: {
    id: 'rentall.cleaningMinusServiceFee',
    defaultMessage: 'cleaning fees, minus service fees.',
    description: 'Cleaning fee and service fee',
  },
  earnings: {
    id: 'rentall.earnings',
    defaultMessage: 'Earnings',
    description: 'Earnings',
  },
  cleaningPlusServiceFee: {
    id: 'rentall.cleaningPlusServiceFee',
    defaultMessage: 'plus cleaning fees, plus host service fees.',
    description: 'plus cleaning fees, plus host service fees.',
  },
  willBeRefund: {
    id: 'rentall.willBeRefund',
    defaultMessage: 'will be refunded the',
    description: 'will be refunded the',
  },
  reservationCost: {
    id: 'rentall.reservationCost',
    defaultMessage: 'reservation cost.',
    description: 'Reservation cost',
  },
  cancelYourTrip: {
    id: 'rentall.cancelYourTrip',
    defaultMessage: 'Cancel your Trip',
    description: 'Cancel your Trip',
  },
  consider: {
    id: 'rentall.consider',
    defaultMessage: 'Consider',
    description: 'Consider',
  },
  tripBeforeCanceling: {
    id: 'rentall.tripBeforeCanceling',
    defaultMessage: 'trip before cancelling',
    description: 'Trip before canceling',
  },
  cancellingInfo: {
    id: 'rentall.cancellingInfo',
    defaultMessage:
      "Cancelling a guest's reservation can disrupt her plans and have serious implications on her trip.",
    description: 'Trip before canceling',
  },
  started: {
    id: 'rentall.started',
    defaultMessage: 'Started',
    description: 'Started',
  },
  startIn: {
    id: 'rentall.startIn',
    defaultMessage: 'Start In',
    description: 'Start In',
  },
  travelingWith: {
    id: 'rentall.travelingWith',
    defaultMessage: 'Traveling With',
    description: 'Traveling With',
  },
  needToCancel: {
    id: 'rentall.needToCancel',
    defaultMessage: 'why you need to cancel',
    description: 'why you need to cancel',
  },
  stayingFor: {
    id: 'rentall.stayingFor',
    defaultMessage: 'Staying for',
    description: 'Staying for',
  },
  propertyLocated: {
    id: 'rentall.propertyLocated',
    defaultMessage: 'property is located in',
    description: 'Property is located in',
  },
  responseFrom: {
    id: 'rentall.responseFrom',
    defaultMessage: 'Response from',
    description: 'Response from',
  },
  savingButton: {
    id: 'rentall.savingButton',
    defaultMessage: 'Saving',
    description: 'Saving Button',
  },
  night: {
    id: 'rentall.night',
    defaultMessage: 'night',
    description: 'Bill Details',
  },
  minimumStay: {
    id: 'rentall.minimumStay',
    defaultMessage: 'minimum stay',
    description: 'Minimum stay',
  },
  maximumNightStay: {
    id: 'rentall.maximumNightStay',
    defaultMessage: 'maximum stay',
    description: 'Maximum stay',
  },
  review: {
    id: 'rentall.review',
    defaultMessage: 'Review',
    description: 'Review',
  },
  verifications: {
    id: 'rentall.verifications',
    defaultMessage: 'Verifications',
    description: 'Verifications',
  },
  verification: {
    id: 'rentall.verification',
    defaultMessage: 'Verification',
    description: 'Verification',
  },
  guestCapcity: {
    id: 'rentall.guestCapcity',
    defaultMessage: 'guest',
    description: 'Guest',
  },
  guestsCapcity: {
    id: 'rentall.guestsCapcity',
    defaultMessage: 'guests',
    description: 'Guests',
  },
  monthlyDiscount: {
    id: 'rentall.monthlyDiscount',
    defaultMessage: 'monthly price discount',
    description: 'Monthly Discount',
  },
  weeklyDiscount: {
    id: 'rentall.weeklyDiscount',
    defaultMessage: 'weekly price discount',
    description: 'Weekly Discount',
  },
  rentalsMinDiscount: {
    id: 'rentall.rentalsMinDiscount',
    defaultMessage: 'last minute discount',
    description: 'Weekly Discount',
  },
  rentalsStayDiscount: {
    id: 'rentall.rentalsStayDiscount',
    defaultMessage: 'length stay discount',
    description: 'Weekly Discount',
  },
  flexibleCheckIn: {
    id: 'rentall.flexibleCheckIn',
    defaultMessage: 'Flexible check in time',
    description: 'Flexible check in time',
  },
  cancellationNoteForGuest: {
    id: 'rentall.cancellationNoteForGuest',
    defaultMessage: 'understand why you want to cancel your reservation',
    description: 'Cancellation notes fot guest',
  },
  howManyday: {
    id: 'rentall.howManyday',
    defaultMessage: 'day',
    description: 'day',
  },
  howManydays: {
    id: 'rentall.howManydays',
    defaultMessage: 'days',
    description: 'days',
  },
  howManyGuest: {
    id: 'rentall.howManyGuest',
    defaultMessage: 'Guest',
    description: 'Guest',
  },
  reviewFor: {
    id: 'rentall.reviewFor',
    defaultMessage: 'Review for',
    description: 'Review for',
  },
  your: {
    id: 'rentall.your',
    defaultMessage: 'Your',
    description: 'Your',
  },
  chooseCancellationPolicy: {
    id: 'rentall.chooseCancellationPolicy',
    defaultMessage: 'Cancellation Policy',
    description: 'Cancellation Policy',
  },
  pendingPayouts: {
    id: 'rentall.pendingPayouts',
    defaultMessage: 'Pending Payouts',
    description: 'Pending Payouts',
  },
  allPayoutMethod: {
    id: 'rentall.allPayoutMethod',
    defaultMessage: 'All payout method',
    description: 'All payout method',
  },
  inboxCompleted: {
    id: 'rentall.inboxCompleted',
    defaultMessage: 'Completed',
    description: 'Message Status - Inbox Item',
  },
  loginConfirmation: {
    id: 'rentall.loginConfirmation',
    defaultMessage:
      'Sorry, you must Login to confirm your email. Please Login and try clicking the link from your email again!',
    description: 'Login confirmation',
  },
  upcomingReservations: {
    id: 'rentall.upcomingReservations',
    defaultMessage: 'Upcoming Reservations',
    description: 'Upcoming Reservations',
  },
  previousReservations: {
    id: 'rentall.previousReservations',
    defaultMessage: 'Previous Reservations',
    description: 'Previous Reservations',
  },
  upcomingTrips: {
    id: 'rentall.upcomingTrips',
    defaultMessage: 'Upcoming Trips',
    description: 'Upcoming Trips',
  },
  previousTrips: {
    id: 'rentall.previousTrips',
    defaultMessage: 'Previous Trips',
    description: 'Previous Trips',
  },
  noUpcomingReservation: {
    id: 'rentall.noUpcomingReservation',
    defaultMessage: "You don't have any upcoming reservations!",
    description: 'noUpcomingReservation',
  },
  noPreviousReservation: {
    id: 'rentall.noPreviousReservation',
    defaultMessage: "You don't have any previous reservations!",
    description: 'noPreviousReservation',
  },
  noUpcomingTrips: {
    id: 'rentall.noUpcomingTrips',
    defaultMessage: "You don't have any upcoming trips!",
    description: 'noUpcomingTrips',
  },
  noPreviousTrips: {
    id: 'rentall.noPreviousTrips',
    defaultMessage: "You don't have any previous trips!",
    description: 'noPreviousTrips',
  },
  payNow: {
    id: 'rentall.paynow',
    defaultMessage: 'Pay now',
    description: 'Pay now',
  },
  creditCard: {
    id: 'rentall.creditCard',
    defaultMessage: 'Credit Card',
    description: 'Credit Card',
  },
  payPal: {
    id: 'rentall.payPal',
    defaultMessage: 'Pay Pal',
    description: 'payPal',
  },
  fullName: {
    id: 'rentall.fullName',
    defaultMessage: 'Full Name',
    description: 'fullName',
  },
  cardNumber: {
    id: 'rentall.cardNumber',
    defaultMessage: 'Credit Card Number',
    description: 'cardNumber',
  },
  cvv: {
    id: 'rentall.cvv',
    defaultMessage: 'CVV',
    description: 'cvv',
  },
  expiryDate: {
    id: 'rentall.expiryDate',
    defaultMessage: 'Month',
    description: 'expiryDate',
  },
  expiryYear: {
    id: 'rentall.expiryYear',
    defaultMessage: 'Year',
    description: 'expiryYear',
  },
  cardExpires: {
    id: 'rentall.Expires',
    defaultMessage: 'Expires',
    description: 'Expires',
  },
  required: {
    id: 'rentall.required',
    defaultMessage: '*Required',
    description: '*Required',
  },
  invalid: {
    id: 'rentall.invalid',
    defaultMessage: '*Invalid',
    description: '*Invalid',
  },
  payoutFirstName: {
    id: 'rentall.payoutFirstName',
    defaultMessage: 'Account holder first name',
    description: 'payoutFirstName',
  },
  payoutLastName: {
    id: 'rentall.payoutLastName',
    defaultMessage: 'Account holder last name',
    description: 'payoutLastName',
  },
  payoutRouting: {
    id: 'rentall.payoutRouting',
    defaultMessage: 'Routing number',
    description: 'payoutRouting',
  },
  accountIbanNumber: {
    id: 'rentall.accountIbanNumber',
    defaultMessage: 'IBAN ',
    description: 'accountIbanNumber',
  },
  confirmIbanNumber: {
    id: 'rentall.confirmIbanNumber',
    defaultMessage: 'Confirm IBAN ',
    description: 'confirmIbanNumber',
  },
  accountIbanAccountNumber: {
    id: 'rentall.accountIbanAccountNumber',
    defaultMessage: 'IBAN/Account Number ',
    description: 'accountIbanAccountNumber',
  },
  confirmIbanAccountNumber: {
    id: 'rentall.confirmIbanAccountNumber',
    defaultMessage: 'Confirm IBAN/Account Number ',
    description: 'confirmIbanAccountNumber',
  },
  accountNumber: {
    id: 'rentall.accountNumber',
    defaultMessage: 'Account number',
    description: 'accountNumber',
  },
  confirmAccountNumber: {
    id: 'rentall.confirmAccountNumber',
    defaultMessage: 'Confirm account number',
    description: 'confirmAccountNumber',
  },
  ssn4Digits: {
    id: 'rentall.ssn4Digits',
    defaultMessage: 'SSN last 4 digits',
    description: 'ssn4Digits',
  },
  sortCode: {
    id: 'rentall.sortCode',
    defaultMessage: 'Sort Code',
    description: 'sortCode',
  },
  payoutFirstNameRequired: {
    id: 'rentall.payoutFirstNameRequired',
    defaultMessage: 'First name is required',
    description: 'payoutFirstNameRequired',
  },
  payoutLastNameRequired: {
    id: 'rentall.payoutLastNameRequired',
    defaultMessage: 'Last name is required',
    description: 'payoutLastNameRequired',
  },
  payoutRoutingRequired: {
    id: 'rentall.payoutRoutingRequired',
    defaultMessage: 'Routing number is required',
    description: 'payoutRoutingRequired',
  },
  payoutRoutingInvalid: {
    id: 'rentall.payoutRoutingInvalid',
    defaultMessage: 'Invalid routing number',
    description: 'payoutRoutingInvalid',
  },
  accountNumberRequired: {
    id: 'rentall.accountNumberRequired',
    defaultMessage: 'Account number is required',
    description: 'accountNumberRequired',
  },
  ibanRequired: {
    id: 'rentall.ibanRequired',
    defaultMessage: 'IBAN is required',
    description: 'ibanRequired',
  },
  accountNumberInvalid: {
    id: 'rentall.accountNumberInvalid',
    defaultMessage: 'Invalid account number',
    description: 'accountNumberInvalid',
  },
  confirmAccountNumberRequired: {
    id: 'rentall.confirmAccountNumberRequired',
    defaultMessage: 'Confirm account number is required',
    description: 'confirmAccountNumberRequired',
  },
  confirmAccountNumberInvalid: {
    id: 'rentall.confirmAccountNumberInvalid',
    defaultMessage: 'Invalid confirm account number',
    description: 'confirmAccountNumberInvalid',
  },
  confirmAccountNumberMismatch: {
    id: 'rentall.confirmAccountNumberMismatch',
    defaultMessage: 'Account number mismatch',
    description: 'confirmAccountNumberMismatch',
  },
  confirmIbanMismatch: {
    id: 'rentall.confirmIbanMismatch',
    defaultMessage: 'IBAN mismatch',
    description: 'confirmIbanMismatch',
  },
  ssn4DigitsRequired: {
    id: 'rentall.ssn4DigitsRequired',
    defaultMessage: 'SSN number is required',
    description: 'ssn4DigitsRequired',
  },
  sortCodeRequired: {
    id: 'rentall.sortCodeRequired',
    defaultMessage: 'Sort code number is required',
    description: 'sortCodeRequired',
  },
  ssn4DigitsInvalid: {
    id: 'rentall.ssn4DigitsInvalid',
    defaultMessage: 'Invalid SSN number',
    description: 'ssn4DigitsInvalid',
  },
  verifiedBy: {
    id: 'rentall.verifiedBy',
    defaultMessage: 'Verified By',
    description: 'verifiedBy',
  },
  saved: {
    id: 'rentall.saved',
    defaultMessage: 'Saved',
    description: 'saved',
  },
  referFriends: {
    id: 'rentall.referFriends',
    defaultMessage: 'Invite a Friend',
    description: 'referFriends',
  },
  wishList: {
    id: 'rentall.wishList',
    defaultMessage: 'Wish List',
    description: 'wishList',
  },
  wishLists: {
    id: 'rentall.wishLists',
    defaultMessage: 'Wish Lists',
    description: 'wishLists',
  },
  createWishList: {
    id: 'rentall.createWishList',
    defaultMessage: 'Create Wish List',
    description: 'createWishList',
  },
  list: {
    id: 'rentall.list',
    defaultMessage: 'List',
    description: 'list',
  },
  lists: {
    id: 'rentall.lists',
    defaultMessage: 'Lists',
    description: 'lists',
  },
  yourLists: {
    id: 'rentall.yourLists',
    defaultMessage: 'Your lists',
    description: 'yourLists',
  },
  homes: {
    id: 'rentall.homes',
    defaultMessage: 'Homes',
    description: 'homes',
  },
  nameRequired: {
    id: 'rentall.nameRequired',
    defaultMessage: 'Name is required',
    description: 'Name Required',
  },
  privacySettings: {
    id: 'rentall.privacySettings',
    defaultMessage: 'Privacy Settings',
    description: 'Privacy Settings',
  },
  wishListAdded: {
    id: 'rentall.wishListAdded',
    defaultMessage: 'Wish list added successfully.',
    description: 'Wish list added',
  },
  wishListUpdated: {
    id: 'rentall.wishListUpdated',
    defaultMessage: 'This property has been updated to your Wish List.',
    description: 'Wish list updated',
  },
  editWishList: {
    id: 'rentall.editWishList',
    defaultMessage: 'Edit Wish List',
    description: 'editWishList',
  },
  yourHomes: {
    id: 'rentall.yourHomes',
    defaultMessage: 'Your Homes',
    description: 'hoyourHomesmes',
  },
  deleteWishList: {
    id: 'rentall.deleteWishList',
    defaultMessage: 'Delete this list',
    description: 'deleteWishList',
  },
  noWishlists: {
    id: 'rentall.noWishlists',
    defaultMessage: "Sorry, You don't have any wishlists!",
    description: 'noWishlists',
  },
  public: {
    id: 'rentall.public',
    defaultMessage: 'Public',
    description: 'public',
  },
  private: {
    id: 'rentall.private',
    defaultMessage: 'Private',
    description: 'private',
  },
  goToAllLists: {
    id: 'rentall.goToAllLists',
    defaultMessage: 'Go to All Lists',
    description: 'Go to All Lists',
  },
  noWishlistsHomes: {
    id: 'rentall.noWishlistsHomes',
    defaultMessage: "Sorry, You don't have any wishlist homes!",
    description: 'noWishlistsHomes',
  },
  areYouSureDeleteWishList: {
    id: 'rentall.areYouSureDeleteWishList',
    defaultMessage: 'Are you sure you want to delete this?',
    description: 'areYouSureDeleteWishList',
  },
  confirmDelete: {
    id: 'rentall.confirmDelete',
    defaultMessage: 'Confirm Delete',
    description: 'confirmDelete',
  },
  similarListings: {
    id: 'rentall.similarListings',
    defaultMessage: 'Similar Listings',
    description: 'similarListings',
  },
  overview: {
    id: 'rentall.overview',
    defaultMessage: 'Overview',
    description: 'Overview',
  },
  theHost: {
    id: 'rentall.theHost',
    defaultMessage: 'The Host',
    description: 'The Host',
  },
  documentverificaiton: {
    id: 'rentall.documentverificaiton',
    defaultMessage: 'Document Verification (Required for hosting)',
    description: 'documentverificaiton',
  },

  documentverificaitonPage: {
    id: 'rentall.documentverificaitonPage',
    defaultMessage: 'Document Verification',
    description: 'documentverificaiton',
  },

  uploadaDocument: {
    id: 'rentall.uploadaDocument',
    defaultMessage: 'Upload a Doc',
    description: 'documentverificaiton',
  },

  phoneverificaiton: {
    id: 'rentall.phoneverificaiton',
    defaultMessage: 'Phone Number Verified',
    description: 'phoneverificaiton',
  },

  verificationphone: {
    id: 'rentall.verificationphone',
    defaultMessage: 'Phone Number Verification',
    description: 'verificationphone',
  },

  verificationdocument: {
    id: 'rentall.verificationdocument',
    defaultMessage: 'Document Verified',
    description: 'verificationdocument',
  },

  documentverificaitonDetails: {
    id: 'rentall.documentverificaitonDetails',
    defaultMessage: 'Your document has been verified',
    description: 'documentverificaitonDetails',
  },

  documentApproveRejectMessage: {
    id: 'rentall.documentApproveRejectMessage',
    defaultMessage: 'Type Your Message',
    description: 'documentApproveRejectMessage',
  },
  documentApprove: {
    id: 'rentall.documentApprove',
    defaultMessage: 'Approve',
    description: 'documentApprove',
  },
  documentReject: {
    id: 'rentall.documentReject',
    defaultMessage: 'Reject',
    description: 'documentReject',
  },

  documentAppRejRequried: {
    id: 'rentall.documentAppRejRequried',
    defaultMessage: 'Please Fill required',
    description: 'documentAppRejRequried',
  },
  documentUploadPlaceholder: {
    id: 'rentall.documentUploadPlaceholder',
    defaultMessage: 'Click here to upload document or drag them in',
    description: 'Document Upload Placeholder',
  },
  documentVerificaitonInfo: {
    id: 'rentall.documentVerificaitonInfo',
    defaultMessage:
      'Please upload a valid Driver’s License or Passport for ID verification prior to hosting.',
    description: 'Document Verification Info',
  },
  phoneVerificaitonInfo: {
    id: 'rentall.phoneVerificaitonInfo',
    defaultMessage:
      'Please upload your phone number for Phone Number verification.',
    description: 'Phone Verification Info',
  },
  searchAsIMove: {
    id: 'rentall.searchAsIMove',
    defaultMessage: 'Search as I move the map',
    description: 'Search as I move the map',
  },
  blankSpace: {
    id: 'rentall.blankSpace',
    defaultMessage: 'Input is Blank',
    description: 'blankSpace',
  },
  ContactMessage: {
    id: 'rentall.ContactMessage',
    defaultMessage: 'Message',
    description: 'ContactMessage',
  },
  contactForm: {
    id: 'rentall.contactForm',
    defaultMessage: 'Contact Us',
    description: 'contactForm',
  },
  Nameincontact: {
    id: 'rentall.Nameincontact',
    defaultMessage: 'Name',
    description: 'Nameincontact',
  },
  datesFlexible: {
    id: 'rentall.datesFlexible',
    defaultMessage: 'Are dates flexible?',
    description: 'Concierge Form',
  },
  noOfTravellers: {
    id: 'rentall.noOfTravellers',
    defaultMessage: 'No. of travellers',
    description: 'Concierge Form',
  },
  noOfRooms: {
    id: 'rentall.noOfRooms',
    defaultMessage: 'No. of rooms',
    description: 'Concierge Form',
  },
  detailsMessage: {
    id: 'rentall.detailsMessage',
    defaultMessage: 'Any other details',
    description: 'Concierge Form',
  },
  detailsMessagePlaceHolder: {
    id: 'rentall.detailsMessagePlaceHolder',
    defaultMessage:
      '"Is your location flexible? Are you looking for a specific trip type? Any special requirements to help us find you the right place?"',
    description: 'Concierge Form',
  },
  locationRequested: {
    id: 'rentall.locationRequested',
    defaultMessage: 'Location',
    description: 'Concierge Form',
  },
  dateRequired: {
    id: 'rentall.dateRequired',
    defaultMessage: 'Dates are Required',
    description: 'Concierge Form',
  },
  recaptchaRequired: {
    id: 'rentall.recaptchaRequired',
    defaultMessage: 'Recaptcha Required',
    description: 'recaptchaRequired',
  },
  sendmail: {
    id: 'rentall.sendmail',
    defaultMessage: 'Send',
    description: 'Send',
  },
  nameYourList: {
    id: 'rentall.nameYourList',
    defaultMessage: 'Name your list',
    description: 'List Name',
  },
  createButton: {
    id: 'rentall.createButton',
    defaultMessage: 'Create',
    description: 'Wish list button',
  },
  flexibleCheckOut: {
    id: 'rentall.flexibleCheckOut',
    defaultMessage: 'Flexible check out time',
    description: 'Flexible check out time',
  },
  helpFirstTitle: {
    id: 'rentall.helpFirstTitle',
    defaultMessage: 'Create Your Listing',
    description: 'Title',
  },
  helpSecondTitle: {
    id: 'rentall.helpSecondTitle',
    defaultMessage: 'Welcome Your Guests',
    description: 'Title',
  },
  helpThirdTitle: {
    id: 'rentall.helpThirdTitle',
    defaultMessage: 'Get That Boss Lady Money!',
    description: 'Title',
  },

  helpFirstDesc1: {
    id: 'rentall.helpFirstDesc1',
    defaultMessage:
      'Hosting is not as scary as it sounds. You’ll know exactly who’s going to rent (or share) your place through our members-only club. And you can easily vet her through reviews, or asking friends in common if she’ll be a good fit. Golightly expects all members to abide by the golden rule (do unto others…) and we have zero tolerance for anybody who disrespects another’s home.',
    description: 'Description',
  },

  helpFirstDesc2: {
    id: 'rentall.helpFirstDesc2',
    defaultMessage:
      'We’re happy to help get you started, make sure you’re fully compliant with local regulations and answer any questions you may have. First, get the basics in there: space, amenities, etc. Set a price you think is fair and competitive. If you have some good photos, upload those (hint: light and minimalism are key). Once the listing is ready, hit publish. Before it goes live, we’ll send a professional photographer out to take beauty shots of your place free of charge, and give some helpful tips about preparing for guests and the little extras that can make a home stay extraordinary.',
    description: 'Description',
  },

  helpFirstDesc3: {
    id: 'rentall.helpFirstDesc3',
    defaultMessage:
      'And keep in mind that only invited Golightly members can see your profile or property listing. Your privacy is of the utmost importance to us, and that means your nosy neighbor, creepy ex-boyfriend or pesky landlord can never access your profile or view your listing.',
    description: 'Description',
  },

  helpFirstDesc4: {
    id: 'rentall.helpFirstDesc4',
    defaultMessage:
      'You’re all set to start making the big bucks and networking with some fabulous ladies. And while you’re at it, you might as well start ticking off that vacation bucket list.',
    description: 'Description',
  },

  helpSecondDesc2a: {
    id: 'rentall.helpSecondDesc2a',
    defaultMessage: 'You Got a Reservation!',
    description: 'Description',
  },

  helpSecondDesc2b: {
    id: 'rentall.helpSecondDesc2b',
    defaultMessage: 'Check-in',
    description: 'Description',
  },

  helpSecondDesc2c: {
    id: 'rentall.helpSecondDesc2c',
    defaultMessage: 'During the Stay:',
    description: 'Description',
  },

  helpSecondDesc2d: {
    id: 'rentall.helpSecondDesc2d',
    defaultMessage: 'Check-Out:',
    description: 'Description',
  },

  helpSecondDesc1: {
    id: 'rentall.helpSecondDesc1',
    defaultMessage:
      'Hospitality is the cornerstone of Golightly and the key to a successful hosting (or staying) experience. Whatever you would expect from a quality hotel, deliver to your guests.',
    description: 'Description',
  },
  helpSecondDesc2: {
    id: 'rentall.helpSecondDesc2',
    defaultMessage:
      'Make sure your guest has all the information she needs prior to arrival: address, directions, your cell #, check-in time, key code or lockbox info; WIFI info, etc',
    description: 'Description',
  },

  helpSecondDesc3: {
    id: 'rentall.helpSecondDesc3',
    defaultMessage:
      'Have clean linens and towels for each guest and clear out a closet and drawers for her use.',
    description: 'Description',
  },

  helpSecondDesc4: {
    id: 'rentall.helpSecondDesc4',
    defaultMessage:
      'Make the check-in process smooth and welcoming – what you’d expect when visiting a new city',
    description: 'Description',
  },

  helpSecondDesc5: {
    id: 'rentall.helpSecondDesc5',
    defaultMessage:
      'Be there to personally welcome your guest, and if you can’t be available ask a trusted female friend to do so.',
    description: 'Description',
  },

  helpSecondDesc6: {
    id: 'rentall.helpSecondDesc6',
    defaultMessage:
      'It’s the added touches that make the stay: a bottle of sparkling water, a decent wine, some light snacks for your guests',
    description: 'Description',
  },

  helpSecondDesc7: {
    id: 'rentall.helpSecondDesc7',
    defaultMessage:
      'Be reachable to answer any questions and give helpful tips.',
    description: 'Description',
  },

  helpSecondDesc8: {
    id: 'rentall.helpSecondDesc8',
    defaultMessage:
      'Curate a list of local recommendations: best brunch, cafe, happy hour, yoga, must-see activities, closest subway',
    description: 'Description',
  },

  helpSecondDesc9: {
    id: 'rentall.helpSecondDesc9',
    defaultMessage:
      'Be flexible on check out – that’s one of the benefits of a home stay.',
    description: 'Description',
  },

  helpSecondDesc10: {
    id: 'rentall.helpSecondDesc10',
    defaultMessage: 'Write a nice review for your guest.',
    description: 'Description',
  },

  helpSecondDesc11: {
    id: 'rentall.helpSecondDesc11',
    defaultMessage: 'Stay in touch and do business together!',
    description: 'Description',
  },

  helpThirdDesc1: {
    id: 'rentall.helpThirdDesc1',
    defaultMessage:
      'As soon as a guest makes a reservation, a hold is put on her credit card, and the amount is guaranteed via our secure partnership with Stripe. Depending on the cancellation policy you’ve set for your listing, even if she cancels last minute, you’ll receive at least a partial payment. And we’ll try to help you book a new guest for those dates.',
    description: 'Description',
  },
  helpThirdDesc2: {
    id: 'rentall.helpThirdDesc2',
    defaultMessage:
      'Payment is usually processed in 5-7 days after check-in (via bank transfer) or 1-3 days with Paypal or Stripe. All payments are processed securely and managed by our payment processing team.',
    description: 'Description',
  },
  processFlowHeading: {
    id: 'rentall.processFlowHeading',
    defaultMessage: 'Earn Extra Money and Become a Host with Rentall',
    description: 'Process Flow Heading',
  },
  processFlowSubHeading: {
    id: 'rentall.processFlowSubHeading',
    defaultMessage:
      'Explorez le site à la recherche du photographe idéal et profitez des bons moments en toute sereinité',
    description: 'Process Flow Heading',
  },
  stepOneHeading: {
    id: 'rentall.stepOneHeading',
    defaultMessage: 'Why host with Rentall?',
    description: 'Step 1',
  },
  stepTwoHeading: {
    id: 'rentall.stepTwoHeading',
    defaultMessage: 'Stay in Control',
    description: 'Step 2',
  },
  stepThreeHeading: {
    id: 'rentall.stepThreeHeading',
    defaultMessage: 'Stay Confident',
    description: 'Step 3',
  },
  stepOneFlow: {
    id: 'rentall.stepOneFlow',
    defaultMessage:
      'Share your villa, condo or apartment and earn yourself an extra income. With over 12 years of experience, we make it simple and safe to reach guests that are looking for fun and unique Places to stay across Thailand and Bali.',
    description: 'Step 1',
  },
  stepTwoFlow: {
    id: 'rentall.stepTwoFlow',
    defaultMessage:
      'Stay in control with Rentall. You have full control of your availability, rates and rules. You can, block dates, set check-in times and manage your own communications directly with your guests through the system.',
    description: 'Step 2',
  },
  stepThreeFlow: {
    id: 'rentall.stepThreeFlow',
    defaultMessage:
      'We offer all the right tools to allow you to host in confidence. From a flexible booking system through to a secure payment system. Stay connected and confident with the latest technology.',
    description: 'Step 3',
  },
  anonymousMessage: {
    id: 'rentall.anonymousMessage',
    defaultMessage: 'Do you want to report this User?',
    description: 'Report User',
  },
  reportUserInfo: {
    id: 'rentall.reportUserInfo',
    defaultMessage: 'If so, please choose one of the following reasons.',
    description: 'Report User',
  },
  reportContent1: {
    id: 'rentall.reportContent1',
    defaultMessage: 'This profile should not be on',
    description: 'Report User',
  },
  reportContent2: {
    id: 'rentall.reportContent2',
    defaultMessage: 'Attempt to share contact information',
    description: 'Report User',
  },
  close: {
    id: 'rentall.close',
    defaultMessage: 'Close',
    description: 'Report User',
  },
  reportContent3: {
    id: 'rentall.reportContent3',
    defaultMessage: 'Inappropriate content of spam',
    description: 'Report User',
  },
  thankyouInfo1: {
    id: 'rentall.thankyouInfo1',
    defaultMessage:
      'Thanks for taking the time to report this user. These reports help make better for everyone, so we take them seriously. We will reach out if we have questions about your report.',
    description: 'Report User',
  },
  thankyouTitle: {
    id: 'rentall.thankyouTitle',
    defaultMessage: 'Thank You',
    description: 'Report User',
  },
  messageHistroyLabel: {
    id: 'rentall.messageHistroyLabel',
    defaultMessage: 'View',
    description: 'Report User',
  },
  titleRequiredLabel: {
    id: 'rentall.titleRequiredLabel',
    defaultMessage: 'Message History',
    description: 'Report User',
  },
  topBannerTitle: {
    id: 'rentall.topBannerTitle',
    defaultMessage: 'Its simple to become a RentALL host',
    description: 'Report User',
  },
  footerTerms: {
    id: 'rentall.footerTerms',
    defaultMessage: 'Terms, Privacy, Currency & More',
    description: 'Footer Button',
  },
  footerClose: {
    id: 'rentall.footerClose',
    defaultMessage: 'Close',
    description: 'Footer Button',
  },
  sendInvites: {
    id: 'rentall.sendInvites',
    defaultMessage: 'Send Invite',
    description: 'Send Invites',
  },
  sendInvitesViaText: {
    id: 'rentall.sendInvitesViaText',
    defaultMessage: 'Send Invites Via Text',
    description: 'Send Invites',
  },
  inputPhoneModalHeader: {
    id: 'rentall.inputPhoneModalHeader',
    defaultMessage: "Add your firend's info to invite her!",
    description: 'Send Invites',
  },
  linkedin: {
    id: 'rentall.linkedin',
    defaultMessage: 'Linkedin',
    description: 'Signup with Linkedin',
  },
  linkedinProfile: {
    id: 'rentall.linkedinProfile',
    defaultMessage: 'Linkedin Profile URL',
    description: 'Member Signup Linkedin Profile URL',
  },
  linkedinProfileRequired: {
    id: 'rentall.linkedinProfileRequired',
    defaultMessage: 'Profile URL is required',
    description: 'Linkedin Profile URL is required',
  },
  linkedinLogin: {
    id: 'rentall.linkedinLogin',
    defaultMessage: 'Login with Linkedin',
    description: 'Log in with Linkedin',
  },
  currentPosition: {
    id: 'rentall.currentPosition',
    defaultMessage: 'Current Position',
    description: 'Current Position',
  },
  education: {
    id: 'rentall.education',
    defaultMessage: 'University',
    description: 'University',
  },
  hostUniversity: {
    id: 'rentall.hostUniversity',
    defaultMessage: "Search by Host's University",
    description: 'University',
  },

  companyName: {
    id: 'rentall.companyName',
    defaultMessage: 'Company Name',
    description: 'Company Name',
  },
  industry: {
    id: 'rentall.industry',
    defaultMessage: 'Industry',
    description: 'Industry',
  },
  searchbyind: {
    id: 'rentall.searchbyind',
    defaultMessage: 'Search by industry',
    description: 'Industry',
  },
  linkedinUrl: {
    id: 'rentall.linkedinUrl',
    defaultMessage: 'Linkedin URL',
    description: 'Linkedin URL',
  },
  invalidLinkedinUrl: {
    id: 'rentall.invalidLinkedinUrl',
    defaultMessage: 'Please enter valid URL.',
    description: 'Invalid Linkedin URL',
  },
  professionalWebsite: {
    id: 'rentall.professionalWebsite',
    defaultMessage: 'Linkedin URL',
    description: 'Professional Website',
  },
  professionalWebsitePlaceHolder: {
    id: 'rentall.professionalWebsitePlaceHolder',
    defaultMessage: "If you don't use Linkedin, please leave it blank",
    description: 'Professional Website',
  },
  professionalWebsiteRequired: {
    id: 'rentall.professionalWebsiteRequired',
    defaultMessage: 'Linkedin URL or Professional Website is required',
    description: 'Professional Website is required',
  },
  professionalWebsiteBecomeMember: {
    id: 'rentall.professionalWebsiteBecomeMember',
    defaultMessage: 'Linkedin Profile or Personal Website',
    description: 'Professional Website',
  },
  professionalWebsiteBecomeMemberPlaceholder: {
    id: 'rentall.professionalWebsiteBecomeMemberPlaceholder',
    defaultMessage: 'If N/A please add anything to help us get to know you',
    description: 'Professional Website',
  },
  signupWithLinkedin: {
    id: 'rentall.signupWithLinkedin',
    defaultMessage: 'Sign Up with Linkedin',
    description: 'Sign Up Modal title',
  },
  maleSiteResponse: {
    id: 'rentall.maleSiteResponse',
    defaultMessage:
      'Unfortunately we cannot process your request at this time. A member of our team will be in touch or you can contact',
    description: 'Golightly is a private club for women.',
  },
  copy: {
    id: 'rentall.copy',
    defaultMessage: 'Copy',
    description: 'Copy',
  },
  commaEmail: {
    id: 'rentall.commaEmail',
    defaultMessage:
      'To send multiple invites, please separate emails with a comma.',
    description: 'or',
  },
  orEmailConent: {
    id: 'rentall.orEmailConent',
    defaultMessage: 'You can share this unique URL',
    description: 'or',
  },
  orConent: {
    id: 'rentall.orConent',
    defaultMessage: 'or',
    description: 'or',
  },
  kindOfProperty: {
    id: 'rentall.kingOfProperty',
    defaultMessage: 'What kind of property?',
    description: 'Description',
  },
  kindOfPlace: {
    id: 'rentall.kindOfPlace',
    defaultMessage: ' How many guests?',
    description: 'Description',
  },
  duplicateListingLabel: {
    id: 'rentall.duplicateListingLabel',
    defaultMessage: ' How many times do you want to duplicate?',
    description: 'Description',
  },
  duplicateListingLabel1: {
    id: 'rentall.duplicateListingLabel1',
    defaultMessage: ' Do you want to duplicate this listing?',
    description: 'Description',
  },
  duplicateListingLabel2: {
    id: 'rentall.duplicateListingLabel2',
    defaultMessage: 'Enter a number less than 50',
    description: 'Description',
  },
  duplicateListingError1: {
    id: 'rentall.duplicateListingError1',
    defaultMessage: 'Invalid, only numbers(eg: 10) are allowed',
    description: 'Description',
  },
  duplicateListingError2: {
    id: 'rentall.duplicateListingError2',
    defaultMessage: 'Please provide a value between 1 and 50.',
    description: 'Description',
  },
  duplicateListingError3: {
    id: 'rentall.duplicateListingError3',
    defaultMessage: 'Number is required.',
    description: 'Description',
  },
  manyGuest: {
    id: 'rentall.manyGuest',
    defaultMessage: ' How many guests?',
    description: 'Description',
  },
  manyBedrooms: {
    id: 'rentall.manyBedrooms',
    defaultMessage: 'How many bedrooms?',
    description: 'Description',
  },

  manyBeds: {
    id: 'rentall.manyBeds',
    defaultMessage: 'How many beds?',
    description: 'Description',
  },
  commonArea: {
    id: 'rentall.commonArea',
    defaultMessage: 'Common area',
    description: 'Become a host page',
  },
  commonListingArea: {
    id: 'rentall.commonListingArea',
    defaultMessage: 'Common Area',
    description: 'View Listing Page',
  },
  sleepingArrangements: {
    id: 'rentall.sleepingArrangements',
    defaultMessage: 'Sleeping arrangements',
    description: 'Become a host page',
  },
  sleepingContent: {
    id: 'rentall.sleepingContent',
    defaultMessage:
      'Sharing the types of beds in each room can help people understand the sleeping arrangements.',
    description: 'Become a host page',
  },
  womensOnly: {
    id: 'rentall.womensOnly',
    defaultMessage: 'Women-Only',
    description: 'Become a host page',
  },
  stayedGuest: {
    id: 'rentall.stayedGuest',
    defaultMessage: 'Stayed Guest',
    description: 'Receipt page',
  },
  allowOnlyWomen: {
    id: 'rentall.allowOnlyWomen',
    defaultMessage: 'Women only allowed.',
    description: 'Calendar',
  },
  onlyWomen: {
    id: 'rentall.onlyWomen',
    defaultMessage: 'Women only',
    description: 'Calendar',
  },
  singleWomenNeed: {
    id: 'rentall.singleWomenNeed',
    defaultMessage: 'At lease single woman is required',
    description: 'Calendar',
  },
  women: {
    id: 'rentall.women',
    defaultMessage: 'Woman',
    description: 'View message page',
  },
  men: {
    id: 'rentall.men',
    defaultMessage: 'Man',
    description: 'View message page',
  },
  child: {
    id: 'rentall.child',
    defaultMessage: 'Child',
    description: 'View message page',
  },
  womens: {
    id: 'rentall.womens',
    defaultMessage: 'Women',
    description: 'View message page',
  },
  mens: {
    id: 'rentall.mens',
    defaultMessage: 'Men',
    description: 'View message page',
  },
  children: {
    id: 'rentall.children',
    defaultMessage: 'Children',
    description: 'View message page',
  },
  clear: {
    id: 'rentall.clear',
    defaultMessage: 'Clear',
    description: 'clear',
  },
  apply: {
    id: 'rentall.apply',
    defaultMessage: 'Apply',
    description: 'apply',
  },
  privateMessage: {
    id: 'rentall.privateMessage',
    defaultMessage: 'Networking',
    description: 'Inbox',
  },
  privateMessageLabel: {
    id: 'rentall.privateMessageLabel',
    defaultMessage: 'Message',
    description: 'Inbox',
  },
  invalidReferralTitle: {
    id: 'rentall.invalidReferralTitle',
    defaultMessage: "We're Sorry!",
    description: 'Invalid Referral',
  },
  invalideReferralSubTitle: {
    id: 'rentall.invalideReferralSubTitle',
    defaultMessage:
      'Your referral code has expired please submit your application to request a new code',
    description: 'Invalid Referral',
  },
  clickHere: {
    id: 'rentall.clickHere',
    defaultMessage: 'Click Here',
    description: 'Invalid Referral',
  },
  isMenStaying: {
    id: 'rentall.isMenStaying',
    defaultMessage: 'Will there be men staying on the property?',
    description: 'Become a host page',
  },
  isMenStay: {
    id: 'rentall.isMenStay',
    defaultMessage: 'Men Staying',
    description: 'View listing page',
  },
  stayingWith: {
    id: 'rentall.stayingWith',
    defaultMessage: 'Who can also stay',
    description: 'View listing page',
  },
  whoElseStaying: {
    id: 'rentall.whoElseStaying',
    defaultMessage: 'Who else will be staying on the property?',
    description: 'Become a host page',
  },
  whoElseStayingPlaceholder: {
    id: 'rentall.whoElseStayingPlaceholder',
    defaultMessage:
      'Please let your guest know who else will be on the premises while she is there.',
    description: 'Become a host page',
  },
  yes: {
    id: 'rentall.yes',
    defaultMessage: 'Yes',
    description: 'Become a host page',
  },
  no: {
    id: 'rentall.no',
    defaultMessage: 'No',
    description: 'Become a host page',
  },
  phoneNumberContent: {
    id: 'rentall.phoneNumberContent',
    defaultMessage:
      'Please confirm your phone number to help so your host can reach you.',
    description: 'Verification info',
  },
  listNotFoundTitle: {
    id: 'rentall.listNotFoundTitle',
    defaultMessage: 'It looks like this listing may deleted or unpublished.',
    description: 'Not Found',
  },
  inviteBlockResponse1: {
    id: 'rentall.inviteBlockResponse1',
    defaultMessage: 'You do not have any invites left, please contact ',
    description: 'Golightly Referral invite blocked.',
  },
  inviteBlockResponse2: {
    id: 'rentall.inviteBlockResponse2',
    defaultMessage: 'if you would like to request additional invites.',
    description: 'Golightly Referral invite blocked.',
  },
  introduceAfriend: {
    id: 'rentall.introduceAfriend',
    defaultMessage: 'Introduce your friends to',
    description: 'Introduce a friend to',
  },
  theyWillGet: {
    id: 'rentall.theyWillGet',
    defaultMessage: 'You’ll get ',
    description: 'They’ll get',
  },
  whenTheySignup: {
    id: 'rentall.whenTheySignup',
    defaultMessage:
      ' in travel credit for each one that signs up and completes her profile, and she’ll get ',
    description: 'Travel Credit',
  },
  onceCompleted: {
    id: 'rentall.onceCompleted',
    defaultMessage: ' in credit. ',
    description: 'Travel Credit',
  },
  onlyFor: {
    id: 'rentall.onlyFor',
    defaultMessage: ' Only for new ',
    description: 'Travel Credit',
  },
  readTheTerms: {
    id: 'rentall.readTheTerms',
    defaultMessage: ' Read the Terms. ',
    description: 'Travel Credit',
  },
  youHaveCredit: {
    id: 'rentall.youHaveCredit',
    defaultMessage: "You've got",
    description: 'Travel Credit',
  },
  creditToSpend: {
    id: 'rentall.creditToSpend',
    defaultMessage: ' in travel credit to spend',
    description: 'Travel Credit',
  },
  applyCredit: {
    id: 'rentall.applyCredit',
    defaultMessage: 'Apply Credit',
    description: 'Travel Credit',
  },
  credit: {
    id: 'rentall.credit',
    defaultMessage: 'Referral Credit',
    description: 'Menu item',
  },
  creditApplied: {
    id: 'rentall.creditApplied',
    defaultMessage: 'Travel credit applied',
    description: 'Itinerary',
  },
  doYouIntend: {
    id: 'rentall.doYouIntend',
    defaultMessage: 'Do you intend to?',
    description: 'Register Form',
  },
  travel: {
    id: 'rentall.travel',
    defaultMessage: 'Travel',
    description: 'Register Form',
  },
  both: {
    id: 'rentall.both',
    defaultMessage: 'Both',
    description: 'Register Form',
  },
  maximumCharcter: {
    id: 'rentall.maximumCharcter',
    defaultMessage: '(Allows Maximum 50 characters)',
    description: 'Description',
  },
  maximumCharcterLeft: {
    id: 'rentall.maximumCharcterLeft',
    defaultMessage: 'characters remaining',
    description: 'Description',
  },
  addAnotherBed: {
    id: 'rentall.addAnotherBed',
    defaultMessage: 'Add another bed',
    description: 'Become a host form',
  },
  blockDates: {
    id: 'rentall.blockedDates',
    defaultMessage: 'Block',
    description: 'Listing Step3',
  },
  deSelect: {
    id: 'rentall.deSelect',
    defaultMessage: 'Cancel',
    description: 'Cancel deSelect button',
  },
  adminCreated: {
    id: 'rentall.adminCreated',
    defaultMessage: 'This list was created by admin',
    description: 'Your calendar has been updated successfully.',
  },
  // selectedDatesError: {
  //   id: 'rentall.selectedDatesError',
  //   defaultMessage: 'Something went wrong, please try again.',
  //   description: 'Something went wrong, please try again.'
  // },
  selectedDates: {
    id: 'rentall.selectedDates',
    defaultMessage: 'Selected dates',
    description: 'Selected dates',
  },
  makeDateAvailable: {
    id: 'rentall.makeDateAvailable',
    defaultMessage: 'Special Price',
    description: 'Listing Step3',
  },
  sessionPrice: {
    id: 'rentall.sessionPrice',
    defaultMessage: 'Special Price',
    description: 'sessionPrice',
  },
  describeYourself: {
    id: 'rentall.describeYourself',
    defaultMessage:
      "Help us get to know you better. What's your favorite vacation spot? Any pets? Tea or coffee? Wine or whiskey?",
    description: 'describeYourself',
  },
  summaryInfoText: {
    id: 'rentall.summaryInfoText',
    defaultMessage:
      'Share some info about your professional life. Your present role. Your experience. Your expertise. Your successes. Or what you’d really like to be doing!',
    description: 'summaryInfoText',
  },
  editProfileMessage: {
    id: 'rentall.editProfileMessage',
    defaultMessage:
      'Profiles are the foundation of Golightly.  By sharing more about yourself, Golightly is able to find better matches for the best accommodations for you.',
    description: 'edit profile message',
  },
  showUserMessage: {
    id: 'rentall.showUserMessage',
    defaultMessage:
      "If you haven't already please add your profile photo. Then check out some of our rentals by searching, check out the hosting page to see if you might want to host, and please spread the word! Request others to join today!",
    description: 'show profile message',
  },

  referAboutUsPlaceholder: {
    id: 'rentall.referAboutUsPlaceholder',
    defaultMessage:
      "Do you love to travel? Do you want to rent out your property or a spare room? Let us know if you'd like to be a Golightly host! And tell us anything else you'd like to share!",
    description: 'Become a member refer about us placeholder',
  },

  thanksMemberFormMessage: {
    id: 'rentall.thanksMemberFormMessage',
    defaultMessage:
      'Thank you for your interest in Golightly. We will review your membership application and get back to you soon!',
    description: 'Memnbership form submit success message',
  },
  memberReferredYou: {
    id: 'rentall.memberReferredYou',
    defaultMessage: 'How Did You Hear About Golightly?',
    description: 'Let us know if a member referred you!',
  },
  emailLabel: {
    id: 'rentall.emailLabel',
    defaultMessage: 'Email',
    description: 'Your Email',
  },
  addPhoneNumber: {
    id: 'rentall.addPhoneNumber',
    defaultMessage: ' Add Phone Number (without dashes or spaces)',
    description: 'Phone number ',
  },
  firstNameLabel: {
    id: 'rentall.firstNameLabel',
    defaultMessage: 'First Name',
    description: 'Phone number ',
  },
  lastNameLabel: {
    id: 'rentall.lastNameLabel',
    defaultMessage: ' Last Name',
    description: 'Phone number ',
  },

  howDidAbout: {
    id: 'rentall.howDidAbout',
    defaultMessage:
      'Tell Us a Bit More About Yourself! We Love Getting to Know Our Members!',
    description: 'Why Golightly?',
  },

  ssnMessage: {
    id: 'rentall.ssnMessage',
    defaultMessage: 'Note: SSN number is only used for Stripe verification',
    description: 'Add payout page ssn message',
  },

  removeDateError: {
    id: 'rentall.removeDateError',
    defaultMessage: 'Please select blocked or available date',
    description: 'Remove date from special pricing error message',
  },

  unBlockInfo: {
    id: 'rentall.unBlockInfo',
    defaultMessage:
      'Note: To unblock a date, select any blocked or available date and save without giving special price.',
    description: 'calendar unblock info',
  },
  selectDropDown: {
    id: 'rentall.selectDropDown',
    defaultMessage: 'Select',
    description: 'Become a host Page',
  },
  other: {
    id: 'rentall.other',
    defaultMessage: 'Other',
    description: 'Edit profile form',
  },
  otherUniversity: {
    id: 'rentall.otherUniversity',
    defaultMessage: 'Other University',
    description: 'Edit profile form',
  },
  neighbourHood: {
    id: 'rentall.neighbourHood',
    defaultMessage: 'Neighborhood',
    description: 'Become Host Form',
  },
  theNeighbourhood: {
    id: 'rentall.theNeighbourhood',
    defaultMessage: 'The Neighborhood',
    description: 'The neighbourhood',
  },
  theNeighbourhoodSubTitle: {
    id: 'rentall.theNeighbourhoodSubTitle',
    defaultMessage:
      "Be transparent about the neighborhood. Let your guests know if it's safe to walk alone at night. If it's well-lit. If it's loud. Anything they may need to know. And your favorite spots!",
    description: 'The neighbourhood sub Title',
  },
  theNeighbourhoodRequired: {
    id: 'rentall.theNeighbourhoodRequired',
    defaultMessage: 'Neighbourhood is required',
    description: 'The neighbourhood is required',
  },
  hideViewListing: {
    id: 'rentall.hideViewListing',
    defaultMessage: 'Hide',
    description: 'whyvegvisits',
  },
  showReadMore: {
    id: 'rentall.readMore',
    defaultMessage: 'Read More',
    description: 'Read More',
  },
  publishSubmit: {
    id: 'rentall.publishSubmit',
    defaultMessage: 'Submit',
    description: 'Publish Submit',
  },
  publishPending: {
    id: 'rentall.publishPending',
    defaultMessage: 'Pending',
    description: 'Publish Pending',
  },
  publishPendingStatus: {
    id: 'rentall.publishPendingStatus',
    defaultMessage: 'Your Listing is waiting for Approval',
    description: 'Publish Pending',
  },
  publishSubmitStatus: {
    id: 'rentall.publishSubmitStatus',
    defaultMessage: 'Submit your listing for Approval',
    description: 'Publish Submit',
  },
  approvedStatus: {
    id: 'rentall.approvedStatus',
    defaultMessage: 'Approved',
    description: 'approvedStatus',
  },
  awaitingStatus: {
    id: 'rentall.awaitingStatus',
    defaultMessage: 'Awaiting Approval',
    description: 'awaitingStatus',
  },
  yourRequestPending: {
    id: 'rentall.yourRequestPending',
    defaultMessage: 'Your Request is pending',
    description: 'yourRequestPending',
  },
  notCharge: {
    id: 'rentall.notCharge',
    defaultMessage: 'You will not be charged',
    description: 'notCharge',
  },
  progressBarText3: {
    id: 'rentall.progressBarText3',
    defaultMessage: '. Please submit your list for approval',
    description: 'progressBarText3',
  },
  infants: {
    id: 'rentall.infants',
    defaultMessage: 'Infants',
    description: 'infants',
  },
  infant: {
    id: 'rentall.infant',
    defaultMessage: 'Infant',
    description: 'infant',
  },
  pets: {
    id: 'rentall.pets',
    defaultMessage: 'Pets',
    description: 'pets',
  },
  pet: {
    id: 'rentall.pet',
    defaultMessage: 'Pet',
    description: 'pet',
  },
  bookingInfantLabel: {
    id: 'rentall.bookingInfantLabel',
    defaultMessage: "Infants don't count toward the number of guests.",
    description: 'bookingInfantLabel',
  },
  exceedLimit: {
    id: 'rentall.exceedLimit',
    defaultMessage: 'Limit Exceeded',
    description: 'exceedLimit',
  },
  moreDetails: {
    id: 'rentall.moreDetails',
    defaultMessage: 'More Details',
    description: 'moreDetails',
  },
  allTrips: {
    id: 'rentall.allTrips',
    defaultMessage: 'All Trips',
    description: 'allTrips',
  },
  allReservation: {
    id: 'rentall.allReservation',
    defaultMessage: 'All Reservations',
    description: 'allReservation',
  },
  syncCalendarNote: {
    id: 'rentall.syncCalendarNote',
    defaultMessage:
      'Note: ICal will be available once your listing is completed. Please come back and connect.',
    description: 'allReservation',
  },
  syncCalendarTitle: {
    id: 'rentall.syncCalendarTitle',
    defaultMessage: 'Integrate your existing Calendar',
    description: 'syncCalendarTitle',
  },
  tripesEmail: {
    id: 'rentall.tripesEmail',
    defaultMessage: 'I Checked in Safely',
    description: 'allReservation',
  },
  readMoreAboutSpace: {
    id: 'rentall.readMoreAboutSpace',
    defaultMessage: 'Read more about the space',
    description: 'readMoreAboutSpace',
  },
  noMatchListing: {
    id: 'rentall.noMatchListing',
    defaultMessage: 'No results matched',
    description: 'noMatchListing',
  },
  noMatchListingSubText: {
    id: 'rentall.noMatchListingSubText',
    defaultMessage: 'Here are some other listings in your destination.',
    description: 'noMatchListingSubText',
  },
  someOtherListing: {
    id: 'rentall.someOtherListing',
    defaultMessage: 'Other Listings',
    description: 'someOtherListing',
  },
  invalidInput: {
    id: 'rentall.invalidInput',
    defaultMessage: 'Invalid Input',
    description: 'invalidInput',
  },
  minNightStart: {
    id: 'rentall.minNightStart',
    defaultMessage: 'Min nights should be greater than',
    description: 'minNightStart',
  },
  minNightEnd: {
    id: 'rentall.minNightEnd',
    defaultMessage: 'Min nights should be lesser than',
    description: 'minNightEnd',
  },
  maxNightStart: {
    id: 'rentall.maxNightStart',
    defaultMessage: 'Max nights should be greater than',
    description: 'maxNightStart',
  },
  maxNightEnd: {
    id: 'rentall.maxNightEnd',
    defaultMessage: 'Max nights should be lesser than',
    description: 'maxNightEnd',
  },
  flexibleDescription: {
    id: 'rentall.flexibleDescription',
    defaultMessage: 'Full refund 1 day prior to arrival',
    description: 'flexibleDescription',
  },
  moderateDescription: {
    id: 'rentall.moderateDescription',
    defaultMessage: 'Full refund 5 days prior to arrival',
    description: 'moderateDescription',
  },
  strictDescription: {
    id: 'rentall.strictDescription',
    defaultMessage: '50% refund up until 1 week prior to arrival',
    description: 'strictDescription',
  },
  superStrictDescription: {
    id: 'rentall.superStrictDescription',
    defaultMessage: '50% refund up until 30 days prior to arrival',
    description: 'superStrictDescription',
  },
  chooseYourPolicy: {
    id: 'rentall.chooseYourPolicy',
    defaultMessage: 'Choose your policy for trip cancellations by guests.',
    description: 'Choose your policy',
  },
  viewCancellationDetails: {
    id: 'rentall.viewCancellationDetails',
    defaultMessage: 'View the full details for these cancellation policies.',
    description: 'Choose your policy',
  },
  passwordError9: {
    id: 'rentall.passwordError9',
    defaultMessage: 'New password should be different from the old one.',
    description: 'Password Error',
  },
  listDeleted: {
    id: 'rentall.listDeleted',
    defaultMessage: 'List deleted',
    description: 'List deleted',
  },
  userDeleted: {
    id: 'rentall.userDeleted',
    defaultMessage: 'User deleted',
    description: 'User deleted',
  },
  securityFee: {
    id: 'rentall.securityFee',
    defaultMessage: 'Security Deposit',
    description: 'securityFee',
  },
  securityFeeDescription: {
    id: 'rentall.securityFeeDescription',
    defaultMessage:
      'Shown to guest before they book. To claim, please submit any damage report within 7 days after check-out and before your next guest checks in.',
    description: 'securityFeeDescription',
  },
  securityFeePlaceHolder: {
    id: 'rentall.securityFeePlaceHolder',
    defaultMessage: 'Security Deposit',
    description: 'securityFeePlaceHolder',
  },
  securityFeeInvalid: {
    id: 'rentall.securityFeeInvalid',
    defaultMessage:
      'Invalid Security Deposit, only numbers(eg: 25) are allowed',
    description: 'securityFeeInvalid',
  },
  securityFeeRefund: {
    id: 'rentall.securityFeeRefund',
    defaultMessage: 'Refundable Security Deposit',
    description: 'securityFeeRefund',
  },
  refundSecurityFee: {
    id: 'rentall.refundSecurityFee',
    defaultMessage: 'Refundable Security Deposit',
    description: 'refundSecurityFee',
  },
  refundableDamageDepositFee: {
    id: 'rentall.refundableDamageDepositFee',
    defaultMessage: 'Refundable',
    description: 'Refundable Damage Deposit fee',
  },
  hostAwayNotIncludedInPrice: {
    id: 'rentall.hostAwayNotIncludedInPrice',
    defaultMessage: 'Not included in total price',
    description: 'Not included in total price',
  },
  hostAwayRefundable: {
    id: 'rentall.hostAwayRefundable',
    defaultMessage: 'Refundable',
    description: 'Refundable',
  },
  claimFee: {
    id: 'rentall.claimFee',
    defaultMessage: 'Claim Security Deposit',
    description: 'claimFee',
  },
  claimedFee: {
    id: 'rentall.claimedFee',
    defaultMessage: 'Claimed Security Deposit',
    description: 'claimedFee',
  },
  extraCharges: {
    id: 'rentall.extraCharges',
    defaultMessage: 'Extra Guests',
    description: 'extraCharges',
  },
  afterMoreThan: {
    id: 'rentall.afterMoreThan',
    defaultMessage: 'After ',
    description: 'afterMoreThan',
  },
  guestsLabel: {
    id: 'rentall.guestsLabel',
    defaultMessage: 'guests',
    description: 'guestsLabel',
  },
  guestLabel: {
    id: 'rentall.guestLabel',
    defaultMessage: 'guest',
    description: 'guestHost',
  },
  charge: {
    id: 'rentall.charge',
    defaultMessage: 'charge',
    description: 'charge',
  },
  perPerson: {
    id: 'rentall.perPerson',
    defaultMessage: 'per person, per night',
    description: 'perPerson',
  },
  extraGuestFee: {
    id: 'rentall.extraGuestFee',
    defaultMessage: 'Extra guest price',
    description: 'extraGuestFee',
  },
  extraGuestFeeInvalid: {
    id: 'rentall.extraGuestFeeInvalid',
    defaultMessage: 'Invalid Extra Guest Fee, only numbers(eg: 25) are allowed',
    description: 'extraGuestFeeInvalid',
  },
  forEachGuestAfter: {
    id: 'rentall.forEachGuestAfter',
    defaultMessage: 'For each guest after',
    description: 'forEachGuestAfter',
  },
  extraGuests: {
    id: 'rentall.extraGuests',
    defaultMessage: 'Extra guests',
    description: 'extraGuests',
  },
  extraGuestIncluded: {
    id: 'rentall.extraGuestIncluded',
    defaultMessage: 'extra guests included',
    description: 'extraGuestIncluded',
  },
  addExclusive: {
    id: 'rentall.addExclusive',
    defaultMessage:
      'Is this property going to be exclusively listed on Golightly?',
    description: 'addExclusive',
  },
  letSearchHighlight: {
    id: 'rentall.letSearchHighlight',
    defaultMessage:
      '(Let us know as we will be sure to highlight you in search results).',
    description: 'letSearchHighlight',
  },
  exclusiveProperty: {
    id: 'rentall.exclusiveProperty',
    defaultMessage: 'Exclusive Property',
    description: 'exclusiveProperty',
  },
  exclusivePropertyInfo: {
    id: 'rentall.exclusivePropertyInfo',
    defaultMessage: 'Listings that are added as Exclusive properties',
    description: 'exclusivePropertyInfo',
  },
  exclusive: {
    id: 'rentall.exclusive',
    defaultMessage: 'Exclusive',
    description: 'exclusive',
  },
  weekEndPrice: {
    id: 'rentall.weekEndPrice',
    defaultMessage: 'Weekend Price',
    description: 'pricing title',
  },
  weekEndPriceInvalid: {
    id: 'rentall.weekEndPriceInvalid',
    defaultMessage: 'Invalid Weekend Price, only numbers(eg: 25) are allowed',
    description: 'Weekend price Invalid',
  },
  familyFriendlyAmenities: {
    id: 'rentall.familyFriendlyAmenities',
    defaultMessage: 'Family Friendly Amenities',
    description: 'familyFriendlyAmenities  Headline',
  },
  uniqueAmenities: {
    id: 'rentall.uniqueAmenities',
    defaultMessage: 'Unique Amenities',
    description: 'uniqueAmenities Headline',
  },
  businessAmenities: {
    id: 'rentall.businessAmenities',
    defaultMessage: 'Business Amenities',
    description: 'businessAmenities Headline',
  },
  otherAmenities: {
    id: 'rentall.otherAmenities',
    defaultMessage: 'Other Amenities',
    description: 'other amenities',
  },
  seeAll: {
    id: 'rentall.seeAll',
    defaultMessage: 'See All',
    description: 'seeAll',
  },
  guestCaps: {
    id: 'rentall.guestCaps',
    defaultMessage: 'Guest',
    description: 'Guest',
  },
  checkOutTime: {
    id: 'rentall.checkOutTime',
    defaultMessage: 'Checkout Time',
    description: 'checkOutTime',
  },
  advanceNoticeCheckOutTitle: {
    id: 'rentall.advanceNoticeCheckOutTitle',
    defaultMessage: 'When can guests check out?',
    description: 'Advance Notice Check Out Title',
  },
  essentialamenities: {
    id: 'rentall.essentialamenities',
    defaultMessage: 'Essential Amenities',
    description: 'essentialamenities',
  },
  showAmenities1: {
    id: 'rentall.showAmenities1',
    defaultMessage: 'Show all',
    description: 'Toggle Button',
  },
  showAmenities2: {
    id: 'rentall.showAmenities2',
    defaultMessage: 'amenities',
    description: 'Toggle Button',
  },
  closeAmenities1: {
    id: 'rentall.closeAmenities1',
    defaultMessage: 'Close all amenities',
    description: 'Toggle Button',
  },
  showSpaces: {
    id: 'rentall.showSpaces',
    defaultMessage: 'Show all Spaces',
    description: 'Toggle Button',
  },
  closeSpaces: {
    id: 'rentall.closeSpaces',
    defaultMessage: 'Close all Spaces',
    description: 'Toggle Button',
  },
  showRules: {
    id: 'rentall.showRules',
    defaultMessage: 'Show all House Rules',
    description: 'Toggle Button',
  },
  closeRules: {
    id: 'rentall.closeRules',
    defaultMessage: 'Close all House Rules',
    description: 'Toggle Button',
  },
  ourMission: {
    id: 'rentall.ourMission',
    defaultMessage: 'Our Mission',
    description: 'About Us',
  },
  ourMissionDecs: {
    id: 'rentall.ourMissionDecs',
    defaultMessage:
      'From day one, the mission of Golightly has been to create the world’s most interconnected network of women who love to travel and provide them with a safe trusted community platform to book her accommodations, transportation, attractions and experiences.',
    description: 'About Us',
  },
  ourPurpose: {
    id: 'rentall.ourPurpose',
    defaultMessage: 'Our Purpose',
    description: 'About Us',
  },
  ourPurposeDesc1: {
    id: 'rentall.ourPurposeDesc1',
    defaultMessage:
      'Given the number of horror stories coming out of traditional home sharing and vacation rental platforms, we saw the need for a marketplace by women, for women, to ensure an added level of security, safety and quality missing in the industry. Golightly helps women travel safely and love where they stay.',
    description: 'About Us',
  },
  ourWork: {
    id: 'rentall.ourWork',
    defaultMessage: 'Our Work',
    description: 'About Us',
  },
  ourWorkDesc1: {
    id: 'rentall.ourWorkDesc1',
    defaultMessage:
      'Founded in Austin in 2018 as an invite only club for women, all properties are owned or managed by women. Bookings are only made by women within the network, though they can travel with whomever they wish.',
    description: 'About Us',
  },
  ourWorkDesc2: {
    id: 'rentall.ourWorkDesc2',
    defaultMessage:
      'With secure, comfortable accommodations and all the luxuries of home, Golightly encourages you to leave life’s baggage behind and go lightly.',
    description: 'About Us',
  },
  ourAdvisors: {
    id: 'rentall.ourAdvisors',
    defaultMessage: 'Our Board of Advisors',
    description: 'About Us',
  },
  advisorsName1: {
    id: 'rentall.advisorsName1',
    defaultMessage: 'Lynn Atchison',
    description: 'About Us',
  },
  advisorsType1: {
    id: 'rentall.advisorsType1',
    defaultMessage: 'Financial Advisor',
    description: 'About Us',
  },
  advisorsRole1: {
    id: 'rentall.advisorsRole1',
    defaultMessage: 'CFO, Spredfast;',
    description: 'About Us',
  },
  advisorsRoleSub1: {
    id: 'rentall.advisorsRoleSub1',
    defaultMessage: 'CFO, HomeAway',
    description: 'About Us',
  },
  advisorsName2: {
    id: 'rentall.advisorsName2',
    defaultMessage: 'Melissa Fortenberry',
    description: 'About Us',
  },
  advisorsType2: {
    id: 'rentall.advisorsType2',
    defaultMessage: 'Product Advisor',
    description: 'About Us',
  },
  advisorsRole2: {
    id: 'rentall.advisorsRole2',
    defaultMessage: 'VP, Product, RVShare;',
    description: 'About Us',
  },
  advisorsRoleSub2: {
    id: 'rentall.advisorsRoleSub2',
    defaultMessage: 'VP, Global Partner and Product ',
    description: 'About Us',
  },
  advisorsRoleSubTwo2: {
    id: 'rentall.advisorsRoleSubTwo2',
    defaultMessage: 'Marketing, HomeAway',
    description: 'About Us',
  },

  advisorsName3: {
    id: 'rentall.advisorsName3',
    defaultMessage: 'Melissa Fruge',
    description: 'About Us',
  },
  advisorsType3: {
    id: 'rentall.advisorsType3',
    defaultMessage: 'Legal Advisor',
    description: 'About Us',
  },
  advisorsRole3: {
    id: 'rentall.advisorsRole3',
    defaultMessage: 'VP Legal, OYO;',
    description: 'About Us',
  },
  advisorsRoleSub3: {
    id: 'rentall.advisorsRoleSub3',
    defaultMessage: 'General Counsel, HomeAway',
    description: 'About Us',
  },

  advisorsName4: {
    id: 'rentall.advisorsName4',
    defaultMessage: 'Emily Thawley',
    description: 'About Us',
  },
  advisorsType4: {
    id: 'rentall.advisorsType4',
    defaultMessage: 'Marketing Advisor',
    description: 'About Us',
  },
  advisorsRole4: {
    id: 'rentall.advisorsRole4',
    defaultMessage: 'Director of Product Marketing, HomeAway',
    description: 'About Us',
  },

  advisorsName5: {
    id: 'rentall.advisorsName5',
    defaultMessage: 'Shanaz Hemmati',
    description: 'About Us',
  },
  advisorsType5: {
    id: 'rentall.advisorsType5',
    defaultMessage: 'Tech Advisor',
    description: 'About Us',
  },
  advisorsRole5: {
    id: 'rentall.advisorsRole5',
    defaultMessage: 'COO, ZenBusiness;',
    description: 'About Us',
  },
  advisorsRoleSub5: {
    id: 'rentall.advisorsRoleSub5',
    defaultMessage: 'Global VP, Information Systems, HomeAway',
    description: 'About Us',
  },

  advisorsName6: {
    id: 'rentall.advisorsName6',
    defaultMessage: 'Matt Curtis',
    description: 'About Us',
  },
  advisorsType6: {
    id: 'rentall.advisorsType6',
    defaultMessage: 'Policy Advisor',
    description: 'About Us',
  },
  advisorsRole6: {
    id: 'rentall.advisorsRole6',
    defaultMessage: 'Founder, SmartCity Policy Group; Sr Director',
    description: 'About Us',
  },
  advisorsRoleSub6: {
    id: 'rentall.advisorsRoleSub6',
    defaultMessage: 'Global Govt. Affairs, HomeAway',
    description: 'About Us',
  },
  newYorkCity: {
    id: 'rentall.newYorkCity',
    defaultMessage: 'New England',
    description: 'Menu item',
  },
  ourStory: {
    id: 'rentall.ourStory',
    defaultMessage: 'our Story',
    description: 'ourStory heading',
  },
  ceoName: {
    id: 'rentall.ceoName',
    defaultMessage: "Victoria O'Connell",
    description: 'ourStory heading',
  },
  walkScore: {
    id: 'rentall.walkScore',
    defaultMessage: 'Walk Score',
    description: 'walkScore',
  },
  showMore: {
    id: 'rentall.showMore',
    defaultMessage: 'See all',
    description: 'Show More',
  },
  showLess: {
    id: 'rentall.showLess',
    defaultMessage: 'Hide',
    description: 'Show Less',
  },
  members: {
    id: 'rentall.members',
    defaultMessage: 'Members',
    description: 'members',
  },
  transitScore: {
    id: 'rentall.transitScore',
    defaultMessage: 'Transit Score',
    description: 'transitScore',
  },
  bikeScore: {
    id: 'rentall.bikeScore',
    defaultMessage: 'Bike Score',
    description: 'bikeScore',
  },
  propertyManager: {
    id: 'rentall.propertyManager',
    defaultMessage: 'Property Manager',
    description: 'Property Manager',
  },
  memberShipText: {
    id: 'rentall.memberShipText',
    defaultMessage: 'Interested in joining',
    description: 'Membership',
  },
  socialMedia: {
    id: 'rentall.socialMedia',
    defaultMessage: 'Social Media Page or Link to Property Page',
    description: 'Social Media Handle',
  },
  socialMediaEditProfile: {
    id: 'rentall.socialMediaEditProfile',
    defaultMessage: 'Social Media Handle',
    description: 'Social Media Handle',
  },
  socialMediaURLPlaceholder: {
    id: 'rentall.socialMediaURLPlaceholder',
    defaultMessage: 'Add Facebook or Instagram to connect with us.',
    description: 'socialMediaURLPlaceholder',
  },
  fbAccount: {
    id: 'rentall.fbAccount',
    defaultMessage: 'Facebook Account URL',
    description: 'Facebook Account',
  },
  instaAccount: {
    id: 'rentall.instaAccount',
    defaultMessage: 'Instagram Account URL',
    description: 'Instagram Account',
  },
  socialMediaUrlRequired: {
    id: 'rentall.socialMediaUrlRequired',
    defaultMessage: 'Facebook or Instagram Account URL Required',
    description: 'Facebook or Instagram Account Required',
  },
  invalidSocialMediaUrl: {
    id: 'rentall.invalidSocialMediaUrl',
    defaultMessage: 'Please enter valid URL.',
    description: 'Invalid Url',
  },
  invalidText: {
    id: 'rentall.invalidText',
    defaultMessage:
      'We would really appreciate if you do not enter any other website information here.',
    description: 'Other website information',
  },
  hintDOB: {
    id: 'rentall.hintDOB',
    defaultMessage:
      'Date of birth is required to verify that all members are 21 or over. Your age and birthday will never be visible or shared.',
    description: 'hintDOB',
  },
  insta: {
    id: 'rentall.insta',
    defaultMessage: 'Instagram',
    description: 'Instagram',
  },
  propPage: {
    id: 'rentall.propPage',
    defaultMessage: 'Property Page or Other Social Link',
    description: 'Property Page',
  },
  propPageURL: {
    id: 'rentall.propPageURL',
    defaultMessage: 'If N/A add anything to help us get to know you',
    description: 'Property Page URL',
  },
  socialMediaURL: {
    id: 'rentall.socialMediaURL',
    defaultMessage: 'Facebook or Instagram Account URL',
    description: 'socialMediaURL',
  },
  memberSuggested: {
    id: 'rentall.memberSuggested',
    defaultMessage: 'And let us know if a member referred you!',
    description: "What is the member's name?",
  },
  aboutBioOne: {
    id: 'rentall.aboutBioOne',
    defaultMessage:
      'Victoria is an e-commerce entrepreneur with over 17 years of experience co-founding and managing JDP Global.',
    description: 'About bio Content',
  },
  secondaryEmailInvalid: {
    id: 'rentall.secondaryEmailInvalid',
    defaultMessage: 'Please add different email',
    description: 'Please add different email',
  },
  meetupInfoDesc: {
    id: 'rentall.meetupInfoDesc',
    defaultMessage:
      "We'll ask you for a photo of your ID, and you'll only do this once.",
    description: 'Meetup information',
  },
  meetupInfoDescTitle: {
    id: 'rentall.meetupInfoDescTitle',
    defaultMessage: "Before you book, let's add your ID",
    description: 'Meetup information',
  },
  meetupInfoBtn: {
    id: 'rentall.meetupInfoBtn',
    defaultMessage: 'Add an ID >',
    description: 'Meetup information',
  },
  idProfBtnOne: {
    id: 'rentall.idProfBtnOne',
    defaultMessage: 'Next >',
    description: 'Meetup information',
  },
  idProfDriver: {
    id: 'rentall.idProfDriver',
    defaultMessage: "Driver's license",
    description: 'Meetup information',
  },
  idProfPassport: {
    id: 'rentall.idProfPassport',
    defaultMessage: 'Passport',
    description: 'Meetup information',
  },
  idProfIdCard: {
    id: 'rentall.idProfIdCard',
    defaultMessage: 'Identity card',
    description: 'Meetup information',
  },
  idProfIdTitle: {
    id: 'rentall.idProfIdTitle',
    defaultMessage: 'Select an ID type to add',
    description: 'Meetup information',
  },
  reviewGuestDescription: {
    id: 'rentall.reviewGuestDescription',
    defaultMessage: 'Please agree to the following:',
    description: 'Guests can book available',
  },
  savedProperties: {
    id: 'rentall.savedProperties',
    defaultMessage: 'Saved Properties',
    description: 'Saved Properties',
  },
  approvalDescription: {
    id: 'rentall.approvalDescription',
    defaultMessage: "We're still reviewing your ID",
    description: "We're still reviewing your ID",
  },
  approvalDescription1: {
    id: 'rentall.approvalDescription1',
    defaultMessage:
      "This will get wrapped up as soon as possible - and we'll be in touch if there's anything else we need.",
    description: 'This will get wrapped up as soon as possible',
  },
  approvalDescription2: {
    id: 'rentall.approvalDescription2',
    defaultMessage: 'In the meantime, you can pick up where you left off.',
    description: 'you can pick up where you left off',
  },
  verificationIdProof: {
    id: 'rentall.verificationIdProof',
    defaultMessage: 'ID Verified',
    description: 'Id Verified',
  },

  idProofverificaitonDetails: {
    id: 'rentall.idProofverificaitonDetails',
    defaultMessage: 'Your ID is verified',
    description: 'Your proof has been verified',
  },
  idProofverificaiton: {
    id: 'rentall.idProofverificaiton',
    defaultMessage: 'ID Verification',
    description: 'idProofverificaiton',
  },

  idVerificaitonInfo: {
    id: 'rentall.idVerificaitonInfo',
    defaultMessage:
      'Please upload a Driver’s License, Passport or Government ID for verification prior to booking or listing a property.',
    description: 'Document Verification Info',
  },

  sanFansicoDiscover: {
    id: 'rentall.sanFansicoDiscover',
    defaultMessage: 'California',
    description: 'Menu item',
  },

  chicagoDiscover: {
    id: 'rentall.chicagoDiscover',
    defaultMessage: 'Colorado',
    description: 'Menu item',
  },

  italyDiscover: {
    id: 'rentall.italyDiscover',
    defaultMessage: 'Europe',
    description: 'Menu item',
  },
  clearFilter: {
    id: 'rentall.clearFilter',
    defaultMessage: 'Clear Filters',
    description: 'clearFilter',
  },
  editProfileZipCode: {
    id: 'rentall.editProfileZipCode',
    defaultMessage: 'Zip / Postal Code',
    description: 'editProfileZipCode',
  },
  profileZipCodeRequired: {
    id: 'rentall.profileZipCodeRequired',
    defaultMessage: 'Zip / Postal Code is required',
    description: 'profileZipCodeRequired',
  },
  invalidZipCode: {
    id: 'rentall.invalidZipCode',
    defaultMessage: 'Zip / Postal Code is Invalid',
    description: 'invalidZipCode',
  },
  photoText: {
    id: 'rentall.photoText',
    defaultMessage: "Final Step: Add your photo now and you're all set",
    description: 'photoText',
  },
  restrictText: {
    id: 'rentall.restrictText',
    defaultMessage:
      'Fill in your profile and upload your profile photo for full access to the site.',
    description: 'restrictText',
  },
  tripType: {
    id: 'rentall.tripType',
    defaultMessage: 'Trip Type',
    description: 'tripType',
  },
  accommodationsText: {
    id: 'rentall.accommodationsText',
    defaultMessage: 'Accommodations',
    description: 'Accommodations',
  },
  accommodations: {
    id: 'rentall.Accommodations',
    defaultMessage: 'Accommodations',
    description: 'The space Accommodations',
  },
  checkInTime: {
    id: 'rentall.checkInTime',
    defaultMessage: 'Check-in Time',
    description: 'Check-in Time',
  },
  checkoutTime: {
    id: 'rentall.checkoutTime',
    defaultMessage: 'Check-out Time',
    description: 'Check-out Time',
  },
  isLongStay: {
    id: 'rentall.isLongStay',
    defaultMessage: 'Long-term stays allowed.',
    description: 'Long-term stays allowed.',
  },
  longerStayText: {
    id: 'rentall.longerStayText',
    defaultMessage: 'Allow stays of 28 days or more.',
    description: 'Allow stays of 28 days or more.',
  },
  longerStayInfo: {
    id: 'rentall.longerStayInfo',
    defaultMessage: 'Listings that are added as Long-term Stays properties',
    description: 'longerStayInfo',
  },
  sanText: {
    id: 'rentall.sanText',
    defaultMessage: 'San',
    description: 'San',
  },
  francisText: {
    id: 'rentall.francisText',
    defaultMessage: 'Francisco',
    description: 'Francisco',
  },
  ExploreText: {
    id: 'rentall.ExploreText',
    defaultMessage: 'Explore',
    description: 'Explore',
  },
  moreText: {
    id: 'rentall.moreText',
    defaultMessage: 'All',
    description: 'More',
  },
  newYorkText: {
    id: 'rentall.newYorkText',
    defaultMessage: 'New England',
    description: 'New York',
  },
  whereToText: {
    id: 'rentall.whereToText',
    defaultMessage: 'Where to',
    description: 'Where to',
  },
  ourCitisLabel: {
    id: 'rentall.ourCitisLabel',
    defaultMessage:
      'Check out some of our recommended properties in top destinations and within driving distance from home. All hosts are vetted and we encourage the highest standards of cleanliness, in line with ',
    description: 'OurCity',
  },
  securityFeeStats: {
    id: 'rentall.securityFeeStats',
    defaultMessage: 'Security Deposit Stats',
    description: 'securityFeeStats',
  },
  securityFeeHost: {
    id: 'rentall.securityFeeHost',
    defaultMessage: 'Security Deposit payout To Host',
    description: 'securityFeeHost',
  },
  securityFeeGuest: {
    id: 'rentall.securityFeeGuest',
    defaultMessage: 'Security Deposit Refund To Guest',
    description: 'securityFeeGuest',
  },
  claimStatus1: {
    id: 'rentall.claimStatus1',
    defaultMessage: 'Claimed',
    description: 'claimStatus1',
  },
  claimStatus2: {
    id: 'rentall.claimStatus2',
    defaultMessage: 'No claim',
    description: 'claimStatus2',
  },
  claimStats: {
    id: 'rentall.claimStats',
    defaultMessage: 'Claim Status',
    description: 'claimStats',
  },
  bondRefundStats: {
    id: 'rentall.bondRefundStats',
    defaultMessage: 'Deposit Refund Status',
    description: 'bondRefundStats',
  },
  bondRefundStatus1: {
    id: 'rentall.bondRefundStatus1',
    defaultMessage: 'Pending',
    description: 'bondRefundStatus1',
  },
  bondRefundStatus2: {
    id: 'rentall.bondRefundStatus2',
    defaultMessage: 'Completed',
    description: 'bondRefundStatus2',
  },
  claimedReason: {
    id: 'rentall.claimedReason',
    defaultMessage: 'Claim Reason',
    description: 'claimedReason',
  },
  viewSecurityStatus: {
    id: 'rentall.viewSecurityStatus',
    defaultMessage: 'Security Deposit Stats',
    description: 'viewSecurityStatus',
  },
  adminApproval: {
    id: 'rentall.adminApprove',
    defaultMessage: 'Admin Approval',
    description: 'adminApprove',
  },
  adminPending: {
    id: 'rentall.adminPending',
    defaultMessage: 'Pending',
    description: 'adminPending',
  },
  adminApproved: {
    id: 'rentall.adminApproved',
    defaultMessage: 'Approved',
    description: 'adminApproved',
  },
  securityFeeReceipt: {
    id: 'rentall.securityFeeReceipt',
    defaultMessage: 'Security Deposit Receipt',
    description: 'securityFeeReceipt',
  },
  advisorsName7: {
    id: 'rentall.advisorsName7',
    defaultMessage: 'Michelle Williams',
    description: 'About Us',
  },
  advisorsType7: {
    id: 'rentall.advisorsType7',
    defaultMessage: 'Diversity & Inclusion Advisor',
    description: 'About Us',
  },
  advisorsRole7: {
    id: 'rentall.advisorsRole7',
    defaultMessage: 'General Manager, Atlantic Vacation Homes',
    description: 'About Us',
  },
  advisorsName8: {
    id: 'rentall.advisorsName8',
    defaultMessage: 'Eileen Buesing',
    description: 'About Us',
  },
  advisorsType8: {
    id: 'rentall.advisorsType8',
    defaultMessage: 'PR Advisor',
    description: 'About Us',
  },
  advisorsRole8: {
    id: 'rentall.advisorsRole8',
    defaultMessage: 'VP, Communications, Twyla;',
    description: 'About Us',
  },
  advisorsRoleSub8: {
    id: 'rentall.advisorsRoleSub8',
    defaultMessage: 'VP, Communications and PR, HomeAway',
    description: 'About Us',
  },
  introDescTitle1: {
    id: 'rentall.introDescTitle1',
    defaultMessage: 'What Is Golightly?',
    description: 'About Us',
  },
  introDescTitle2: {
    id: 'rentall.introDescTitle2',
    defaultMessage: 'Why Golightly?',
    description: 'About Us',
  },
  introDesc1: {
    id: 'rentall.introDesc1',
    defaultMessage:
      'Golightly is an invite-only private club providing a vacation rental and home-sharing platform for women. All Golightly properties are owned or managed by women. Bookings are only made by women within the network, though they can travel with whomever they wish.',
    description: 'About Us',
  },
  introDesc2: {
    id: 'rentall.introDesc2',
    defaultMessage:
      'Media reports and research indicate that women often feel unsafe while traveling. A recent survey by Wakefield Research shows 89% of women routinely take extreme safety precautions when they travel, such as double-checking locks and looking for hidden cameras, and nearly 50% of women are more likely to stay in a woman-owned rental.',
    description: 'About Us',
  },
  introDesc3: {
    id: 'rentall.introDesc3',
    defaultMessage:
      'Golightly is focused on providing a safer and better travel experience for women.',
    description: 'About Us',
  },
  exploreMoreText: {
    id: 'rentall.exploreMoreText',
    defaultMessage: 'Explore All',
    description: 'Explore More',
  },
  connected: {
    id: 'rentall.connected',
    defaultMessage: 'Connected',
    description: 'connected connection',
  },
  connectConnection: {
    id: 'rentall.connectConnection',
    defaultMessage: 'Connect',
    description: 'connect connection',
  },
  pendingConnect: {
    id: 'rentall.pendingConnect',
    defaultMessage: 'Pending',
    description: 'pendingConnect',
  },
  declinedConnect: {
    id: 'rentall.declinedConnect',
    defaultMessage: 'Declined',
    description: 'declinedConnect',
  },
  noUserSubTitle: {
    id: 'rentall.noUserSubTitle',
    defaultMessage: 'Try adjusting your search.',
    description: 'No results message for search listing',
  },
  noUserTerms1: {
    id: 'rentall.noUserTerms1',
    defaultMessage: 'Change your filters or dates',
    description: 'No results message for search listing',
  },
  noUserTerms2: {
    id: 'rentall.noUserTerms2',
    defaultMessage: 'Change your filters or dates',
    description: 'No results message for search listing',
  },
  notInEarnings: {
    id: 'rentall.notInEarnings',
    defaultMessage: '(not included in your earnings)',
    description: 'not included in your earnings',
  },
  descriptionLength: {
    id: 'rentall.descriptionLength',
    defaultMessage: 'Maximum 300 characters only.',
    description: 'Description',
  },
  descriptionLabelTwo: {
    id: 'rentall.descriptionLabelTwo',
    defaultMessage: 'Description',
    description: 'Description',
  },
  passwordInvalidError: {
    id: 'rentall.passwordInvalidError',
    defaultMessage:
      'Your password must contain at least one upper case character and one number.',
    description:
      'Your password must contain at least one upper case character and one number.',
  },
  confirmPasswordError5: {
    id: 'rentall.confirmPasswordError5',
    defaultMessage:
      'Your confirm password must contain at least one upper case character and one number.',
    description:
      'Your confirm password must contain at least one upper case character and one number.',
  },
  passwordMismatch: {
    id: 'rentall.passwordMismatch',
    defaultMessage: 'Password is mismatch, Please try again.',
    description: 'Password is mismatch, Please try again.',
  },
  securityFeeCondition: {
    id: 'rentall.securityFeeCondition',
    defaultMessage:
      '*Security deposit will be charged in case of host claim after check-out.',
    description:
      '*Security deposit will be charged in case of host claim after check-out.',
  },
  payAtLocationCondition: {
    id: 'rentall.payAtLocationCondition',
    defaultMessage: 'To be paid at location',
    description: 'To be paid at location',
  },
  noteContent: {
    id: 'rentall.noteContent',
    defaultMessage:
      'Golightly members are more likely to accept connection requests that include a personal note.',
    description:
      'Golightly members are more likely to accept connection requests that include a personal note.',
  },
  isPhoneVerifiedError: {
    id: 'rentall.isPhoneVerifiedError',
    defaultMessage:
      'Phone number verification is required to save your profile.',
    description: 'Phone Number Verification is Required',
  },
  taxRate: {
    id: 'rentall.taxRate',
    defaultMessage: 'Tax Rate %',
    description: 'Tax Rate %',
  },
  taxRateLabel: {
    id: 'rentall.taxRateLabel',
    defaultMessage:
      'Please add in the appropriate tax rate to cover any local and state taxes you may be responsible for.',
    description: 'Tax Rate Label',
  },
  taxRateInvalid: {
    id: 'rentall.taxRateInvalid',
    defaultMessage: 'Invalid Tax Rate, only numbers(eg: 25) are allowed',
    description: 'taxRateInvalid',
  },
  taxRateFee: {
    id: 'rentall.taxRateFee',
    defaultMessage: 'Tax Rate',
    description: 'Tax Rate',
  },
  taxes: {
    id: 'rentall.taxes',
    defaultMessage: 'Taxes',
    description: 'Taxes',
  },
  findTaxRate: {
    id: 'rentall.findTaxRate',
    defaultMessage: 'Find your tax rate',
    description: 'Taxes',
  },
  membersConnection: {
    id: 'rentall.membersConnection',
    defaultMessage: 'Your connection request is on its way',
    description: 'Your connection request is on its way',
  },
  membersSubConnection: {
    id: 'rentall.membersSubConnection',
    defaultMessage: 'Your connection request to',
    description: 'Your connection request to',
  },
  membersSubConnection1: {
    id: 'rentall.membersSubConnection1',
    defaultMessage:
      'is on its way. You can add a note to personalize your request.',
    description:
      'is on its way. You can add a note to personalize your request.',
  },
  addNote: {
    id: 'rentall.addNote',
    defaultMessage: 'Add Note',
    description: 'Add Note',
  },
  connectionNote: {
    id: 'rentall.connectionNote',
    defaultMessage: 'Add a note to your connection request',
    description: 'Add a note to your connection request',
  },
  businessLicense: {
    id: 'rentall.businessLicense',
    defaultMessage: 'Business License #',
    description: 'Business License Number',
  },
  businessLicenseDescription: {
    id: 'rentall.businessLicenseDescription',
    defaultMessage:
      "Please add your property's local short-term rental license number if applicable.",
    description: 'Business License Description',
  },
  businessLicenseDescription2: {
    id: 'rentall.businessLicenseDescription2',
    defaultMessage: 'Please note this is only required in certain cities.',
    description: 'Business License Description',
  },
  houseRulesSubTitle: {
    id: 'rentall.houseRulesSubTitle',
    defaultMessage: "Have a rule that's not listed here? Add your own.",
    description: "Have a rule that's not listed here? Add your own.",
  },
  yourCustomRule: {
    id: 'rentall.yourCustomRule',
    defaultMessage: 'Your custom rule',
    description: 'Your custom rule',
  },
  payoutType: {
    id: 'rentall.payoutType',
    defaultMessage: 'Account Type',
    description: 'payout Account Type',
  },
  payoutIndividual: {
    id: 'rentall.payoutIndividual',
    defaultMessage: 'Individual',
    description: 'Individual text',
  },
  payoutCompany: {
    id: 'rentall.payoutCompany',
    defaultMessage: 'Company',
    description: 'Company text',
  },
  payoutCompanyName: {
    id: 'rentall.payoutCompanyName',
    defaultMessage: 'Company Name',
    description: 'Company text',
  },
  payoutStripeInfo: {
    id: 'rentall.payoutStripeInfo',
    defaultMessage:
      'We use Stripe to make sure you get paid on time and to keep your personal bank and details secure. Click "Finish" to set up your payments on Stripe.',
    description: 'Stripe text info',
  },
  notReady: {
    id: 'rentall.notReady',
    defaultMessage: 'Not Ready',
    description: 'Payout Method',
  },
  payoutVerify: {
    id: 'rentall.payoutVerify',
    defaultMessage: 'Verify',
    description: 'Payout Method',
  },
  payoutRetry: {
    id: 'rentall.payoutRetry',
    defaultMessage: 'Retry',
    description: 'Payout Method',
  },
  payoutVerifyStripeInfo: {
    id: 'rentall.payoutVerifyStripeInfo',
    defaultMessage:
      'We use Stripe to make sure you get paid on time safe and secure. It requires additional verification to connect your bank account with the platform.',
    description: 'Payout Method',
  },
  inviteCopyText: {
    id: 'rentall.inviteCopyText',
    defaultMessage:
      ' Only one user can sign up per unique URL. To invite a friend this way hit "Generate copy" — then paste it into a DM, email or text.',
    description: 'Invited Text',
  },
  inviteCopyNote: {
    id: 'rentall.inviteCopyNote',
    defaultMessage: 'Note: ',
    description: 'Invited Text',
  },
  generateAndCopy: {
    id: 'rentall.generateAndCopy',
    defaultMessage: 'Generate and copy',
    description: 'Invited Text',
  },
  minNight: {
    id: 'rentall.minNight',
    defaultMessage: 'Minimum Night',
    description: 'Minimum Night',
  },
  seasonName: {
    id: 'rentall.seasonName',
    defaultMessage: 'Season Name',
    description: 'Season Name',
  },
  transitNumber: {
    id: 'rentall.transitNumber',
    defaultMessage: 'Transit Number',
    description: 'Transit Number',
  },
  institutionNumber: {
    id: 'rentall.institutionNumber',
    defaultMessage: 'Institution Number',
    description: 'Institution Number',
  },
  transitNumberRequired: {
    id: 'rentall.transitNumberRequired',
    defaultMessage: 'Transit number is required',
    description: 'Transit number is required',
  },
  institutionNumberRequired: {
    id: 'rentall.institutionNumberRequired',
    defaultMessage: 'Institution Number is required',
    description: 'Institution Number is required',
  },
  payoutCompanyNameRequired: {
    id: 'rentall.payoutCompanyNameRequired',
    defaultMessage: 'Company name is required',
    description: 'payoutCompanyNameRequired',
  },
  idCard: {
    id: 'rentall.idCard',
    defaultMessage: 'Government issued Identity Card ',
    description: 'Meetup information',
  },
  residencePermit: {
    id: 'rentall.residencePermit',
    defaultMessage: 'Residence Permit',
    description: 'Meetup information',
  },
  utilityBill: {
    id: 'rentall.utilityBill',
    defaultMessage: 'Utility Bill',
    description: 'Meetup information',
  },
  requiredMessage: {
    id: 'rentall.requiredMessage',
    defaultMessage: '*Required',
    description: 'requiredMessage',
  },
  fromMessage: {
    id: 'rentall.fromMessage',
    defaultMessage: 'From value should be less than to value',
    description: 'requiredMessage',
  },
  toMessage: {
    id: 'rentall.toMessage',
    defaultMessage: 'To value should be greater than from value',
    description: 'requiredMessage',
  },
  edit: {
    id: 'rentall.edit',
    defaultMessage: 'Edit',
    description: 'Edit Text',
  },
  exttax: {
    id: 'rentall.exttax',
    defaultMessage: 'Extra tax',
    description: 'Edit Text',
  },
  Valuee: {
    id: 'rentall.Valuee',
    defaultMessage: 'Value',
    description: 'Edit Text',
  },
  Calby: {
    id: 'rentall.Calby',
    defaultMessage: 'Calculation by',
    description: 'Edit Text',
  },
  Caltime: {
    id: 'rentall.Caltime',
    defaultMessage: 'Collect time',
    description: 'Edit Text',
  },
  Optional: {
    id: 'rentall.Optional',
    defaultMessage: 'Optional',
    description: 'Edit Text',
  },
  Extgst: {
    id: 'rentall.Extgst',
    defaultMessage: 'N# Extra Guest',
    description: 'Edit Text',
  },
  Nguest: {
    id: 'rentall.Nguest',
    defaultMessage: 'N# Guests',
    description: 'Edit Text',
  },
  Nnight: {
    id: 'rentall.Nnight',
    defaultMessage: 'N# Nights',
    description: 'Edit Text',
  },
  fromdayariv: {
    id: 'rentall.fromdayariv',
    defaultMessage: 'From Days before arrival',
    description: 'Edit Text',
  },
  todayariv: {
    id: 'rentall.todayariv',
    defaultMessage: 'To Days before arrival',
    description: 'Edit Text',
  },
  disc: {
    id: 'rentall.disc',
    defaultMessage: 'Discount(%)',
    description: 'Edit Text',
  },
  staylong: {
    id: 'rentall.staylong',
    defaultMessage: 'Stays Longer than (days)',
    description: 'Edit Text',
  },
  Shorterthan: {
    id: 'rentall.Shorterthan',
    defaultMessage: 'Shorter than (days)',
    description: 'Edit Text',
  },
  seaname: {
    id: 'rentall.seaname',
    defaultMessage: 'Season Name',
    description: 'Edit Text',
  },
  dafrom: {
    id: 'rentall.dafrom',
    defaultMessage: 'Date From',
    description: 'Edit Text',
  },
  dato: {
    id: 'rentall.dato',
    defaultMessage: 'Date To',
    description: 'Edit Text',
  },
  dailyprc: {
    id: 'rentall.dailyprc',
    defaultMessage: 'Daily Price',
    description: 'Edit Text',
  },
  extgstprc: {
    id: 'rentall.extgstprc',
    defaultMessage: 'Extra Guest Price',
    description: 'Edit Text',
  },
  ministy: {
    id: 'rentall.ministy',
    defaultMessage: 'Minimum Stay',
    description: 'Edit Text',
  },
  addedit: {
    id: 'rentall.addedit',
    defaultMessage: 'Add / Edit Season',
    description: 'Edit Text',
  },
  wkprc: {
    id: 'rentall.wkprc',
    defaultMessage: 'Weekend pricing:',
    description: 'Edit Text',
  },
  appon: {
    id: 'rentall.appon',
    defaultMessage: 'Applies on:',
    description: 'Edit Text',
  },
  prcprnt: {
    id: 'rentall.prcprnt',
    defaultMessage: 'Price per night:',
    description: 'Edit Text',
  },
  Changeover: {
    id: 'rentall.Changeover',
    defaultMessage: 'Changeover:',
    description: 'Edit Text',
  },
  chkinon: {
    id: 'rentall.chkinon',
    defaultMessage: 'Check-in never on:',
    description: 'Edit Text',
  },
  chkouton: {
    id: 'rentall.chkouton',
    defaultMessage: 'Check-out never on:',
    description: 'Edit Text',
  },
  addeditprc: {
    id: 'rentall.addeditprc',
    defaultMessage: 'Add / Edit Price',
    description: 'Edit Text',
  },
  addeditdsc: {
    id: 'rentall.addeditdsc',
    defaultMessage: 'Add / Edit Discount',
    description: 'Edit Text',
  },
  occupric: {
    id: 'rentall.occupric',
    defaultMessage: 'Occupancy pricing:',
    description: 'Edit Text',
  },
  extraprc: {
    id: 'rentall.extraprc',
    defaultMessage: 'Extra price per guest',
    description: 'Edit Text',
  },
  losprc: {
    id: 'rentall.losprc',
    defaultMessage: 'LOS pricing:',
    description: 'Edit Text',
  },
  lenthstay: {
    id: 'rentall.lenthstay',
    defaultMessage: 'Length of stay price',
    description: 'Edit Text',
  },
  discc: {
    id: 'rentall.discc',
    defaultMessage: 'Discounts:',
    description: 'Edit Text',
  },
  lstmnt: {
    id: 'rentall.lstmnt',
    defaultMessage: 'last minute',
    description: 'Edit Text',
  },
  lntofsty: {
    id: 'rentall.lntofsty',
    defaultMessage: 'Length of Stay',
    description: 'Edit Text',
  },
  securityDepositError1: {
    id: 'rentall.securityDepositError1',
    defaultMessage: 'The maximum should be ',
    description: 'The maximum should be ',
  },
  securityDepositError2: {
    id: 'rentall.securityDepositError2',
    defaultMessage:
      '. If additional security deposit is required, please add this to your House Rules.',
    description:
      'If additional security deposit is required, please add this to your House Rules',
  },
  securityDepositRule: {
    id: 'rentall.securityDepositRule',
    defaultMessage:
      'If additional security deposit is required, please add this to your House Rules.',
    description:
      'If additional security deposit is required, please add this to your House Rules.',
  },
  securityEmptyError: {
    id: 'rentall.securityEmptyError',
    defaultMessage: '.',
    description: 'Empty Error',
  },
  longerStay: {
    id: 'rentall.longerStay',
    defaultMessage: 'Long-term Stays',
    description: 'Long-term stays button',
  },
  arrivalInformation: {
    id: 'rentall.arrivalInformation',
    defaultMessage: 'Arrival Information',
    description: 'Step 3',
  },
  ourCitisLabelLink: {
    id: 'rentall.ourCitisLabelLink',
    defaultMessage: 'CDC guidelines',
    description: 'OurCity',
  },
  discountDaily: {
    id: 'rentall.discountDaily',
    defaultMessage: 'Daily discount',
    description: 'Daily discount',
  },
  memberFormSignup: {
    id: 'rentall.memberFormSignup',
    defaultMessage:
      'You will have full access to the site as soon as your application is approved.',
    description: 'memberFormSignup',
  },
  memberVerified: {
    id: 'rentall.memberVerified',
    defaultMessage:
      'Fill in your profile information and upload your profile photo for full access to the site.',
    description: 'memberVerified',
  },
  yourListing: {
    id: 'rentall.yourListing',
    defaultMessage: 'Your listings',
    description: 'Side Menu',
  },
  welcomeTo: {
    id: 'rentall.welcomeTo',
    defaultMessage: 'Welcome to',
    description: 'IDM Toaster',
  },
  idmNotUpload: {
    id: 'rentall.idmNotUpload',
    defaultMessage:
      'Please verify your ID to continue. This is the final step in the verification process and enables all members to have full access to the site, including booking, hosting, connecting and networking.',
    description: 'IDM Toaster',
  },
  connectionExit: {
    id: 'rentall.connectionExit',
    defaultMessage:
      'You have reached your limit of 10 connections for the day. Please re-connect tomorrow!',
    description: 'IDM Toaster',
  },
  accountPending: {
    id: 'rentall.accountPending',
    defaultMessage: 'Your account is currently pending.',
    description: 'Verification MOdal',
  },
  travelCreditLabel: {
    id: 'rentall.travelCreditLabel',
    defaultMessage:
      'Whilst pending, access to the site is limited and you are only able to edit and save your profile. Once approved you will have full access to the site including the ability to browse, book, connect and be part of the fastest growing community of women who love to travel!',
    description: 'Verification MOdal',
  },
  thankyouLable: {
    id: 'rentall.thankyouLable',
    defaultMessage: ' Thank you for your patience!',
    description: 'Verification MOdal',
  },
  isMonthStay: {
    id: 'rentall.isMonthStay',
    defaultMessage: 'Month + Stays Only.',
    description: 'Month + Stays Only.',
  },
  monthStayText: {
    id: 'rentall.monthStayText',
    defaultMessage: 'Allow stays of 31 days or more.',
    description: 'Allow stays of 31 days or more.',
  },
  makeSpecialOffer: {
    id: 'rentall.makeSpecialOffer',
    defaultMessage: 'Request a special price',
    description: 'Allow stays of 31 days or more.',
  },
  messageStatus12: {
    id: 'rentall.messageStatus12',
    defaultMessage: 'Special price request',
    description: 'Message Status',
  },
  specialOfferOne: {
    id: 'rentall.specialOfferOne',
    defaultMessage: 'to book by offer this trip',
    description: 'Host Action Block.',
  },
  otherLabel: {
    id: 'rentall.otherLabel',
    defaultMessage: 'Other',
    description: 'Payment Summery',
  },
  othersLabel: {
    id: 'rentall.othersLabel',
    defaultMessage: 'Others',
    description: 'Payment Summery',
  },
  dublicateLabel: {
    id: 'rentall.dublicateLabel',
    defaultMessage: 'Duplicate',
    description: 'Payment Summery',
  },
  yesLabel: {
    id: 'rentall.yesLabel',
    defaultMessage: 'Yes',
    description: 'Confirm Modal',
  },
  noLabel: {
    id: 'rentall.noLabel',
    defaultMessage: 'No',
    description: 'Confirm Modal',
  },
  syncLable: {
    id: 'rentall.syncLabel',
    defaultMessage: 'The dates blocked via iCal sync will not be duplicated',
    description: 'Confirm Modal',
  },
  duplicatePhotosLabel: {
    id: 'rentall.duplicatePhotosLabel',
    defaultMessage: 'Duplicate photos, too?',
    description: 'Photos Modal',
  },
  duplicatePhotosContent: {
    id: 'rentall.duplicatePhotosContent',
    defaultMessage: 'Would you like to dupliate the photos from',
    description: 'Photos Modal',
  },
  duplicatePhotosContentTwo: {
    id: 'rentall.duplicatePhotosContentTwo',
    defaultMessage: 'This may take a few moments.',
    description: 'Photos Modal',
  },
  duplicateListPhotos: {
    id: 'rentall.duplicateListPhotos',
    defaultMessage: 'Duplicate photos',
    description: 'Photos Modal',
  },
  noThanks: {
    id: 'rentall.noThanks',
    defaultMessage: 'No, thanks',
    description: 'Photos Modal',
  },
  duplicateListingTitle: {
    id: 'rentall.duplicateListingTitle',
    defaultMessage: 'Duplicate Listing',
    description: 'Description',
  },
  photosConfirm: {
    id: 'rentall.photosConfirm',
    defaultMessage: 'Would you like to duplicate the photos from',
    description: 'Description',
  },
  messageStatus13: {
    id: 'rentall.messageStatus13',
    defaultMessage: 'Special price request approved',
    description: 'Message Status',
  },
  counterOffer: {
    id: 'rentall.counterOffer',
    defaultMessage: 'Are you sure you want to decline this request?',
    description: 'Decline Status',
  },
  totalGuests: {
    id: 'rentall.totalGuests',
    defaultMessage: 'Total allowed guests are',
    description: 'View Listing',
  },
  totalGuestLabel: {
    id: 'rentall.totalGuestLabel',
    defaultMessage: 'Total allowed guest is',
    description: 'View Listing',
  },
  messageStatus14: {
    id: 'rentall.messageStatus14',
    defaultMessage: 'Special price request declined',
    description: 'Message Status',
  },
  messageStatus15: {
    id: 'rentall.messageStatus15',
    defaultMessage: 'counter-offer',
    description: 'Message Status',
  },
  messageStatus16: {
    id: 'rentall.messageStatus16',
    defaultMessage: 'counter-offer declined',
    description: 'Message Status',
  },
  messageStatus17: {
    id: 'rentall.messageStatus17',
    defaultMessage: 'counter-offer accepted',
    description: 'Message Status',
  },
  messageStatus18: {
    id: 'rentall.messageStatus18',
    defaultMessage: 'Special price requested',
    description: 'Message Status',
  },
  sendRequest: {
    id: 'rentall.sendRequest',
    defaultMessage: 'Send request',
    description: 'Make specail offer button',
  },
  offerInfo: {
    id: 'rentall.offerInfo',
    defaultMessage:
      'Quote the price per night  you want to book this listing for.',
    description: 'Offer Request',
  },
  specialPriceSend: {
    id: 'rentall.specialPriceSend',
    defaultMessage: 'Special price request sent',
    description: 'Guest Side',
  },
  specialPriceDescription: {
    id: 'rentall.specialPriceDescription',
    defaultMessage:
      'Your special price request has been sent. The host will review your special price request and respond within 24 hours. If you have any extra requests or information to share, you can message the host below.',
    description: 'Guest Side',
  },
  hostApproveDeclineContent1: {
    id: 'rentall.hostApproveDecline',
    defaultMessage:
      'You have received a special price request for your listing. You can review this request and approve or decline within',
    description: 'Host Side',
  },
  hostApproveDeclineContent2: {
    id: 'rentall.hostApproveDeclineContent2',
    defaultMessage:
      'before it expires. You have the choice to either decline this request or send a counter-offer to the guest.',
    description: 'Host Side',
  },
  hostApproveDeclineHeading: {
    id: 'rentall.hostApproveDeclineHeading',
    defaultMessage: 'You have received a special price request',
    description: 'Host Side',
  },
  guestSideAccept: {
    id: 'rentall.guestSideAccept',
    defaultMessage:
      'Your special price request has been approved by the host for her listing!',
    description: 'Guest Side View',
  },
  hostSideAccept: {
    id: 'rentall.hostSideAccept',
    defaultMessage:
      'You have approved this special price request. The guest has 24 hours to book your listing with the special price approved.',
    description: 'Host Side View',
  },
  offerPriceOne: {
    id: 'rentall.offerPriceOne',
    defaultMessage:
      'You can book this listing  with the special price approved by clicking on the book button below before the request expires in',
    description: 'Host Side View',
  },
  offerPriceTwo: {
    id: 'rentall.offerPriceTwo',
    defaultMessage: 'Your special price request is expired',
    description: 'Booking expired',
  },
  offerPriceThree: {
    id: 'rentall.offerPriceThree',
    defaultMessage:
      'Try sending a request again or try to get in touch with the host',
    description: 'Booking expired',
  },
  offerPriceFour: {
    id: 'rentall.offerPriceFour',
    defaultMessage: 'Special price request expired',
    description: 'Booking request is expired',
  },
  offerPriceFive: {
    id: 'rentall.offerPriceFive',
    defaultMessage:
      'This request has expired. You can contact the guest by messaging below if your listing is still available.',
    description: 'Booking request is expired',
  },
  offerPriceSix: {
    id: 'rentall.offerPriceSix',
    defaultMessage:
      'You can choose to either decline this request or you can send a counter-offer and quote a price that you find suitable.',
    description: 'Booking request is expired',
  },
  offerPriceEight: {
    id: 'rentall.offerPriceEight',
    defaultMessage: 'Send a counter-offer',
    description: 'Booking request is expired',
  },
  offerPriceNine: {
    id: 'rentall.offerPriceNine',
    defaultMessage: 'Decline this request',
    description: 'Booking request is expired',
  },
  offerPriceTen: {
    id: 'rentall.offerPriceTen',
    defaultMessage: 'counter-offer',
    description: 'Decline Status',
  },
  offerPriceEleven: {
    id: 'rentall.offerPriceEleven',
    defaultMessage: 'counter-offer',
    description: 'Decline Status',
  },
  offerPriceTwelve: {
    id: 'rentall.offerPriceTwelve',
    defaultMessage:
      'In case you have a hard time deciding a counter-offer price, you can always close this window and try messaging the guest to negoatiate a price before you send the counter-offer.',
    description: 'Decline Status',
  },
  offerPriceThirteen: {
    id: 'rentall.offerPriceThirteen',
    defaultMessage: 'Price quoted by guest:',
    description: 'Decline Status',
  },
  offerPriceFourteen: {
    id: 'rentall.offerPriceFourteen',
    defaultMessage: 'counter-offer price:',
    description: 'Decline Status',
  },
  offerPriceFiveteen: {
    id: 'rentall.offerPriceFiveteen',
    defaultMessage: 'Send counter-offer',
    description: 'Decline Status',
  },
  offerPriceSixteen: {
    id: 'rentall.offerPriceSixteen',
    defaultMessage: 'Date:',
    description: 'Decline Status',
  },
  offerPriceSeventeen: {
    id: 'rentall.offerPriceSeventeen',
    defaultMessage:
      'Make sure your calendar is up-to-date and that your listing page accurately reflects your house rules and requirements.',
    description: 'Decline Status',
  },
  offerPriceEightteen: {
    id: 'rentall.offerPriceEightteen',
    defaultMessage:
      'Your special price request has been declined by the host. You can  book this listing at the regular price  or search for other listings.',
    description: 'Decline Status',
  },
  offerPriceNighteen: {
    id: 'rentall.offerPriceNighteen',
    defaultMessage: 'Your counter-offer has been sent',
    description: 'Decline Status',
  },
  offerPriceTwenty: {
    id: 'rentall.offerPriceTwenty',
    defaultMessage:
      'The guest has 24 hours to respond to your counter-offer and book this listing. You can contact the host with any extra information below.',
    description: 'Decline Status',
  },
  offerPriceTwentyOne: {
    id: 'rentall.offerPriceTwentyOne',
    defaultMessage:
      'The host has sent a counter-offer. If you find this offer suitable you can accept and proceed to book her listing or else decline it. You have',
    description: 'Decline Status',
  },
  offerPriceTwentyTwo: {
    id: 'rentall.offerPriceTwentyTwo',
    defaultMessage: 'to respond to the host.',
    description: 'Decline Status',
  },
  offerPriceTwentyThree: {
    id: 'rentall.offerPriceTwentyThree',
    defaultMessage: 'counter-offer expired',
    description: 'Decline Status',
  },
  offerPriceTwentyFour: {
    id: 'rentall.offerPriceTwentyFour',
    defaultMessage:
      'This counter offer has expired. If you found this offer suitable, you can try making another special price request to the host.',
    description: 'Decline Status',
  },
  offerPriceTwentyFive: {
    id: 'rentall.offerPriceTwentyFive',
    defaultMessage: 'The guest has declined the counter-offer',
    description: 'Decline Status',
  },
  offerPriceTwentySix: {
    id: 'rentall.offerPriceTwentySix',
    defaultMessage: 'The counter-offer sent to the guest has been declined.',
    description: 'Decline Status',
  },
  offerPriceTwentySeven: {
    id: 'rentall.offerPriceTwentySeven',
    defaultMessage: 'You have',
    description: 'Decline Status',
  },
  offerPriceTwentyEight: {
    id: 'rentall.offerPriceTwentyEight',
    defaultMessage:
      'to book this listing. You can proceed to book this listing by clicking on the book button below before this offer expires.',
    description: 'Decline Status',
  },
  offerPriceTwentyNine: {
    id: 'rentall.offerPriceTwentyNine',
    defaultMessage: 'Your counter-offer is accepted',
    description: 'Decline Status',
  },
  offerPriceThirty: {
    id: 'rentall.offerPriceThirty',
    defaultMessage:
      'The guest has 24 hours to book this listing before the offer expires.',
    description: 'Decline Status',
  },
  requestPrice: {
    id: 'rentall.requestPrice',
    defaultMessage: 'Special request price',
    description: 'Decline Status',
  },
  ourDistance: {
    id: 'rentall.ourDistance',
    defaultMessage: 'Stay close',
    description: 'Payment Summery',
  },
  accommodationGuest: {
    id: 'rentall.accommodationGuest',
    defaultMessage: 'Guest information',
    description: 'Accommodation Guest',
  },
  propertyAddress: {
    id: 'rentall.propertyAddress',
    defaultMessage: 'Property address',
    description: 'View Message',
  },
  specialPriceError: {
    id: 'rentall.specialPriceError',
    defaultMessage: 'Invalid Price, only numbers(eg: 25) are allowed',
    description: 'Description',
  },
  toLabel: {
    id: 'rentall.toLabel',
    defaultMessage: 'to',
    description: 'Our City',
  },
  stayCloseTo: {
    id: 'rentall.stayCloseTo',
    defaultMessage: 'Go Safely. Golightly ...',
    description: 'Our City',
  },
  spacesLabel: {
    id: 'rentall.spacesLabel',
    defaultMessage: 'Spaces',
    description: 'View listing shared spaces',
  },
  taxToolTips: {
    id: 'rentall.taxToolTips',
    defaultMessage: 'Average rate per night for your trip.',
    description: 'Bill Details',
  },
  taxToolTipsTwo: {
    id: 'rentall.taxToolTipsTwo',
    defaultMessage: 'Taxes set and collected by host',
    description: 'Bill Details',
  },
  longerStayLabel: {
    id: 'rentall.longerStayLabel',
    defaultMessage: 'Longer Stays',
    description: 'Home Page',
  },
  adventureLabel: {
    id: 'rentall.adventureLabel',
    defaultMessage: 'Unlock your next travel adventure!',
    description: 'Home Page',
  },
  enterLabel: {
    id: 'rentall.enterLabel',
    defaultMessage: 'Enter',
    description: 'Home Page',
  },
  conciergeTitle: {
    id: 'rentall.conciergeTitle',
    defaultMessage: 'About',
    description: 'About Golightly Concierge',
  },
  conciergeTitleTwo: {
    id: 'rentall.conciergeTitleTwo',
    defaultMessage: 'Golightly',
    description: 'About Golightly Concierge',
  },
  conciergeTitleThree: {
    id: 'rentall.conciergeTitleThree',
    defaultMessage: 'Concierge',
    description: 'About Golightly Concierge',
  },
  conciergeContent: {
    id: 'rentall.conciergeContent',
    defaultMessage:
      'Hi there, welcome to Golightly concierge service where we take all the hassle out of searching for your rental. Some times there are upcoming properties available and other host    s who may accomodate different requests so you may find more selection by letting us help you.Please fill in the form below and we will do our best to get back to you  within the next 48 hours.',
    description: 'About Golightly Concierge',
  },
  conciergeContent1: {
    id: 'rentall.conciergeContent1',
    defaultMessage:
      'Welcome to Golightly concierge service where we help you find your perfect rental.',
    description: 'About Golightly Concierge',
  },
  conciergeContent2: {
    id: 'rentall.conciergeContent2',
    defaultMessage:
      'Sometimes there are upcoming properties available or hosts who may accommodate a specific request that isn\'t detailed on the site, or you just need us to answer that question "Where should I go (work from virtually) next?" The point is we are here to help!',
    description: 'About Golightly Concierge',
  },
  conciergeContent3: {
    id: 'rentall.conciergeContent3',
    defaultMessage:
      "Fill out this form and we'll try to get back to you within 24 hours!",
    description: 'About Golightly Concierge',
  },
  budgetIsRequired: {
    id: 'rentall.budgetIsRequired',
    defaultMessage: 'Budget is required',
    description: 'Name Required',
  },
  budgetLabel: {
    id: 'rentall.budgetLabel',
    defaultMessage: 'Budget',
    description: 'Name Required',
  },
  budgetInvalid: {
    id: 'rentall.budgetInvalid',
    defaultMessage: 'Invalid Budget',
    description: 'Name Required',
  },
  newFeatures: {
    id: 'rentall.newFeatures',
    defaultMessage: 'New Features',
    description: 'Menu item',
  },
  footerLabel: {
    id: 'rentall.footerLabel',
    defaultMessage: 'Your currency & lingo',
    description: 'Menu item',
  },
  removeFile: {
    id: 'rentall.removeFile',
    defaultMessage: 'Remove file',
    description: 'Remove File',
  },
  advisorsLabel: {
    id: 'rentall.advisorsLabel',
    defaultMessage: 'Advisors',
    description: 'About us',
  },
  founderCEO: {
    id: 'rentall.founderCEO',
    defaultMessage: 'Founder & CEO',
    description: 'About us',
  },
  goLabel: {
    id: 'rentall.goLabel',
    defaultMessage: 'Go',
    description: 'About us',
  },
  golightlyLabel: {
    id: 'rentall.golightlyLabel',
    defaultMessage: 'lightly',
    description: 'About us',
  },
  aboutUsOne: {
    id: 'rentall.aboutUsOne',
    defaultMessage: 'is the first',
    description: 'About us',
  },
  aboutUsTwo: {
    id: 'rentall.aboutUsTwo',
    defaultMessage: 'private',
    description: 'About us',
  },
  aboutUsThree: {
    id: 'rentall.aboutUsThree',
    defaultMessage: 'home-sharing and vacation rental club for',
    description: 'About us',
  },
  aboutUsFour: {
    id: 'rentall.aboutUsFour',
    defaultMessage: 'women.',
    description: 'About us',
  },
  emailAddressLabel: {
    id: 'rentall.emailAddressLabel',
    defaultMessage: 'Enter email addresses',
    description: 'Invite Page',
  },
  yourInviteFriends: {
    id: 'rentall.yourInviteFriends',
    defaultMessage: 'Your invited Friends',
    description: 'Invite Page',
  },
  personalTest: {
    id: 'rentall.personalTest',
    defaultMessage: 'Enter personal text',
    description: 'Invite Page',
  },
  phoneNumberLabel: {
    id: 'rentall.phoneNumberLabel',
    defaultMessage: 'Add a phone number (without dashes or spaces)',
    description: 'Invite Page',
  },
  inviteeName: {
    id: 'rentall.inviteeName',
    defaultMessage: 'Enter Invitee Name',
    description: 'Invite Page',
  },
  rentalUnitedFullyLogoutInfo: {
    id: 'rentall.rentalUnitedFullyLogoutInfo',
    defaultMessage:
      'Please do not logout until the Rentals United sync completes.',
    description: 'Manage Listings',
  },
  nextPaxLogoutInfo: {
    id: 'rentall.nextPaxLogoutInfo',
    defaultMessage: 'Please do not logout until the NextPax sync completes.',
    description: 'Manage Listings',
  },
  youCanLeave: {
    id: 'rentall.youCanLeave',
    defaultMessage:
      'You can leave this page, the sync will continue in background',
    description: 'You can leave from this page',
  },
  hostActionOne: {
    id: 'rentall.hostActionOne',
    defaultMessage: 'Reservation of your listing is completed.',
    description: 'View Message',
  },
  addLabel: {
    id: 'rentall.addLabel',
    defaultMessage: 'Add',
    description: 'Seasonl Price',
  },
  clearDates: {
    id: 'rentall.clearDates',
    defaultMessage: 'Clear dates',
    description: 'View Listing',
  },
  guestReviewLabel: {
    id: 'rentall.guestReviewLabel',
    defaultMessage: 'Golightly Sparkle Pledge',
    description: 'Become a host',
  },
  guestReviewLabelTwo: {
    id: 'rentall.guestReviewLabelTwo',
    defaultMessage:
      'I will keep my property clean and do my best to follow CDC sanitary guidelines and adhere to the',
    description: 'Become a host',
  },
  localLawsOne: {
    id: 'rentall.localLawsOne',
    defaultMessage:
      'Take a moment to review your local laws. We want to make sure you’ve got everything you need to get off to a great start.',
    description: 'Become a host',
  },
  localLawsTwo: {
    id: 'rentall.localLawsTwo',
    defaultMessage:
      'Please educate yourself about the laws in your jurisdiction before listing your space.',
    description: 'Become a host',
  },
  localLawsThree: {
    id: 'rentall.localLawsThree',
    defaultMessage:
      'Most cities have rules covering homesharing, and the specific codes and ordinances can appear in many places (such as zoning, building, licensing or tax codes). In most places, you must register, get a permit, or obtain a license before you list your property or accept guests. You may also be responsible for collecting and remitting certain taxes. In some places, short-term rentals could be prohibited altogether.',
    description: 'Become a host',
  },
  localLawsFour: {
    id: 'rentall.localLawsFour',
    defaultMessage:
      'Since you are responsible for your own decision to list or book, you should get comfortable with the applicable rules before listing on',
    description: 'Become a host',
  },
  localLawsFive: {
    id: 'rentall.localLawsFive',
    defaultMessage:
      'By accepting our Terms of Service and listing your space, you certify that you will follow applicable laws and regulations.',
    description: 'Become a host',
  },
  localLawsSix: {
    id: 'rentall.localLawsSix',
    defaultMessage: 'For more info on tax for your specific property',
    description: 'Become a host',
  },
  localLawsSeven: {
    id: 'rentall.localLawsSeven',
    defaultMessage: 'click here.',
    description: 'Become a host',
  },
  aboutConcierge: {
    id: 'rentall.aboutConcierge',
    defaultMessage: 'About Concierge',
    description: 'About Conierge',
  },
  aboutConciergeOne: {
    id: 'rentall.aboutConciergeOne',
    defaultMessage:
      'Golightly concierge service is a new service for us to help you find your perfect rental.',
    description: 'About Conierge',
  },
  aboutConciergeTwo: {
    id: 'rentall.aboutConciergeTwo',
    defaultMessage:
      'Sometimes there are upcoming properties with availability, hosts who may accommodate a specific request that isn\'t detailed on the site, or you just need us to answer that question "Where should I go (work from virtually) next?" And then sometimes you just don\'t have the time to find exactly what you are looking for... The point is we are here to help!',
    description: 'About Conierge',
  },
  aboutConciergeThree: {
    id: 'rentall.aboutConciergeThree',
    defaultMessage:
      'If you feel Golightly concierge may be right for you, please either sign in or sign up, then fill out the form and we will get back to you within 24 hrs!',
    description: 'About Conierge',
  },
  aboutConciergeFour: {
    id: 'rentall.aboutConciergeFour',
    defaultMessage:
      'If you feel Golightly concierge may be right for you, please either sign in or sign up, then fill out the form and we will get back to you within 24 hrs!',
    description: 'About Conierge',
  },
  aboutConciergeFive: {
    id: 'rentall.aboutConciergeFive',
    defaultMessage: 'Already a member?',
    description: 'About Conierge',
  },
  aboutConciergeSix: {
    id: 'rentall.aboutConciergeSix',
    defaultMessage: 'Go direct to Concierge Service here.',
    description: 'About Conierge',
  },
  aboutConciergeSeven: {
    id: 'rentall.aboutConciergeSeven',
    defaultMessage: 'Ready to become a member?',
    description: 'About Conierge',
  },
  aboutConciergeEight: {
    id: 'rentall.aboutConciergeEight',
    defaultMessage: 'Sign up here first then go to Concierge Service.',
    description: 'About Conierge',
  },
  proHost: {
    id: 'rentall.proHost',
    defaultMessage: 'Pro Host',
    description: 'View Profile',
  },
  proHostToolTips: {
    id: 'rentall.proHostToolTips',
    defaultMessage:
      'As a Go Pro Host, all listings are professionally managed and fully approved. The member is accountable for your stay and she will be responsible for your booking, even if there may be times when other team members check you in. Please ask your host.',
    description: 'View Profile',
  },
  guestRequirementsTopHeading: {
    id: 'rentall.guestRequirementsTopHeading',
    defaultMessage: 'step 3 | Golightly Host Pledge',
    description: 'STEP 3 Heading',
  },
  requiredLabel: {
    id: 'rentall.requiredLabel',
    defaultMessage: '*Required',
    description: '*Required',
  },
  petFee: {
    id: 'rentall.petFee',
    defaultMessage: 'Pet Fee',
    description: 'Pricing Become a host',
  },
  petFeeInvalid: {
    id: 'rentall.petFeeInvalid',
    defaultMessage: 'Invalid Pet Fee, only numbers(eg: 25) are allowed',
    description: 'Cleaning price Invalid',
  },
  availableLabel: {
    id: 'rentall.availableLabel',
    defaultMessage: 'Available',
    description: 'Calendar',
  },
  averageWeeklyPrice: {
    id: 'rentall.averageWeeklyPrice',
    defaultMessage: 'Average weekly price with',
    description: 'Discount Become a host',
  },
  discountHeading: {
    id: 'rentall.discountHeading',
    defaultMessage: 'discount:',
    description: 'Tab bar',
  },
  averageMonthlyPrice: {
    id: 'rentall.averageMonthlyPrice',
    defaultMessage: 'Average monthly price with',
    description: 'Discount Become a host',
  },
  averageThreeMonthPrice: {
    id: 'rentall.averageThreeMonthPrice',
    defaultMessage: 'Average three months price with',
    description: 'Discount Become a host',
  },
  discountStep: {
    id: 'rentall.discountStep',
    defaultMessage: 'Discount for longer stays',
    description: 'Discount Become a host',
  },
  longTermDiscount: {
    id: 'rentall.longTermDiscount',
    defaultMessage: 'Long-term discount (90+ days)',
    description: 'Long-term Discount (90+ days)',
  },
  threeMonthLabel: {
    id: 'rentall.threeMonthLabel',
    defaultMessage: '90+ days discount',
    description: 'Monthly Discount',
  },
  perNightLabel: {
    id: 'rentall.perNightLabel',
    defaultMessage: 'per night',
    description: 'Per Night',
  },
  petLable: {
    id: 'rentall.petLable',
    defaultMessage: 'Are you bringing any pets along with you?',
    description: 'Payment Summery',
  },
  inviteOne: {
    id: 'rentall.inviteOne',
    defaultMessage: 'person has joined from your invitation.',
    description: 'Invite Page',
  },
  inviteTwo: {
    id: 'rentall.inviteTwo',
    defaultMessage: 'you have invited',
    description: 'Invite Page',
  },
  inviteThree: {
    id: 'rentall.inviteThree',
    defaultMessage: 'You can invite',
    description: 'Invite Page',
  },
  inviteFour: {
    id: 'rentall.inviteFour',
    defaultMessage: 'more members with your invitation code.',
    description: 'Invite Page',
  },
  inviteFive: {
    id: 'rentall.inviteFive',
    defaultMessage: 'more members with your invitation code.',
    description: 'Invite Page',
  },
  inviteSix: {
    id: 'rentall.inviteSix',
    defaultMessage:
      'You can invite unlimited members with your invitation code.',
    description: 'Invite Page',
  },
  conciergeResults: {
    id: 'rentall.conciergeResults',
    defaultMessage: 'Alternatively, please try our',
    description: 'No Reuslts',
  },
  conciergeLabel: {
    id: 'rentall.conciergeLabel',
    defaultMessage: 'Golightly Concierge Service.',
    description: 'No Reuslts',
  },
  coverLabel: {
    id: 'rentall.coverLabel',
    defaultMessage: 'Cover',
    description: 'Listing Step2',
  },
  perMonth: {
    id: 'rentall.perMonth',
    defaultMessage: 'Per Month',
    description: 'Per Night',
  },
  shareLabel: {
    id: 'rentall.shareLabel',
    defaultMessage: 'Share',
    description: 'View Listing',
  },
  reasonForList: {
    id: 'rentall.reasonForList',
    defaultMessage: 'Reason for listing',
    description: 'View Listing',
  },
  reasonForUnList: {
    id: 'rentall.reasonForUnList',
    defaultMessage: 'Reason for unlisting',
    description: 'View Listing',
  },
  becomeAMemberOne: {
    id: 'rentall.becomeAMemberOne',
    defaultMessage:
      "Thank you for your interest in Golightly. Please fill in the form below so we can call you. A member of our team will contact you before you're invited to join. We take pride in vetting our community members to ensure safety for travelers and hosts.",
    description: 'Become A Member ',
  },
  becomeAMemberTwo: {
    id: 'rentall.becomeAMemberTwo',
    defaultMessage:
      'Your information will only be used for verification purposes. Phone number, email and date of birth will not be displayed or shared.',
    description: 'Become A Member ',
  },
  becomeAMemberThree: {
    id: 'rentall.becomeAMemberThree',
    defaultMessage: 'Thank you for your interest in',
    description: 'Become A Member',
  },
  becomeAMemberFour: {
    id: 'rentall.becomeAMemberFour',
    defaultMessage:
      'We will review your membership application and get back to you soon!',
    description: 'Become A Member',
  },
  becomeAMemberFive: {
    id: 'rentall.becomeAMemberFive',
    defaultMessage:
      "This can take a while but as soon as we've completed the verification process, a member of the",
    description: 'Become A Member',
  },
  becomeAMemberSix: {
    id: 'rentall.becomeAMemberSix',
    defaultMessage:
      'team will call you from a 512 (Austin) phone # to say hello,',
    description: 'Become A Member',
  },
  becomeAMemberSeven: {
    id: 'rentall.becomeAMemberSeven',
    defaultMessage:
      'you can also log in at any time to see the status of your application.',
    description: 'Become A Member',
  },
  becomeAMemberEight: {
    id: 'rentall.becomeAMemberEight',
    defaultMessage: 'We appreciate your patience!',
    description: 'Become A Member',
  },
  seasonalPriceLabel: {
    id: 'rentall.seasonalPriceLabel',
    defaultMessage: 'Seasonal Price',
    description: 'Become A Host',
  },
  notAvailable: {
    id: 'rentall.notAvailable',
    defaultMessage: 'Property is not available for selected dates',
    description: 'Contact host error message',
  },
  paymentCardNumber: {
    id: 'rentall.paymentCardNumber',
    defaultMessage: 'Card Number',
    description: 'cardNumber',
  },
  miniumStay: {
    id: 'rentall.miniumStay',
    defaultMessage: 'Minimum stay is',
    description: 'Maximum day error',
  },
  memberPerks: {
    id: 'rentall.memberPerks',
    defaultMessage: 'Member Perks',
    description: 'Footer',
  },
  memberPerksOne: {
    id: 'rentall.memberPerksOne',
    defaultMessage: 'Perks for our members',
    description: 'Member Perks',
  },
  memberPerksTwo: {
    id: 'rentall.memberPerksTwo',
    defaultMessage: 'Yes! You read that right, you get perks!',
    description: 'Member Perks',
  },
  memberPerksThree: {
    id: 'rentall.memberPerksThree',
    defaultMessage:
      'Seriously, in return for your amazing support, we are building partnerships and opening up our member perks so that we can support our community as you support each other.',
    description: 'Member Perks',
  },
  memberPerksFour: {
    id: 'rentall.memberPerksFour',
    defaultMessage:
      'To see exactly what perks we are offering, login and view this page, and keep checking as we are only just beginning!',
    description: 'Member Perks',
  },
  memberPerksFive: {
    id: 'rentall.memberPerksFive',
    defaultMessage:
      'Our member perks will consist of fun opportunities, unique discounts, ways to give back and finally, some exciting freebies.',
    description: 'Member Perks',
  },
  memberPerksSix: {
    id: 'rentall.memberPerksSix',
    defaultMessage:
      'If you have an idea and are interested in partnering further please let us know',
    description: 'Member Perks',
  },
  memberPerksSeven: {
    id: 'rentall.memberPerksSeven',
    defaultMessage: 'here!',
    description: 'Member Perks',
  },
  showComments: {
    id: 'rentall.showComments',
    defaultMessage: 'Show more Comments',
    description: 'Toggle Button',
  },
  closeComments: {
    id: 'rentall.closeComments',
    defaultMessage: 'Close more Comments',
    description: 'Toggle Button',
  },
  like: {
    id: 'rentall.like',
    defaultMessage: 'Like',
    description: 'Like Text',
  },
  likes: {
    id: 'rentall.likes',
    defaultMessage: 'Likes',
    description: 'Likes Text',
  },
  comment: {
    id: 'rentall.comment',
    defaultMessage: 'Comment',
    description: 'Comment Text',
  },
  comments: {
    id: 'rentall.comments',
    defaultMessage: 'Comments',
    description: 'Comments Text',
  },
  writeComment: {
    id: 'rentall.writeComment',
    defaultMessage: 'Share a positive comment!',
    description: 'Write a comment...',
  },
  errorMessagePhoneNumber: {
    id: 'rentall.errorMessagePhoneNumber',
    defaultMessage:
      'NB: We trust all our members to support Golightly’s mission to provide a safer space for women. Please be respectful and do not solicit, send phone #s, emails, other sites, social handles or anything unsupportive of our mission. Thanks!',
    description: 'Error Message',
  },
  addPostLabel: {
    id: 'rentall.addPostLabel',
    defaultMessage: 'Add Post',
    description: 'Add Post',
  },
  noRecordFound: {
    id: 'rentall.noRecordFound',
    defaultMessage: 'No records found.',
    description: 'No records found.',
  },
  areYouSureDeleteBlogPost: {
    id: 'rentall.areYouSureDeleteBlogPost',
    defaultMessage: 'Are you sure you want to delete this?',
    description: 'areYouSureDeleteBlogPost',
  },
  deletePostDetails: {
    id: 'rentall.deletePostDetails',
    defaultMessage: 'Deleting Post Details',
    description: 'Deleting Post Details',
  },
  unpublishLabel: {
    id: 'rentall.unpublishLabel',
    defaultMessage: 'Unpublish',
    description: 'Unpublish',
  },
  publishLabel: {
    id: 'rentall.publishLabel',
    defaultMessage: 'Publish',
    description: 'Publish',
  },
  editLabel: {
    id: 'rentall.editLabel',
    defaultMessage: 'Edit',
    description: 'Edit',
  },
  writeSomethingLabel: {
    id: 'rentall.writeSomethingLabel',
    defaultMessage:
      "Please write something inspiring, uplifting, creative, cool! Anything you'd like to share. Offer travel tips, ask destination advice, or share stories and photos from your journeys. Thanks for contributing!",
    description: 'write something.......',
  },
  contentLabel: {
    id: 'rentall.contentLabel',
    defaultMessage: 'Content',
    description: 'Content',
  },
  imageLabel: {
    id: 'rentall.imageLabel',
    defaultMessage: 'Image',
    description: 'Image',
  },
  titleIsRequired: {
    id: 'rentall.titleIsRequired',
    defaultMessage: 'Title is Required',
    description: 'Title is Required',
  },
  contentIsRequired: {
    id: 'rentall.contentIsRequired',
    defaultMessage: 'Blog Content is Required',
    description: 'Blog Content is Required',
  },
  adminLabel: {
    id: 'rentall.adminLabel',
    defaultMessage: 'Admin',
    description: 'Admin',
  },
  golightlyAdminLabel: {
    id: 'rentall.golightlyAdminLabel',
    defaultMessage: 'Golightly Admin',
    description: 'Golightly Admin',
  },
  blogPostLabel: {
    id: 'rentall.blogPostLabel',
    defaultMessage: 'Blog posts',
    description: 'Blog posts',
  },
  goHost: {
    id: 'rentall.goHost',
    defaultMessage: 'Go Host',
    description: 'Footer',
  },
  emailErrorBecomeAMember: {
    id: 'rentall.emailErrorBecomeAMember',
    defaultMessage:
      'Unfortunately there is an issue with this email address, please contact us for further information.',
    description: 'Become a member',
  },
  social: {
    id: 'rentall.social',
    defaultMessage: 'Social',
    description: 'Social',
  },
  mypost: {
    id: 'rentall.mypost',
    defaultMessage: 'My Post',
    description: 'My Post',
  },
  userAccessOne: {
    id: 'rentall.userAccessOne',
    defaultMessage:
      'Whoops! Your account is currently on hold. This could be due to a number of reasons. Please contact us',
    description: 'User Access',
  },
  userAccessTwo: {
    id: 'rentall.userAccessTwo',
    defaultMessage:
      'Whilst we have your attention please read the agreed Golightly’s mission statement below:',
    description: 'User Access',
  },
  userAccessThree: {
    id: 'rentall.userAccessThree',
    defaultMessage: 'I will treat all members with respect.',
    description: 'User Access',
  },
  userAccessFour: {
    id: 'rentall.userAccessFour',
    defaultMessage: 'I will not discriminate against any member.',
    description: 'User Access',
  },
  userAccessFive: {
    id: 'rentall.userAccessFive',
    defaultMessage: 'I will keep my property clean whether as a host or guest.',
    description: 'User Access',
  },
  userAccessSix: {
    id: 'rentall.userAccessSix',
    defaultMessage:
      "I will not share phone #'s, emails, social handles or any material that doesn't support our mission.",
    description: 'User Access',
  },
  userAccessSeven: {
    id: 'rentall.userAccessSeven',
    defaultMessage:
      'I will not attempt to solicit or agree to any business off platform.',
    description: 'User Access',
  },
  userAccessEight: {
    id: 'rentall.userAccessEight',
    defaultMessage:
      "Remember, we built Golightly to support the mission of women's safety in travel and we strive to provide our members with not just safe travel but connections, cool partners, events, community and lots more. The reason we have this mission statement is to support women like yourself. By disrespecting our mission the whole community may suffer. If you have any suggestions to support the mission and the community, please let us know.",
    description: 'User Access',
  },
  reloadsite: {
    id: 'rentall.reloadsite',
    defaultMessage: 'Please refresh the screen and try again!',
    description: 'User Access',
  },
  showLessLabel: {
    id: 'rentall.showLessLabel',
    defaultMessage: 'Show Less',
    description: 'Show Less',
  },
  conciergeIdVerificationInfo: {
    id: 'rentall.conciergeIdVerificationInfo',
    defaultMessage:
      'Thanks for uploading your ID. Once verified, you will be all set to proceed with your concierge request. Thank you',
    description: 'conciergeIdVerificationInfo',
  },
  allLabel: {
    id: 'rentall.allLabel',
    defaultMessage: 'All',
    description: 'All',
  },
  pendingLabel: {
    id: 'rentall.pendingLabel',
    defaultMessage: 'Pending',
    description: 'Pending',
  },
  approvedLabel: {
    id: 'rentall.approvedLabel',
    defaultMessage: 'Approved',
    description: 'Approved',
  },
  declinedLabel: {
    id: 'rentall.declinedLabel',
    defaultMessage: 'Declined',
    description: 'Declined',
  },
  noPostPublished: {
    id: 'rentall.noPostPublished',
    defaultMessage: 'No posts published',
    description: 'No posts published',
  },
  myPostLabel: {
    id: 'rentall.myPostLabel',
    defaultMessage: 'My Posts',
    description: 'My Posts',
  },
  writeSomething: {
    id: 'rentall.writeSomething',
    defaultMessage: 'Write something here...',
    description: 'Write something here...',
  },
  backToSocialMedia: {
    id: 'rentall.backToSocialMedia',
    defaultMessage: 'Back to social feed',
    description: 'Back to social feed',
  },
  publishedLabel: {
    id: 'rentall.publishedLabel',
    defaultMessage: 'Published',
    description: 'Published',
  },
  socialPrivacySettings: {
    id: 'rentall.socialPrivacySettings',
    defaultMessage: 'Social Privacy Settings',
    description: 'Social Privacy Settings',
  },
  LoadingSymbol: {
    id: 'rentall.LoadingSymbol',
    defaultMessage: 'Loading...',
    description: 'Loading...',
  },
  blockedDayCheckBoxLabel: {
    id: 'rentall.blockedDayCheckBoxLabel',
    defaultMessage: 'Do not allow check in on ',
    description: 'Do not allow check in on ',
  },
  checkInDayLabel1: {
    id: 'rentall.checkInDayLabel1',
    defaultMessage: 'MON',
    description: 'MON',
  },
  checkInDayLabel2: {
    id: 'rentall.checkInDayLabel2',
    defaultMessage: 'TUE',
    description: 'TUE',
  },
  checkInDayLabel3: {
    id: 'rentall.checkInDayLabel3',
    defaultMessage: 'WED',
    description: 'WED',
  },
  checkInDayLabel4: {
    id: 'rentall.checkInDayLabel4',
    defaultMessage: 'THU',
    description: 'THU',
  },
  checkInDayLabel5: {
    id: 'rentall.checkInDayLabel5',
    defaultMessage: 'FRI',
    description: 'FRI',
  },
  checkInDayLabel6: {
    id: 'rentall.checkInDayLabel6',
    defaultMessage: 'SAT',
    description: 'SAT',
  },
  checkInDayLabel7: {
    id: 'rentall.checkInDayLabel7',
    defaultMessage: 'SUN',
    description: 'SUN',
  },
  checkInDayTitle: {
    id: 'rentall.checkInDayTitle',
    defaultMessage: 'Check-in day restrictions (optional)',
    description: 'Check-in day restrictions (optional)',
  },
  checkInDayTitleContent: {
    id: 'rentall.checkInDayTitleContent',
    defaultMessage:
      'If you have a day where guests cannot check in please enter it here',
    description:
      'If you have a day where guests cannot check in please enter it here',
  },
  checkInBlockedDaysHoverMsg: {
    id: 'rentall.checkInBlockedDaysHoverMsg',
    defaultMessage: 'Check-in restricted on this day!',
    description: 'Check-in restricted on this day!',
  },
  listingUpdateDate: {
    id: 'rentall.listingUpdateDate',
    defaultMessage: 'Last updated:',
    description: 'Ical sync calendar',
  },
  notFoundContactInfo: {
    id: 'rentall.notFoundContactInfo',
    defaultMessage: 'Please contact',
    description: 'Not found',
  },
  notFoundContactInfoTwo: {
    id: 'rentall.notFoundContactInfoTwo',
    defaultMessage: 'for more info.',
    description: 'Not found',
  },
  idProofUploadContent: {
    id: 'rentall.idProofUploadContent',
    defaultMessage:
      'Click here to upload a photo of your ID or drag it in. (Must be a PNG or JPG file under 1MB in size)',
    description: 'Document Upload Placeholder',
  },
  sortLabel: {
    id: 'rentall.sortLabel',
    defaultMessage: 'Sort',
    description: 'Sort',
  },
  priceLowToHigh: {
    id: 'rentall.priceLowToHigh',
    defaultMessage: 'Price Low to High',
    description: 'Price Low to High',
  },
  priceHighToLow: {
    id: 'rentall.priceHighToLow',
    defaultMessage: 'Price High to Low',
    description: 'Price High to Low',
  },
  proopertyStatusOne: {
    id: 'rentall.proopertyStatus',
    defaultMessage: 'Do not update these items from',
    description: 'Existing page',
  },
  proopertyStatusTwo: {
    id: 'rentall.proopertyStatusTwo',
    defaultMessage:
      'during syncs: House Rules, Cancellation Policy and Check-in / Check-out Time',
    description: 'Existing page',
  },
  bsbNumber: {
    id: 'rentall.bsbNumber',
    defaultMessage: 'BSB Number',
    description: 'Payout',
  },
  branchCode: {
    id: 'rentall.branchCode',
    defaultMessage: 'Branch Code',
    description: 'Payout',
  },
  clearingCode: {
    id: 'rentall.clearingCode',
    defaultMessage: 'Clearing Code',
    description: 'Payout',
  },
  accountOwnerName: {
    id: 'rentall.accountOwnerName',
    defaultMessage: 'Account Owner Name',
    description: 'Payout',
  },
  branchName: {
    id: 'rentall.branchName',
    defaultMessage: 'Branch Name',
    description: 'Payout',
  },
  bankName: {
    id: 'rentall.bankName',
    defaultMessage: 'Bank Name',
    description: 'Payout',
  },
  bankCode: {
    id: 'rentall.bankCode',
    defaultMessage: 'Bank Code',
    description: 'Payout',
  },
  clabe: {
    id: 'rentall.clabe',
    defaultMessage: 'Clabe',
    description: 'Payout',
  },
  confirmClabe: {
    id: 'rentall.confirmClabe',
    defaultMessage: 'Confirm Clabe',
    description: 'Payout',
  },
  noPostsMatched: {
    id: 'rentall.noPostsMatched',
    defaultMessage: 'No posts matched',
    description: 'No posts matched',
  },
  subscriptionLabel: {
    id: 'rentall.subscriptionLabel',
    defaultMessage: 'Subscribe to our newsletter',
    description: 'Footer',
  },
  subscriptionTitle: {
    id: 'rentall.subscriptionTitle',
    defaultMessage: 'Subscribe',
    description: 'Footer',
  },
  blogPostPinLabel: {
    id: 'rentall.blogPostPinLabel',
    defaultMessage: 'Pin',
    description: 'Pin',
  },
  blogPostUnPinLabel: {
    id: 'rentall.blogPostUnPinLabel',
    defaultMessage: 'Unpin',
    description: 'Unpin',
  },
  pinnedByAdminLabel: {
    id: 'rentall.pinnedByAdminLabel',
    defaultMessage: 'Pinned by Admin',
    description: 'Pinned by Admin',
  },
  blogTermsAndConditon: {
    id: 'rentall.blogTermsAndConditon',
    defaultMessage: 'I agree to the above',
    description: 'I agree to the above',
  },
  blogRulesAndRegulation1: {
    id: 'rentall.blogRulesAndRegulation1',
    defaultMessage: 'Social',
    description: 'GoSocial',
  },
  confirmLabel: {
    id: 'rentall.confirmLabel',
    defaultMessage: 'Ok',
    description: 'Ok',
  },
  blogRulesAndRegulation2: {
    id: 'rentall.blogRulesAndRegulation2',
    defaultMessage:
      "Social is a forum for Golightly members to offer travel tips and insight, ask destination advice and share stories and photos from your journeys. We trust our members to support Golightly's mission to provide a safe space for women. Please be respectful of all members and do not solicit or promote products/services, or share personal contact information. Please keep your posts and comments encouraging and uplifting, in the spirit of our community!",
    description:
      "GoSocial is a forum for Golightly members to offer travel tips and insight, ask destination advice and share stories and photos from your journeys. We trust our members to support Golightly's mission to provide a safe space for women. Please be respectful of all members and do not solicit or promote products/services, or share personal contact information. Please keep your posts and comments encouraging and uplifting, in the spirit of our community!",
  },
  rulesAndRegulationLabel: {
    id: 'rentall.rulesAndRegulationLabel',
    defaultMessage: 'Rules',
    description: 'Rules and Regulations',
  },
  twitter: {
    id: 'rentall.twitter',
    defaultMessage: 'Twitter',
    description: 'sign up with twitter',
  },
  connectionLabel: {
    id: 'rentall.connectionLabel',
    defaultMessage: 'Connection',
    description: 'sign up with twitter',
  },
  guestRequirementLabel: {
    id: 'rentall.guestRequirementLabel',
    defaultMessage: 'I agree to Golightly’s',
    description: 'sign up with twitter',
  },

  addEventButtonLabel: {
    id: 'rentall.addEventButtonLabel',
    defaultMessage: 'Add Event',
    description: 'Add Event',
  },
  eventLabel: {
    id: 'rentall.eventLabel',
    defaultMessage: 'Event',
    description: 'Event',
  },
  eventsLabel: {
    id: 'rentall.eventsLabel',
    defaultMessage: 'Events',
    description: 'Events',
  },
  deleteEventDetails: {
    id: 'rentall.deleteEventDetails',
    defaultMessage: 'Deleting Event Details',
    description: 'Deleting Event Details',
  },
  socialBlogSearch: {
    id: 'rentall.socialBlogSearch',
    defaultMessage: "What's on your mind ",
    description: "What's on your mind ",
  },
  bookingDate: {
    id: 'rentall.bookingDate',
    defaultMessage: 'Booking Date',
    description: 'Start Date',
  },
  startDate: {
    id: 'rentall.startDate',
    defaultMessage: 'Start Date',
    description: 'Start Date',
  },
  endDate: {
    id: 'rentall.endDate',
    defaultMessage: 'End Date',
    description: 'End Date',
  },
  enableLabel: {
    id: 'rentall.enableLabel',
    defaultMessage: 'Enable',
    description: 'Enable',
  },
  disableLabel: {
    id: 'rentall.disableLabel',
    defaultMessage: 'Disable',
    description: 'Disable',
  },
  recentEventsLabel: {
    id: 'rentall.recentEventsLabel',
    defaultMessage: 'Recent Events',
    description: 'Recent Events',
  },
  upcomingEvents: {
    id: 'rentall.upcomingEvents',
    defaultMessage: 'Upcoming Events',
    description: 'Upcoming Events',
  },
  confirm: {
    id: 'rentall.confirm',
    defaultMessage: 'Confirm',
    description: 'Confirm',
  },
  eventInfo1: {
    id: 'rentall.eventInfo1',
    defaultMessage: 'Are you sure you want to confirm the event?',
    description: 'Are you sure you want to confirm the event?',
  },
  going: {
    id: 'rentall.going',
    defaultMessage: 'Going',
    description: 'Going',
  },
  attended: {
    id: 'rentall.attended',
    defaultMessage: 'Attended',
    description: 'attended',
  },
  declineReasonModalLabel: {
    id: 'rentall.declineReasonModalLabel',
    defaultMessage:
      'We ask Golightly members to always include a reason for declining a booking request.',
    description: 'attended',
  },
  declineReasonFieldPlaceholder: {
    id: 'rentall.declineReasonFieldPlaceholder',
    defaultMessage:
      "We're sorry you can't accommodate this booking. Please send your guest a nice note and let her know why you're unable to host and we'll try to find her another great place.",
    description: 'attended',
  },
  advisorsName10: {
    id: 'rentall.advisorsName10',
    defaultMessage: 'Lavina Liyanage',
    description: 'About Us',
  },
  advisorsType10: {
    id: 'rentall.advisorsType10',
    defaultMessage: 'Operations Advisor',
    description: 'About Us',
  },
  advisorsRole10: {
    id: 'rentall.advisorsRole10',
    defaultMessage: 'Chief Markets Officer,',
    description: 'About Us',
  },
  advisorsRoleSub10: {
    id: 'rentall.advisorsRoleSub10',
    defaultMessage: 'Onefinestay',
    description: 'About Us',
  },
  aboutUsTitleOne: {
    id: 'rentall.aboutUsTitleOne',
    defaultMessage: 'Martine Boursiquot-LaConte',
    description: 'About Us',
  },
  aboutUsTitleTwo: {
    id: 'rentall.aboutUsTitleTwo',
    defaultMessage: 'Head of Community & Engagement',
    description: 'About Us',
  },
  aboutUsTitleThree: {
    id: 'rentall.aboutUsTitleThree',
    defaultMessage:
      'Martine joins Golightly as Head of Community & Engagement at a time when our community has grown into 85 countries around the world. Based in our hometown of Austin, Texas, Martine is a travel obsessed mom who brings to Golightly her wealth of experience and knowledge from nearly two decades in marketing and most recently as an Events Director at Pershing House, where she fully developed their events program through sales, marketing and networking.',
    description: 'About Us',
  },
  aboutUsTitleFour: {
    id: 'rentall.aboutUsTitleFour',
    defaultMessage:
      "Through her love of traveling and creating women-focused content, Martine brings a tremendous amount of excitement and passion to our community. Golightly is thrilled to welcome Martine along with her communication and leadership skills as well as her team-focused spirit, honesty, kindness and humor! Connect with Martine on Golightly if you haven't already!",
    description: 'About Us',
  },
  addNewLabel: {
    id: 'rentall.addNewLabel',
    defaultMessage: 'Add New',
    description: 'Add New',
  },
  manageAdminUsers: {
    id: 'rentall.manageAdminUsers',
    defaultMessage: 'Manage Admin Users',
    description: 'Manage Admin Users',
  },
  sNoLabel: {
    id: 'rentall.sNoLabel',
    defaultMessage: 'S.No',
    description: 'S.No',
  },
  adminRoleLabel: {
    id: 'rentall.adminRoleLabel',
    defaultMessage: 'Admin Role',
    description: 'Admin Role',
  },
  adminUserDesc: {
    id: 'rentall.adminUserDesc',
    defaultMessage:
      'Provide new password only. Ignore this field to use the existing password for this user.',
    description:
      'Provide new password only. Ignore this field to use the existing password for this user.',
  },
  roleLabel: {
    id: 'rentall.roleLabel',
    defaultMessage: 'Role',
    description: 'Role',
  },
  selectroleLabel: {
    id: 'rentall.selectroleLabel',
    defaultMessage: 'Select Role',
    description: 'Select Role',
  },
  update: {
    id: 'rentall.update',
    defaultMessage: 'Update',
    description: 'Update Button',
  },
  adminUserLabel: {
    id: 'rentall.adminUserLabel',
    defaultMessage: 'Admin User',
    description: 'Admin User',
  },
  idLabel: {
    id: 'rentall.idLabel',
    defaultMessage: 'ID',
    description: 'ID',
  },
  descriptionAdminLabel: {
    id: 'rentall.descriptionAdminLabel',
    defaultMessage: 'Description',
    description: 'Description',
  },
  roleNameLabel: {
    id: 'rentall.roleNameLabel',
    defaultMessage: 'Role Name',
    description: 'Role Name',
  },
  privilagesLabel: {
    id: 'rentall.privilagesLabel',
    defaultMessage: 'Privileges',
    description: 'Privileges',
  },
  manageAdminRoles: {
    id: 'rentall.manageAdminRoles',
    defaultMessage: 'Manage Admin Roles',
    description: 'Manage Admin Roles',
  },
  inviteFieldLableOne: {
    id: 'rentall.inviteFieldLableOne',
    defaultMessage:
      'Finally invite your Friends and get $100 credit right away !',
    description: 'Edit profile',
  },
  inviteFieldLableTwo: {
    id: 'rentall.inviteFieldLableTwo',
    defaultMessage: 'Invite up to',
    description: 'Edit profile',
  },
  inviteFieldLableThree: {
    id: 'rentall.inviteFieldLableThree',
    defaultMessage:
      'of your trusted friends now.  You get $20 credit when she joins (and so will she)!',
    description: 'Edit profile',
  },
  yourMessage: {
    id: 'rentall.yourMessage',
    defaultMessage: 'Your message',
    description: 'Edit profile',
  },
  waiting: {
    id: 'rentall.waiting',
    defaultMessage: 'Waiting',
    description: 'Social page',
  },
  welcomeAdmin: {
    id: 'rentall.welcomeAdmin',
    defaultMessage: 'Welcome, Admin',
    description: 'Welcome, Admin',
  },
  siteSettings: {
    id: 'rentall.siteSettings',
    defaultMessage: 'Site Settings',
    description: 'Site Settings',
  },
  changeCommonPassword: {
    id: 'rentall.changeCommonPassword',
    defaultMessage: 'Change Common Password',
    description: 'Change Common Password',
  },
  manageAdmin: {
    id: 'rentall.manageAdmin',
    defaultMessage: 'Manage Admins',
    description: 'Manage Admins',
  },
  manageUser: {
    id: 'rentall.manageUser',
    defaultMessage: 'Manage Users',
    description: 'Manage Users',
  },
  managePressUsers: {
    id: 'rentall.managePressUsers',
    defaultMessage: 'Manage Press Users',
    description: 'Manage Press Users',
  },
  deletedUsers: {
    id: 'rentall.deletedUsers',
    defaultMessage: 'Deleted Users',
    description: 'Deleted Users',
  },
  manageMembers: {
    id: 'rentall.manageMembers',
    defaultMessage: 'Manage Members',
    description: 'Manage Members',
  },
  managePropertyManagers: {
    id: 'rentall.managePropertyManagers',
    defaultMessage: 'Manage Property Managers',
    description: 'Manage Property Managers',
  },
  approveListings: {
    id: 'rentall.approveListings',
    defaultMessage: 'Approve Listings',
    description: 'Approve Listings',
  },
  manageTopPicks: {
    id: 'rentall.manageTopPicks',
    defaultMessage: 'Manage Top Picks',
    description: 'Manage Top Picks',
  },
  manageLongTermStays: {
    id: 'rentall.manageLongTermStays',
    defaultMessage: 'Manage Long-term Stays',
    description: 'Manage Long-term Stays',
  },
  manageSoloTravelPicks: {
    id: 'rentall.manageSoloTravelPicks',
    defaultMessage: 'Manage Solo Travel Picks',
    description: 'Manage Solo Travel Picks',
  },
  manageUniqueTravelPicks: {
    id: 'rentall.manageUniqueTravelPicks',
    defaultMessage: 'Manage Unique Travel Picks',
    description: 'Manage Unique Travel Picks',
  },
  mangeFamilyFriendlyPicks: {
    id: 'rentall.mangeFamilyFriendlyPicks',
    defaultMessage: 'Manage Family Friendly Picks',
    description: 'Manage Family Friendly Picks',
  },
  manageBusinessTravelPicks: {
    id: 'rentall.manageBusinessTravelPicks',
    defaultMessage: 'Manage Business Travel Picks',
    description: 'Manage Business Travel Picks',
  },
  manageReservations: {
    id: 'rentall.manageReservations',
    defaultMessage: 'Manage Reservations',
    description: 'Manage Reservations',
  },
  icalManagement: {
    id: 'rentall.icalManagement',
    defaultMessage: 'iCal Management',
    description: 'iCal Management',
  },
  adminReviews: {
    id: 'rentall.adminReviews',
    defaultMessage: 'Admin Reviews',
    description: 'Admin Reviews',
  },
  integrationReports: {
    id: 'rentall.integrationReports',
    defaultMessage: 'Integration Reports',
    description: 'Integration Reports',
  },
  userReviews: {
    id: 'rentall.userReviews',
    defaultMessage: 'User Reviews',
    description: 'User Reviews',
  },
  manageReviwes: {
    id: 'rentall.manageReviwes',
    defaultMessage: 'Manage Reviews',
    description: 'Manage Reviews',
  },
  writeReviwes: {
    id: 'rentall.writeReviwes',
    defaultMessage: 'Write Review',
    description: 'Write Review',
  },
  manageServiceFee: {
    id: 'rentall.manageServiceFee',
    defaultMessage: 'Manage ServiceFee',
    description: 'Manage ServiceFee',
  },
  managePropertyServiceFee: {
    id: 'rentall.managePropertyServiceFee',
    defaultMessage: 'Manage Property ServiceFee',
    description: 'Manage Property ServiceFee',
  },
  idProofVerification: {
    id: 'rentall.idProofVerification',
    defaultMessage: 'ID Proof Verification',
    description: 'ID Proof Verification',
  },
  privateMessages: {
    id: 'rentall.privateMessages',
    defaultMessage: 'Private Messages',
    description: 'Private Messages',
  },
  connectionMessages: {
    id: 'rentall.connectionMessages',
    defaultMessage: 'Connection Messages',
    description: 'Connection Messages',
  },
  reportManagement: {
    id: 'rentall.reportManagement',
    defaultMessage: 'Report Management',
    description: 'Report Management',
  },
  manageCurrency: {
    id: 'rentall.manageCurrency',
    defaultMessage: 'Manage Currency',
    description: 'Manage Currency',
  },
  searchSettings: {
    id: 'rentall.searchSettings',
    defaultMessage: 'Search Settings',
    description: 'Search Settings',
  },
  adminChangePassword: {
    id: 'rentall.adminChangePassword',
    defaultMessage: 'Admin Change Password',
    description: 'Admin Change Password',
  },
  travelCreditSettings: {
    id: 'rentall.travelCreditSettings',
    defaultMessage: 'Travel Credit Settings',
    description: 'Travel Credit Settings',
  },
  homePageSettings: {
    id: 'rentall.homePageSettings',
    defaultMessage: 'Home Page Settings',
    description: 'Home Page Settings',
  },
  bannerCaptionLabel: {
    id: 'rentall.bannerCaptionLabel',
    defaultMessage: 'Banner Caption',
    description: 'Banner Caption',
  },
  imageBannerLabel: {
    id: 'rentall.imageBannerLabel',
    defaultMessage: 'Image Banner',
    description: 'Image Banner',
  },
  footerBlockLabel: {
    id: 'rentall.footerBlockLabel',
    defaultMessage: 'Footer Block',
    description: 'Footer Block',
  },
  footerSignupBlock: {
    id: 'rentall.footerSignupBlock',
    defaultMessage: 'Footer Signup Block',
    description: 'Footer Signup Block',
  },
  footerInfoBlock: {
    id: 'rentall.footerInfoBlock',
    defaultMessage: 'Footer Info Block',
    description: 'Footer Info Block',
  },
  homeBannerLabel: {
    id: 'rentall.homeBannerLabel',
    defaultMessage: 'Home Banner',
    description: 'Home Banner',
  },
  listSettingStep: {
    id: 'rentall.listSettingStep',
    defaultMessage: 'List Settings for Step',
    description: 'List Settings for Step',
  },
  personCapacity: {
    id: 'rentall.personCapacity',
    defaultMessage: 'Person Capacity',
    description: 'Person Capacity',
  },
  houseTypeLabel: {
    id: 'rentall.houseTypeLabel',
    defaultMessage: 'House Type',
    description: 'House Type',
  },
  bedRoomsLabel: {
    id: 'rentall.bedRoomsLabel',
    defaultMessage: 'Bed Rooms',
    description: 'Bed Rooms',
  },
  bedTypeLabel: {
    id: 'rentall.bedTypeLabel',
    defaultMessage: 'Bed Type',
    description: 'Bed Type',
  },
  bathroomType: {
    id: 'rentall.bathroomType',
    defaultMessage: 'Bathroom Type',
    description: 'Bathroom Type',
  },
  infantsCapacity: {
    id: 'rentall.infantsCapacity',
    defaultMessage: 'Infants Capacity',
    description: 'Infants Capacity',
  },
  reviewHowGuestBook: {
    id: 'rentall.reviewHowGuestBook',
    defaultMessage: 'Review How Guests Book',
    description: 'Review How Guests Book',
  },
  bookingNoticeTime: {
    id: 'rentall.bookingNoticeTime',
    defaultMessage: 'Booking Notice Time',
    description: 'Booking Notice Time',
  },
  minimumNightsLabel: {
    id: 'rentall.minimumNightsLabel',
    defaultMessage: 'Minimum Nights',
    description: 'Minimum Nights',
  },
  maximumNightsLabel: {
    id: 'rentall.maximumNightsLabel',
    defaultMessage: 'Maximum Nights',
    description: 'Maximum Nights',
  },
  contentManagementLabel: {
    id: 'rentall.contentManagementLabel',
    defaultMessage: 'Content Management',
    description: 'Content Management',
  },
  staticPageManagement: {
    id: 'rentall.staticPageManagement',
    defaultMessage: 'Static Page Management',
    description: 'Static Page Management',
  },
  blogContentManagement: {
    id: 'rentall.blogContentManagement',
    defaultMessage: 'Blog Content Management',
    description: 'Blog Content Management',
  },
  adminBlogContent: {
    id: 'rentall.adminBlogContent',
    defaultMessage: 'Admin Blog Content',
    description: 'Admin Blog Content',
  },
  userBlogContent: {
    id: 'rentall.userBlogContent',
    defaultMessage: 'User Blog Content',
    description: 'User Blog Content',
  },
  eventManagement: {
    id: 'rentall.eventManagement',
    defaultMessage: 'Event Management',
    description: 'Event Management',
  },
  maniHostManagement: {
    id: 'rentall.maniHostManagement',
    defaultMessage: 'Main Host Management',
    description: 'Main Host Management',
  },
  naturalRetreats: {
    id: 'rentall.naturalRetreats',
    defaultMessage: 'Natural Retreats',
    description: 'Natural Retreats',
  },
  interhome: {
    id: 'rentall.interhome',
    defaultMessage: 'Interhome',
    description: 'Interhome',
  },
  bookingsLabel: {
    id: 'rentall.bookingsLabel',
    defaultMessage: 'Bookings',
    description: 'Bookings',
  },
  listingsLabel: {
    id: 'rentall.listingsLabel',
    defaultMessage: 'Listings',
    description: 'Listings',
  },
  emailBlankSpaceAdmin: {
    id: 'rentall.emailBlankSpaceAdmin',
    defaultMessage: 'Email should not be blank spaces',
    description: 'email address error',
  },
  exceedLimit255: {
    id: 'rentall.exceedLimit255',
    defaultMessage: 'Maximum allowed character is 255.',
    description: 'exceedLimit',
  },
  viewInsurance: {
    id: 'rentall.viewInsurance',
    defaultMessage: 'View insurance details and disclosures',
    description: 'Payment summary page',
  },
  insuranceYesLabel: {
    id: 'rentall.insuranceYesLabel',
    defaultMessage: 'Yes, I want Booking Protection for my trip to',
    description: 'Payment summary page',
  },
  insuranceNoLabel: {
    id: 'rentall.insuranceNoLabel',
    defaultMessage: 'Yes, I want Booking Protection for my trip to',
    description: 'Payment summary page',
  },
  insuranceNoLabelFirst: {
    id: 'rentall.insuranceNoLabelFirst',
    defaultMessage: 'No, I’m willing to risk my',
    description: 'Payment summary page',
  },
  insuranceNoLabelLast: {
    id: 'rentall.insuranceNoLabelLast',
    defaultMessage: 'trip',
    description: 'Payment summary page',
  },
  travelInsurance: {
    id: 'rentall.travelInsurance',
    defaultMessage: 'Travel Insurance',
    description: 'Payment summary page',
  },
  important: {
    id: 'rentall.important',
    defaultMessage: 'Important Option for Travelers',
    description: 'Payment summary page',
  },
  insuranceHeading: {
    id: 'rentall.insuranceHeading',
    defaultMessage: 'Protect yourself with Guest Protection Travel Insurance',
    description: 'Payment summary page',
  },
  insuranceHeadingTwo: {
    id: 'rentall.insuranceHeadingTwo',
    defaultMessage: 'Consider Protecting Your Stay',
    description: 'Payment summary page',
  },
  insuranceLabel: {
    id: 'rentall.insuranceLabel',
    defaultMessage: 'Some Reasons you might need travel protection',
    description: 'Payment summary page',
  },
  tripCancellation: {
    id: 'rentall.tripCancellation',
    defaultMessage: 'Trip Cancellation (100% of Trip Cost)',
    description: 'Payment summary page',
  },
  tripInterruption: {
    id: 'rentall.tripInterruption',
    defaultMessage: 'Trip Interruption (150% of Trip Cost)',
    description: 'Payment summary page',
  },
  travelDelay: {
    id: 'rentall.travelDelay',
    defaultMessage: 'Travel Delay (Up to $600)',
    description: 'Payment summary page',
  },
  baggageDelay: {
    id: 'rentall.baggageDelay',
    defaultMessage: 'Baggage Loss / Delay (Up to $1,000)',
    description: 'Payment summary page',
  },
  medicalCoverage: {
    id: 'rentall.medicalCoverage',
    defaultMessage: 'Medical Coverage (Up to $25,000)',
    description: 'Payment summary page',
  },
  emergencyAssistance: {
    id: 'rentall.emergencyAssistance',
    defaultMessage: 'Emergency Assistance (Up to $1,000,000)',
    description: 'Payment summary page',
  },
  rentalCarDamage: {
    id: 'rentall.rentalCarDamage',
    defaultMessage: 'Rental Car Damage (Up to $25,000)',
    description: 'Payment summary page',
  },
  insuranceParaOne: {
    id: 'rentall.insuranceParaOne',
    defaultMessage:
      'We chose this insurance for our members because it is the most affordable option and they have a great reputation. But as with all things, we recommend you read the details in the link below to make sure this is the right option for you!',
    description: 'Payment summary page',
  },
  insuranceParaTwo: {
    id: 'rentall.insuranceParaTwo',
    defaultMessage:
      'This insurance is provided by Generali Insurance and is currently available to all US residents. If you are not a US resident, please let us know so we can help.',
    description: 'Payment summary page',
  },
  insuranceFieldLabel: {
    id: 'rentall.insuranceFieldLabel',
    defaultMessage: 'Select Yes or No to continue booking',
    description: 'Payment summary page',
  },
  universityOrCollegeName: {
    id: 'rentall.universityOrCollegeName',
    defaultMessage: 'University / college name',
    description: 'Edit profile page',
  },
  tagManagement: {
    id: 'rentall.tagManagement',
    defaultMessage: 'Tag Management',
    description: 'tag management',
  },
  tagDescription: {
    id: 'rentall.tagDescription',
    defaultMessage: 'Tags Description',
    description: 'tag management',
  },
  feature: {
    id: 'rentall.feature',
    defaultMessage: 'Feature',
    description: 'tag management',
  },
  set: {
    id: 'rentall.set',
    defaultMessage: 'set',
    description: 'tag management',
  },
  noTagFound: {
    id: 'rentall.noTagFound',
    defaultMessage: 'No tags were found',
    description: 'tag management',
  },
  areYouSureToDelete: {
    id: 'rentall.areYouSureToDelete',
    defaultMessage: 'Are you sure you want to delete this?',
    description: 'tag management',
  },
  deletingTag: {
    id: 'rentall.deletingTag',
    defaultMessage: 'Deleting Tag',
    description: 'tag management',
  },
  addTag: {
    id: 'rentall.addTag',
    defaultMessage: 'Add Tag',
    description: 'tag management',
  },
  editTag: {
    id: 'rentall.editTag',
    defaultMessage: 'Edit Tag',
    description: 'tag management',
  },
  tags: {
    id: 'rentall.tags',
    defaultMessage: 'Tags',
    description: 'tag management',
  },
  popularTopics: {
    id: 'rentall.popularTopics',
    defaultMessage: 'Popular Topics',
    description: 'social page',
  },
  welcomeToAdminPanel: {
    id: 'rentall.welcomeToAdminPanel',
    defaultMessage: 'Welcome to Golightly Admin Panel',
    description: 'Sub Admin Panel',
  },
  secondaryEmail: {
    id: 'rentall.secondaryEmail',
    defaultMessage: 'Secondary Email',
    description: 'Edit Profile',
  },
  userAccessEmail: {
    id: 'rentall.userAccessEmail',
    defaultMessage: 'here so we can sort it out.',
    description: 'User Access',
  },
  videos: {
    id: 'rentall.videos',
    defaultMessage: 'Videos',
    description: 'User Access',
  },
  userManagement: {
    id: 'rentall.userManagement',
    defaultMessage: 'User Management',
    description: 'Siteadmin user management',
  },
  maxInviteSettings: {
    id: 'rentall.maxInviteSettings',
    defaultMessage: 'Max Invite Settings',
    description: 'Siteadmin Invite Section',
  },
  travelCriditMessage: {
    id: 'rentall.travelCriditMessage',
    defaultMessage: 'Total Travel Credit',
    description: 'Siteadmin Invite Section',
  },
  hostServiceFee: {
    id: 'rentall.hostServiceFee',
    defaultMessage: 'Host Service Fee',
    description: 'Siteadmin Invite Section',
  },
  videosPlaceholder: {
    id: 'rentall.videosPlaceholder',
    defaultMessage:
      'If you have a video of your home you would like to share, please upload it here. (Optional)',
    description: 'videos Placeholder',
  },
  integrationId: {
    id: 'rentall.integrationId',
    defaultMessage: 'Integration ID',
    description: 'Siteadmin',
  },
  agencyId: {
    id: 'rentall.agencyId',
    defaultMessage: 'Agency ID',
    description: 'Siteadmin Hostfully',
  },
  interhomeID: {
    id: 'rentall.interhomeID',
    defaultMessage: 'Interhome Listing ID',
    description: 'Siteadmin Interhome',
  },
  hostAwayID: {
    id: 'rentall.hostAwayID',
    defaultMessage: 'HostAway ID',
    description: 'Siteadmin HostAway',
  },
  nextPaxID: {
    id: 'rentall.nextPaxID',
    defaultMessage: 'NextPax ID',
    description: 'Siteadmin NextPax',
  },
  hostFullyID: {
    id: 'rentall.hostFullyID',
    defaultMessage: 'Hostfully ID',
    description: 'Siteadmin Hostfully',
  },
  rentalsUnitedID: {
    id: 'rentall.rentalsUnitedID',
    defaultMessage: 'RentalsUnited ID',
    description: 'Siteadmin RentalsUnited',
  },
  happyRentalsId: {
    id: 'rentall.happyRentalsId',
    defaultMessage: 'HappyRentals Listing ID',
    description: 'Siteadmin HappyRentals',
  },
  commanID: {
    id: 'rentall.commanID',
    defaultMessage: 'ID',
    description: 'Siteadmin',
  },
  userID: {
    id: 'rentall.userID',
    defaultMessage: 'User ID',
    description: 'Siteadmin',
  },
  bookingId: {
    id: 'rentall.bookingId',
    defaultMessage: 'Booking ID',
    description: 'Siteadmin',
  },
  listingID: {
    id: 'rentall.listingID',
    defaultMessage: 'Listing ID',
    description: 'Siteadmin',
  },
  listingName: {
    id: 'rentall.listingName',
    defaultMessage: 'Listing Name',
    description: 'Siteadmin',
  },
  listingLink: {
    id: 'rentall.listingLink',
    defaultMessage: 'Listing Link',
    description: 'Siteadmin',
  },
  lastUpdated: {
    id: 'rentall.lastUpdated',
    defaultMessage: 'Last updated',
    description: 'Siteadmin',
  },
  userName: {
    id: 'rentall.userName',
    defaultMessage: 'User Name',
    description: 'Siteadmin',
  },
  propertyManageName: {
    id: 'rentall.propertyManageName',
    defaultMessage: 'Property Manager Name',
    description: 'Siteadmin Hostfully',
  },
  nextPaxEmail: {
    id: 'rentall.nextPaxEmail',
    defaultMessage: 'Email',
    description: 'Siteadmin NextPax',
  },
  hostFullyEmail: {
    id: 'rentall.hostFullyEmail',
    defaultMessage: 'Email',
    description: 'Siteadmin Hostfully',
  },
  rentalsUnitedEmail: {
    id: 'rentall.rentalsUnitedEmail',
    defaultMessage: 'Email',
    description: 'Siteadmin RentalsUnited',
  },
  propertyManageEmail: {
    id: 'rentall.propertyManageEmail',
    defaultMessage: 'Property Manager Email Id',
    description: 'Siteadmin Hostfully',
  },
  numberOfProperties: {
    id: 'rentall.NumberOfProperties',
    defaultMessage: 'Number of properties',
    description: 'Siteadmin NextPax',
  },
  merchantOfRecord: {
    id: 'rentall.merchantOfRecord',
    defaultMessage: 'Merchant of record',
    description: 'Siteadmin NextPax',
  },
  updatedAt: {
    id: 'rentall.updatedAt',
    defaultMessage: 'Last synced',
    description: 'Siteadmin',
  },
  lastSynced: {
    id: 'rentall.lastSynced',
    defaultMessage: 'Last synced',
    description: 'Siteadmin',
  },
  hostfullyManagement: {
    id: 'rentall.hostfullyManagement',
    defaultMessage: 'Hostfully Management',
    description: 'Siteadmin',
  },
  manageInterhomeListings: {
    id: 'rentall.manageInterhomeListings',
    defaultMessage: 'Interhome Listings',
    description: 'Siteadmin',
  },
  manageInterhomeBookings: {
    id: 'rentall.manageInterhomeBookings',
    defaultMessage: 'Interhome Bookings',
    description: 'Siteadmin',
  },
  manageHostAway: {
    id: 'rentall.manageHostAway',
    defaultMessage: 'HostAway',
    description: 'Siteadmin',
  },
  manageHostAwayBookings: {
    id: 'rentall.manageHostAwayBookings',
    defaultMessage: 'HostAway Bookings',
    description: 'Siteadmin',
  },
  manageHappyRentals: {
    id: 'rentall.manageHappyRentals',
    defaultMessage: 'HappyRentals',
    description: 'Siteadmin',
  },
  manageHostfully: {
    id: 'rentall.manageHostfully',
    defaultMessage: 'Hostfully',
    description: 'Siteadmin',
  },
  manageNextPax: {
    id: 'rentall.manageNextPax',
    defaultMessage: 'NextPax',
    description: 'Siteadmin',
  },
  manageRentalsUnited: {
    id: 'rentall.manageRentalsUnited',
    defaultMessage: 'Rentals United',
    description: 'Siteadmin',
  },
  localSpots: {
    id: 'rentall.localSpots',
    defaultMessage: 'Tell us your local spots',
    description: 'View Profile',
  },
  localSpotsOne: {
    id: 'rentall.localSpotsOne',
    defaultMessage: 'Drink',
    description: 'View Profile',
  },
  localSpotsTwo: {
    id: 'rentall.localSpotsTwo',
    defaultMessage: "What's your recommendation?",
    description: 'View Profile',
  },
  localSpotsThree: {
    id: 'rentall.localSpotsThree',
    defaultMessage: 'At what cafe or bar?',
    description: 'View Profile',
  },
  localSpotsFour: {
    id: 'rentall.localSpotsFour',
    defaultMessage: 'Dine',
    description: 'View Profile',
  },
  localSpotsFive: {
    id: 'rentall.localSpotsFive',
    defaultMessage: 'Your must have dish?',
    description: 'View Profile',
  },
  localSpotsSix: {
    id: 'rentall.localSpotsSix',
    defaultMessage: 'At which local spot?',
    description: 'View Profile',
  },
  localSpotsSeven: {
    id: 'rentall.localSpotsSeven',
    defaultMessage: 'Do this',
    description: 'View Profile',
  },
  localSpotEight: {
    id: 'rentall.localSpotEight',
    defaultMessage: 'Your favorite adventure?',
    description: 'View Profile',
  },
  localSpotNine: {
    id: 'rentall.localSpotNine',
    defaultMessage: 'Where can we do this?',
    description: 'View Profile',
  },
  localSpotTen: {
    id: 'rentall.localSpotTen',
    defaultMessage: 'Discover',
    description: 'View Profile',
  },
  localSpotEleven: {
    id: 'rentall.localSpotEleven',
    defaultMessage: 'Your hometown hidden gem?',
    description: 'View Profile',
  },
  localSpotTweleven: {
    id: 'rentall.localSpotTweleven',
    defaultMessage: 'Where can we check it out?',
    description: 'View Profile',
  },
  localSpotThirteen: {
    id: 'rentall.localSpotThirteen',
    defaultMessage: "Don't Miss",
    description: 'View Profile',
  },
  localSpotFourteen: {
    id: 'rentall.localSpotFourteen',
    defaultMessage: "One thing we can't miss?",
    description: 'View Profile',
  },
  localSpotFiveteen: {
    id: 'rentall.localSpotFiveteen',
    defaultMessage: 'Share the details!',
    description: 'View Profile',
  },
  localSpotsSixteen: {
    id: 'rentall.localSpotsSixteen',
    defaultMessage: 'My local spots',
    description: 'View Profile',
  },
  localSpotsSeventeen: {
    id: 'rentall.localSpotsSeventeen',
    defaultMessage: 'Your local spots are now live.',
    description: 'View Profile',
  },
  localSpotsEightteen: {
    id: 'rentall.localSpotsEightteen',
    defaultMessage: 'To edit make sure to save again.',
    description: 'View Profile',
  },
  welcomeLabel: {
    id: 'rentall.welcomeLabel',
    defaultMessage: 'Welcome',
    description: 'View Profile',
  },
  sortByDate: {
    id: 'rentall.sortByDate',
    defaultMessage: 'Sort by Date',
    description: 'View Profile',
  },
  sortByName: {
    id: 'rentall.sortByName',
    defaultMessage: 'Sort by Name',
    description: 'View Profile',
  },
  faorviteListings: {
    id: 'rentall.faorviteListings',
    defaultMessage: '’s favorite listings',
    description: 'View Profile',
  },
  emptySpaceNotAllowed: {
    id: 'rentall.emptySpaceNotAllowed',
    defaultMessage: 'Spaces are not allowed.',
    description: 'Tags',
  },
  clickUploadImage: {
    id: 'rentall.clickUploadImage',
    defaultMessage: 'Click Here to Upload Image',
    description: 'Tags',
  },
  clickUploadVideo: {
    id: 'rentall.clickUploadVideo',
    defaultMessage: 'Click Here to Upload Video',
    description: 'Tags',
  },
  listIdLabel: {
    id: 'rentall.listIdLabel',
    defaultMessage: 'List Id',
    description: 'ICAL Management',
  },
  urlLabel: {
    id: 'rentall.urlLabel',
    defaultMessage: 'URL',
    description: 'Comman',
  },
  icalLabel: {
    id: 'rentall.icalLabel',
    defaultMessage: 'iCal',
    description: 'Comman',
  },
  trendingTopics: {
    id: 'rentall.trendingTopics',
    defaultMessage: 'Trending Topics',
    description: 'Dashboard',
  },
  topicOne: {
    id: 'rentall.topicOne',
    defaultMessage: 'Welcome To GoSocial',
    description: 'Dashboard',
  },
  topicTwo: {
    id: 'rentall.topicTwo',
    defaultMessage: 'Topical places to work...',
    description: 'Dashboard',
  },
  topicThree: {
    id: 'rentall.topicThree',
    defaultMessage: 'Softside vs Hardside luggage',
    description: 'Dashboard',
  },
  topicFour: {
    id: 'rentall.topicFour',
    defaultMessage: 'Join the conversation',
    description: 'Dashboard',
  },
  createPost: {
    id: 'id.createPost',
    defaultMessage: 'Create Post',
    description: 'Dashboard',
  },
  editPost: {
    id: 'id.editPost',
    defaultMessage: 'Edit Post',
    description: 'Dashboard',
  },
  spotsLeft: {
    id: 'rentall.spotsLeft',
    defaultMessage: 'Spots left',
    description: 'Dashboard',
  },
  beta: {
    id: 'rentall.beta',
    defaultMessage: 'Beta',
    description: 'Dashboard',
  },
  joinWaitList: {
    id: 'rentall.joinWaitList',
    defaultMessage: 'Join Wait List',
    description: 'Dashboard',
  },
  joinedUsers: {
    id: 'rentall.joinedUsers',
    defaultMessage: 'Joined Users',
    description: 'Dashboard',
  },
  waitingUsers: {
    id: 'rentall.waitingUsers',
    defaultMessage: 'Waiting Users',
    description: 'Dashboard',
  },
  goSocial: {
    id: 'rentall.goSocial',
    defaultMessage: 'GoSocial',
    description: 'GoSocial',
  },
  betaDesc1: {
    id: 'rentall.betaDesc1',
    defaultMessage:
      'GoSocial is in Beta, pls send through suggestions / bugs you may encounter!',
    description:
      'As a GoSocial Beta user, all suggestions/bugs are very welcome!',
  },
  betaDescLink: {
    id: 'rentall.betaDescLink',
    defaultMessage: '(kat@wegolightly.com)',
    description: '(kat@wegolightly.com)',
  },
  viewUserDetails: {
    id: 'rentall.viewUserDetails',
    defaultMessage: 'View User Details',
    description: 'View Event Details',
  },
  you: {
    id: 'rentall.you',
    defaultMessage: 'You',
  },
  listedLabel: {
    id: 'rentall.listedLabel',
    defaultMessage: 'Listed',
    description: 'View Event Details',
  },
  unlistedLabel: {
    id: 'rentall.unlistedLabel',
    defaultMessage: 'Unlisted',
    description: 'View Event Details',
  },
  sendInviaViaEmail: {
    id: 'rentall.sendInviaViaEmail',
    defaultMessage: 'Send Invites Via Email',
    description: 'Invites',
  },
  commentLabel: {
    id: 'rentall.commentLabel',
    defaultMessage: 'has mentioned you in a Comment',
    description: 'comment',
  },
  postLabel: {
    id: 'rentall.postLabel',
    defaultMessage: 'has tagged you in a Post',
    description: 'Post',
  },
  hostAway: {
    id: 'rentall.hostAway',
    defaultMessage: 'HostAway',
    description: 'Sync Listing',
  },
  hostAwaySecret: {
    id: 'rentall.hostAwaySecret',
    defaultMessage: 'Client Secret',
    description: 'Sync Listing',
  },
  hostAwayClientId: {
    id: 'rentall.hostAwayClientId',
    defaultMessage: 'Client ID',
    description: 'Sync Listing',
  },
  nextPax: {
    id: 'rentall.nextPax',
    defaultMessage: 'NextPax',
    description: 'Sync Listing',
  },
  eventTitleOne: {
    id: 'rentall.eventTitleOne',
    defaultMessage: 'You Are Invited to a New Event',
    description: 'Event',
  },
  eventTitleTwo: {
    id: 'rentall.eventTitleTwo',
    defaultMessage: 'Accept',
    description: 'Event',
  },
  eventAcceptStatus: {
    id: 'rentall.eventAcceptStatus',
    defaultMessage: 'You have already accepted the event',
    description: 'Event',
  },
  eventDeclineStatus: {
    id: 'rentall.eventDeclineStatus',
    defaultMessage: 'You have already declined the event',
    description: 'Event',
  },
  noEventStatus: {
    id: 'rentall.noEventStatus',
    defaultMessage: 'Unfortunately event is cancelled',
    description: 'Event',
  },
  alreadyEvents: {
    id: 'rentall.alreadyEvents',
    defaultMessage: 'The Event already registered',
    description: 'Event',
  },
  eventExpired: {
    id: 'rentall.eventExpired',
    defaultMessage: 'Event expired',
    description: 'Event',
  },
  eventTitle: {
    id: 'rentall.eventTitle',
    defaultMessage: 'Title',
    description: 'Event',
  },
  eventDates: {
    id: 'rentall.eventDates',
    defaultMessage: 'Date',
    description: 'Event',
  },
  eventTime: {
    id: 'rentall.eventTime',
    defaultMessage: 'Time',
    description: 'Event',
  },
  eventTimeZone: {
    id: 'rentall.eventTimeZone',
    defaultMessage: 'Time Zone',
    description: 'Event',
  },
  eventLocation: {
    id: 'rentall.eventLocation',
    defaultMessage: 'Location',
    description: 'Event',
  },
  eventDescription: {
    id: 'rentall.eventDescription',
    defaultMessage: 'Description',
    description: 'Event',
  },
  eventDisable: {
    id: 'rentall.eventDisable',
    defaultMessage: 'No events available now',
    description: 'Event',
  },
  becomeAMemberTitle: {
    id: 'rentall.becomeAMemberTitle',
    defaultMessage: 'Yay. you’re nearly there!',
    description: 'Become A Meber',
  },
  becomeAMemberContent: {
    id: 'rentall.becomeAMemberContent',
    defaultMessage:
      'As a final step, just fill in this form to tell us a bit about yourself so a member of our team can call you and invite you to join! We take pride in vetting our community members to ensure safety for our travelers and hosts.',
    description: 'Become A Meber',
  },
  becomeAMemberContentTwo: {
    id: 'rentall.becomeAMemberContentTwo',
    defaultMessage:
      'Your information is only used for verification purposes. Phone number, Last name, email and date of birth are never displayed or shared.',
    description: 'Become A Meber',
  },
  becomeAMemberButton: {
    id: 'rentall.becomeAMemberButton',
    defaultMessage: 'Click Here to Apply',
    description: 'Become A Meber',
  },
  becomeAMemberPassword: {
    id: 'rentall.becomeAMemberPassword',
    defaultMessage:
      'Create your password now so that when approved you can sign in easily',
    description: 'Become A Meber',
  },
  pendingMember: {
    id: 'rentall.pendingMember',
    defaultMessage: 'Pending Members',
    description: 'Side Menu',
  },
  otherFees: {
    id: 'rentall.otherFees',
    defaultMessage: 'Other fees',
    description: 'Other fees',
  },
  dmgWaiver: {
    id: 'rentall.dmgWaiver',
    defaultMessage: 'Damage waiver',
    description: 'Damage waiver fee',
  },
  memberReferral: {
    id: 'rentall.memberReferral',
    defaultMessage: 'Member Referral',
    description: 'Become a member',
  },
  memberReferralPlaceHolder: {
    id: 'rentall.memberReferralPlaceHolder',
    defaultMessage: "What's her name?",
    description: 'Become a member',
  },
  pressLabel: {
    id: 'rentall.pressLabel',
    defaultMessage: 'Press',
    description: 'Become a member',
  },
  pressLabelPlaceHolder: {
    id: 'rentall.pressLabelPlaceHolder',
    defaultMessage: "Where'd you read about us?",
    description: 'Become a member',
  },
  socialPlaceHolder: {
    id: 'rentall.socialPlaceHolder',
    defaultMessage: "Where'd you see us?",
    description: 'Become a member',
  },
  otherPlaceHolder: {
    id: 'rentall.otherPlaceHolder',
    defaultMessage: "How'd you find Golightly?",
    description: 'Become a member',
  },
  phoneNumberPlaceHolder: {
    id: 'rentall.phoneNumberPlaceHolder',
    defaultMessage: 'No dashes or spaces',
    description: 'Become a member',
  },
  verificationModalOne: {
    id: 'rentall.verificationModalOne',
    defaultMessage:
      'Fill in your profile and upload a photo for full access to the site.',
    description: 'Verification Modal',
  },
  verificationModalTwo: {
    id: 'rentall.verificationModalTwo',
    defaultMessage:
      'The next 3 steps are to verify your identity and finalise your profile on',
    description: 'Verification Modal',
  },
  verificationModalThree: {
    id: 'rentall.verificationModalThree',
    defaultMessage:
      'All members have been fully vetted and their identities verified to protect our community and provide a network of safe travel and hosting for women.',
    description: 'Verification Modal',
  },
  verificationModalFour: {
    id: 'rentall.verificationModalFour',
    defaultMessage: 'Step 1: Verify your phone number',
    description: 'Verification Modal',
  },
  verificationModalFive: {
    id: 'rentall.verificationModalFive',
    defaultMessage:
      'And don’t worry we don’t want to bug you. Your number is only shared if you have a confirmed booking with another user or if we need to contact you for something important regarding your membership.',
    description: 'Verification Modal',
  },
  verificationModalSix: {
    id: 'rentall.verificationModalSix',
    defaultMessage: 'Step 2: Please Confirm your information',
    description: 'Verification Modal',
  },
  phoneNumberTitle: {
    id: 'rentall.phoneNumberTitle',
    defaultMessage: 'We sent a verification code to',
    description: 'PhoneNumber Modal',
  },
  phoneNumberTwo: {
    id: 'rentall.phoneNumberTwo',
    defaultMessage: 'Please enter the 4-digit code:',
    description: 'PhoneNumber Modal',
  },
  phoneNumberThree: {
    id: 'rentall.phoneNumberThree',
    defaultMessage: "If it doesn't arrive, click cancel and try again.",
    description: 'PhoneNumber Modal',
  },
  phoneNumberNotice: {
    id: 'rental.phoneNumberNotice',
    defaultMessage:
      'Some providers may experience issues receiving verification texts. If this is the case and you have not received your confirmation message within 10 minutes please contact us on ',
    description: 'PhoneNumber Modal',
  },
  editProfileTitle: {
    id: 'rentall.editProfileTitle',
    defaultMessage:
      "Most of this has already been entered when you signed up. Please update and save to go to the final step, and you'll have access to the Golightly site and all features.",
    description: 'Edit Profile',
  },
  welcomeCommon: {
    id: 'rentall.welcomeCommon',
    defaultMessage: 'Welcome',
    description: 'Common',
  },
  editProfileTitleTwo: {
    id: 'rentall.editProfileTitleTwo',
    defaultMessage: 'Just add your photo to see what you can do next on',
    description: 'Common',
  },
  editProfileTitleThree: {
    id: 'rentall.editProfileTitleThree',
    defaultMessage: 'Your profile photo',
    description: 'Edit Profile',
  },
  editProfileTitleFour: {
    id: 'rentall.editProfileTitleFour',
    defaultMessage: 'Let us know more about you',
    description: 'Edit Profile',
  },
  editProfileTitleFive: {
    id: 'rentall.editProfileTitleFive',
    defaultMessage:
      'Please don’t be shy! You can make your profile as simple or as detailed as you like. Every member has a profile, and only members with a complete profile have access to the site. Your profile information will never be visible or shared outside of',
    description: 'Edit Profile',
  },
  editProfileStepTwo: {
    id: 'rentall.editProfileStepTwo',
    defaultMessage: 'Now feel free to …',
    description: 'Edit Profile',
  },
  editProfileThree: {
    id: 'rentall.editProfileThree',
    defaultMessage: 'Book',
    description: 'Edit Profile',
  },
  editProfileFour: {
    id: 'rentall.editProfileFour',
    defaultMessage:
      "Just one final step and then you're all set to search our gorgeous properties, book your next stay and share tips/travel advice with others on GoSocial.",
    description: 'Edit Profile',
  },
  editProfileFive: {
    id: 'rentall.editProfileFive',
    defaultMessage: 'List',
    description: 'Edit Profile',
  },
  editProfileSix: {
    id: 'rentall.editProfileSix',
    defaultMessage:
      'At Golightly, our fees are low to support women keep more $ in their pockets whether traveling or hosting, so thank you for joining our global community, we are thrilled to have you as a member!',
    description: 'Edit Profile',
  },
  editProfileSeven: {
    id: 'rentall.editProfileSeven',
    defaultMessage: 'Invite your friends',
    description: 'Edit Profile',
  },
  editProfileEight: {
    id: 'rentall.editProfileEight',
    defaultMessage:
      'For every friend who signs up, you get a $20 travel credit that can be used for an upcoming booking! (And so do they.)',
    description: 'Edit Profile',
  },
  editProfilenine: {
    id: 'rentall.editProfilenine',
    defaultMessage: 'Connect',
    description: 'Edit Profile',
  },
  editProfileTen: {
    id: 'rentall.editProfileTen',
    defaultMessage:
      'Looking to connect with other Golightly members, you can search by city, college/university or work industry to build your connections.',
    description: 'Edit Profile',
  },
  editProfileEleven: {
    id: 'rentall.editProfileEleven',
    defaultMessage: 'Share your local Spots',
    description: 'Edit Profile',
  },
  editProfileTwelve: {
    id: 'rentall.editProfileTwelve',
    defaultMessage:
      'Love your home town? Share your local recommendations for other Golightly members to see.',
    description: 'Edit Profile',
  },
  editProfileThirteen: {
    id: 'rentall.editProfileThirteen',
    defaultMessage: 'GoSocial',
    description: 'Edit Profile',
  },
  editProfileFourteen: {
    id: 'rentall.editProfileFourteen',
    defaultMessage:
      "Just one final step and then you're all set to search our gorgeous properties, book your next stay and share tips/travel advice with others on GoSocial.",
    description: 'Edit Profile',
  },
  editProfileFifteen: {
    id: 'rentall.editProfileFifteen',
    defaultMessage: 'Events',
    description: 'Edit Profile',
  },
  editProfileSixteen: {
    id: 'rentall.editProfileSixteen',
    defaultMessage:
      'Join a new member event in person or online, then consider signing up for one of our upcoming retreats / tours. Culinary tour of Italy? Yes pls!',
    description: 'Edit Profile',
  },
  memberVerifiedTwo: {
    id: 'rentall.memberVerifiedTwo',
    defaultMessage:
      'Fill in your profile information for full access to the site.',
    description: 'memberVerified',
  },
  socialHover: {
    id: 'rentall.socialHover',
    defaultMessage:
      'when notifications are turned on you will be notified by email when you are mentioned in a post or a comment',
    description: 'memberVerified',
  },
  lgbtq: {
    id: 'rentall.lgbtq',
    defaultMessage: 'LGBTQ+',
    description: 'lgbtqScore',
  },
  medical: {
    id: 'rentall.medical',
    defaultMessage: 'Medical',
    description: 'medicalScore',
  },
  overall: {
    id: 'rentall.overall',
    defaultMessage: 'Overall',
    description: 'overallScore',
  },
  physicalHarm: {
    id: 'rentall.physicalHarm',
    defaultMessage: 'Physical Safety',
    description: 'physicalHarmScore',
  },
  politicalFreedom: {
    id: 'rentall.politicalFreedom',
    defaultMessage: 'Political Freedom',
    description: 'politicalFreedomScore',
  },
  theft: {
    id: 'rentall.theft',
    defaultMessage: 'Theft',
    description: 'theftScore',
  },
  womenScore: {
    id: 'rentall.womenScore',
    defaultMessage: "Women's Safety",
    description: 'womenScore',
  },
  safetyScore: {
    id: 'rentall.safetyScore',
    defaultMessage: 'Safety Score',
    description: 'safetyScore',
  },
  GeoSafeScores: {
    id: 'rentall.GeoSafeScores',
    defaultMessage: 'GeoSafeScores',
    description: 'GeoSafeScores',
  },
  goSolo: {
    id: 'rentall.goSolo',
    defaultMessage: 'GoSolo',
    description: 'Menu item',
  },
  subtitle: {
    id: 'id.subtitle',
    defaultMessage: 'Subtitle',
    description: 'Field Label',
  },
  descriptionTitleLabel: {
    id: 'id.descriptionTitleLabel',
    defaultMessage: 'Description Title',
    description: 'Field Label',
  },
  numberOfNights: {
    id: 'id.numberOfNights',
    defaultMessage: 'Number of Nights',
    description: 'Field Label',
  },
  groupSize: {
    id: 'id.groupSize',
    defaultMessage: 'Group Size',
    description: 'Field Label',
  },
  accommodationTypeLabel: {
    id: 'id.accommodationTypeLabel',
    defaultMessage: 'Type of Accomodation',
    description: 'Field Label',
  },
  highLightedFeature: {
    id: 'id.highLightedFeature',
    defaultMessage: 'Highlighted Feature',
    description: 'Field Label',
  },
  bestFor: {
    id: 'id.bestFor',
    defaultMessage: 'Best For',
    description: 'Field Label',
  },
  datesForTrip1: {
    id: 'id.datesForTrip1',
    defaultMessage: 'Dates of Trip 1',
    description: 'Field Label',
  },
  datesForTrip2: {
    id: 'id.datesForTrip2',
    defaultMessage: 'Dates of Trip 2',
    description: 'Field Label',
  },
  datesForTrip3: {
    id: 'id.datesForTrip3',
    defaultMessage: 'Dates of Trip 3',
    description: 'Field Label',
  },
  party: {
    id: 'id.party',
    defaultMessage: 'Party',
    description: 'Field Label',
  },
  quiet: {
    id: 'id.quiet',
    defaultMessage: 'Quiet',
    description: 'Field Label',
  },
  adventure: {
    id: 'id.adventure',
    defaultMessage: 'Adventure',
    description: 'Field Label',
  },
  priceTrip2: {
    id: 'id.priceTrip2',
    defaultMessage: 'Price Trip 2',
    description: 'Field Label',
  },
  priceTrip3: {
    id: 'id.priceTrip3',
    defaultMessage: 'Price Trip 3',
    description: 'Field Label',
  },
  luxEnSuitePrivate: {
    id: 'id.luxEnSuitePrivate',
    defaultMessage: 'Price Private room Lux [En suite]',
    description: 'Field Label',
  },
  enSuitePrivate: {
    id: 'id.enSuitePrivate',
    defaultMessage: 'Price Private room [En suite]',
    description: 'Field Label',
  },
  luxSharedBathPrivate: {
    id: 'id.luxSharedBathPrivate',
    defaultMessage: 'Price Private room Lux [Shared Bath]',
    description: 'Field Label',
  },
  sharedBathPrivate: {
    id: 'id.sharedBathPrivate',
    defaultMessage: 'Price Private room [Shared Bath]',
    description: 'Field Label',
  },
  enSuiteShared: {
    id: 'id.enSuiteShared',
    defaultMessage: 'Price Shared room [En suite]',
    description: 'Field Label',
  },
  sharedBathePrivate: {
    id: 'id.sharedBathePrivate',
    defaultMessage: 'Price Private room [Shared Bath]',
    description: 'Field Label',
  },
  sharedRoomSharedBath: {
    id: 'id.sharedRoomSharedBath',
    defaultMessage: 'Price Shared room [Shared Bath]',
    description: 'Field Label',
  },
  propertyLink: {
    id: 'id.propertyLink',
    defaultMessage: 'Property Link',
    description: 'Field Label',
  },
  tripPackageInclude: {
    id: 'id.tripPackageInclude',
    defaultMessage: 'Trip Package Includes',
    description: 'Field Label',
  },
  tripHighLight: {
    id: 'id.tripHighLight',
    defaultMessage: 'Trip highlights/optional extras',
    description: 'Field Label',
  },
  tripTypeLabel: {
    id: 'id.tripTypeLabel',
    defaultMessage: 'Type of trip',
    description: 'Field Label',
  },
  longerAdventures: {
    id: 'id.longerAdventures',
    defaultMessage: 'Longer Adventures',
    description: 'Field Label',
  },
  shortSweet: {
    id: 'id.shortSweet',
    defaultMessage: 'Short & Sweet',
    description: 'Field Label',
  },
  floatError: {
    id: 'id.floatError',
    defaultMessage: 'It should be a number value',
    description: 'Numeric Error Response',
  },
  intError: {
    id: 'app.intError',
    defaultMessage: 'It should be a decimal value',
    description: 'Integer Error Response',
  },
  manageGoSolo: {
    id: 'app.manageGoSolo',
    defaultMessage: 'Manage GoSolo Trips',
    description: 'Title',
  },
  goSoloTrips: {
    id: 'app.goSoloTrips',
    defaultMessage: 'GoSolo Trips',
    description: 'Label',
  },
  addATrip: {
    id: 'app.addATrip',
    defaultMessage: 'Add a Trip',
    description: 'Label',
  },
  editATrip: {
    id: 'app.editATrip',
    defaultMessage: 'Edit a Trip',
    description: 'Label',
  },
  goSoloOverView1: {
    id: 'app.goSoloOverView1',
    defaultMessage:
      'With the help of our local hosts and members you will have the option to travel solo or with a friend to some seriously cool properties around the world, feeling more safe and secure within the community. For those who want more structure but you are welcome to build these up as you wish',
    description: 'Content',
  },
  goSoloOverView2: {
    id: 'app.goSoloOverView2',
    defaultMessage: 'Travel solo or bring a friend.',
    description: 'Content',
  },
  goSoloOverView3: {
    id: 'app.goSoloOverView3',
    defaultMessage:
      'Breakfast included every morning, and Happy Hours are hosted in the evening.',
    description: 'Content',
  },
  goSoloOverView4: {
    id: 'app.goSoloOverView4',
    defaultMessage:
      'Additional extras are available for those who want more structure to their trip.',
    description: 'Content',
  },
  highLightOptionalExtra: {
    id: 'app.highLightOptionalExtra',
    defaultMessage: 'Highlights & Optional Extras',
    description: 'Title',
  },
  datesAndPrices: {
    id: 'app.datesAndPrices',
    defaultMessage: 'Dates & prices',
    description: 'Title',
  },
  otherDatesAvailable: {
    id: 'app.otherDatesAvailable',
    defaultMessage: 'Interested in other dates available',
    description: 'Title',
  },
  greaterThanZeroError: {
    id: 'app.greaterThanZeroError',
    defaultMessage: 'It should be greater than zero',
    description: 'Title',
  },
  luxEnSuitePrivateLabel: {
    id: 'id.luxEnSuitePrivateLabel',
    defaultMessage: 'Private room Lux [En suite]',
    description: 'Field Label',
  },
  enSuitePrivateLabel: {
    id: 'id.enSuitePrivateLabel',
    defaultMessage: 'Private room [En suite]',
    description: 'Title',
  },
  luxSharedBathPrivateLabel: {
    id: 'id.luxSharedBathPrivateLabel',
    defaultMessage: 'Private room Lux [Shared Bath]',
    description: 'Field Label',
  },
  sharedBathPrivateLabel: {
    id: 'id.sharedBathPrivateLabel',
    defaultMessage: 'Private room [Shared Bath]',
    description: 'Field Label',
  },
  enSuiteSharedLabel: {
    id: 'id.enSuiteSharedLabel',
    defaultMessage: 'Shared room [En suite]',
    description: 'Field Label',
  },
  sharedBathePrivateLabel: {
    id: 'id.sharedBathePrivateLabel',
    defaultMessage: 'Private room [Shared Bath]',
    description: 'Field Label',
  },
  homeGoSoloSliderLabel: {
    id: 'id.homeGoSoloSliderLabel',
    defaultMessage: 'Room, Breakfast & Happy Hour',
    description: 'Label',
  },
  headerContent1: {
    id: 'id.headerContent1',
    defaultMessage:
      'Designed to make world travel safer for the Golightly community',
    description: 'Label',
  },
  headerContent2: {
    id: 'id.headerContent2',
    defaultMessage:
      'Stay with fellow female Golightly members at a vetted Golightly property, with the freedom to do your own solo adventuring!',
    description: 'Label',
  },
  headerContent3: {
    id: 'id.headerContent3',
    defaultMessage:
      'Feel secure with our included Trip Insurance with every booking. Learn more here',
    description: 'Label',
  },
  headerContent4: {
    id: 'id.headerContent4',
    defaultMessage:
      'Feel confident planning what you want, where you want enjoying time together or solo.',
    description: 'Label',
  },
  peaceOfMind: {
    id: 'id.peaceOfMind',
    defaultMessage: 'Peace of mind',
    description: 'Label',
  },
  independant: {
    id: 'id.independant',
    defaultMessage: 'Independent',
    description: 'Label',
  },
  footerContent1: {
    id: 'id.footerContent1',
    defaultMessage: 'Travel solo, Stay together.',
    description: 'Label',
  },
  footerContent2: {
    id: 'id.footerContent2',
    defaultMessage:
      "Let us know if you'd like to join, or have a dream destination in mind. As details and dates start to firm up, we'll reach out to interested members so you can reserve a space. And if you want to help plan a GoSocial Getaway or lead a tour in your hometown, or even make your property available, please reach out to kat@wegolightly.com.",
    description: 'Label',
  },
  smallGroups: {
    id: 'id.smallGroups',
    defaultMessage: 'Small Groups',
    description: 'Label',
  },
  communitySupport: {
    id: 'id.communitySupport',
    defaultMessage: 'Community Support',
    description: 'Label',
  },
  staySafe: {
    id: 'id.staySafe',
    defaultMessage: 'Stay Safe',
    description: 'Label',
  },
  diyItinerary: {
    id: 'id.diyItinerary',
    defaultMessage: 'DIY itinerary',
    description: 'Label',
  },
  worldwide: {
    id: 'id.worldwide',
    defaultMessage: 'Worldwide',
    description: 'Label',
  },
  memberSpot: {
    id: 'id.memberSpot',
    defaultMessage: 'Member Spots',
    description: 'Label',
  },
  viewTrip: {
    id: 'id.viewTrip',
    defaultMessage: 'View Trip',
    description: 'Label',
  },
  goSoloHomeBanner: {
    id: 'id.goSoloHomeBanner',
    defaultMessage: 'Trips to make your solo travels feel safer.',
    description: 'Label',
  },
  accomodation: {
    id: 'id.accomodation',
    defaultMessage: 'Accomodation',
    description: 'accomodation',
  },
  alreadyHaveARefferal: {
    id: 'rentall.alreadyHaveARefferal',
    defaultMessage: 'Already been invited? Click here',
    description: 'Common',
  },
  registerPageContent1: {
    id: 'rentall.registerPageContent1',
    defaultMessage:
      'Welcome to Golightly, the world’s best travel club for women. We pride ourselves on being a trusted community as well as an international home-sharing network, vacation and short-term rental marketplace. With thousands of members and properties in over 90 countries around the world, our mission is safety and quality at any budget. All our members are carefully vetted to guarantee the security and comfort of our hosts and guests. Community features, Partner offerings, Property listings and Member profiles are only viewable within the Golightly community, for the privacy of our members.',
    description: 'Register',
  },
  registerPageContent2: {
    id: 'rentall.registerPageContent2',
    defaultMessage:
      "All Golightly properties are owned or managed by women you're connected to within the network and carefully curated, with a dedication to safety, quality, and ease of travel. Only Golightly members can list or book a property, but you can feel free to bring along whomever you like (including men), as long as your host is cool with it.",
    description: 'Register',
  },
  registerPageContent3: {
    id: 'rentall.registerPageContent3',
    defaultMessage:
      'As a Golightly member, you’ll receive all the benefits of our club:',
    description: 'Register',
  },
  registerPageContent4: {
    id: 'rentall.registerPageContent4',
    defaultMessage: 'Safer and More Secure Travel and Hosting Experiences',
    description: 'Register',
  },
  registerPageContent5: {
    id: 'rentall.registerPageContent5',
    defaultMessage:
      'Access to Our Network of Thousands of Properties and Members Worldwide',
    description: 'Register',
  },
  registerPageContent6: {
    id: 'rentall.registerPageContent6',
    defaultMessage: 'Invitations for Your Friends and Referral Credits',
    description: 'Register',
  },
  registerPageContent7: {
    id: 'rentall.registerPageContent7',
    defaultMessage: 'Exclusive Events, Retreats and Tours',
    description: 'Register',
  },
  registerPageContent8: {
    id: 'rentall.registerPageContent8',
    defaultMessage: 'Partner Perks, Gifts and Discounts',
    description: 'Register',
  },
  registerPageContent9: {
    id: 'rentall.registerPageContent9',
    defaultMessage: 'Discounted Travel',
    description: 'Register',
  },
  registerPageContent10: {
    id: 'rentall.registerPageContent10',
    defaultMessage:
      'We want women to travel often, stay safely and... Golightly.',
    description: 'Register',
  },
  registerPageContent11: {
    id: 'rentall.registerPageContent11',
    defaultMessage: 'Welcome to Golightly',
    description: 'Register',
  },
  registerPageContent12: {
    id: 'rentall.registerPageContent12',
    defaultMessage:
      'Since you have been invited to join you have a referral code and can sign up for free, your referral code may be already entered in the sign up form depending on how you got here. Once signed up you will automatically get your travel credit applied and so will your friend who invited you.',
    description: 'Register',
  },
  registerPageContent13: {
    id: 'rentall.registerPageContent13',
    defaultMessage: 'With Golightly, wherever you go is home.',
    description: 'Register',
  },
  registerPageContent14: {
    id: 'rentall.registerPageContent14',
    defaultMessage: 'Networking Both Online and Offline',
    description: 'Register',
  },
  registerPageContent15: {
    id: 'rentall.registerPageContent15',
    defaultMessage: 'Invited by a friend? Skip the wait and sign up here:',
    description: 'Register',
  },
  inviteEmail: {
    id: 'rentall.inviteEmail',
    defaultMessage: 'Invite your friend!',
    description: 'Invite Page',
  },
  inviteSectionHeader: {
    id: 'rentall.inviteSectionHeader',
    defaultMessage:
      'Share the love! Invite your friends below so they can join Golightly for free, without having to wait!',
    description: 'Invite Page',
  },
  inviteSectionOne: {
    id: 'rentall.inviteSectionOne',
    defaultMessage: "You'll get a",
    description: 'Invite Page',
  },
  inviteFirstName: {
    id: 'rentall.inviteFirstName',
    defaultMessage: 'Her First Name',
    description: 'Invite Page',
  },
  inviteLastName: {
    id: 'rentall.inviteLastName',
    defaultMessage: 'And Last Name',
    description: 'Invite Page',
  },
  inviteEmailName: {
    id: 'rentall.inviteEmailName',
    defaultMessage: 'Her Email Address',
    description: 'Invite Page',
  },
  inviteYourPersonalMessage: {
    id: 'rentall.inviteYourPersonalMessage',
    defaultMessage: 'Your personal message',
    description: 'Invite Page',
  },
  inviteContent: {
    id: 'rentall.inviteContent',
    defaultMessage:
      'invites, these will be your inital connections on Golighlty and you will get',
    description: 'Invite Page',
  },
  inviteContentTwo: {
    id: 'rentall.inviteContentTwo',
    defaultMessage:
      'credit for each friend who completes her profile. Need more invites? Let us know!',
    description: 'Invite Page',
  },
  inviteContentThree: {
    id: 'rentall.inviteContentThree',
    defaultMessage:
      'credit for each invitee who completes their profile and so will she! Need more invites? Let us know!',
    description: 'Invite Page',
  },
  inviteContentFour: {
    id: 'rentall.inviteContentFour',
    defaultMessage:
      'You can also send invites via text or by generating an invite code',
    description: 'Invite Page',
  },
  inviteContentFive: {
    id: 'rentall.inviteContentFive',
    defaultMessage:
      'You do not have any invites left, if you would like to request additional invites please contact ',
    description: 'Invite Page',
  },
  invitePhoneFirstName: {
    id: 'rentall.invitePhoneFirstName',
    defaultMessage: 'Enter Her First Name',
    description: 'Invite Page',
  },
  invitePhoneLastName: {
    id: 'rentall.invitePhoneLastName',
    defaultMessage: 'Enter Her Last Name',
    description: 'Invite Page',
  },
  inviteFriends: {
    id: 'rentall.inviteFriends',
    defaultMessage: 'friends.',
    description: 'Invite Page',
  },
  inviteFriendOne: {
    id: 'rentall.inviteFriendOne',
    defaultMessage: 'friend.',
    description: 'Invite Page',
  },
  invitePageHeaderTop: {
    id: 'rentall.invitePageHeaderTop',
    defaultMessage:
      'Share the love! Invite up to 5 friends so they can join Golightly for free (no application needed!)',
    description: 'Invite Page',
  },
  invitePageHeaderLast: {
    id: 'rentall.invitePageHeaderLast',
    defaultMessage:
      'Complete our short survey here for a chance to win extra credit! ',
    description: 'Invite Page',
  },
  signUpHereWithCode: {
    id: 'rentall.signUpHereWithCode',
    defaultMessage: 'Sign up here with your code:',
    description: 'Invite Page',
  },
  invitePageFriends: {
    id: 'rentall.invitePageFriends',
    defaultMessage:
      'Click on one of your invites here to invite your friends by email:',
    description: 'Invite Page',
  },
  sameDateError: {
    id: 'rentall.sameDateError',
    defaultMessage: 'Dates should not be same',
    description: 'GoSolo Form',
  },
  referralLabel: {
    id: 'app.referralLabel',
    defaultMessage: 'Referral?',
    description: 'Become a member',
  },
  referralPlaceHolder: {
    id: 'app.referralPlaceHolder',
    defaultMessage: 'Have a referral code or member name?',
    description: 'Become a member',
  },
  manageGoSoloBooking: {
    id: 'app.manageGoSoloBooking',
    defaultMessage: 'Manage GoSolo Bookings',
    description: 'Title',
  },
  goSoloBooking: {
    id: 'app.goSoloBooking',
    defaultMessage: 'GoSolo Bookings',
    description: 'Title',
  },
  noGoSoloBookings: {
    id: 'app.noGoSoloBookings',
    defaultMessage: 'You have no Go solo trips',
    description: 'Message',
  },
  nameOfTheTrip: {
    id: 'app.nameOfTheTrip',
    defaultMessage: 'Name of the trip',
    description: 'Title',
  },
  priceOfTheTrip: {
    id: 'app.priceOfTheTrip',
    defaultMessage: 'Price of the trip',
    description: 'Title',
  },
  checkInAndOut: {
    id: 'app.checkInAndOut',
    defaultMessage: 'Check in and Check out date',
    description: 'Title',
  },
  soldOutContent: {
    id: 'app.soldOutContent',
    defaultMessage:
      'The type of room you requested for this trip is currently sold out, click here to be notified of any changes and we will let you know if anything opens up',
    description: 'Title',
  },
  joinWaitListGoSolo: {
    id: 'app.joinWaitListGoSolo',
    defaultMessage: 'Join waiting list',
    description: 'Title',
  },
  linkCopied: {
    id: 'app.linkCopied',
    defaultMessage: 'Link Copied',
    description: 'Title',
  },
  copyLink: {
    id: 'app.copyLink',
    defaultMessage: 'Copy Link',
    description: 'Title',
  },
  tripMissing: {
    id: 'app.tripMissing',
    defaultMessage: 'Trip is missing',
    description: 'Title',
  },
  priceTrip1: {
    id: 'id.priceTrip1',
    defaultMessage: 'Price Trip 1',
    description: 'Field Label',
  },
  manageSubscription: {
    id: 'id.manageSubscription',
    defaultMessage: 'Subscription',
    description: 'Title',
  },
  manageMemberShip: {
    id: 'id.manageMemberShip',
    defaultMessage: 'Manage Membership',
    description: 'Title',
  },
  block1Subscription: {
    id: 'id.block1Subscription',
    defaultMessage: 'Block 1',
    description: 'Title',
  },
  block2Subscription: {
    id: 'id.block2Subscription',
    defaultMessage: 'Block 2',
    description: 'Title',
  },
  block3Subscription: {
    id: 'id.block3Subscription',
    defaultMessage: 'Block 3',
    description: 'Title',
  },
  monthsOption: {
    id: 'id.monthsOption',
    defaultMessage: 'Months',
    description: 'Label',
  },
  membershipPeriod: {
    id: 'id.membershipPeriod',
    defaultMessage: 'Membership period',
    description: 'Label',
  },
  manageInquiry: {
    id: 'app.manageInquiry',
    defaultMessage: 'Manage Inquiry',
    description: 'Title',
  },
  weAreSorry: {
    id: 'id.weAreSorry',
    defaultMessage: 'We are Sorry!',
    description: 'Field Label',
  },
  deletedListings: {
    id: 'id.deletedListings',
    defaultMessage: 'Deleted Listings',
    description: 'Field Label',
  },
  membership: {
    id: 'id.membership',
    defaultMessage: 'Membership',
    description: 'Content',
  },
  pageFooterOne: {
    id: 'id.pageFooterOne',
    defaultMessage: 'Over $600 a year in Savings & Perks.',
    description: 'Content',
  },
  pageFooterTwo: {
    id: 'id.pageFooterTwo',
    defaultMessage: 'Explore the world your way with trip Discounts.',
    description: 'Content',
  },
  pageFooterThree: {
    id: 'id.pageFooterThree',
    defaultMessage: 'Enjoy Exclusive Partner Perks.',
    description: 'Content',
  },
  pageButtonOne: {
    id: 'id.pageButtonOne',
    defaultMessage: 'Not ready for massive savings yet?',
    description: 'Content',
  },
  pageButtonTwo: {
    id: 'id.pageButtonTwo',
    defaultMessage: 'Continue as a Standard member',
    description: 'Content',
  },
  introductionOffer: {
    id: 'id.introductionOffer',
    defaultMessage: 'Introductory offer',
    description: 'Content',
  },
  annually: {
    id: 'id.annually',
    defaultMessage: 'Annually',
    description: 'Content',
  },
  membershipSubContent: {
    id: 'id.membershipSubContent',
    defaultMessage:
      'Still unsure & would like us to contact you to answer any questions?',
    description: 'Content',
  },
  membershipFooterOneTopPageTwo: {
    id: 'id.membershipFooterOneTopPageTwo',
    defaultMessage: 'See the value for yourself!',
    description: 'Content',
  },
  membershipFooterOneBottomPageTwo: {
    id: 'id.membershipFooterOneBottomPageTwo',
    defaultMessage: 'Mary became a Golightly diamond member.',
    description: 'Content',
  },
  membershipFooterTwoTopPageTwo: {
    id: 'id.membershipFooterTwoTopPageTwo',
    defaultMessage: 'She received her immediate $50 travel credit',
    description: 'Content',
  },
  membershipFooterTwoBottomPageTwo: {
    id: 'id.membershipFooterTwoBottomPageTwo',
    defaultMessage: "Mary's cute $50 travel gift arrived.",
    description: 'Content',
  },
  membershipFooterThreeTopPageTwo: {
    id: 'id.membershipFooterThreeTopPageTwo',
    defaultMessage:
      'She booked a 1 wk family stay at a stunning Costa Rica Villa : Mary saved $200.',
    description: 'Content',
  },
  membershipFooterThreeBottomPageTwo: {
    id: 'id.membershipFooterThreeBottomPageTwo',
    defaultMessage:
      "Mary's friend Anne and 9 other friends signed up, she received a further $250 credit.",
    description: 'Content',
  },
  membershipFooterFourTopPageTwo: {
    id: 'id.membershipFooterFourTopPageTwo',
    defaultMessage:
      'Mary needed some space and decided to book a GoSolo Trip to Miami and save $100.',
    description: 'Content',
  },
  membershipFooterFiveOnePageTwo: {
    id: 'id.membershipFooterFiveOnePageTwo',
    defaultMessage: 'Mary saved',
    description: 'Content',
  },
  membershipFooterFiveTwoPageTwo: {
    id: 'id.membershipFooterFiveTwoPageTwo',
    defaultMessage: '$650',
    description: 'Content',
  },
  membershipFooterFiveThreePageTwo: {
    id: 'id.membershipFooterFiveThreePageTwo',
    defaultMessage:
      'made global connections, got amazing travel itineraries, attended exclusive events & realised she had a community ready to help her',
    description: 'Content',
  },
  membershipFooterFiveFourPageTwo: {
    id: 'id.membershipFooterFiveFourPageTwo',
    defaultMessage: 'travel often, stay safely and Golightly',
    description: 'Content',
  },
  membershipFooterFiveFivePageTwo: {
    id: 'id.membershipFooterFiveFivePageTwo',
    defaultMessage: 'around the world!',
    description: 'Content',
  },
  membershipFooterFiveSixPageTwo: {
    id: 'id.membershipFooterFiveSixPageTwo',
    defaultMessage: 'Mary was happy!',
    description: 'Content',
  },
  membershipClickHere: {
    id: 'id.membershipClickHere',
    defaultMessage: 'Click here',
    description: 'Content',
  },
  onlyLabel: {
    id: 'id.onlyLabel',
    defaultMessage: 'Only',
    description: 'Content',
  },
  amazingLabel: {
    id: 'id.amazingLabel',
    defaultMessage: 'Amazing Value',
    description: 'Content',
  },
  monthly: {
    id: 'id.monthly',
    defaultMessage: 'Monthly',
    description: 'Content',
  },
  minLabel: {
    id: 'id.minLabel',
    defaultMessage: '*Min',
    description: 'Content',
  },
  mthsLabel: {
    id: 'id.mthsLabel',
    defaultMessage: 'mths',
    description: 'Content',
  },
  subscribeNow: {
    id: 'id.subscribeNow',
    defaultMessage: 'Subscribe Now',
    description: 'Content',
  },
  newExistingPassword: {
    id: 'id.newExistingPassword',
    defaultMessage: 'New/Existing Password',
    description: 'Field Label',
  },
  waitingForAdminConfirm: {
    id: 'id.waitingForAdminConfirm',
    defaultMessage: 'Waiting for Admin Confirmation',
    description: 'Field Label',
  },
  goSoloHomeBannerDesc: {
    id: 'id.goSoloHomeBannerDesc',
    defaultMessage:
      'Carefully curated stays, perfect for solo travellers or groups of up to 2 people',
    description: 'GoSoloBanner',
  },
  allGoSoloStays: {
    id: 'id.allGoSoloStays',
    defaultMessage: 'All GoSolo Stays Include:',
    description: 'GoSoloAllStays',
  },
  safeAndSecure: {
    id: 'id.safeAndSecure',
    defaultMessage: 'Safe & Secure Accommodation',
    description: 'GoSoloAllStays',
  },
  breakfastLabel: {
    id: 'id.breakfastLabel',
    defaultMessage: 'Breakfast',
    description: 'GoSoloAllStays',
  },
  happyHourLabel: {
    id: 'id.happyHourLabel',
    defaultMessage: 'Happy Hour',
    description: 'GoSoloAllStays',
  },
  goSoloAllStaysDesc1: {
    id: 'id.goSoloAllStaysDesc1',
    defaultMessage:
      'With the help of our local hosts and members you will have the option to travel solo or with a friend to some seriously cool properties around the world, feeling more safe and secure within the community. For those who want more structure but you are welcome to build these up as you wish',
    description: 'GoSoloAllStays',
  },
  goSoloAllStaysDesc2: {
    id: 'id.goSoloAllStaysDesc2',
    defaultMessage: 'Travel solo or bring a friend.',
    description: 'GoSoloAllStays',
  },
  goSoloAllStaysDesc3: {
    id: 'id.goSoloAllStaysDesc3',
    defaultMessage:
      'Breakfast included every morning, and Happy Hours are hosted in the evening.',
    description: 'GoSoloAllStays',
  },
  goSoloAllStaysDesc4: {
    id: 'id.goSoloAllStaysDesc4',
    defaultMessage:
      'Additional extras are available for those who want more structure to their trip.',
    description: 'GoSoloAllStays',
  },
  paymentLink: {
    id: 'id.paymentLink',
    defaultMessage: 'Payment link',
    description: 'Field Label',
  },
  bookDescriptionOne: {
    id: 'id.bookDescriptionOne',
    defaultMessage: 'Stay safely',
    description: 'Field Label',
  },
  supportWomenGlobally: {
    id: 'id.supportWomenGlobally',
    defaultMessage: 'Support women globally',
    description: 'Field Label',
  },
  enjoyLowestFees: {
    id: 'id.enjoyLowestFees',
    defaultMessage: 'Enjoy lowest fees',
    description: 'Field Label',
  },
  hostDescriptionOne: {
    id: 'id.hostDescriptionOne',
    defaultMessage: 'Host safely',
    description: 'Field Label',
  },
  discoverDescriptionOne: {
    id: 'id.discoverDescriptionOne',
    defaultMessage: 'Explore safely',
    description: 'Field Label',
  },
  discoverDescriptionTwo: {
    id: 'id.discoverDescriptionTwo',
    defaultMessage: 'Join our global adventures',
    description: 'Field Label',
  },
  discoverDescriptionThree: {
    id: 'id.discoverDescriptionThree',
    defaultMessage: 'Travel solo together',
    description: 'Field Label',
  },
  connectionDescriptionOne: {
    id: 'id.connectionDescriptionOne',
    defaultMessage: 'Share safely',
    description: 'Field Label',
  },
  connectionDescriptionTwo: {
    id: 'id.connectionDescriptionTwo',
    defaultMessage: 'Travel tips and hints',
    description: 'Field Label',
  },
  connectionDescriptionThree: {
    id: 'id.connectionDescriptionThree',
    defaultMessage: 'Exclusive community',
    description: 'Field Label',
  },
  notBookedYet: {
    id: 'id.notBookedYet',
    defaultMessage:
      "Looks like you're not booked yet! Learn more and start your journey with us!",
    description: 'Field Label',
  },
  enjoyYourMembership: {
    id: 'id.enjoyYourMembership',
    defaultMessage: 'Enjoy your membership way more...Upgrade to Diamond now!',
    description: 'Field Label',
  },
  enjoyYourMembershipDiamond: {
    id: 'id.enjoyYourMembershipDiamond',
    defaultMessage: 'Congrats! You’re already a Diamond!',
    description: 'Field Label',
  },
  inviteYourFriends: {
    id: 'id.inviteYourFriends',
    defaultMessage: 'Invite Your Friends!!',
    description: 'Field Label',
  },
  inviteClaimDashboardOne: {
    id: 'id.inviteClaimDashboardOne',
    defaultMessage: 'Claim',
    description: 'Field Label',
  },
  inviteClaimDashboardOneDiamond: {
    id: 'id.inviteClaimDashboardOneDiamond',
    defaultMessage: 'As a diamond you get',
    description: 'Field Label',
  },
  inviteClaimDashboardTwo: {
    id: 'id.inviteClaimDashboardTwo',
    defaultMessage: 'right away! Get',
    description: 'Field Label',
  },
  inviteClaimDashboardThree: {
    id: 'id.inviteClaimDashboardThree',
    defaultMessage: 'credit for each friend who completes her profile!!',
    description: 'Field Label',
  },
  inviteClaimDashboardThreeDiamond: {
    id: 'id.inviteClaimDashboardThreeDiamond',
    defaultMessage: 'credit for each invite who completes her profile!!',
    description: 'Field Label',
  },
  usedYourInvite: {
    id: 'id.usedYourInvite',
    defaultMessage: 'You have used your invites, let us know if you need more!',
    description: 'Field Label',
  },
  diamondLabel: {
    id: 'id.diamondLabel',
    defaultMessage: 'Diamond',
    description: 'Label',
  },
  diamondSubsectionOne: {
    id: 'id.diamondSubsectionOne',
    defaultMessage: 'Diamond member page',
    description: 'Label',
  },
  diamondSubsectionTwo: {
    id: 'id.diamondSubsectionTwo',
    defaultMessage: 'Diamond member offers',
    description: 'Diamond Label',
  },
  diamondMemberTitle: {
    id: 'id.diamondMemberTitle',
    defaultMessage: 'Congrats you Diamond!',
    description: 'Label',
  },
  diamondMemberSubTitle: {
    id: 'id.diamondMemberSubTitle',
    defaultMessage:
      "Being a Diamond member of Golightly, you get Exclusive Access to Cool Perks and Discounted Travel that you won't get anywhere else!",
    description: 'Label',
  },
  yourDiamondPerks: {
    id: 'id.yourDiamondPerks',
    defaultMessage: 'Your Golightly Diamond Perks',
    description: 'Label',
  },
  diamondMemberLabel: {
    id: 'id.diamondMemberLabel',
    defaultMessage: 'Diamond member',
    description: 'Label',
  },
  diamondMemberOffer: {
    id: 'id.diamondMemberOffer',
    defaultMessage: 'Diamond member offer',
    description: 'Label',
  },
  diamondMemberOfferInfoOne: {
    id: 'id.diamondMemberOfferInfo',
    defaultMessage: 'As a',
    description: 'Label',
  },
  diamondMemberOfferInfoTwo: {
    id: 'id.diamondMemberOfferInfoTwo',
    defaultMessage: 'you get your',
    description: 'Label',
  },
  diamondMemberOfferInfoThree: {
    id: 'id.diamondMemberOfferInfoThree',
    defaultMessage: 'discount on this GoSolo Trip!',
    description: 'Label',
  },
  diamondMemberOfferInfoFour: {
    id: 'id.diamondMemberOfferInfoFour',
    defaultMessage: 'discount off all GoSolo trips',
    description: 'Label',
  },
  noDiamondMemberOfferInfoOne: {
    id: 'id.noDiamondMemberOfferInfoOne',
    defaultMessage: 'Upgrade now to',
    description: 'Label',
  },
  noDiamondMemberOfferInfoTwo: {
    id: 'id.noDiamondMemberOfferInfoTwo',
    defaultMessage: 'and get your GoSolo discount of',
    description: 'Label',
  },
  noDiamondGetDiscount: {
    id: 'id.noDiamondGetDiscount',
    defaultMessage: 'Click here',
    description: 'Label',
  },
  noDiamondMemberBookingOfferInfoOne: {
    id: 'id.noDiamondMemberBookingOfferInfoOne',
    defaultMessage: 'Upgrade now to',
    description: 'Label',
  },
  noDiamondMemberBookingOfferInfoTwo: {
    id: 'id.noDiamondMemberBookingOfferInfoTwo',
    defaultMessage: 'to lower your service fee to',
    description: 'Label',
  },
  diamondMemberBookingOfferInfoOne: {
    id: 'id.diamondMemberBookingOfferInfoOne',
    defaultMessage: 'the lower service fee of',
    description: 'Label',
  },
  clickDiamondOfferBooking: {
    id: 'id.clickDiamondOfferBooking',
    defaultMessage: 'Click here',
    description: 'Label',
  },
  diamondMemberOfferLabel: {
    id: 'id.diamondMemberOfferLabel',
    defaultMessage: 'Diamond Member',
    description: 'Label',
  },
  diamondMemberShipOfferLabel: {
    id: 'id.diamondMemberShipOfferLabel',
    defaultMessage: 'Diamond membership',
    description: 'Label',
  },
  diamondMemberInviteOfferInfoOne: {
    id: 'id.diamondMemberInviteOfferInfoOne',
    defaultMessage: 'As a',
    description: 'Label',
  },
  diamondMemberInviteOfferInfoTwo: {
    id: 'id.diamondMemberInviteOfferInfoTwo',
    defaultMessage: 'you get',
    description: 'Label',
  },
  diamondMemberInviteOfferInfoThree: {
    id: 'id.diamondMemberInviteOfferInfoThree',
    defaultMessage: 'credit for each invite!',
    description: 'Label',
  },
  noDiamondMemberInviteOfferInfoOne: {
    id: 'id.noDiamondMemberInviteOfferInfoOne',
    defaultMessage: 'Upgrade to',
    description: 'Label',
  },
  noDiamondMemberInviteOfferInfoTwo: {
    id: 'id.noDiamondMemberInviteOfferInfoTwo',
    defaultMessage: 'to receive',
    description: 'Label',
  },
  noDiamondMemberInviteOfferInfoThree: {
    id: 'id.noDiamondMemberInviteOfferInfoThree',
    defaultMessage: 'credit for each invite!',
    description: 'Label',
  },
  clickDiamondOfferInvite: {
    id: 'id.clickDiamondOfferInvite',
    defaultMessage: 'Click here',
    description: 'Label',
  },
  noDiamondReservation: {
    id: 'id.noDiamondReservation',
    defaultMessage:
      'Your membership offers is not applicable on this bookings as this reservation was made prior to your membership',
    description: 'Label',
  },
  diamondMembership: {
    id: 'id.diamondMembership',
    defaultMessage: 'Diamond Membership',
    description: 'Label',
  },
  diamondDropDownLabel: {
    id: 'id.diamondDropDownLabel',
    defaultMessage: 'Golightly perks',
    description: 'Label',
  },
  partnerLabel: {
    id: 'id.partnerLabel',
    defaultMessage: 'Partners Perks',
    description: 'Label',
  },
  perksWithPartner: {
    id: 'id.perksWithPartner',
    defaultMessage: 'Your perks with our Partners!',
    description: 'Label',
  },
  goSoloBlockMiddleOne: {
    id: 'id.goSoloBlockMiddle',
    defaultMessage: 'Diamond members',
    description: 'Label',
  },
  goSoloBlockMiddleTwo: {
    id: 'id.goSoloBlockMiddleTwo',
    defaultMessage:
      'get early access and discounts off GoSolo trips…Upgrade to Diamond now for these and more benefits!',
    description: 'Label',
  },
  localSpotLabel: {
    id: 'id.localSpotLabel',
    defaultMessage: 'Member Recommend Guides',
    description: 'Label',
  },
  bffAdd: {
    id: 'id.bffAdd',
    defaultMessage: 'BFF (Add)',
    description: 'GoSoloAllStays',
  },
  justBfsAdd: {
    id: 'id.justBfsAdd',
    defaultMessage: 'Just BFs (Add)',
    description: 'GoSoloAllStays',
  },
  buddyUpAdd: {
    id: 'id.buddyUpAdd',
    defaultMessage: 'Buddy UP (Add)',
    description: 'GoSoloAllStays',
  },
  whoGoingPricing: {
    id: 'id.whoGoingPricing',
    defaultMessage: '"Who is going?" Pricing',
    description: 'GoSoloAllStays',
  },
  bookingGoSoloContentOne: {
    id: 'id.bookingGoSoloContentOne',
    defaultMessage: 'Who is going on this trip?',
    description: 'GoSoloAllStays',
  },
  bookingGoSoloContentTwo: {
    id: 'id.bookingGoSoloContentTwo',
    defaultMessage:
      'Remember if the room has 1 bed and you are adding your BFF to the trip you will be sharing beds. Solo travelers have their own beds!',
    description: 'GoSoloAllStays',
  },
  bookingSoloTitle: {
    id: 'id.bookingSoloTitle',
    defaultMessage: 'Solo',
    description: 'GoSoloAllStays',
  },
  bookingBFFTitle: {
    id: 'id.bookingBFFTitle',
    defaultMessage: 'BFFs',
    description: 'GoSoloAllStays',
  },
  bookingSoloContent: {
    id: 'id.bookingSoloContent',
    defaultMessage: 'This trip is my gift to me and me only',
    description: 'GoSoloAllStays',
  },
  bookingBFFContent: {
    id: 'id.bookingBFFContent',
    defaultMessage:
      'Bringing my bestie and treating us both (must be a member).',
    description: 'GoSoloAllStays',
  },
  bookingJustBFFTitle: {
    id: 'id.bookingJustBFFTitle',
    defaultMessage: 'Just BFs',
    description: 'GoSoloAllStays',
  },
  bookingJustBFFContent: {
    id: 'id.bookingJustBFFContent',
    defaultMessage: 'We are splitting the cost (must be a member)',
    description: 'GoSoloAllStays',
  },
  bookingJustBFFSubContent: {
    id: 'id.bookingJustBFFSubContent',
    defaultMessage:
      '[She will be sent her half of invoice & have 24 Hrs to pay for confirmation]',
    description: 'GoSoloAllStays',
  },
  canceledByAdmin: {
    id: 'id.canceledByAdmin',
    defaultMessage: 'Canceled by admin',
    description: 'Label',
  },
  clickThroughLearnMore: {
    id: 'id.clickThroughLearnMore',
    defaultMessage: 'Click through to Learn More',
    description: 'Label',
  },
  upgradeToLabel: {
    id: 'id.upgradeToLabel',
    defaultMessage: 'Upgrade to',
    description: 'Label',
  },
  canceledLabel: {
    id: 'id.canceledLabel',
    defaultMessage: 'Canceled',
    description: 'Label',
  },
  paymentStatusLabel: {
    id: 'id.paymentStatusLabel',
    defaultMessage: 'Payment Status',
    description: 'Label',
  },
  interstedLabel: {
    id: 'id.interstedLabel',
    defaultMessage: 'Interested',
    description: 'Label',
  },
  unPaidCancelled: {
    id: 'id.unPaidCancelled',
    defaultMessage: 'Unpaid and Canceled',
    description: 'Label',
  },
  compareToLabel: {
    id: 'id.compareToLabel',
    defaultMessage: 'Compare to',
    description: 'Label',
  },
  completeSignUpHereLabel: {
    id: 'id.completeSignUpHereLabel',
    defaultMessage: 'Complete Signup here',
    description: 'GoSoloAllStays',
  },
  localSpotsLabel: {
    id: 'id.localSpotsLabel',
    defaultMessage: 'Local Spots',
    description: 'Title',
  },
  localSpotsExperience: {
    id: 'id.localSpotsExperience',
    defaultMessage: 'Click on a member below to experience',
    description: 'Title',
  },
  herWayLabel: {
    id: 'id.herWayLabel',
    defaultMessage: 'her way',
    description: 'Label',
  },
  noMemberLocalSpotOne: {
    id: 'id.noMemberLocalSpotOne',
    defaultMessage: 'No member has completed their local spots for',
    description: 'Label',
  },
  noMemberLocalSpotTwo: {
    id: 'id.noMemberLocalSpotTwo',
    defaultMessage: 'yet but check out some below options for our hometown of',
    description: 'Label',
  },
  noMemberLocalSpotThree: {
    id: 'id.noMemberLocalSpotThree',
    defaultMessage: 'just for fun',
    description: 'Label',
  },
  localSpotInfoContent: {
    id: 'id.localSpotInfoContent',
    defaultMessage:
      'Discover other members local spots to help build your itinerary your way!',
    description: 'Label',
  },
  searchPropGlobally: {
    id: 'id.searchPropGlobally',
    defaultMessage: 'Search properties globally',
    description: 'Label',
  },
  verifiedHostGloabal: {
    id: 'id.verifiedHostGloabal',
    defaultMessage: 'Verified hosts and properties',
    description: 'Label',
  },
  welcomeWomenWorld: {
    id: 'id.welcomeWomenWorld',
    defaultMessage: 'Welcome women worldwide',
    description: 'Label',
  },
  joinGlobal: {
    id: 'id.joinGlobal',
    defaultMessage: 'Welcome women worldwide',
    description: 'Label',
  },
  dashboardDiamondContentOne: {
    id: 'id.dashboardDiamondContentOne',
    defaultMessage: 'Over $600 a year in Savings',
    description: 'Label',
  },
  dashboardDiamondContentTwo: {
    id: 'id.dashboardDiamondContentTwo',
    defaultMessage: 'Explore the world with GoSolo Discounts',
    description: 'Label',
  },
  dashboardDiamondContentThreePartOne: {
    id: 'id.dashboardDiamondContentThreePartOne',
    defaultMessage: 'Enjoy Exclusive',
    description: 'Label',
  },
  dashboardDiamondContentThreePartTwo: {
    id: 'id.dashboardDiamondContentThreePartTwo',
    defaultMessage: 'Partner Perks.',
    description: 'Label',
  },
  youSaved: {
    id: 'id.youSaved',
    defaultMessage: 'You Saved',
    description: 'Label',
  },
  youWillSave: {
    id: 'id.youWillSave',
    defaultMessage: 'You will save',
    description: 'Label',
  },
  ifUpgradeDiamond: {
    id: 'id.ifUpgradeDiamond',
    defaultMessage: 'if you upgrade to Diamond',
    description: 'Label',
  },
  diamondPageContentOne: {
    id: 'id.diamondPageContentOne',
    defaultMessage: 'Mary became a Golightly diamond Member.',
    description: 'Label',
  },
  diamondPageContentTwo: {
    id: 'id.diamondPageContentTwo',
    defaultMessage: 'She received her immediate $50 travel credit',
    description: 'Label',
  },
  diamondPageContentThree: {
    id: 'id.diamondPageContentThree',
    defaultMessage: 'Mary’s cute $50 travel credit gift arrived.',
    description: 'Label',
  },
  diamondPageContentFour: {
    id: 'id.diamondPageContentFour',
    defaultMessage:
      'Mary’s friend Anne and 9 other friends signed up, Mary gets $250 credit.',
    description: 'Label',
  },
  diamondPageContentFive: {
    id: 'id.diamondPageContentFive',
    defaultMessage:
      'She booked a family stay at a stunning Costa Rica Villa : Mary Saved $200.',
    description: 'Label',
  },
  diamondPageContentSix: {
    id: 'id.diamondPageContentSix',
    defaultMessage:
      'Mary needed space so booked a GoSolo trip to Miami and saved $100.',
    description: 'Label',
  },
  diamondPageContentSeven: {
    id: 'id.diamondPageContentSeven',
    defaultMessage: 'Mary saved',
    description: 'Label',
  },
  diamondPageContentEight: {
    id: 'id.diamondPageContentEight',
    defaultMessage: 'Mary was happy!',
    description: 'Label',
  },
  diamondPageContentNine: {
    id: 'id.diamondPageContentNine',
    defaultMessage: '$600',
    description: 'Label',
  },
  diamondPageContentTen: {
    id: 'id.diamondPageContentTen',
    defaultMessage:
      'made global connections, got amazing travel itineraries, attended exclusive events realised she had a community ready to help her',
    description: 'Label',
  },
  diamondPageContentEleven: {
    id: 'id.diamondPageContentEleven',
    defaultMessage: 'travel often, stay safely and Golightly',
    description: 'Label',
  },
  diamondPageContentTwelve: {
    id: 'id.diamondPageContentTwelve',
    defaultMessage: 'around the world!',
    description: 'Label',
  },
  extendReservation: {
    id: 'id.extendReservation',
    defaultMessage: 'Extend reservation',
    description: 'GoSoloAllStays',
  },
  strikeOutPrice: {
    id: 'id.strikeOutPrice',
    defaultMessage: 'Strike out Price',
    description: 'Strike Out Price',
  },
  diamondTermsAndCondition: {
    id: 'id.diamondTermsAndCondition',
    defaultMessage: 'Golightly Diamond Subscription Terms & Conditions',
    description: 'Content',
  },
  viewTripInfoContent: {
    id: 'id.viewTripInfoContent',
    defaultMessage: 'Rooms can be split between 2 members',
    description: 'Content',
  },
  goSoloViewDiamondMemberOne: {
    id: 'id.goSoloViewDiamondMemberOne',
    defaultMessage: 'Diamond members',
    description: 'Content',
  },
  goSoloViewDiamondMemberTwo: {
    id: 'id.goSoloViewDiamondMemberTwo',
    defaultMessage: 'enjoy',
    description: 'Content',
  },
  goSoloViewDiamondMemberThree: {
    id: 'id.goSoloViewDiamondMemberThree',
    defaultMessage: 'off GoSolo  Getaways!',
    description: 'Content',
  },
  resetPasswordContent: {
    id: 'id.resetPasswordContent',
    defaultMessage: 'Reset password',
    description: 'Content',
  },
  lowestPrice: {
    id: 'id.lowestPrice',
    defaultMessage: 'Lowest Price',
    description: 'Content',
  },
  insuranceYesGoSoloLabel: {
    id: 'rentall.insuranceYesGoSoloLabel',
    defaultMessage: 'Yes, I want Booking Protection for my trip',
    description: 'GoSolo Payment summary page',
  },
  diamondMemberViewGoSoloTrip: {
    id: 'rentall.diamondMemberViewGoSoloTrip',
    defaultMessage: 'Diamond members enjoy the lowest prices!',
    description: 'GoSolo Payment summary page',
  },
  roomsSplitUpViewGoSoloTrip: {
    id: 'rentall.roomsSplitUpViewGoSoloTrip',
    defaultMessage: '*Rooms & Prices can be split between 2 members',
    description: 'GoSolo Payment summary page',
  },
  diamondMemberGoSoloTripPayment: {
    id: 'rentall.diamondMemberGoSoloTripPayment',
    defaultMessage:
      'To view and book with the lowest prices upgrade to Diamond now!',
    description: 'GoSolo Payment summary page',
  },
  loginInformation: {
    id: 'id.loginInformation',
    defaultMessage:
      'As Golightly is a private club, some areas are hidden for our members privacy. Please login or sign up to proceed. Thank you!',
    description: 'Content',
  },
  resetPasswordHere: {
    id: 'id.resetPasswordHere',
    defaultMessage: 'Reset password here.',
    description: 'Content',
  },
  bffAmount: {
    id: 'id.bffAmount',
    defaultMessage: 'BFF amount',
    description: 'Content',
  },
  justBfAmount: {
    id: 'id.justBfAmount',
    defaultMessage: 'Just BF amount',
    description: 'Content',
  },
  inviteCreditApplied: {
    id: 'id.inviteCreditApplied',
    defaultMessage: 'Invite credit applied',
    description: 'Content',
  },
  goSoloPriceSplitUpLabel: {
    id: 'id.goSoloPriceSplitUpLabel',
    defaultMessage: 'Price breakdown for your trip',
    description: 'Content',
  },
  goSoloSharedPriceLabel: {
    id: 'id.goSoloSharedPriceLabel',
    defaultMessage: 'Shared price',
    description: 'Content',
  },
  seeLongerAdventures: {
    id: 'rentall.seeLongerAdventures',
    defaultMessage: 'See Longer Adventures',
    description: 'See Longer Adventures',
  },
  seeShortSweet: {
    id: 'rentall.seeShortSweet',
    defaultMessage: 'See Short & Sweet',
    description: 'See Short & Sweet',
  },
  moreGoSoloGetaways: {
    id: 'rentall.moreGoSoloGetaways',
    defaultMessage: 'More GoSolo Getaways',
    description: 'More GoSolo Getaways',
  },
  gosoloBookingConditionOne: {
    id: 'id.gosoloBookingConditionOne',
    defaultMessage:
      'By clicking ‘Pay Now’, you are acknowledging that you have read and are bound by Golightly’s Terms & Conditions, Privacy Policy, plus the',
    description: 'content',
  },
  gosoloBookingConditionTwo: {
    id: 'id.gosoloBookingConditionTwo',
    defaultMessage: 'booking conditions',
    description: 'content',
  },
  gosoloBookingConditionThree: {
    id: 'id.gosoloBookingConditionThree',
    defaultMessage: 'for your stay.',
    description: 'content',
  },
  clickToLabel: {
    id: 'id.clickToLabel',
    defaultMessage: 'Click to',
    description: 'Click to',
  },
  yourCreditAmount: {
    id: 'id.yourCreditAmount',
    defaultMessage: 'Your Credit Amount',
    description: 'Descritpion',
  },
  enterYourCreditAmount: {
    id: 'id.enterYourCreditAmount',
    defaultMessage: 'Enter Your Credit Amount',
    description: 'Descritpion',
  },
  applyCreditContent: {
    id: 'id.applyCreditContent',
    defaultMessage:
      '*You can apply credit value up to 50% of the payment amount',
    description: 'Descritpion',
  },
  conciergeHeadContent: {
    id: 'id.conciergeHeadContent',
    defaultMessage:
      'Concierge Service is Available for Golightly Diamond Members',
    description: 'Descritpion',
  },
  uploadPDFLabel: {
    id: 'id.uploadPDFLabel',
    defaultMessage: 'Upload PDF',
    description: 'Label',
  },
  moreInfoGoSoloPdf: {
    id: 'id.moreInfoGoSoloPdf',
    defaultMessage: 'More information (rooms & trip)',
    description: 'Label',
  },
  manageGoSoloStay: {
    id: 'id.manageGoSoloStay',
    defaultMessage: 'Type of Stay',
    description: 'Label',
  },
  itemNameLabel: {
    id: 'id.itemNameLabel',
    defaultMessage: 'Item name',
    description: 'Label',
  },
  manageGoSoloTrip: {
    id: 'id.manageGoSoloTrip',
    defaultMessage: 'Type of Trip',
    description: 'Label',
  },
  goSoloRequestContent1: {
    id: 'id.goSoloRequestContent1',
    defaultMessage:
      'Want to organize your own GoSolo Girls trip with your friends?',
    description: 'Label',
  },
  goSoloRequestContent2: {
    id: 'id.goSoloRequestContent2',
    defaultMessage: 'Click here and we will set it up for you.',
    description: 'Label',
  },
  goSoloUserRequestLabel: {
    id: 'id.goSoloUserRequestLabel',
    defaultMessage: 'Propose your trip to Golightly',
    description: 'Label',
  },
  extraLabel: {
    id: 'id.extraLabel',
    defaultMessage: 'Extras',
    description: 'Label',
  },
  extraMaxCharValidationError: {
    id: 'id.extraMaxCharValidationError',
    defaultMessage: 'Maximum 3000 characters only.',
    description: 'Description',
  },
  guestsValidationError: {
    id: 'id.guestsValidationError',
    defaultMessage: 'The count should not exceed 1000',
    description: 'Description',
  },
  unblockDateDescription: {
    id: 'id.unblockDateDescription',
    defaultMessage:
      'unblocking dates will default dates back to base pricing if you wish to add special pricing you will need to add it back',
    description: 'Click to',
  },
  locationPreference: {
    id: 'id.locationPreference',
    defaultMessage: 'Location Preference',
    description: 'Label',
  },
  addMember: {
    id: 'id.addMember',
    defaultMessage: 'Add members',
    description: 'Label',
  },
  tripPropasalLabel: {
    id: 'id.tripPropasalLabel',
    defaultMessage: 'Personal Girls Trip Proposals',
    description: 'Label',
  },
  nameLabel: {
    id: 'id.nameLabel',
    defaultMessage: 'Name of the user',
    description: 'Label',
  },
  emailAddressSmallLabel: {
    id: 'id.emailAddressSmallLabel',
    defaultMessage: 'Email address',
    description: 'Label',
  },
  phoneNumberAdminLabel: {
    id: 'id.phoneNumberAdminLabel',
    defaultMessage: 'Phone number',
    description: 'Label',
  },
  privateGoSoloLabel: {
    id: 'id.privateGoSoloLabel',
    defaultMessage: 'Do you want to display this trip only for added members?',
    description: 'Label',
  },
  chatWithTeamLabel: {
    id: 'id.chatWithTeamLabel',
    defaultMessage: 'Chat with your team',
    description: 'Label',
  },
  tripDatesLabel: {
    id: 'id.tripDatesLabel',
    defaultMessage: 'Trip date',
    description: 'Label',
  },
  houseRulePDFName: {
    id: 'id.houseRulePDFName',
    defaultMessage: 'House rules.pdf',
    description: 'Label',
  },
  houseRulePDFLabel: {
    id: 'id.houseRulePDFLabel',
    defaultMessage: 'House rules PDF',
    description: 'Label',
  },
  uploadRoomImage: {
    id: 'id.uploadRoomImage',
    defaultMessage: 'Upload room image',
    description: 'Click to',
  },
  repalaceRoomImage: {
    id: 'id.repalaceRoomImage',
    defaultMessage: 'Replace room image',
    description: 'Click to',
  },
  houseRulePdfContent: {
    id: 'id.houseRulePdfContent',
    defaultMessage:
      'If you have a PDF for your guest, add it here and it will be visible to your guest after booking',
    description: 'Click to',
  },
  emptyMessageInfo: {
    id: 'id.emptyMessageInfo',
    defaultMessage: 'No thread found',
    description: 'Content',
  },
  goHustle: {
    id: 'id.goHustle',
    defaultMessage: 'GoHustle',
    description: 'GoHustle',
  },
  tagType: {
    id: 'id.tagType',
    defaultMessage: 'Tag type',
    description: 'Tag Type',
  },
  goHustleManagement: {
    id: 'id.goHustleManagement',
    defaultMessage: 'GoHustle management',
    description: 'GoHustle management',
  },
  adminPostManagement: {
    id: 'id.adminPostManagement',
    defaultMessage: 'Admin post management',
    description: 'Admin post management',
  },
  userPostManagement: {
    id: 'id.userPostManagement',
    defaultMessage: 'User post management',
    description: 'User post management',
  },
  industryManagement: {
    id: 'id.industryManagement',
    defaultMessage: 'Industry management',
    description: 'Industry management',
  },
  jobTypeManagement: {
    id: 'id.jobTypeManagement',
    defaultMessage: 'Job type management',
    description: 'Job type management',
  },
  contentManagement: {
    id: 'id.contentManagement',
    defaultMessage: 'Content management',
    description: 'Content management',
  },
  communityAdviceManagement: {
    id: 'id.communityAdviceManagement',
    defaultMessage: 'Community management',
    description: 'Community management',
  },
  limitExceedText: {
    id: 'id.limitExceedText',
    defaultMessage:
      'Limit exceeded! You can add the industry name up to 50 characters.',
    description:
      'Limit exceeded! You can add the industry name up to 50 characters.',
  },
  industryNameLabel: {
    id: 'id.industryNameLabel',
    defaultMessage: 'Industry name',
    description: 'Industry name',
  },
  jobTypeNameLabel: {
    id: 'id.jobTypeNameLabel',
    defaultMessage: 'Job type name',
    description: 'Job type name',
  },
  limitExceedForJobType: {
    id: 'id.limitExceedForJobType',
    defaultMessage:
      'Limit exceeded! You can add the job type up to 50 characters.',
    description:
      'Limit exceeded! You can add the job type up to 50 characters.',
  },
  limitExceedForContent: {
    id: 'id.limitExceedForContent',
    defaultMessage: 'Limit exceeded! You can add content up to 1000 characters',
    description: 'Limit exceeded! You can add content up to 1000 characters',
  },
  userNameLabel: {
    id: 'id.userNameLabel',
    defaultMessage: 'User name',
    description: 'User name',
  },
  limitExceedForName: {
    id: 'id.limitExceedForName',
    defaultMessage: 'Limit exceeded! You can add the name up to 50 characters',
    description: 'Limit exceeded! You can add the name up to 50 characters',
  },
  jobTitle: {
    id: 'id.jobTitle',
    defaultMessage: 'Job title',
    description: 'Job title',
  },
  experienceLabel: {
    id: 'id.experienceLabel',
    defaultMessage: 'Experience',
    description: 'Experience',
  },
  jobLocation: {
    id: 'id.jobLocation',
    defaultMessage: 'Job Location',
    description: 'Job Location',
  },
  jobTypeLabel: {
    id: 'id.jobTypeLabel',
    defaultMessage: 'Job type',
    description: 'Job type',
  },
  limitExceedTextJob: {
    id: 'id.limitExceedTextJob',
    defaultMessage:
      'Limit exceeded! You can add the job title up to 255 characters.',
    description:
      'Limit exceeded! You can add the job title up to 255 characters.',
  },
  limitExceedTextCompany: {
    id: 'id.limitExceedTextCompany',
    defaultMessage:
      'Limit exceeded! You can add the company up to 255 characters.',
    description:
      'Limit exceeded! You can add the company up to 255 characters.',
  },
  communityAdviceTitle: {
    id: 'id.communityAdviceTitle',
    defaultMessage: 'Community advice',
    description: 'Community advice',
  },
  communityAdviceContent1: {
    id: 'id.communityAdviceContent1',
    defaultMessage:
      'Need advice? Here you go, kindly given by some of your awesome fellow community members',
    description: 'Community Advice Content',
  },
  communityAdviceContent2: {
    id: 'id.communityAdviceContent2',
    defaultMessage: 'Feel free to let us know if you have advice to share!',
    description: 'Community Advice Content',
  },
  showMoreLabel: {
    id: 'id.showMoreLabel',
    defaultMessage: 'Show More',
    description: 'Show More',
  },
  postDayLabel: {
    id: 'id.postDayLabel',
    defaultMessage: 'day ago',
    description: 'day',
  },
  postDaysLabel: {
    id: 'id.postDaysLabel',
    defaultMessage: 'days ago',
    description: 'days ago',
  },
  myJobsLabel: {
    id: 'id.myJobsLabel',
    defaultMessage: 'My Jobs',
    description: 'My Jobs',
  },
  positionFilled: {
    id: 'id.positionFilled',
    defaultMessage: 'Position filled',
    description: 'Position filled',
  },
  noJobsFound: {
    id: 'id.noJobsFound',
    defaultMessage: 'No Jobs Found',
    description: 'No Jobs Found',
  },
  postedToday: {
    id: 'id.postedToday',
    defaultMessage: 'Posted Today',
    description: 'Posted Today',
  },
  noJobFound: {
    id: 'id.noJobFound',
    defaultMessage: 'No Job Found',
    description: 'No Job Found',
  },
  listSettings: {
    id: 'id.listSettings',
    defaultMessage: 'List Settings',
    description: 'List Settings',
  },
  userEmailLabel: {
    id: 'id.userEmailLabel',
    defaultMessage: 'User email',
    description: 'User email',
  },
  tripName: {
    id: 'id.tripName',
    defaultMessage: 'GoSolo trip name',
    description: 'GoSolo trip name',
  },
  bffName: {
    id: 'id.bffName',
    defaultMessage: 'BFF name',
    description: 'BFF name',
  },
  justBffName: {
    id: 'id.justBffName',
    defaultMessage: 'Just BF name',
    description: 'Just BF name',
  },
  tripCharges: {
    id: 'id.tripCharges',
    defaultMessage: 'Trip Charges',
    description: 'Trip Charges',
  },
  validCharactersFirstName: {
    id: 'id.validCharactersFirstName',
    defaultMessage: 'Your first name must contain one alphabetic character.',
    description: 'Your first name must contain one alphabetic character.',
  },
  validCharactersLastName: {
    id: 'id.validCharactersLastName',
    defaultMessage: 'Your last name must contain one alphabetic character.',
    description: 'Your last name must contain one alphabetic character.',
  },
  showMoreContent: {
    id: 'id.showMoreContent',
    defaultMessage: 'Become a Diamond Member now to see more',
    description: 'Become a Diamond Member now to see more',
  },
  applied: {
    id: 'id.applied',
    defaultMessage: 'Applied',
    description: 'Applied',
  },
  jobApplications: {
    id: 'id.jobApplications',
    defaultMessage: 'Job Applications',
    description: 'Job Applications',
  },
  budgetPerMonth: {
    id: 'id.budgetPerMonth',
    defaultMessage: 'Budget per month',
    description: 'Budget per month',
  },
  goTeamLabel: {
    id: 'id.goTeamLabel',
    defaultMessage: 'GoTeam',
    description: 'GoTeam',
  },
  selectAllListings: {
    id: 'id.selectAllListings',
    defaultMessage: 'Select all listings',
    description: 'Select all listings',
  },
  appliedJobs: {
    id: 'id.appliedJobs',
    defaultMessage: 'Applied Jobs',
    description: 'Applied Jobs',
  },
  noAppliedJobs: {
    id: 'id.noAppliedJobs',
    defaultMessage: "You haven't applied for any job yet.",
    description: "You haven't applied for any job yet.",
  },
  locationType: {
    id: 'id.locationType',
    defaultMessage: 'Location type',
    description: 'Location type',
  },
  remoteLabel: {
    id: 'id.remoteLabel',
    defaultMessage: 'Remote',
    description: 'Remote',
  },
  atLocation: {
    id: 'id.atLocation',
    defaultMessage: 'At location',
    description: 'At location',
  },
  listingTransfer: {
    id: 'id.listingTransfer',
    defaultMessage: 'Listing Transfer',
    description: 'Listing Transfer',
  },
  manage: {
    id: 'id.manage',
    defaultMessage: 'Manage',
    description: 'Manage',
  },
  searchPlaceHolder: {
    id: 'id.searchPlaceHolder',
    defaultMessage: 'Search by name or email',
    description: 'Search by name or email',
  },
  noUsersFound: {
    id: 'id.noUsersFound',
    defaultMessage: 'No users found',
    description: 'No users found',
  },
  exceedLimit1000: {
    id: 'rentall.exceedLimit1000',
    defaultMessage: 'Maximum allowed character is 1000.',
    description: 'exceedLimit',
  },
  manageGoSoloReviews: {
    id: 'id.manageGoSoloReviews',
    defaultMessage: 'Manage GoSolo reviews',
    description: 'Manage GoSolo reviews',
  },
  goSoloReviews: {
    id: 'id.goSoloReviews',
    defaultMessage: 'GoSolo reviews',
    description: 'GoSolo reviews',
  },
  discountTitle: {
    id: 'id.discountTitle',
    defaultMessage: 'Discount Title',
    description: 'Discount Title',
  },
  discountPercentage: {
    id: 'id.discountPercentage',
    defaultMessage: 'Discount Percentage',
    description: 'Discount Percentage',
  },
  discountDates: {
    id: 'id.discountDates',
    defaultMessage: 'Discount dates',
    description: 'Discount dates',
  },
  percentageInvalid: {
    id: 'id.percentageInvalid',
    defaultMessage: 'Only numeric values are allowed',
    description: 'Only numeric values are allowed',
  },
  percentageError: {
    id: 'id.percentageError',
    defaultMessage: 'Invalid Discount Percentage',
    description: 'Invalid Discount Percentage',
  },
  discountBannerImages: {
    id: 'id.discountBannerImages',
    defaultMessage: 'Discount banner images',
    description: 'Discount banner images',
  },
  webBannerImage: {
    id: 'id.webBannerImage',
    defaultMessage: 'Web banner',
    description: 'Web banner',
  },
  mobileBannerImage: {
    id: 'id.mobileBannerImage',
    defaultMessage: 'Mobile banner',
    description: 'Mobile banner',
  },
  dicountContent1: {
    id: 'id.dicountContent1',
    defaultMessage: 'You got a discount of',
    description: 'You got a discount of',
  },
  discountContent2: {
    id: 'id.discountContent2',
    defaultMessage: 'for making the booking on the special offer dates.',
    description: 'for making the booking on the special offer dates.',
  },
  connectHostfullyAccound: {
    id: 'rentall.connectHostfullyAccound',
    defaultMessage: 'Connect  Hostfully account',
    description: 'connect hostfully accound button',
  },
  upcomingSoloDestLabel: {
    id: 'id.upcomingSoloDestLabel',
    defaultMessage: 'Travel With us! Upcoming GoSolo destination:',
    description: 'Travel With us! Upcoming GoSolo destination:',
  },
  oneTimePayment: {
    id: 'id.oneTimePayment',
    defaultMessage: 'One time payment',
    description: 'One time payment',
  },
  pleaseEnsureThe: {
    id: 'id.pleaseEnsureThe',
    defaultMessage: 'Please ensure that the',
    description: 'One time payment',
  },
  membershipText: {
    id: 'id.membershipText',
    defaultMessage: 'membership',
    description: 'One time payment',
  },
  feeIsPaid: {
    id: 'id.feeIsPaid',
    defaultMessage: "fee is paid, if it hasn't been done yet.",
    description: 'One time payment',
  },
});

export default messages;
