import { EMAIL_REGEX } from '../../../constants/regex';
import messages from '../../../locale/messages';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = messages.emailRequired;
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = messages.emailInvalid;
  }

  return errors;
};

export default validate;
