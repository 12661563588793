import {
  SET_MEMBER_FORM_STATE,
  SET_MEMBER_EMAIL_FORM_STATE,
} from '../constants';

export default function memberForm(state = {}, action) {
  switch (action.type) {
    case SET_MEMBER_FORM_STATE:
      return {
        ...state,
        showMemberForm: action.payload.showMemberForm,
      };
    case SET_MEMBER_EMAIL_FORM_STATE:
      return {
        ...state,
        emailStatus: action.payload.emailStatus,
      };
    default:
      return state;
  }
}
