import React, { Component } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import {
  closeReferralModal,
  openReferralModal,
} from '../../actions/modalActions';
import messages from '../../locale/messages';
import ReferralForm from '../ReferralForm';

import s from './ReferralModal.module.css';

class ReferralModal extends Component {
  static propTypes = {
    closeReferralModal: PropTypes.func,
    referralModal: PropTypes.bool,
    openReferralModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      referralModalStatus: false,
    };
  }

  componentDidMount() {
    const { referralModal } = this.props;
    if (referralModal === true) {
      this.setState({ referralModalStatus: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { referralModal } = nextProps;
    if (referralModal === true) {
      this.setState({ referralModalStatus: true });
    } else {
      this.setState({ referralModalStatus: false });
    }
  }

  render() {
    const { closeReferralModal, openReferralModal } = this.props;
    const { referralModalStatus } = this.state;
    return (
      <div>
        <Modal
          animation={false}
          dialogClassName={cx(s.logInModalContainer, 'loginModal')}
          onHide={closeReferralModal}
          show={referralModalStatus}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage {...messages.referralText} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={s.logInModalBody}>
            <ReferralForm />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => ({
  referralModal: state.modalStatus.referralFormModalOpen,
});

const mapDispatch = {
  closeReferralModal,
  openReferralModal,
};

export default connect(mapState, mapDispatch)(ReferralModal);
