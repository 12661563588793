import { gql } from 'react-apollo';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';

import {
  EDIT_BLOG_POST_ERROR,
  EDIT_BLOG_POST_START,
  EDIT_BLOG_POST_SUCCESS,
  OPEN_EDIT_POST_MODAL,
} from '../../constants';
import { setPersonalizedValues } from '../personalized';

const query = gql`
  query editBlogContent($id: Int!) {
    editBlogContent(id: $id) {
      status
      errorMessage
      result {
        id
        tags {
          id
          name
        }
        contentImage {
          id
          name
          blogId
        }
      }
    }
  }
`;

export const editBlogPost = (id, values, refetch, from) => async (
  dispatch,
  getState,
  { client }
) => {
  try {
    await dispatch({ type: EDIT_BLOG_POST_START });
    const { data } = await client.query({
      query,
      variables: { id },
      fetchPolicy: 'network-only',
    });
    const isAdmin =
      getState() &&
      getState().runtime &&
      getState().runtime.isAdminAuthenticated;

    if (data && data.editBlogContent.status === 200) {
      const { result } = data.editBlogContent;
      const tagsData =
        result && result.tags && result.tags.length > 0
          ? result.tags.map((tag) => ({ value: tag.id, label: tag.name }))
          : [];
      dispatch({ type: EDIT_BLOG_POST_SUCCESS });
      await dispatch({
        type: OPEN_EDIT_POST_MODAL,
        isPostModalOpen: true,
        data: {
          ...values,
          tags: tagsData,
          isAdmin,
          contentImage: result && result.contentImage,
          refetch,
        },
        id,
      });
      const searchWord = (await getState().personalized.searchWord) || '';
      await dispatch(
        initialize('BlogContent', {
          ...values,
          tags: tagsData,
          isAdmin,
          refetch,
          from,
          searchWord,
        })
      );
      if (from === 'userBlog' || from === 'blog') {
        let blogData = [
          ...(getState().personalized[
            from === 'userBlog' ? 'userBlogData' : 'blogData'
          ] || []),
        ];
        blogData = JSON.parse(JSON.stringify(blogData));

        let index = blogData.findIndex((item) => item.id === id);
        if (index === -1) return;
        blogData[index] = { ...blogData[index], ...result };
        dispatch(
          setPersonalizedValues({
            name: from === 'userBlog' ? 'userBlogData' : 'blogData',
            value: blogData,
          })
        );
      }
    } else {
      if (data && data.editBlogContent.status === 404) {
        toastr.error(
          'Error!',
          'This blog post might have been deleted/unpublished by user. Please refresh the page and try again.'
        );
      } else if (
        data &&
        data.editBlogContent &&
        data.editBlogContent.errorMessage
      ) {
        toastr.error('Error!', data.editBlogContent.errorMessage);
      }
      refetch && refetch();
      dispatch({ type: EDIT_BLOG_POST_ERROR });
    }
  } catch (error) {
    dispatch({
      type: EDIT_BLOG_POST_ERROR,
      payload: {
        error,
      },
    });
    return false;
  }
  return true;
};
