import { CLOSE_POST_MODAL, OPEN_POST_MODAL } from '../constants';

export default function blogMyPostModal(state = {}, action) {
  switch (action.type) {
    case OPEN_POST_MODAL:
      return {
        ...state,
        isMyPostModalOpen: action.isMyPostModalOpen,
      };

    case CLOSE_POST_MODAL:
      return {
        ...state,
        isMyPostModalOpen: action.isMyPostModalOpen,
      };

    default:
      return state;
  }
}
