import {
  GET_OWNERREZ_BOOKINGS_BY_DATES_START,
  GET_OWNERREZ_BOOKINGS_BY_DATES_SUCCESS,
  SEND_OWNERREZ_BOOKING_DATA_START,
  SEND_OWNERREZ_BOOKING_DATA_SUCCESS,
  SET_OWNERREZ_BOOKINGS,
  // SET_OWNERREZ_RESERVATION_ID,
  // SET_OWNERREZ_PAYMENT_LOADING,
  // GET_OWNERREZ_BOOKINGS_BY_DATES_START,
  // GET_OWNERREZ_BOOKINGS_BY_DATES_SUCCESS,
  // SET_OWNERREZ_BOOKINGS,
} from '../constants';

const initialState = {
  prices: null,
  reservationId: null,
  loading: false,
  ownerRezPaymentLoading: false,
  paymentSuccess: false,
  bookings: [],
};

export default function ownerRez(state = initialState, action) {
  switch (action.type) {
    case SEND_OWNERREZ_BOOKING_DATA_START:
      return {
        ...state,
        prices: null,
        loading: true,
      };

    case SEND_OWNERREZ_BOOKING_DATA_SUCCESS:
      return {
        ...state,
        prices: action.payload,
        loading: false,
      };

    case SET_OWNERREZ_BOOKINGS: {
      return {
        ...state,
        bookings: action.payload,
        loading: false,
      };
    }

    case GET_OWNERREZ_BOOKINGS_BY_DATES_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_OWNERREZ_BOOKINGS_BY_DATES_SUCCESS: {
      return {
        ...state,
        bookings: action.payload,
        loading: false,
      };
    }
    // case SET_OWNERREZ_RESERVATION_ID: {
    //   return {
    //     ...state,
    //     reservationId: action.payload,
    //   };
    // }
    //
    // case SET_OWNERREZ_PAYMENT_LOADING: {
    //   return {
    //     ...state,
    //     paymentLoading: action.payload,
    //   };
    // }
    //
    // case SET_OWNERREZ_BOOKINGS: {
    //   return {
    //     ...state,
    //     bookings: action.payload,
    //     loading: false,
    //   };
    // }
    //
    // case GET_OWNERREZ_BOOKINGS_BY_DATES_START: {
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // }
    //
    // case GET_OWNERREZ_BOOKINGS_BY_DATES_SUCCESS: {
    //   return {
    //     ...state,
    //     bookings: action.payload,
    //     loading: false,
    //   };
    // }

    default:
      return state;
  }
}
