import {
  INTEGRATION_UNSUBSCRIBING_START,
  INTEGRATION_UNSUBSCRIBING_SUCCESS,
  INTEGRATION_UNSUBSCRIBING_ERROR,
} from '../constants';

export default function unsubscribeIntegration(state = {}, action) {
  switch (action.type) {
    case INTEGRATION_UNSUBSCRIBING_START:
      return {
        ...state,
        isLoading: true,
      };
    case INTEGRATION_UNSUBSCRIBING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case INTEGRATION_UNSUBSCRIBING_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
