import React from 'react';

import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';

import { setLocale } from '../../actions/intl';

import s from './LanguageSwitcher.module.css';

const localeDict = {
  'en-US': 'English',
  'cs-CZ': 'Czech',
  es: 'Español',
  'it-IT': 'Italiano',
  'fr-FR': 'Français',
  'pt-PT': 'Português',
};

const localeName = (locale) => localeDict[locale] || locale;

class LanguageSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { setLocale } = this.props;
    setLocale({ locale: event.target.value });
  }

  render() {
    const { currentLocale, availableLocales } = this.props;
    return (
      <FormControl
        className={s.formControlSelect}
        componentClass="select"
        onChange={this.handleChange}
        value={currentLocale}
      >
        {availableLocales.map((locale) => (
          <option key={locale} value={locale}>
            {localeName(locale)}
          </option>
        ))}
      </FormControl>
    );
  }
}

LanguageSwitcher.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
  setLocale: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  availableLocales: state.runtime.availableLocales,
  currentLocale: state.intl.locale,
});

const mapDispatch = {
  setLocale,
};

export default connect(mapState, mapDispatch)(LanguageSwitcher);
