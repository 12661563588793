import {
  CLOSE_USER_EDIT_POST_MODAL,
  OPEN_USER_EDIT_POST_MODAL,
} from '../constants';

export default function blogUserPostModal(state = {}, action) {
  switch (action.type) {
    case OPEN_USER_EDIT_POST_MODAL:
      return {
        ...state,
        isUserPostModalOpen: action.isUserPostModalOpen,
        data: action.data,
        id: action.id,
      };

    case CLOSE_USER_EDIT_POST_MODAL:
      return {
        ...state,
        isUserPostModalOpen: action.isUserPostModalOpen,
      };

    default:
      return state;
  }
}
