import React from 'react';

import cx from 'classnames';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { Col, Grid, Row } from 'react-bootstrap';

import AccountSettingsSideMenu from '../../components/AccountSettingsSideMenu';
import Transaction from '../../components/Transaction';

import s from './Transaction.module.css';

const getTransactionHistory = loader('./getTransactionHistory.graphql');

class TransactionContainer extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data } = this.props;
    return (
      <div className={s.root}>
        <div className={s.container}>
          <Grid>
            <Row className={cx(s.spaceTop4, s.landingContainer)}>
              <Col className={s.smPadding} lg={3} md={3} sm={3} xs={12}>
                <AccountSettingsSideMenu />
              </Col>
              <Col
                className={cx(s.smPadding, s.panelSpace)}
                lg={9}
                md={9}
                sm={9}
                xs={12}
              >
                <Transaction data={data} />
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(getTransactionHistory, {
    options: (props) => ({
      variables: {
        mode: props.mode,
        currentPage: 1,
      },
      fetchPolicy: 'network-only',
      ssr: false,
    }),
  })
)(TransactionContainer);
