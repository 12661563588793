import { gql } from 'react-apollo';
import { toastr } from 'react-redux-toastr';

import { adminEmaillist, backendUrl, profileChangeAdmin, url } from '../config'; // To Refresh the verification status
import {
  EMAIL_CONTENT_FEMALE_USER_ERROR,
  EMAIL_CONTENT_FEMALE_USER_START,
  EMAIL_CONTENT_FEMALE_USER_SUCCESS,
  EMAIL_CONTENT_MALE_USER_ERROR,
  EMAIL_CONTENT_MALE_USER_START,
  EMAIL_CONTENT_MALE_USER_SUCCESS,
  EMAIL_VERIFICATION_ERROR,
  EMAIL_VERIFICATION_START,
  EMAIL_VERIFICATION_SUCCESS,
  RESEND_EMAIL_VERIFICATION_ERROR,
  RESEND_EMAIL_VERIFICATION_START,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
  UPDATE_VERIFICATION_ERROR,
  UPDATE_VERIFICATION_START,
  UPDATE_VERIFICATION_SUCCESS,
} from '../constants';
import { sendEmail } from '../core/email/sendEmail';

import { loadAccount } from './account';
import { setLoaderComplete, setLoaderStart } from './loader/loader';

const query = gql`
  query($userId: String!) {
    getUserVerifiedInfo(userId: $userId) {
      id
      isEmailConfirmed
      isFacebookConnected
      isGoogleConnected
      isIdVerification
      isLinkedinConnected
      status
    }
  }
`;

export function disconnectVerification(item, userId) {
  return async (dispatch, getState, { client }) => {
    dispatch({ type: UPDATE_VERIFICATION_START });

    const mutation = gql`
      mutation updateUserVerifiedInfo($item: String!) {
        updateUserVerifiedInfo(item: $item) {
          status
        }
      }
    `;

    try {
      const { data } = await client.mutate({
        mutation,
        variables: {
          item,
        },
        refetchQueries: [{ query, variables: { userId } }],
      });

      if (data.updateUserVerifiedInfo.status === 'success') {
        dispatch({ type: UPDATE_VERIFICATION_SUCCESS });
      } else {
        dispatch({
          type: UPDATE_VERIFICATION_ERROR,
          payload: {
            status: data.updateUserVerifiedInfo.status,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_VERIFICATION_ERROR,
        payload: {
          error,
        },
      });
    }
  };
}

export function emailVerification(token, email, userId) {
  return async (dispatch, getState, { client }) => {
    dispatch({ type: EMAIL_VERIFICATION_START });

    const emailQuery = gql`
      query EmailVerification($token: String!, $email: String!) {
        EmailVerification(token: $token, email: $email) {
          userId
          status
        }
      }
    `;

    try {
      const { data } = await client.query({
        query: emailQuery,
        variables: {
          token,
          email,
        },
      });

      if (data.EmailVerification.status === 'confirmed') {
        dispatch({ type: EMAIL_VERIFICATION_SUCCESS });
        await dispatch(disconnectVerification('email', userId));
        await dispatch(loadAccount());
      }
    } catch (error) {
      dispatch({
        type: EMAIL_VERIFICATION_ERROR,
        payload: {
          error,
        },
      });
    }
  };
}

export function resendEmailVerification() {
  return async (dispatch, getState, { client }) => {
    const { isEmailConfirmed } = getState().account.data.verification;
    dispatch({ type: RESEND_EMAIL_VERIFICATION_START });
    dispatch(setLoaderStart('resendEmailLoading'));

    const resendQuery = gql`
      query ResendConfirmEmail {
        ResendConfirmEmail {
          id
          userId
          profile {
            firstName
            lastName
          }
          token
          email
          status
        }
      }
    `;

    try {
      const { data } = await client.query({
        query: resendQuery,
      });

      if (
        data.ResendConfirmEmail.status !== 'error' &&
        isEmailConfirmed === false
      ) {
        const content = {
          token: data.ResendConfirmEmail.token,
          name: data.ResendConfirmEmail.profile.firstName,
          email: data.ResendConfirmEmail.email,
        };

        // const { status, response } = await sendEmail(data.ResendConfirmEmail.email, 'confirmEmail', content);

        const { status, errorMessage } = await fetch(
          `${backendUrl}/getMailChimpSubscribe`,
          {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: data.ResendConfirmEmail.email,
            }),
            credentials: 'include',
          }
        );

        let resp1;

        if (status === 404) {
          resp1 = await fetch(`${backendUrl}/mailChimpSubscribe`, {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token: data.ResendConfirmEmail.token,
              firstName: data.ResendConfirmEmail.profile.firstName,
              lastName: data.ResendConfirmEmail.profile.lastName,
              email: data.ResendConfirmEmail.email,
            }),
            credentials: 'include',
          });
        }
        const verifyLink = `${url}/user/verification?confirm=${data.ResendConfirmEmail.token}&email=${data.ResendConfirmEmail.email}`;

        const resp3 = await fetch(`${backendUrl}/mailChimpUpdateProfile`, {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: data.ResendConfirmEmail.token,
            firstName: data.ResendConfirmEmail.profile.firstName,
            lastName: data.ResendConfirmEmail.profile.lastName,
            email: data.ResendConfirmEmail.email,
            verifyLink,
          }),
          credentials: 'include',
        });

        const resp2 = await fetch(`${backendUrl}/verifyEmailMailchimp`, {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: data.ResendConfirmEmail.token,
            firstName: data.ResendConfirmEmail.profile.firstName,
            lastName: data.ResendConfirmEmail.profile.lastName,
            email: data.ResendConfirmEmail.email,
          }),
          credentials: 'include',
        });

        // if (resp2 && resp2.status === 200) {
        if (resp2 && resp2.status === 200) {
          toastr.success(
            'Check your email',
            'Confirmation link has been sent to your email.'
          );
        } else {
          toastr.error(
            'Failed to send email',
            'Sorry, something went wrong. Please try again!'
          );
        }

        dispatch({ type: RESEND_EMAIL_VERIFICATION_SUCCESS });
        dispatch(setLoaderComplete('resendEmailLoading'));
      }
    } catch (error) {
      dispatch({
        type: RESEND_EMAIL_VERIFICATION_ERROR,
        payload: {
          error,
        },
      });
      dispatch(setLoaderComplete('resendEmailLoading'));
    }
  };
}

export function sendEmailofFemaleUser() {
  return async (dispatch, getState, { client }) => {
    dispatch({ type: EMAIL_CONTENT_FEMALE_USER_START });
    dispatch(setLoaderStart('sendEmailofFemaleUserLoading'));

    const resendQuery = gql`
      query ShowUserProfile($profileId: Int, $isUser: Boolean) {
        showUserProfile(profileId: $profileId, isUser: $isUser) {
          id
          userId
          profileId
          firstName
          lastName
          dateOfBirth
          gender
          phoneNumber
          dialCode
          preferredLanguage
          preferredCurrency
          location
          info
          summary
          countryCode
          industry
          education
          universityId
          currentPosition
          companyName
          linkedinUrl
          socialMediaUrl
          createdAt
          picture
        }
        userAccount {
          id
          userData {
            id
            email
            type
          }
        }
      }
    `;

    try {
      const { profileId } = getState().account.data;
      const userEmail = getState().account.data.email;
      const params = {
        profileId,
        isUser: true,
      };
      const { data } = await client.query({
        query: resendQuery,
        variables: params,
        fetchPolicy: 'network-only',
      });

      if (
        data.showUserProfile &&
        data.userAccount.userData.type === 'linkedin'
      ) {
        const content = {
          name: data.showUserProfile.firstName,
          email: userEmail,
          summary: data.showUserProfile.summary,
          location: data.showUserProfile.location,
          currentPosition: data.showUserProfile.currentPosition,
          companyName: data.showUserProfile.companyName,
          industry: data.showUserProfile.industry,
          linkedinUrl: data.showUserProfile.linkedinUrl,
          countryCode: data.showUserProfile.countryCode,
          profileId,
        };
        const { status, response } = await sendEmail(
          adminEmaillist,
          'linkedinFemaleUserEmail',
          content
        );
      } else if (
        data.showUserProfile &&
        data.userAccount.userData.type === 'email'
      ) {
        const content = {
          name: data.showUserProfile.firstName,
          email: userEmail,
          profileId,
        };
        const { status, response } = await sendEmail(
          profileChangeAdmin,
          'normalFemaleUserEmail',
          content
        );
      }
      dispatch({ type: EMAIL_CONTENT_FEMALE_USER_SUCCESS });
      dispatch(setLoaderComplete('sendEmailofFemaleUserLoading'));
    } catch (error) {
      dispatch({
        type: EMAIL_CONTENT_FEMALE_USER_ERROR,
        payload: {
          error,
        },
      });
      dispatch(setLoaderComplete('sendEmailofFemaleUserLoading'));
    }
  };
}

export function emailContentMaleUser() {
  return async (dispatch, getState) => {
    dispatch({ type: EMAIL_CONTENT_MALE_USER_START });
    dispatch(setLoaderStart('emailContentMaleUserLoading'));
    try {
      const name = getState().account.data.firstName;
      const { email, profileId } = getState().account.data;
      const content = {
        name,
        email,
        gender: 'male',
        profileId,
      };
      // const { status, response } = await sendEmail(
      //   email,
      //   'maleUserContent',
      //   content
      // );
      const { status, response } = await sendEmail(
        profileChangeAdmin,
        'normalFemaleUserEmail',
        content
      );
      if (status === 200) {
        toastr.success('Email is sent', 'Please check your mail.');
      } else {
        toastr.error(
          'Failed to sent email',
          'Sorry, something went wrong. Please try again!'
        );
      }

      dispatch({ type: EMAIL_CONTENT_MALE_USER_SUCCESS });
      dispatch(setLoaderComplete('emailContentMaleUserLoading'));
    } catch (error) {
      dispatch({
        type: EMAIL_CONTENT_MALE_USER_ERROR,
        payload: {
          error,
        },
      });
      dispatch(setLoaderComplete('emailContentMaleUserLoading'));
    }
  };
}
