import React, { Component } from 'react';

import cx from 'classnames';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { Col, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { closeWishListModal } from '../../actions/WishList/modalActions';
import messages from '../../locale/messages';
import Loader from '../Loader';
import WishListModalForm from '../WishListModalForm';

import CreateWishList from './CreateWishList';
import ListDetails from './ListDetails';
import s from './WishListModal.module.css';

const getWishListGroupQuery = loader('./getAllWishListGroup.graphql');

class WishListModal extends Component {
  static propTypes = {
    closeWishListModal: PropTypes.func,
    wishListModal: PropTypes.bool,
    formatMessage: PropTypes.func,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      getAllWishListGroup: PropTypes.any,
    }),
  };

  static defaultProps = {
    data: {
      loading: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      wishListModalStatus: false,
    };
  }

  componentDidMount() {
    const { wishListModal } = this.props;
    if (wishListModal === true) {
      this.setState({ wishListModalStatus: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { wishListModal } = nextProps;
    if (wishListModal === true) {
      this.setState({ wishListModalStatus: true });
    } else {
      this.setState({ wishListModalStatus: false });
    }
  }

  render() {
    const {
      closeWishListModal,
      data,
      data: { loading, getAllWishListGroup },
      profileId,
      listId,
    } = this.props;
    const { wishListModalStatus } = this.state;
    const wishListGroups = [];
    if (getAllWishListGroup && getAllWishListGroup.count > 0) {
      getAllWishListGroup.wishListGroupData.forEach((option, index) => {
        if (option.wishListIds.indexOf(listId) !== -1) {
          wishListGroups.push(option.id);
        }
      });
    }

    const initialValues = {
      listId,
      wishListGroups,
    };

    return (
      <div>
        <Modal
          animation={false}
          dialogClassName={cx(s.logInModalContainer, 'loginModal')}
          onHide={closeWishListModal}
          show={wishListModalStatus}
        >
          <Modal.Header closeButton>
            <Modal.Title className={s.wishListTitle}>
              <FormattedMessage {...messages.wishLists} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={s.logInModalBody}>
            <div className={s.root}>
              <div className={s.container}>
                <CreateWishList initialValues={initialValues} />
                {loading && (
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Loader type="text" />
                  </Col>
                )}
                {!loading && (
                  <WishListModalForm
                    data={data}
                    initialValues={initialValues}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
          <div className={s.footerContainer}>
            <a
              className={s.linkContainer}
              href={`/rooms/${listId}`}
              rel="noreferrer"
              target="_blank"
            >
              <ListDetails initialValues={initialValues} />
            </a>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => ({
  wishListModal: state.modalStatus.wishListModalOpen,
  profileId: state.account.data.profileId,
  listId: state.modalStatus.listId,
});

const mapDispatch = {
  closeWishListModal,
};

export default compose(
  connect(mapState, mapDispatch),
  graphql(getWishListGroupQuery, {
    options: (props) => ({
      variables: {
        profileId: props.profileId,
      },
      fetchPolicy: 'network-only',
    }),
  })
)(WishListModal);
