import React from 'react';

import PropTypes from 'prop-types';
import { Checkbox } from 'react-bootstrap';

class CustomCheckbox extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
  };

  render() {
    const { children, name, value, onChange, checked, disabled } = this.props;

    return (
      <Checkbox
        checked={checked}
        disabled={disabled}
        name={name}
        onChange={() => onChange(!checked)}
        value={value}
      >
        {children}
      </Checkbox>
    );
  }
}

export default CustomCheckbox;
