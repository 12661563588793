import React from 'react';

import moment from 'moment';
import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class OfferApproved extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      receiverName: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
      senderName: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      threadId: PropTypes.number.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkout: PropTypes.string.isRequired,
      personCapacity: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const btnCenter = {
      textAlign: 'center',
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const {
      content: {
        receiverName,
        type,
        senderName,
        message,
        receiverProfileId,
        senderProfileId,
        userEmail,
        hostEmail,
      },
    } = this.props;
    const {
      content: { title, listId, startDate, endDate },
    } = this.props;
    const checkInDate = startDate
      ? moment(startDate).format('ddd, MMM Do, YYYY')
      : '';
    const checkOutDate = endDate
      ? moment(endDate).format('ddd, MMM Do, YYYY')
      : '';
    const profileURL = `${url}/users/show/`;
    const listURL = `${url}/rooms/${listId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi Admin,</div>
                  <div>
                    <a href={profileURL + senderProfileId}>
                      {senderName}
                      {userEmail ? ` (${userEmail})` : ''}
                    </a>{' '}
                    has approved special price request from{' '}
                    <a href={profileURL + receiverProfileId}>
                      {receiverName}
                      {hostEmail ? ` (${hostEmail})` : ''}
                    </a>{' '}
                    for the listing <a href={listURL}>{title}</a> {checkInDate}{' '}
                    {checkOutDate}.
                  </div>
                  <EmptySpace height={40} />
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default OfferApproved;
