import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';

export function minMaxDays(startDate, endDate, minNights) {
  let checkIn;
  let checkOut;
  let dayDifference;
  let status = false;
  if (startDate && endDate) {
    checkIn = moment(startDate);
    checkOut = moment(endDate);
    dayDifference = checkOut.diff(checkIn, 'days');
    status = minNights <= dayDifference;
  }
  return status;
}

export function guestPhoneNumber(country, dialCode, phoneNumber, phoneUtil) {
  const getGuestCode = parsePhoneNumber(dialCode + phoneNumber).country;
  return phoneUtil.formatInOriginalFormat(
    phoneUtil.parseAndKeepRawInput(phoneNumber, getGuestCode),
    getGuestCode
  );
}

export function hostPhoneNumber(country, dialCode, phoneNumber, phoneUtil) {
  const getHostCode = parsePhoneNumber(dialCode + phoneNumber).country;
  return phoneUtil.formatInOriginalFormat(
    phoneUtil.parseAndKeepRawInput(phoneNumber, getHostCode),
    getHostCode
  );
}

export function showFields(typeId) {
  const type = [10, 11, 12, 21, 22, 23];
  return !!type.includes(typeId);
}

export function showBedTypes(typeId) {
  const type = [7];
  return !!type.includes(typeId);
}

export function propertyData(listId) {
  const propertyId = [27418, 26975, 26873, 26016, 25998, 25990, 25980, 28412];
  return propertyId.includes(listId);
}

export function propertyBedNames() {
  const bedRooms = [
    'baby bed',
    'double bed ',
    'option: baby bed and baby chair',
    'sofa bed',
    'Extra Bed',
    'sofabed',
    'single bed',
    'king size bed',
    'Bunk beds (sgl on top, dbl on bottom',
    'Pair of twin beds',
    'Bunk Bed',
    '2 single beds in the lounge',
    'Queen size bed',
    'day bed',
    'Wallbed',
    'Pull-Out Bed',
    'Canopy / Poster Bed',
    'Murphy Bed',
    'Rollaway Beds',
    'French bed',
    'Tribunk bed',
    'electric profiling bed',
  ];

  return bedRooms.map((item) =>
    item
      .replace(/\s/g, '')
      .replace(/[^a-zA-Z0-9]+/g, '')
      .toLowerCase()
  );
}

export function rentalBedNames() {
  return [
    'baby bed',
    'double bed ',
    'option: baby bed and baby chair',
    'sofa bed',
    'Extra Bed',
    'sofabed',
    'single bed',
    'king size bed',
    'Bunk beds (sgl on top, dbl on bottom',
    'Pair of twin beds',
    'Bunk Bed',
    '2 single beds in the lounge',
    'Queen size bed',
    'day bed',
    'Wallbed',
    'Pull-Out Bed',
    'Canopy / Poster Bed',
    'Murphy Bed',
    'Rollaway Beds',
    'French bed',
    'Tribunk bed',
    'electric profiling bed',
  ];
}

export function bookingStatus(status) {
  switch (status) {
    case 'inquiry':
      return 'Inquiry';
    case 'preApproved':
      return 'Pre Approved';
    case 'declined':
      return 'Declined';
    case 'approved':
      return 'Approved';
    case 'pending':
      return 'Pending';
    case 'cancelledByHost':
      return 'Cancelled by host';
    case 'cancelledByGuest':
      return 'Cancelled by guest';
    case 'intantBooking':
      return 'Instant Book';
    case 'confirmed':
      return 'Booking Confirmed';
    case 'expired':
      return 'Expired';
    case 'requestToBook':
      return 'Request to book';
    case 'completed':
      return 'Completed';
    case 'specialPriceInquiry':
      return 'Special price request';
    case 'specialPriceHostAccepted':
      return 'Special price request approved';
    case 'specialPriceHostDeclined':
      return 'Special price request declined';
    case 'specialPriceHostRequest':
      return 'counter-offer';
    case 'specialPriceGuestDeclined':
      return 'counter-offer declined';
    case 'specialPriceGuestAccepted':
      return 'counter-offer accepted';
    default:
      return '';
  }
}

export function fileFormates() {
  return [
    'image/svg+xml',
    'application/sql',
    'application/pdf',
    'application/vnd.oasis.opendocument.presentation',
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/epub+zip',
    'application/zip',
    'text/plain',
    'application/rtf',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.spreadsheet',
    'text/tab-separated-values',
    'image/webp',
    'image/heic',
    'image/heif',
  ];
}
