import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Footer from '../Footer';
import Header from '../Header';
import SubnavBar from '../SubnavBar';

class UserLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { account } = this.props;
    const { isAdminAuthenticated, email } = this.props;

    let isProfileUpdated;
    let showBanner;
    let isVerified;
    let userType;
    let isStatus = false;
    let profilePhoto;
    let step1;
    let step2;
    let step3;
    let step4;

    if (account) {
      isProfileUpdated = account ? account.isProfileUpdated : true;
      userType = account.userType;
      isVerified = account.memberRecords && account.memberRecords.isVerified;
      profilePhoto = account.picture;
      step1 = account.step1 === 'completed';
      step2 = account.step2 === 'completed';
      step3 = account.step3 === 'completed';
      step4 = account.step4 === 'completed';
    }

    if (
      (isVerified &&
        profilePhoto &&
        isProfileUpdated &&
        userType === 'memberForm') ||
      isAdminAuthenticated
    ) {
      isStatus = true;
    } else if (
      (isProfileUpdated &&
        profilePhoto &&
        (userType === 'inviteForm' || !userType)) ||
      isAdminAuthenticated
    ) {
      isStatus = true;
    }

    return (
      <div>
        <Header />
        {/* {
          isStatus && <SubnavBar
            accountData={account}
          />
        } */}
        {((step1 && step2 && step3 && step4) ||
          (isAdminAuthenticated && email)) && (
          <SubnavBar accountData={account} />
        )}
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

const mapState = (state) => ({
  // isProfileUpdated: state.account.data.isProfileUpdated,
  isAdminAuthenticated: state.runtime.isAdminAuthenticated,
  account: state.account.data,
});

const mapDispatch = {};
export default connect(mapState, mapDispatch)(UserLayout);
