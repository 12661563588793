import React, { Component } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import {
  closeForgotPasswordModal,
  openLoginModal,
} from '../../actions/modalActions';
import messages from '../../locale/messages';

import s from './ForgotPassword.module.css';
import ForgotPasswordForm from './ForgotPasswordForm';

class ForgotPassword extends Component {
  static propTypes = {
    closeForgotPasswordModal: PropTypes.func,
    forgotModal: PropTypes.bool,
    openLoginModal: PropTypes.func,
    formatMessage: PropTypes.func,
  };

  render() {
    const {
      closeForgotPasswordModal,
      openLoginModal,
      forgotModal,
    } = this.props;

    return (
      <div>
        <Modal
          animation={false}
          dialogClassName={cx(s.logInModalContainer, 'loginModal')}
          onHide={closeForgotPasswordModal}
          show={forgotModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage {...messages.forgotPassword} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={s.logInModalBody}>
            <div className={s.root}>
              <ForgotPasswordForm openLoginModal={openLoginModal} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => ({
  forgotModal: state.modalStatus.isForgotPasswordOpen,
});

const mapDispatch = {
  closeForgotPasswordModal,
  openLoginModal,
};

export default connect(mapState, mapDispatch)(ForgotPassword);
