import React, { Component } from 'react';

import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import * as FontAwesome from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import messages from '../../locale/messages';
import NavLink from '../NavLink';

import s from './GoSocial.module.css';

const UnreadSocialCount = loader('./getSocialCount.graphql');

class Message extends Component {
  static propTypes = {
    allSocialcount: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      getSocialCount: PropTypes.shape({
        socialCount: PropTypes.number.isRequired,
      }),
    }),
  };

  static defaultProps = {
    allSocialcount: {
      loading: true,
      getSocialCount: {
        socialCount: 0,
      },
    },
  };

  render() {
    const {
      allSocialcount: { loading, getSocialCount },
    } = this.props;
    let count = 0;
    if (!loading && getSocialCount) {
      count = getSocialCount.socialCount ? getSocialCount.socialCount : 0;
    }
    const isNewMessage = count > 0 ? count : '';
    return (
      <NavLink to="/social">
        <span>
          <FormattedMessage {...messages.goSocial} />
        </span>{' '}
        {isNewMessage && <sup className={s.count}>{isNewMessage}</sup>}
      </NavLink>
    );
  }
}

export default compose(
  graphql(UnreadSocialCount, {
    name: 'allSocialcount',
    options: {
      ssr: false,
      pollInterval: 1000,
    },
  })
)(Message);
