import { loader } from 'graphql.macro';

import {
  GET_USER_NOTIFICATON_ERROR,
  GET_USER_NOTIFICATON_START,
  GET_USER_NOTIFICATON_SUCCESS,
} from '../../constants';
import { setLoaderComplete, setLoaderStart } from '../loader/loader';

const query = loader('./getAllNotification.graphql');

export const getAllUserNotification = (requestData) => async (
  dispatch,
  getState,
  { client }
) => {
  let { currentPage, hideLoader, changeState } = requestData || {};
  try {
    let notificationData =
      (getState().blogContentExplore &&
        getState().blogContentExplore.notification &&
        getState().blogContentExplore.notification.data) ||
      [];
    notificationData = JSON.parse(JSON.stringify(notificationData));

    if (!currentPage) currentPage = 1;
    if (currentPage === 1) {
      if (changeState) changeState({ havingData: true });
      notificationData = [];
    }

    if (!hideLoader) dispatch(setLoaderStart('getSocialNotificationLoading'));
    // dispatch({ type: GET_USER_NOTIFICATON_START });
    const notifications = await client.query({
      query,
      variables: { currentPage },
      fetchPolicy: 'network-only',
    });
    const { data } = (await notifications) || {};
    const { errorMessage, status, results } =
      (data && data.getAllNotification) || {};
    if (status !== 200 || !(results && results.length > 0)) {
      if (changeState) {
        changeState({ havingData: false });
      }
    } else {
      if (changeState) changeState({ currentPage });
      results.map((item) => {
        let isDataFound = notificationData.some((data) => data.id === item.id);
        if (!isDataFound) notificationData = [...notificationData, item];
      });
    }
    dispatch({ type: GET_USER_NOTIFICATON_SUCCESS, payload: notificationData });
    dispatch(setLoaderComplete('getSocialNotificationLoading'));
    if (changeState) changeState({ loader: false });
  } catch (error) {
    dispatch(setLoaderComplete('getSocialNotificationLoading'));
    if (changeState) changeState({ loader: false });
    console.log(error);
    return false;
  }
  return true;
};
