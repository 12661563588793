import { SubmissionError } from 'redux-form';

import { closeReferralModal } from '../../actions/modalActions';
import { backendUrl } from '../../config';
import fetch from '../../core/fetch';
import messages from '../../locale/messages';

async function submit(values, dispatch) {
  const referralKey = values.referralCode;
  const pageType = 'linkedIn';

  const query = `query($referralKey: String, $pageType: String){
          getValidReferralKey(referralKey: $referralKey, pageType: $pageType) {
            userId
            profileId
            firstName
            lastName
            referralKey
            status
            uniqueReferralKey
          }
        }`;

  const variables = {
    referralKey,
    pageType,
  };

  const resp = await fetch(`${backendUrl}/graphql`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
    }),
    credentials: 'include',
  });

  const { data } = await resp.json();
  if (
    data &&
    data.getValidReferralKey &&
    data.getValidReferralKey.referralKey
  ) {
    let LinkedinURL;
    dispatch(closeReferralModal());
    // if (data.getValidReferralKey.referralKey) {
    //   LinkedinURL = '/login/linkedin?ref=' + data.getValidReferralKey.referralKey;
    // }
    if (data.getValidReferralKey.uniqueReferralKey) {
      LinkedinURL = `${backendUrl}/login/linkedin?ref=${data.getValidReferralKey.uniqueReferralKey}`;
    }
    window.location = LinkedinURL;
  } else if (
    data &&
    data.getValidReferralKey &&
    data.getValidReferralKey.status === 'limitExpired'
  ) {
    throw new SubmissionError({ _error: messages.referralCodeLimitExceeded });
  } else if (
    data &&
    data.getValidReferralKey &&
    data.getValidReferralKey.status === 'invalidReferral'
  ) {
    throw new SubmissionError({ _error: messages.referralCodeInvalid });
  } else {
    throw new SubmissionError({ _error: messages.referralCodeInvalid });
  }
}

export default submit;
