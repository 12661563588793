import { SET_PERSONALIZED_VALUES } from '../constants';

export default function personalized(state = {}, action = {}) {
  switch (action.type) {
    case SET_PERSONALIZED_VALUES: {
      const { name, value, bulkData } = action.payload;
      return {
        ...state,
        ...(bulkData || { [name]: value }),
      };
    }
    default:
      return state;
  }
}
