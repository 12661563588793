import React from 'react';

import { url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class GoSoloViewTripClick extends React.Component {
  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const {
      content: {
        guestFirstName,
        guestProfileId,
        guestEmail,
        roomType,
        goSoloId,
        title,
        checkIn,
      },
    } = this.props;

    const guestProfile = `${url}/users/show/${guestProfileId}`;
    const goSoloActionURL = `${url}/view-trip/${goSoloId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          <div>
            <a href={guestProfile} style={linkText}>
              {guestFirstName}
              {guestEmail ? ` (${guestEmail})` : ''}
            </a>{' '}
            has clicked through on the {roomType} for the{' '}
            <a href={goSoloActionURL} style={linkText}>
              {title}
            </a>{' '}
            trip on {checkIn}
          </div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default GoSoloViewTripClick;
