import {
  YOUR_LISTINGS_START,
  YOUR_LISTINGS_SUCCESS,
  YOUR_LISTINGS_ERROR,
} from '../constants';

export default function yourListings(state = {}, action) {
  switch (action.type) {
    case YOUR_LISTINGS_START:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case YOUR_LISTINGS_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        data: action.data,
      };

    case YOUR_LISTINGS_ERROR:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    default:
      return state;
  }
}
