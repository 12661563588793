/* eslint-disable import/prefer-default-export */
import { SET_PERSONALIZED_VALUES } from '../constants';

export function setPersonalizedValues({ name, value, bulkData }) {
  return {
    type: SET_PERSONALIZED_VALUES,
    payload: {
      name,
      value,
      bulkData,
    },
  };
}
