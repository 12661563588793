import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedNumber, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { convert } from '../../helpers/currencyConvertion';

class CurrencyConverter extends Component {
  static propTypes = {
    from: PropTypes.string,
    amount: PropTypes.number,
    base: PropTypes.string,
    rates: PropTypes.object,
    superSymbol: PropTypes.bool,
    className: PropTypes.string,
    toCurrency: PropTypes.string,
  };

  static defaultProps = {
    amount: 0,
    superSymbol: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      base: null,
      rates: null,
    };
  }

  UNSAFE_componentWillMount() {
    const { base, rates } = this.props;
    if (base && rates) {
      this.setState({ base, rates });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { base, rates } = nextProps;
    if (base && rates) {
      this.setState({ base, rates });
    }
  }

  render() {
    const {
      from,
      amount,
      superSymbol,
      className,
      toCurrency,
      locale,
      isCreditApplied,
      roundAmount,
      isRounded,
    } = this.props;
    const { base, rates } = this.state;
    let targetCurrency;
    let convertedAmount = amount;
    const fromCurrency = from || base;

    if (rates)
      convertedAmount = convert(base, rates, amount, fromCurrency, toCurrency);

    targetCurrency = toCurrency || base;
    if (isRounded) convertedAmount = Math.round(convertedAmount);

    return (
      <span className={className}>
        <FormattedNumber
          currency={targetCurrency}
          currencyDisplay="symbol"
          maximumFractionDigits={convertedAmount % 1 === 0 ? 0 : 2}
          minimumFractionDigits={convertedAmount % 1 === 0 ? 0 : 2}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          value={convertedAmount}
        />
        {superSymbol && <sup>{` ${targetCurrency}`}</sup>}
        {isCreditApplied && <p>Travel credit applied</p>}
      </span>
    );
  }
}

const mapState = (state) => ({
  base: state.currency.base,
  toCurrency: state.currency.to,
  rates: state.currency.rates,
  locale: state.intl.locale,
});

const mapDispatch = {};

export default injectIntl(connect(mapState, mapDispatch)(CurrencyConverter));
