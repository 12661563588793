import React, { Component } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../locale/messages';
import NoTransaction from '../NoTransaction';
import s from '../Transaction.module.css';

import CompletedTransactionItem from './CompletedTransactionItem';
import CompletedTransactionPayout from './CompletedTransactionPayout';

class CompletedTransactions extends Component {
  static propTypes = {
    formatMessage: PropTypes.func,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        checkIn: PropTypes.string.isRequired,
        checkOut: PropTypes.string.isRequired,
        confirmationCode: PropTypes.number.isRequired,
        listData: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }),
        guestData: PropTypes.shape({
          firstName: PropTypes.string.isRequired,
        }),
        hostTransaction: PropTypes.shape({
          payoutId: PropTypes.number,
          payEmail: PropTypes.string,
          amount: PropTypes.number,
          currency: PropTypes.string,
          createdAt: PropTypes.string,
        }),
      })
    ),
  };

  static defaultProps = {
    data: [],
  };

  render() {
    const { data } = this.props;
    const completeData = [];
    if (data.length > 0) {
      data.forEach((item) => {
        if (item.hostTransaction && item.hostTransaction) {
          completeData.push(item.hostTransaction);
        }
        completeData.push(item);
      });
    }
    return (
      <div className={cx('table-responsive', s.noBorder)}>
        <table className={cx('table', s.noBorder)}>
          <thead>
            <tr className={s.rowBorder}>
              <th className={cx(s.labelText, s.noBorder)}>
                <FormattedMessage {...messages.transferDate} />
              </th>
              <th className={cx(s.labelText, s.noBorder)}>
                <FormattedMessage {...messages.transferType} />
              </th>
              <th className={cx(s.labelText, s.noBorder)}>
                <FormattedMessage {...messages.details} />
              </th>
              <th className={cx(s.labelText, s.noBorder)}>
                <FormattedMessage {...messages.transferAmount} />
              </th>
              <th className={cx(s.labelText, s.noBorder)}>
                <FormattedMessage {...messages.paidOut} />
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              completeData.map((item, index) => {
                if (item.checkIn) {
                  return (
                    <CompletedTransactionItem
                      key={index}
                      className={s.labelText}
                      data={item}
                    />
                  );
                }
                return (
                  <CompletedTransactionPayout
                    key={index}
                    className={s.labelText}
                    data={item}
                  />
                );
              })}
          </tbody>
        </table>
        {data.length === 0 && <NoTransaction />}
      </div>
    );
  }
}

export default CompletedTransactions;
