import React from 'react';

import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { payment } from '../config';

import AsyncStripeProvider from './AsyncStripeProvider';

class App extends React.PureComponent {
  render() {
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    const { initialNow, locale, messages } = this.props.intl || {};
    const localeMessages = (messages && messages[locale]) || {};

    return (
      <AsyncStripeProvider apiKey={payment.stripe.publishableKey}>
        <IntlProvider
          defaultLocale="en-US"
          initialNow={initialNow}
          locale={locale}
          messages={localeMessages}
          onError={() => {}}
          textComponent="span"
        >
          <>{this.props.children}</>
        </IntlProvider>
      </AsyncStripeProvider>
    );
  }
}

export default connect((state) => ({ intl: state.intl }))(App);
