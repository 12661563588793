import React, { Component } from 'react';

import cx from 'classnames';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { compose, gql, graphql } from 'react-apollo';
import { Button, Col, FormControl, FormGroup } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm, reset } from 'redux-form';

import messages from '../../../locale/messages';

import s from './CreateWishList.module.css';

const getAllWishListGroupQuery = loader('../getAllWishListGroup.graphql');

class CreateWishList extends Component {
  static propTypes = {
    formatMessage: PropTypes.func,
    listId: PropTypes.number,
    getListingData: PropTypes.shape({
      loading: PropTypes.bool,
      UserListing: PropTypes.object,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      shown: true,
      disabled: true,
    };
    this.toggle = this.toggle.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggle() {
    this.setState({
      shown: !this.state.shown,
    });
  }

  onChange(e) {
    if (e.target.value && e.target.value.trim() !== '') {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  }

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
    placeholder,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(s.formGroup, 'row')}>
        <Col lg={12} md={12} sm={12} xs={12}>
          {touched && error && (
            <span className={s.errorMessage}>{formatMessage(error)}</span>
          )}
          <FormControl
            {...input}
            className={className}
            placeholder={placeholder}
            type={type}
          />
        </Col>
      </FormGroup>
    );
  };

  async submitForm(values, dispatch) {
    const {
      mutate,
      profileId,
      wishListGroups,
      change,
      createWishListGroup,
      createWishList,
      listId,
    } = this.props;
    if (values.name.trim() !== '') {
      const updatedWishLists = wishListGroups;
      const { data } = await createWishListGroup({
        variables: values,
        /* refetchQueries: [{
                    query: getAllWishListGroupQuery,
                    variables: {
                        profileId
                    }
                }] */
      });

      if (data && data.CreateWishListGroup) {
        if (data.CreateWishListGroup.status === 'success') {
          this.setState({
            shown: !this.state.shown,
          });
          const {
            data: { CreateWishList },
          } = await createWishList({
            variables: {
              listId,
              wishListGroupsId: data.CreateWishListGroup.id,
              eventKey: true,
            },
            refetchQueries: [
              {
                query: getAllWishListGroupQuery,
                variables: {
                  profileId,
                },
              },
            ],
          });
          await updatedWishLists.push(data.CreateWishListGroup.id);
          await change('WishListModalForm', 'wishListGroups', updatedWishLists);
          dispatch(reset('CreateWishList'));
        }
      }
    }
  }

  render() {
    const { error, handleSubmit, submitting, dispatch, listId } = this.props;
    const { formatMessage } = this.props.intl;
    const { disabled, shown } = this.state;

    return (
      <form
        autoComplete="off"
        className={s.formContainer}
        onSubmit={handleSubmit(this.submitForm)}
      >
        {shown && (
          <a
            className={cx(
              s.modalCaptionLink,
              s.labelText,
              s.pullRight,
              s.noMargin,
              s.paddingRight
            )}
            onClick={this.toggle}
          >
            <FormattedMessage {...messages.createWishList} />
          </a>
        )}
        {!shown && (
          <div>
            <h3 className={s.labelText}>
              <FormattedMessage {...messages.name} />
            </h3>
            <Field
              className={cx(s.formControlInput, s.space1)}
              component={this.renderFormControl}
              label={formatMessage(messages.name)}
              name="name"
              onChange={this.onChange}
              placeholder={formatMessage(messages.nameYourList)}
              type="text"
            />
            <FormGroup className={cx(s.spaceTop3, s.formGroup, 'text-right')}>
              <Button
                className={cx(s.button, s.btnPrimaryBorder)}
                disabled={submitting}
                onClick={this.toggle}
              >
                {formatMessage(messages.cancel)}
              </Button>
              <Button
                className={cx(s.button, s.btnPrimary, s.marginLeft)}
                disabled={disabled}
                type="submit"
              >
                {formatMessage(messages.save)}
              </Button>
            </FormGroup>
          </div>
        )}
      </form>
    );
  }
}

const createWishList = reduxForm({
  form: 'CreateWishList', // a unique name for this form
})(CreateWishList);

const selector = formValueSelector('WishListModalForm');

const mapState = (state) => ({
  listId: state.modalStatus.listId,
  profileId: state.account.data.profileId,
  wishListGroups: selector(state, 'wishListGroups'),
});

const mapDispatch = {
  change,
};

export default compose(
  injectIntl,
  connect(mapState, mapDispatch),
  graphql(
    gql`
      mutation CreateWishListGroup($name: String!, $isPublic: String) {
        CreateWishListGroup(name: $name, isPublic: $isPublic) {
          status
          id
        }
      }
    `,
    {
      name: 'createWishListGroup',
    }
  ),
  graphql(
    gql`
      mutation CreateWishList(
        $listId: Int!
        $wishListGroupsId: Int
        $eventKey: Boolean
      ) {
        CreateWishList(
          listId: $listId
          wishListGroupId: $wishListGroupsId
          eventKey: $eventKey
        ) {
          status
        }
      }
    `,
    {
      name: 'createWishList',
    }
  )
)(createWishList);
