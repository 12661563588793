import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../locale/messages';
import CurrencyConverter from '../../CurrencyConverter';
import Link from '../../Link';

class GrossEarningItem extends Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    formatMessage: PropTypes.func,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      guestServiceFee: PropTypes.number.isRequired,
      hostServiceFee: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      listData: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      guestData: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
      }).isRequired,
      hostTransaction: PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
      }).isRequired,
    }),
  };

  render() {
    const { className, data } = this.props;
    const date = data.hostTransaction
      ? moment(data.hostTransaction.createdAt).format('DD-MM-YYYY')
      : 'Pending';
    const checkInDate = data.checkIn
      ? moment(data.checkIn).format('MMM DD, YYYY')
      : '';
    const checkOutDate = data.checkOut
      ? moment(data.checkOut).format('MMM DD, YYYY')
      : '';
    const totalAmount =
      Math.ceil(data.total) - Math.ceil(data.hostServiceFee.toFixed(2));

    return (
      <tr>
        <td className={className}>{date}</td>
        <td className={className}>
          <FormattedMessage {...messages.reservation} />
        </td>
        <td className={className}>
          <p>
            {checkInDate} - {checkOutDate}{' '}
            <Link to={`/users/trips/receipt/${data.id}`}>
              {data.confirmationCode}
            </Link>
          </p>
        </td>
        <td className={className}>
          <CurrencyConverter amount={totalAmount} from={data.currency} />
        </td>
        <td className={className} />
      </tr>
    );
  }
}

export default GrossEarningItem;
