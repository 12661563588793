import React, { Component } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Col, FormControl, FormGroup } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { openForgotPasswordModal } from '../../actions/modalActions';
import messages from '../../locale/messages';
import Loader from '../Loader';

import s from './Login.module.css';
import submit from './submit';
import validate from './validate';

class LoginForm extends Component {
  static propTypes = {
    openForgotPasswordModal: PropTypes.func.isRequired,
    formatMessage: PropTypes.func,
  };

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
        <FormControl
          {...input}
          className={className}
          placeholder={label}
          type={type}
        />
      </div>
    );
  };

  render() {
    const {
      error,
      handleSubmit,
      submitting,
      dispatch,
      loading,
      submitSucceeded,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { openForgotPasswordModal } = this.props;

    return (
      <form onSubmit={handleSubmit(submit)}>
        {error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}

        <FormGroup className={s.formGroup}>
          <Field
            className={s.formControlInput}
            component={this.renderFormControl}
            label={formatMessage(messages.email)}
            name="email"
            type="text"
          />
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Field
            className={s.formControlInput}
            component={this.renderFormControl}
            label={formatMessage(messages.password)}
            name="password"
            type="password"
          />
        </FormGroup>

        <FormGroup className={s.formGroup}>
          {/* <Button className={cx(s.button, s.btnPrimary)} bsSize="large" block type="submit" disabled={submitting}>
            {formatMessage(messages.login)}
          </Button> */}
          <Loader
            buttonType="submit"
            className={cx(s.button, s.btnPrimary)}
            disabled={submitting}
            label={formatMessage(messages.login)}
            show={loading || submitSucceeded}
            type="button"
          />
        </FormGroup>
        <FormGroup className={cx(s.formGroup, s.formSection)}>
          <Col
            className={cx(s.noPadding, s.textAlignCenter)}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <a className={s.modalCaptionLink} onClick={openForgotPasswordModal}>
              <FormattedMessage {...messages.cantLogin} />{' '}
              <FormattedMessage {...messages.resetPasswordHere} />
            </a>
          </Col>
        </FormGroup>
      </form>
    );
  }
}

const loginForm = reduxForm({
  form: 'LoginForm', // a unique name for this form
  validate,
  destroyOnUnmount: false,
})(LoginForm);

const mapState = (state) => ({
  loading: state.loader.loginForm,
});

const mapDispatch = {
  openForgotPasswordModal,
};

export default injectIntl(connect(mapState, mapDispatch)(loginForm));
