import { gql } from 'react-apollo';

import {
  GET_STRIPESTATE_ERROR,
  GET_STRIPESTATE_START,
  GET_STRIPESTATE_SUCCESS,
} from '../constants';

const query = gql`
  query($countryId: Int) {
    getStripeStates(countryId: $countryId) {
      id
      countryId
      stateName
      stateCode
      isEnable
    }
  }
`;

export function getStripeStates(countryId) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_STRIPESTATE_START,
    });

    try {
      const { data } = await client.query({
        query,
        variables: { countryId },
        fetchPolicy: 'network-only',
      });

      if (data && data.getStripeStates) {
        dispatch({
          type: GET_STRIPESTATE_SUCCESS,
          availableStates: data.getStripeStates,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_STRIPESTATE_ERROR,
      });
      return false;
    }

    return true;
  };
}
