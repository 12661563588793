import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import CurrencyConverter from '../../CurrencyConverter';
import Link from '../../Link';
import s from '../Transaction.module.css';

class CompletedTransactionItem extends Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      guestServiceFee: PropTypes.number.isRequired,
      hostServiceFee: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      listData: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      guestData: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
      }).isRequired,
      hostTransaction: PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
      }).isRequired,
    }),
  };

  render() {
    const { className, data } = this.props;
    const date = data.hostTransaction
      ? moment(data.hostTransaction.createdAt).format('DD-MM-YYYY')
      : 'Pending';
    const checkInDate = data.checkIn
      ? moment(data.checkIn).format('MMM DD, YYYY')
      : '';
    const checkOutDate = data.checkOut
      ? moment(data.checkOut).format('MMM DD, YYYY')
      : '';
    const totalAmount =
      Math.ceil(data.total) - Math.ceil(data.hostServiceFee.toFixed(2));
    return (
      <tr>
        <td className={className}>{date}</td>
        <td className={className}>Reservation</td>
        <td className={className}>
          <ul className={s.listLayout}>
            <li>
              {checkInDate} - {checkOutDate}{' '}
              <Link to={`/users/trips/receipt/${data.id}`}>
                {data.confirmationCode}
              </Link>
            </li>
            <li>{data.guestData ? data.guestData.firstName : ''}</li>
            <li>{data.listData ? data.listData.title : ''}</li>
          </ul>
        </td>
        <td className={className}>
          <CurrencyConverter
            amount={totalAmount}
            className={s.currencyColor}
            from={data.currency}
          />
        </td>
        <td className={className} />
      </tr>
    );
  }
}

export default CompletedTransactionItem;
