import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { compose, gql, graphql } from 'react-apollo';
import * as FontAwesome from 'react-icons/fa';

import diamondIcon from '../../assets/MemberShip/Image-3.png';
import { backendUrl, cloudFrontUrl } from '../../config';
import Link from '../Link';

import s from './Avatar.module.css';

class Avatar extends React.Component {
  constructor() {
    super();

    this.state = {
      fallbackCount: 0,
    };

    this.incrementFallback = this.incrementFallback.bind(this);
    this.handleFallback = this.handleFallback.bind(this);
  }

  static propTypes = {
    source: PropTypes.string,
    title: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string,
    withLink: PropTypes.bool,
    profileId: PropTypes.number,
    linkClassName: PropTypes.string,
    profilePictureData: PropTypes.shape({
      loading: PropTypes.bool,
      account: PropTypes.shape({
        picture: PropTypes.string,
      }),
    }),
    isUser: PropTypes.bool,
    type: PropTypes.string,
    staticImage: PropTypes.bool,
  };

  static defaultProps = {
    source: null,
    height: 100,
    width: 100,
    profileId: null,
    withLink: false,
    profilePictureData: {
      loading: true,
      userAccount: {
        picture: null,
        verification: {
          isEmailConfirmed: false,
        },
      },
    },
    isUser: false,
    type: 'medium',
    staticImage: false,
  };

  incrementFallback = () => {
    this.setState({ fallbackCount: this.state.fallbackCount + 1 });
  };

  handleFallback = (e, imgSource, defaultPic) => {
    e.target.onerror = null;
    if (!this.state.fallbackCount) {
      this.incrementFallback();
      e.target.src = `${cloudFrontUrl + imgSource}`;
    } else {
      e.target.src = `${cloudFrontUrl + defaultPic}`;
    }
  };

  render() {
    const {
      source,
      title,
      height,
      width,
      myProfile,
      editphoto,
      className,
      withLink,
      linkClassName,
      profileId,
      profilePictureData: { loading, userAccount },
      getUserAvatar,
      isUser,
      type,
      staticImage,
      editProfileDisable,
      checkUserId,
      isDiamond,
      daimondClass,
    } = this.props;
    const isEmailConfirmed =
      userAccount &&
      userAccount.verification &&
      userAccount.verification.isEmailConfirmed
        ? userAccount.verification.isEmailConfirmed
        : false;
    const path = `/avatar/${type}_`;
    const defaultPic = '/defaultPic1.png';
    let imgSource = defaultPic;

    if (isUser && isEmailConfirmed) {
      if (staticImage) {
        imgSource = source || defaultPic;
      } else if (!loading && userAccount) {
        imgSource = userAccount.picture
          ? path + userAccount.picture
          : defaultPic;
      }
    } else if (staticImage) {
      imgSource = source || defaultPic;
    } else {
      imgSource = source ? path + source : defaultPic;
    }

    let profileImage = backendUrl + imgSource;

    if (imgSource.includes('http')) {
      profileImage = imgSource.replace(path, '');
    }

    if (withLink && profileId) {
      return (
        <div>
          {checkUserId !== 'c505e590-f3bc-11e9-bdc7-0913579b3abc' && (
            <Link className={linkClassName} to={`/users/show/${profileId}`}>
              <img
                alt={title}
                className={cx(s.imgBackground, className)}
                height={height}
                onError={(e) => this.handleFallback(e, imgSource, defaultPic)}
                src={profileImage}
                width={width}
              />
              {isDiamond && (
                <img className={daimondClass} src={`${diamondIcon}`} />
              )}
            </Link>
          )}
          {checkUserId === 'c505e590-f3bc-11e9-bdc7-0913579b3abc' && (
            <div className={linkClassName}>
              <img
                alt={title}
                className={cx(s.imgBackground, className)}
                height={height}
                onError={(e) => this.handleFallback(e, imgSource, defaultPic)}
                src={profileImage}
                width={width}
              />
              {isDiamond && (
                <img className={daimondClass} src={`${diamondIcon}`} />
              )}
            </div>
          )}
        </div>
      );
    }
    if (editphoto && myProfile && !editProfileDisable) {
      return (
        <div className={linkClassName}>
          <Link
            className={cx(s.editProfilePic, s.editProfilePicMarker)}
            to="/user/photo/"
          >
            <FontAwesome.FaEdit className={s.profileIconSize} />
          </Link>
          <Link className={s.displayBlock} to="/user/photo/">
            <img
              alt={title}
              className={cx(s.imgBackground, className)}
              height={height}
              onError={(e) => this.handleFallback(e, imgSource, defaultPic)}
              src={profileImage}
              width={width}
            />
          </Link>
          {isDiamond && <img className={daimondClass} src={`${diamondIcon}`} />}
        </div>
      );
    }
    return (
      <div className={linkClassName}>
        <img
          alt={title}
          className={cx(s.imgBackground, className)}
          height={height}
          onError={(e) => this.handleFallback(e, imgSource, defaultPic)}
          src={profileImage}
          width={width}
        />
        {isDiamond && <img className={daimondClass} src={`${diamondIcon}`} />}
      </div>
    );
  }
}

export default compose(
  graphql(
    gql`
      query {
        userAccount {
          id
          picture
          verification {
            id
            isEmailConfirmed
          }
        }
      }
    `,
    {
      name: 'profilePictureData',
      options: (props) => ({
        skip: !props.isUser,
        ssr: false,
      }),
    }
  ),
  graphql(
    gql`
      query {
        getUserAvatar {
          picture
        }
      }
    `,
    {
      name: 'getUserAvatar',
      options: (props) => ({
        ssr: false,
        skip: !props.isUser,
        fetchPolicy: 'network-only',
      }),
    }
  )
)(Avatar);
