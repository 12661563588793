import { gql } from 'react-apollo';

import {
  GET_PROPERTY_SERVICE_FEES_ERROR,
  GET_PROPERTY_SERVICE_FEES_START,
  GET_PROPERTY_SERVICE_FEES_SUCCESS,
} from '../../constants';

const query = gql`
  query getPropertyServiceFees {
    getPropertyServiceFees {
      id
      guestPropertyType
      guestPropertyValue
      hostPropertyType
      hostPropertyValue
      currency
      status
    }
  }
`;

export function getPropertyServiceFees() {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_PROPERTY_SERVICE_FEES_START,
    });

    try {
      const { data } = await client.query({ query });

      if (data && data.getPropertyServiceFees) {
        const host = {
          type: data.getPropertyServiceFees.hostPropertyType,
          value: data.getPropertyServiceFees.hostPropertyValue,
          currency: data.getPropertyServiceFees.currency,
        };

        dispatch({
          type: GET_PROPERTY_SERVICE_FEES_SUCCESS,
          payload: {
            propertyServiceFees: { host },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: GET_PROPERTY_SERVICE_FEES_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }

    return true;
  };
}
