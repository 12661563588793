/* eslint-disable import/prefer-default-export */

import { SET_USER_TOKEN_DATA } from '../constants';

export function setUser(payload) {
  return {
    type: SET_USER_TOKEN_DATA,
    payload,
  };
}
