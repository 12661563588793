export function getSubject(type, content) {
  let subject;
  let previewText;

  if (type === 'welcomeEmail' || type === 'confirmEmail') {
    subject = 'Please confirm your email address';
    previewText = 'Action Required! Confirm your email';
  }
  if (type === 'memberDetails') {
    subject = 'Interested to Join in Golightly member';
    previewText = 'Register through membership form';
  }
  if (type === 'formalRegisterAdminEmail') {
    subject = 'Sign Up for More Info';
    previewText = 'Register through Formal Registration form';
  }
  if (type === 'formalRegisterMemberEmail') {
    subject = 'Thank you for your interest in Golightly!';
    previewText = 'Thank you for your interest in Golightly!';
  }
  if (type === 'maleUserContent') {
    subject = 'Golightly - Registration Status';
    previewText = 'Golightly - Registration Status';
  }
  if (type === 'newUserEmail') {
    subject = 'New User Details - Linkedin signup';
    previewText = 'Great News! You have a new user';
  }
  if (type === 'linkedinFemaleUserEmail') {
    subject = 'Female User Details - Linkedin signup';
    previewText = 'Great News! You have a new female user';
  }
  if (type === 'referralInvite') {
    subject = 'You have been invited to join Golightly';
    previewText = 'Your Invitation Link inside';
  }
  if (type === 'memberDecline') {
    subject = 'Your Golightly request has been declined.';
    previewText = 'Your Golightly request has been declined.';
  }
  if (type === 'normalFemaleUserEmail') {
    let { gender } = content || {};
    let status = gender === 'male' ? 'Male' : 'Female';
    subject = `${status} User Details - Email signup`;
    previewText = `Great News! You have a new ${status.toLocaleLowerCase()} user`;
  }
  if (type === 'bookingRequest') {
    subject = 'New Reservation on Golightly.';
    previewText = 'Great News! New Reservation on Golightly.';
  }
  if (type === 'bookingRequestGuest') {
    subject = 'Your Golightly reservation request has been sent.';
    previewText = 'Great News! Your reservation is shared with host';
  }
  if (type === 'bookingConfirmedToHost') {
    subject = 'New Booking on Golightly';
    previewText = 'Confirmed Reservation Details';
  }
  if (type === 'bookingConfirmedToGuest') {
    subject = 'Your Golightly reservation is confirmed.';
    previewText = 'Pack your bag, you are ready for your trip!';
  }
  if (type === 'bookingDeclinedToGuest') {
    subject = 'Your Golightly reservation has been declined.';
    previewText = 'We are sorry, your request is declined';
  }
  if (type === 'bookingExpiredGuest') {
    subject = 'Your Golightly reservation request has expired.';
    previewText = 'We are sorry, your request is expired';
  }
  if (type === 'bookingExpiredHost') {
    subject = 'Your Golightly reservation has expired.';
    previewText = 'Your Golightly reservation has expired.';
  }
  if (type === 'cancelledByHost') {
    subject = 'Your Golightly reservation has been cancelled by the host.';
    previewText = 'Your reservation is cancelled';
  }
  if (type === 'cancelledByGuest') {
    subject = 'Your Golightly reservation has been cancelled by the guest.';
    previewText = 'Your reservation is cancelled';
  }
  if (type === 'completedGuest') {
    subject = 'Please write a review for your Golightly hostess.';
    previewText = 'Action Required! Write a Review';
  }
  if (type === 'completedHost') {
    subject = 'Please write a review for your Golightly guest.';
    previewText = 'Action Required! Write a Review';
  }
  if (type === 'forgotPasswordLink') {
    subject = 'Please reset your Golightly password.';
    previewText = 'Action Required! Reset your Password';
  }
  if (type === 'message' || type === 'privateMessageRequest') {
    subject = 'You have received a new message on Golightly.';
    previewText = 'Someone sent you a new message!';
  }
  if (type === 'inquiry') {
    subject = 'You have a new Golightly inquiry.';
    previewText = 'Someone sent you an inquiry from contact form!';
  }
  if (type === 'documentVerification') {
    subject = 'Golightly Document Verification';
    previewText = 'Golightly Document Verification';
  }
  if (type === 'contact') {
    subject = 'Contact Form';
    previewText = 'Contact Form';
  }
  if (type === 'reportUser') {
    subject = 'Reporting the User';
    previewText = 'Report User Form';
  }
  if (type === 'requestConnection') {
    subject = 'You Have a New Golightly Connection Request!';
    previewText = 'You Have a New Golightly Connection Request!';
  }
  if (type === 'requestConnectionAdmin') {
    subject = 'You Have a New Golightly Connection Request!';
    previewText = 'You Have a New Golightly Connection Request!';
  }
  if (type === 'acceptedMutualConnection') {
    subject = 'You Have a New Golightly Connection!';
    previewText = 'You Have a New Golightly Connection!';
  }
  if (type === 'remindPhotoUpload') {
    subject = 'You Have a New Golightly Reminder!';
    previewText = 'You Have a New Golightly Reminder!';
  }
  if (type === 'listPublishRequest') {
    subject = 'You have New Listing submission for Approval';
    previewText = 'You have New Listing submission for Approval';
  }
  if (type === 'adminListApprove') {
    subject = 'Your Listing submission is Approved';
    previewText = 'Your Listing submission is Approved';
  }
  if (type === 'adminListingApproved') {
    subject = 'User Listing has been Approved';
    previewText = 'User Listing has been Approved';
  }
  if (type === 'adminListReject') {
    subject = 'Your Listing submission is Rejected';
    previewText = 'Your Listing submission is Rejected';
  }
  if (type === 'adminNewProperty') {
    subject = 'Admin has added new property';
    previewText = 'Admin has added new property';
  }
  if (type === 'checkInSafeHost') {
    subject = 'Your guest has checked in safely';
    previewText = 'Your guest has checked in safely';
  }
  if (type === 'checkInSafeGuest') {
    subject = 'Your have been checked in safely';
    previewText = 'Your have been checked in safely';
  }
  if (type === 'checkInSafeAdmin') {
    subject = 'Your guest has checked in safely';
    previewText = 'Your guest has checked in safely';
  }
  if (type === 'claimSecurityFee') {
    subject = 'Your Host has claimed Security Deposit';
    previewText = 'Your Host has claimed Security Deposit';
  }
  if (type === 'deleteHosfullyProperty') {
    subject = 'Property Unpublished from Golightly!';
    previewText = 'Property Unpublished from Golightly!';
  }
  if (type === 'propertyManagerInvite') {
    subject = 'You have been added as a Property Manager on Golightly';
    previewText = 'You have been added as a Property Manager on Golightly';
  }
  if (type === 'secondaryEmailInvite') {
    subject = 'You have been added as a secondary email';
    previewText = 'You have been added as a secondary email';
  }
  if (type === 'idProofVerification') {
    subject = 'Golightly ID proof Verification';
    previewText = 'Golightly ID proof Verification';
  }
  if (type === 'claimSecurityFeeHost') {
    subject = 'You have claimed Security Deposit';
    previewText = 'Your have claimed Security Deposit';
  }
  if (type === 'claimSecurityFeeGuest') {
    subject = 'Your Host has claimed Security Deposit';
    previewText = 'Your Host has claimed Security Deposit';
  }
  if (type === 'messageAdmin' || type === 'privateMessageRequestAdmin') {
    subject = 'You have received a new message on Golightly.';
    previewText = 'Someone sent you a new message!';
  }
  if (type === 'adminNewInvite') {
    subject = 'You have been invited to join Golightly';
    previewText = 'Your Invitation Link inside';
  }
  if (type === 'documentUpload') {
    subject = 'Document Verification';
    previewText = 'Document Verification';
  }
  if (type === 'bookingRequestAdmin') {
    subject = 'New Reservation on Golightly.';
    previewText = 'Great News! New Reservation on Golightly.';
  }
  if (type === 'bookingConfirmAdmin') {
    subject = 'Your Golightly reservation is confirmed.';
    previewText = 'Pack your bag, you are ready for your trip!';
  }
  if (type === 'bookingDeclineAdmin') {
    subject = 'Your Golightly reservation has been declined.';
    previewText = 'We are sorry, your request is declined';
  }
  if (type === 'inquiryAdmin') {
    subject = 'You have a new Golightly inquiry.';
    previewText = 'Someone sent a inquiry from contact form!';
  }
  if (type === 'cancelledByHostAdmin') {
    subject = 'Reservation has been cancelled by the host.';
    previewText = 'Reservation is cancelled';
  }
  if (type === 'cancelledByGuestAdmin') {
    subject = 'Reservation has been cancelled by the guest.';
    previewText = 'Reservation is cancelled';
  }
  if (type === 'reviewSubmitted') {
    subject = 'You have New Review submission for Approval';
    previewText = 'You have New Review submission for Approval';
  }
  if (type === 'advanceNoticePeriod') {
    subject = 'Reminder : Upcoming Golightly reservation';
    previewText = 'Upcoming Golightly reservation';
  }
  if (type === 'signUpPending') {
    subject = 'Welcome to Golightly!';
    previewText = 'Welcome to Golightly!';
  }
  if (type === 'signUpApproved') {
    subject = 'Approval of Golightly sign-up application';
    previewText = 'Approval of Golightly sign-up application';
  }
  if (type === 'signUpRejected') {
    subject = 'User details verification failed';
    previewText = 'User details verification failed';
  }
  if (type === 'documentIDMRejected') {
    subject = 'Document verification failed';
    previewText = 'Document verification failed';
  }
  if (type === 'manualReviewStatus') {
    subject = 'Documents flagged for manual review';
    previewText = 'User documents flagged for manual review';
  }
  if (type === 'memberAccept') {
    subject = 'Welcome to Golightly!';
    previewText = 'Welcome to Golightly!';
  }
  if (type === 'memberAccept') {
    subject = 'Welcome to Golightly!';
    previewText = 'Welcome to Golightly!';
  }
  if (type === 'messageAdminError') {
    subject = '	User message flagged';
    previewText = 'User message flagged';
  }
  if (type === 'specialPriceInquiry') {
    subject = 'Special price request';
    previewText = 'Your have received a special price request';
  }
  if (type === 'offerPriceInquiryAdmin') {
    subject = 'Special price request';
    previewText = 'Special price request';
  }
  if (type === 'offerApproved') {
    subject = 'Special price request approved!';
    previewText = 'Your special price request has been approved';
  }
  if (type === 'offerApprovedAdmin') {
    subject = 'Special price request';
    previewText = 'Special price request';
  }
  if (type === 'offerHostDecline') {
    subject = 'Special price request declined!';
    previewText = 'Your special price request has been declined';
  }
  if (type === 'offerHostDeclineAdmin') {
    subject = 'Host has declined your special offer request';
    previewText = 'Someone sent you a new message!';
  }
  if (type === 'offerHostRequest') {
    subject = 'counter-offer';
    previewText = 'You have received a counter-offer';
  }
  if (type === 'offerHostRequestAdmin') {
    subject = 'counter-offer approved';
    previewText = 'counter-offer approved';
  }
  if (type === 'offerGuestDecline') {
    subject = 'counter-offer declined';
    previewText = 'Your counter-offer has been declined ';
  }
  if (type === 'offerGuestDeclineAdmin') {
    subject = 'counter-offer declined';
    previewText = 'counter-offer declined';
  }
  if (type === 'offerGuestAccept') {
    subject = 'counter-offer approved';
    previewText = 'Your counter-offer has been approved';
  }
  if (type === 'offerGuestAcceptAdmin') {
    subject = 'counter-offer approved';
    previewText = 'counter-offer approved';
  }
  if (type === 'bookingDeclinedToHost') {
    subject = 'Your Golightly reservation has been declined.';
    previewText = 'We are sorry, your request is declined';
  }
  if (type === 'bookingDeclineToAdmin') {
    subject = 'Your Golightly reservation has been declined.';
    previewText = 'We are sorry, your request is declined';
  }
  if (type === 'bookingDeclinedCardToGuest') {
    subject = 'Your Golightly reservation has been declined.';
    previewText = 'We are sorry, your request is declined';
  }
  if (type === 'bookingPreApproval') {
    subject = 'Host pre-approved your request';
    previewText = 'Booking pre-approved';
  }
  if (type === 'bookingPreApprovalAdmin') {
    subject = 'Host pre-approved your request';
    previewText = 'Booking pre-approved';
  }
  if (type === 'conciergeRequestAdmin') {
    subject = 'New Concierge request';
    previewText = 'New concierge request';
  }
  if (type === 'conciergeRequestAcknowledge') {
    subject = 'Concierge request';
    previewText = 'Concierge request received';
  }
  if (type === 'remainderHostBooking') {
    subject = 'Reminder for Your Upcoming Golightly Reservation in 7 days';
    previewText = 'Reminder for Your Upcoming Golightly Reservation in 7 days';
  }
  if (type === 'remainderGuestBooking') {
    subject = 'Get Ready for Your Golightly Trip in 7 Days!';
    previewText = 'Get Ready for Your Golightly Trip in 7 Days!';
  }
  if (type === 'remainderAdminBooking') {
    subject = 'Reminder for upcoming reservation in 7 days';
    previewText = 'Reminder for upcoming reservation in 7 days';
  }
  if (type === 'syncListing') {
    subject = 'A user has synced their listing with our platform!';
    previewText = 'A user has synced their listing with our platform!';
  }
  // 24 Hours Reminder
  if (type === 'remainderHostBookingBefore') {
    subject = 'Reminder of Golightly Guest Arrival Tomorrow!';
    previewText = 'Reminder of Golightly Guest Arrival Tomorrow!';
  }
  if (type === 'remainderGuestBookingBefore') {
    subject = 'Pack Your Bags for Your Golightly Trip Tomorrow!';
    previewText = 'Pack Your Bags for Your Golightly Trip Tomorrow!';
  }
  if (type === 'remainderAdminBookingBefore') {
    subject = 'Reminder for upcoming reservation in 24 hours';
    previewText = 'Reminder for upcoming reservation in 24 hours';
  }
  if (type === 'syncListingHost') {
    subject =
      'Thank you for syncing your properties with our platform and partnering with us.';
    previewText =
      'Thank you for syncing your properties with our platform and partnering with us.';
  }
  if (type === 'socialBlogPostApproval') {
    subject = 'New Social Post';
    previewText = 'Please take the necessary action for the new post.';
  }
  if (type === 'eventConfirmation') {
    let { isAdmin, eventStatus } = content || {};
    subject = isAdmin
      ? 'New Event request'
      : eventStatus === 'going'
      ? 'Event Request'
      : 'Event Waitlist Request';
    previewText = isAdmin ? 'New Event request' : 'Event Request';
  }
  if (type === 'propertyUnlist') {
    subject = 'Listing Unpublished!';
    previewText = 'Listing Unpublished!';
  }
  if (type === 'inquiryDecline' || type === 'inquiryDeclineAdmin') {
    subject = 'Inquiry request denied!';
    previewText = 'Inquiry request denied!';
  }
  if (type === 'eventConfirmed') {
    let { isAdmin, inviteStatus } = content || {};
    subject = isAdmin
      ? 'New Event request'
      : inviteStatus === 'going'
      ? 'Event Request'
      : 'Event Waitlist Request';
    previewText = isAdmin ? 'New Event request' : 'Event Request';
  }
  if (type === 'sendGoSoloPaymentLink') {
    subject = 'Go Solo - Payment link';
    previewText = 'Go Solo - Payment link';
  }
  if (type === 'restrictionMessageOnSocialBlog') {
    subject = 'User content flagged';
    previewText = 'User content flagged';
  }
  if (type === 'diamondSucess') {
    subject = 'Congrats on Being a Golightly Diamond Member!';
    previewText = 'Congrats on Being a Golightly Diamond Member!';
  }
  if (type === 'requestMembershipSubscription') {
    subject = 'Diamond membership - Button click';
    previewText = 'Diamond membership - Button click';
  }
  if (type === 'groupMessageAdminError') {
    subject = 'User message flagged';
    previewText = 'User message flagged';
  }
  if (type === 'goSoloViewTripClick') {
    subject = 'GoSolo trip - Button click';
    previewText = 'GoSolo trip - Button click';
  }
  if (type === 'bookingButtonMail') {
    subject = 'Book button click';
    previewText = 'Book button click';
  }
  if (type === 'postApplyUserMail') {
    subject = 'Job application on GoHustle';
    previewText = 'Job application on GoHustle';
  }
  if (type === 'postApplyAdminMail') {
    const { isAdminPost } = content;
    subject = isAdminPost
      ? 'You receive a new job application'
      : 'New job application on GoHustle';
    previewText = isAdminPost
      ? 'You receive a new job application'
      : 'New job application on GoHustle';
  }
  if (type === 'postApplyOwner') {
    subject = 'You receive a new job application';
    previewText = 'You receive a new job application';
  }
  return {
    subject,
    previewText,
  };
}
