import React, { Component } from 'react';

import moment from 'moment';
import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename } from '../../../config';
import Layout from '../layouts/Layout';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class BookingDeclineCardToHost extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const linkText = {
      color: '#77d1da',
      fontSize: '18px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const textCenter = {
      textAlign: 'center',
      marginTop: '6px',
    };

    const listPic = {
      borderRadius: '999px',
      margin: '0',
      padding: '0',
      lineHeight: '150%',
      borderSpacing: '0',
      width: '125px',
    };

    const imageSection = {
      display: 'table-cell',
      width: '33%',
    };

    const displatTable = {
      display: 'table',
      width: '100%',
      marginTop: '12px',
    };

    const displayRow = {
      display: 'table-row',
      width: '100%',
    };

    const {
      content: {
        guestName,
        hostName,
        confirmationCode,
        checkIn,
        listIds,
        listPhotos,
        listingTitles,
        listDetails,
        city,
      },
    } = this.props;
    const checkInDate = checkIn
      ? moment(checkIn).format('ddd, MMM Do, YYYY')
      : '';

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi {guestName},</div>
                  <EmptySpace height={20} />
                  <div>
                    We regret to inform you that your request to book has been
                    canceled due to insufficient funds in your card. Please try
                    another request with a valid card.
                  </div>
                  <EmptySpace height={40} />
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default BookingDeclineCardToHost;
