export const localeLoaders = {
  'en-US': () => import('../messages/en-US.json'),
  'cs-CZ': () => import('../messages/cs-CZ.json'),
  es: () => import('../messages/es.json'),
  fr: () => import('../messages/fr-FR.json'),
  it: () => import('../messages/it-IT.json'),
  pt: () => import('../messages/pt-PT.json'),
};

export const parseUkmedixResponse = (str) => {
  let tmp = str.replace(/\n/gi, ' ').trim();

  return tmp
    .slice(1, str.length - 1)
    .split('},')
    .map((item) => {
      try {
        return JSON.parse(`${item}}`);
      } catch (e) {
        return null;
      }
    })
    .filter(Boolean);
};
