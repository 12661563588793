import {
  REMOVE_STATIC_LOCATION_IMAGE_ERROR,
  REMOVE_STATIC_LOCATION_IMAGE_SUCCESS,
  STATIC_LOCATION_IMAGE_UPLOAD_ERROR,
  STATIC_LOCATION_IMAGE_UPLOAD_LOADER_START,
  STATIC_LOCATION_IMAGE_UPLOAD_SUCCESS,
} from '../../constants';

export default function staticLocationDetails(state = {}, action) {
  switch (action.type) {
    case STATIC_LOCATION_IMAGE_UPLOAD_LOADER_START:
      return {
        ...state,
        staticLocationImageUploaderLoading:
          action.payload.staticLocationImageUploaderLoading,
      };

    case STATIC_LOCATION_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        staticLocationImageUploaderLoading:
          action.payload.staticLocationImageUploaderLoading,
      };

    case STATIC_LOCATION_IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        staticLocationImageUploaderLoading:
          action.payload.staticLocationImageUploaderLoading,
      };

    case REMOVE_STATIC_LOCATION_IMAGE_SUCCESS:
      return {
        ...state,
        staticLocationImageUploaderLoading:
          action.payload.staticLocationImageUploaderLoading,
      };

    case REMOVE_STATIC_LOCATION_IMAGE_ERROR:
      return {
        ...state,
        staticLocationImageUploaderLoading:
          action.payload.staticLocationImageUploaderLoading,
      };

    default:
      return state;
  }
}
