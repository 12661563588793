import React from 'react';

import PropTypes from 'prop-types';

import { sitename } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class SignUpApprovedinIDM extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const {
      content: { email, name, firstName },
    } = this.props;
    const userFirstName = firstName || name;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi {userFirstName},</div>
          <EmptySpace height={20} />

          <div>
            Great news! We have completed the process of reviewing your sign-up
            application and approved it. You will now be able to access the
            site. We are glad to have you on our platform and we hope you have a
            great time. Thank you !
          </div>

          <div>Cheers!</div>
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default SignUpApprovedinIDM;
