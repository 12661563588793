import React from 'react';

import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class ReferralInvitationEmail extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      link: PropTypes.string.isRequired,
      InviterName: PropTypes.string.isRequired,
      // name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'normal',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#ff5a5f',
      backgroundColor: '#ff5a5f',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '13px',
      padding: '35px',
    };

    const headingStyle = {
      fontWeight: 700,
      color: 'rgb(119,209,218)',
      fontSize: '13px',
      textDecoration: 'underline',
    };

    const headingSectionStyle = {
      padding: '0px 18px 9px',
    };

    // const { content: { link, name, InviterName, referralKey } } = this.props;
    const {
      content: {
        link,
        InviterName,
        referralKey,
        name,
        email,
        personalText,
        dateOfBirth,
        zipCode,
        accept,
      },
    } = this.props;

    // let referralURL = url + '/c/' + referralKey + '/' + email;
    let referralURL;

    if (email && Array.isArray(email) && email.length > 0) {
      referralURL = `${url}/c/${referralKey}/${
        email[email.length - 1]
      }/${accept}`;
    } else if (dateOfBirth) {
      referralURL = `${url}/c/${referralKey}/${email}/${dateOfBirth}/${zipCode}/${accept}`;
    } else {
      referralURL = `${url}/c/${referralKey}/${email}/${zipCode}/${accept}`;
    }

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>
            {!name && <div>Hello,</div>}
            {name && <div>Hi {name},</div>}
          </div>
          <EmptySpace height={20} />
          <div>
            {InviterName} has invited you to join as a Founding Member of
            Golightly, the first invite-only vacation rental and home-sharing
            platform for women.
          </div>
          <EmptySpace height={10} />
          <div>
            Joining is currently free for Founding Members so we encourage you
            to join at the earliest, <b> invite your friends for free,</b> start
            planning trips and if you'd like to host, list your room, home or
            vacation house for other members to rent within the private network.
          </div>
          <EmptySpace height={10} />
          <div>For more information please see the FAQ's below.</div>
          <EmptySpace height={20} />
          {personalText && (
            <div>
              <b>{InviterName} has sent an additional message:</b>{' '}
              {personalText}
            </div>
          )}
          <EmptySpace height={10} />
          <div>Please use the link below to sign up:</div>

          <div>
            {/* { email && email.length > 0 && email.map((item,key)=>{
                           return( */}
            <a href={referralURL}>{link}</a>

            {/* )
                       })
                       }  */}
          </div>
          <EmptySpace height={20} />
          <div>We look forward to having you as a Golightly member!</div>
          <EmptySpace height={30} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>

          <EmptySpace height={20} />
          <div style={headingSectionStyle}>
            <div style={headingStyle}>FAQs</div>

            <EmptySpace height={20} />
            <div style={headingStyle}>What is Golightly</div>
            <EmptySpace height={15} />

            <div>
              Golightly is a private club for women with a platform for listing
              and booking vacation rentals and home share stays.
            </div>
            <EmptySpace height={15} />
            <div style={headingStyle}>But who are you?</div>
            <EmptySpace height={15} />

            <div>
              Our goal is to create an interconnected network for all women,
              aiming to provide a safer and more accountable place to book your
              travel (and in some cases, longer term) accommodation and a
              platform for you to rent out your space, whether it be one room or
              your entire home.
            </div>
            <EmptySpace height={15} />
            <div style={headingStyle}>So, do you hate men?</div>
            <EmptySpace height={15} />

            <div>
              Never! We love men! All listings and bookings are done by members,
              however you can chose to travel with whomever you wish: your
              boyfriends, husband, kids, girlfriends, grandma or Neighbour Steve
              - even Betsie the pug is welcome in some homes!
            </div>
            <EmptySpace height={15} />
            <div style={headingStyle}>Can I travel solo?</div>
            <EmptySpace height={15} />

            <div>
              Of course - in fact we encourage it! Besides the fact that you can
              see exactly how you are connected to your host, there are many
              filters and factors to help you feel safer when traveling solo and
              ensure you feel more comfortable traveling within the support of
              the Golightly network.
            </div>
            <EmptySpace height={15} />
            <div style={headingStyle}>Is there a membership fee?</div>
            <EmptySpace height={15} />

            <div>
              There is a new member fee, but it’s FREE for founding members. So
              become part of it now and invite your friends ASAP so they can
              join for free too!
            </div>
            <EmptySpace height={15} />
            <div style={headingStyle}>Why should I invite my friends?</div>
            <EmptySpace height={15} />

            <div>
              Inviting your friends not only lets you share the exciting perks
              of Golightly, but you'll receive travel credit to use on Golightly
              homes for every friend who joins. Love free travel? Invite your
              friends!
            </div>
            <EmptySpace height={15} />
            <div style={headingStyle}>How many invites do I have?</div>
            <EmptySpace height={15} />

            <div>
              Invites are limited to five. This is to help us maintain the
              "friend of a friend" quality of our network and helps to provide
              the level of accountability we all want when we travel.
            </div>
            <EmptySpace height={15} />
            <div style={headingStyle}>What's in it for me?</div>
            <EmptySpace height={15} />

            <div>
              Besides the peace of mind of travelling within a safe network,
              you'll get free membership for life (and a lot of love from us).
              You will also get access to an exclusive network and social events
              plus the perks of our membership card, and all the cool
              partnerships and discounts that come with it.
            </div>
            <EmptySpace height={15} />
            <div>
              <b>
                <div style={headingStyle}>
                  {' '}
                  What do I do once I have signed up?
                </div>
                <EmptySpace height={15} />

                <div>
                  1. Fill in your profile, it’s an ice breaker and everyone
                  feels much more comfortable knowing something about her host
                  or guest.
                </div>
                <EmptySpace height={15} />

                <div>
                  2. Check out some of our beautiful homes already listed.
                </div>
                <EmptySpace height={15} />

                <div>
                  3. Invite your friends and gain travel credit! (Did we say
                  that already...)
                </div>
                <EmptySpace height={15} />

                <div>
                  4. Search through your connections to connect with their
                  connections so you can build your own network.
                </div>
                <EmptySpace height={15} />

                <div>
                  5. And finally take advantage and list your room or your home
                  now to start making some extra cash and be part of a network
                  providing safe travel for women!
                </div>
              </b>
            </div>
            <EmptySpace height={15} />
            <div style={headingStyle}>
              Why should I list my home or spare room?
            </div>
            <EmptySpace height={15} />

            <div>
              Because who wouldn't want that extra money to pay for that holiday
              in Ibiza?! Seriously though you can rent out for as long or as
              short amount of time as you wish and you will also get the
              opportunity to meet and connect with others in the network. Plus
              you can feel safer knowing that you will have some degree of
              connection with your guest.
            </div>
            <EmptySpace height={15} />
            <div style={headingStyle}>
              Hmmm, ok, I really want to list my home but I'm still a little
              concerned.
            </div>
            <EmptySpace height={15} />

            <div>
              Don't be! We highly recommend you list now as we are currently
              sending a professional photographer around free of charge and
              since we are a private club you can feel safe in knowing that your
              listing is private and only members can view your home. We also
              provide comprehensive insurance coverage for all hosts and right
              now, each host is eligible for more invites, meaning more friends
              in your network AND more credits for you ;-)
            </div>
            <EmptySpace height={15} />
            <div style={headingStyle}>
              Wow. This is a win win situation. Why would I even think about it?
            </div>
            <EmptySpace height={15} />

            <div>
              Yeah, that’s what we’re thinking. Cool network, safe accommodation
              and loads of upcoming perks. Plus, my friends and I are some of
              the first to get our feet in the door. Oh, and it’s free right
              now..
            </div>
            <EmptySpace height={15} />
            <div style={headingStyle}>
              Wait - last question - why a private vacation rental club, anyway?
            </div>
            <EmptySpace height={15} />

            <div>
              Doesn't have to be a last question, we are always here to answer
              any more! But to your point, our mission is to help all women
              travel safely and feel secure knowing her host or guest is always
              a friend of a friend. We hope to take some of the stress out of
              booking or listing a vacation rental so you can leave life’s
              baggage behind and go lightly!
            </div>
          </div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default ReferralInvitationEmail;
