import React from 'react';

import 'react-geosuggest/module/geosuggest.css';

import Geosuggest from 'react-geosuggest';

// Escapes special characters in user input for regex
function escapeRegExp(str) {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}

class CustomGeoSuggest extends Geosuggest {
  UNSAFE_componentWillMount() {
    if (typeof window === 'undefined') {
      return;
    }

    const googleMaps =
      this.props.googleMaps ||
      (window.google && window.google.maps) ||
      this.googleMaps;

    this.googleMaps = googleMaps;

    this.autocompleteService = new googleMaps.places.AutocompleteService();
    this.placesService = new googleMaps.places.PlacesService(
      document.createElement('div')
    );
    this.sessionToken = new googleMaps.places.AutocompleteSessionToken();
    this.geocoder = new googleMaps.Geocoder();
  }

  componentDidMount() {
    if (typeof window === 'undefined') {
      return;
    }
    const googleMaps =
      this.props.googleMaps ||
      (window.google && window.google.maps) ||
      this.googleMaps;

    if (googleMaps) {
      this.googleMaps = googleMaps;
    }
  }

  /**
   * Change inputValue if prop changes
   */
  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.initialValue !== props.initialValue) {
      this.setState({ userInput: props.initialValue || '' });
    }
  }

  /**
   * Search for new suggests
   */
  searchSuggests() {
    if (!this.state.userInput) {
      this.updateSuggests();
      return;
    }

    let isMapAPILoaded = false;
    let options;

    const googleMaps =
      this.props.googleMaps ||
      (window.google && window.google.maps) ||
      this.googleMaps;
    if (googleMaps && !this.googleMaps) {
      this.googleMaps = googleMaps;

      this.autocompleteService = new googleMaps.places.AutocompleteService();
      this.placesService = new googleMaps.places.PlacesService(
        document.createElement('div')
      );
      this.sessionToken = new googleMaps.places.AutocompleteSessionToken();
      this.geocoder = new googleMaps.Geocoder();
    }

    if (
      typeof window.google === 'object' &&
      typeof window.google.maps === 'object'
    ) {
      options = {
        input: this.state.userInput,
        sessionToken: this.sessionToken,
      };
      isMapAPILoaded = true;
    } else {
      options = {};
    }
    const inputLength = this.state.userInput.length;
    const isShorterThanMinLength =
      this.props.minLength && inputLength < this.props.minLength;

    if (isShorterThanMinLength) {
      return;
    }

    const { location, radius, bounds, types, country } = this.props;
    const unWantedTypes = [
      'establishment',
      'street_address',
      'street_number',
      'route',
      'sublocality',
      'premise',
      'subpremise',
    ];
    let formattedSuggestions = [];

    /* tslint:disable:curly */
    if (location) options.location = location;
    if (radius) options.radius = Number(this.props.radius);
    if (bounds) options.bounds = bounds;
    if (types) options.types = types;
    if (country) options.componentRestrictions = { country };
    /* tslint:enable:curly */

    this.setState({ isLoading: true }, () => {
      if (isMapAPILoaded) {
        this.autocompleteService.getPlacePredictions(
          options,
          (suggestsGoogle) => {
            this.setState({ isLoading: false });
            formattedSuggestions =
              suggestsGoogle &&
              suggestsGoogle.length > 0 &&
              suggestsGoogle.filter((o) => {
                if (o.types && o.types.length > 0) {
                  return !unWantedTypes.some((item) => o.types.includes(item));
                }
                return false;
              });

            this.updateSuggests(
              formattedSuggestions || [], // can be null
              () => {
                if (
                  this.props.autoActivateFirstSuggest &&
                  !this.state.activeSuggest
                ) {
                  this.activateSuggest('next');
                }
              }
            );
          }
        );
      } else {
        this.updateSuggests([], this.activateSuggest('next'));
      }
    });
  }

  activateSuggest(direction) {
    if (this.state.isSuggestsHidden) {
      this.showSuggests();
      return;
    }

    const suggestsCount = this.state.suggests.length - 1;
    const next = direction === 'next';
    let newActiveSuggest = null;
    let newIndex = 0;
    let i = 0;

    for (i; i <= suggestsCount; i += 1) {
      if (this.state.suggests[i] === this.state.activeSuggest) {
        newIndex = next ? i + 1 : i - 1;
      }
    }

    if (!this.state.activeSuggest) {
      newIndex = next ? 0 : suggestsCount;
    }

    if (newIndex >= 0 && newIndex <= suggestsCount) {
      newActiveSuggest = this.state.suggests[newIndex];
    }

    if (this.props.onActivateSuggest) {
      this.props.onActivateSuggest(newActiveSuggest);
    }

    this.setState({ activeSuggest: newActiveSuggest });
  }

  /**
   * Update the suggests
   */
  updateSuggests(
    suggestsGoogle = [],
    // tslint:disable-next-line:no-empty
    callback
  ) {
    const suggests = [];
    const formattedSuggestions = [];
    const unWantedTypes = ['establishment'];
    const { userInput } = this.state;
    const { skipSuggest, maxFixtures, fixtures } = this.props;
    const regex = new RegExp(escapeRegExp(userInput), 'gim');
    let fixturesSearched = 0;
    let activeSuggest = null;
    let fixtureOffset;
    if (fixtures) {
      fixtures.forEach((fixture) => {
        if (maxFixtures && fixturesSearched >= maxFixtures) {
          return;
        }

        if (
          skipSuggest &&
          !skipSuggest(fixture) &&
          fixture.label.match(regex)
        ) {
          fixturesSearched += 1;
          fixtureOffset =
            fixture.label.indexOf(userInput) >= 0
              ? fixture.label.indexOf(userInput)
              : 0;
          suggests.push({
            className: fixture.className,
            isFixture: true,
            label: fixture.label,
            location: fixture.location,
            matchedSubstrings: {
              length: userInput.length,
              // offset: fixture.label.indexOf(userInput)
              offset: fixtureOffset,
            },
            placeId: fixture.label,
          });
        }
      });
    }

    // formattedSuggestions = suggestsGoogle.filter((o) => {
    //     if (o.types && o.types.length > 0 ) {
    //         let isUnwantedType = false;
    //         unWantedTypes.map((item) => {
    //             isUnwantedType = o.types.indexOf(item) >= 0 ? true : isUnwantedType;
    //         });

    //         if (isUnwantedType) {
    //             return false;
    //         } else {
    //             return true;
    //         }
    //     } else {
    //         return false;
    //     }
    // })

    suggestsGoogle.forEach((suggest) => {
      if (skipSuggest && !skipSuggest(suggest)) {
        suggests.push({
          description: suggest.description,
          isFixture: false,
          label: this.props.getSuggestLabel
            ? this.props.getSuggestLabel(suggest)
            : '',
          matchedSubstrings: suggest.matched_substrings[0],
          placeId: suggest.place_id,
        });
      }
    });

    activeSuggest = this.updateActiveSuggest(suggests);

    if (this.props.onUpdateSuggests) {
      this.props.onUpdateSuggests(suggests, activeSuggest);
    }
    this.setState({ suggests, activeSuggest }, callback);
  }

  /**
   * Return the new activeSuggest object after suggests have been updated
   */
  updateActiveSuggest(suggests) {
    let { activeSuggest } = this.state;

    if (activeSuggest) {
      const newSuggest = suggests.filter(
        (listedSuggest) =>
          activeSuggest &&
          activeSuggest.placeId === listedSuggest.placeId &&
          activeSuggest.isFixture === listedSuggest.isFixture
      )[0];

      activeSuggest = newSuggest || null;
    }

    return activeSuggest;
  }
}

// const mapState = (state) => ({
//     personalized: state.personalized
// });

// const mapDispatch = {
//     setPersonalizedValues
// };

// export default GoogleMapLoader((connect(mapState, mapDispatch)(PlaceGeoSuggest)), {
//     libraries: ["places"],
//     region: "UK",
//     language: "en",
//     key: googleMapAPI,
// });

export default CustomGeoSuggest;
