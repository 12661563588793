import React, { Component } from 'react';

import awesomePhoneNumber from 'awesome-phonenumber';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { compose, gql, graphql } from 'react-apollo';
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from 'react-bootstrap';
import * as FontAwesome from 'react-icons/fa';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import PopulateData from '../../helpers/populateData';
import { randomString } from '../../helpers/randomString';
import messages from '../../locale/messages';
import CountryList from '../CountryList';
import Loader from '../Loader';

import s from './RegisterForm.module.css';
import submit from './submit';
import validate from './validate';

class RegisterForm extends Component {
  static propTypes = {
    formatMessage: PropTypes.func,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      getCountries: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          countryCode: PropTypes.string.isRequired,
          countryName: PropTypes.string.isRequired,
          isEnable: PropTypes.bool.isRequired,
        }).isRequired
      ),
    }).isRequired,
  };

  static defaultProps = {
    data: {
      getCountries: [],
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      dateOfBirthData: {},
      passtype: 'password',
      confirmpasstype: 'password',
      countryCode: 'US',
      country: '+1',
      submitting: false,
      error: null,
      typingTimeout: 0,
    };
    this.passwordShowHide = this.passwordShowHide.bind(this);
    this.confirmPasswordShowHide = this.confirmPasswordShowHide.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleTyping = this.handleTyping.bind(this);
  }

  UNSAFE_componentWillMount() {
    let now = new Date();
    let currentYear = now.getFullYear();
    let years = PopulateData.generateData(1920, currentYear, 'desc');
    let days = PopulateData.generateData(1, 31);
    let months = PopulateData.generateData(0, 11);
    this.setState({
      dateOfBirthData: {
        years,
        months,
        days,
      },
    });
  }

  UNSAFE_componentWillReceiveProps() {
    const { change, loginKey, email } = this.props;
    const { country, countryCode } = this.state;

    let referralKey = randomString();
    change('referralKey', referralKey);
    change('loginKey', loginKey);
    change('email', email);

    if (countryCode && country) {
      change('countryCode', countryCode);
    }
  }

  handleTyping() {
    const { countryCode, phoneNumber, typingTimeout } = this.state;
    const { change } = this.props;
    let formatPn = new awesomePhoneNumber(phoneNumber, countryCode);
    let formattedNumber = formatPn.getNumber('international');
    let deFormatted = formatPn.getNumber('significant');
    change('phoneNumber', deFormatted);
    this.setState({ phoneNumber: formattedNumber });
    let getCountryCode = `+${formatPn.getCountryCode(countryCode)}`;
    change('dialCode', getCountryCode);
  }

  // handleChange(event) {
  //   const { typingTimeout } = this.state;
  //   const { change } = this.props;
  //   const self = this;
  //   // self.setState({ [event.target.name]: event.target.value });
  //   if (event.target.name === 'phoneNumber') {
  //     if (typingTimeout) {
  //       clearTimeout(typingTimeout);
  //     }
  //     change('phoneNumber', event.target.value.replace(/[^\d.]/g, ''));
  //     self.setState({
  //       phoneNumber: event.target.value.replace(/[^\d.]/g, ''),
  //       typingTimeout: setTimeout(() => {
  //         self.handleTyping();
  //       }, 800),
  //     });
  //   } else {
  //     self.setState({ [event.target.name]: event.target.value });
  //   }
  // }
  handleChange(event) {
    const { typingTimeout, dialCode } = this.state;
    const { change } = this.props;
    const self = this;
    if (event.target.name === 'phoneNumber') {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      change('phoneNumber', event.target.value);
    } else {
      self.setState({ [event.target.name]: event.target.value });
    }
  }

  async handleCountryChange(e, selectedData) {
    const { change } = this.props;
    this.setState({
      country: selectedData.dialCode,
      countryCode: selectedData.countryCode,
    });
    await change('zipCode', null);
    await change(
      'countryCode',
      selectedData.countryCode ? selectedData.countryCode : 'US'
    );
    await change(
      'dialCode',
      selectedData.dialCode ? selectedData.dialCode : '+1'
    );
  }

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
        <FormControl
          {...input}
          className={className}
          placeholder={label}
          type={type}
        />
      </div>
    );
  };

  renderFormControlReferral = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    const { loginKey } = this.props;
    let isDisabled = false;
    if (loginKey != null) {
      isDisabled = true;
    }
    return (
      <div>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
        <FormControl
          {...input}
          className={className}
          disabled={isDisabled}
          placeholder={label}
          type={type}
        />
      </div>
    );
  };

  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input}>
          {children}
        </FormControl>
      </div>
    );
  };

  renderPhonenumber = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
    isDisabled,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
        <FormControl
          {...input}
          className={className}
          disabled={isDisabled}
          placeholder={label}
          type={type}
        />
      </div>
    );
  };

  passwordShowHide(e) {
    const { passtype } = this.state;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ passtype: passtype === 'input' ? 'password' : 'input' });
  }

  confirmPasswordShowHide(e) {
    const { confirmpasstype } = this.state;
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      confirmpasstype: confirmpasstype === 'input' ? 'password' : 'input',
    });
  }

  render() {
    const {
      error,
      handleSubmit,
      submitting,
      dispatch,
      loginKey,
      initialValues,
      loading,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { dateOfBirthData, passtype, confirmpasstype } = this.state;
    const { country, countryCode, phoneNumber } = this.state;
    let phoneClass = 'phoneClass';

    return (
      <form onSubmit={handleSubmit(submit)}>
        {error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
        <FormGroup className={cx(s.formGroup, s.formSection)}>
          <Col
            className={cx(s.noPadding, s.textAlignCenter)}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            {!loginKey && (
              <span className={cx(s.modalCaptionLink, s.signUpHereCode)}>
                {formatMessage(messages.completeSignUpHereLabel)}
              </span>
            )}
            {/* <a
              className={cx(s.modalCaptionLink, s.signUpHereCode)}
              href="/become-a-member"
            >
              {formatMessage(messages.signUpHereWithCode)}
            </a> */}
          </Col>
        </FormGroup>
        {loginKey && (
          <FormGroup className={s.formGroup}>
            <Field
              className={s.formControlInput}
              component={this.renderFormControlReferral}
              disabled={loginKey}
              label={formatMessage(messages.loginKey)}
              name="loginReferralKey"
              type="text"
            />
          </FormGroup>
        )}
        <FormGroup className={s.formGroup}>
          <Field
            className={s.formControlInput}
            component={this.renderFormControl}
            label={formatMessage(messages.firstName)}
            name="firstName"
            type="text"
          />
        </FormGroup>
        <FormGroup className={s.formGroup}>
          <Field
            className={s.formControlInput}
            component={this.renderFormControl}
            label={formatMessage(messages.lastName)}
            name="lastName"
            type="text"
          />
        </FormGroup>
        <FormGroup className={s.formGroup}>
          <Field
            autoComplete="username"
            className={s.formControlInput}
            component={this.renderFormControl}
            label={formatMessage(messages.email)}
            name="email"
            type="text"
          />
        </FormGroup>
        <FormGroup className={s.formGroup}>
          <div className={s.passwordPosition}>
            <div className={s.passwordOne}>
              <Field
                autoComplete="new-password"
                className={s.formControlInput}
                component={this.renderFormControl}
                label={formatMessage(messages.password)}
                name="password"
                type={passtype}
              />
            </div>
            <div className={s.passwordTwo}>
              <span className={s.eyeStyle} onClick={this.passwordShowHide}>
                {passtype === 'input' ? (
                  <FontAwesome.FaEyeSlash className={s.profileIconSize} />
                ) : (
                  <FontAwesome.FaEye className={s.profileIconSize} />
                )}
              </span>
            </div>
          </div>
        </FormGroup>
        <FormGroup className={s.formGroup}>
          <div className={s.passwordPosition}>
            <div className={s.passwordOne}>
              <Field
                className={s.formControlInput}
                component={this.renderFormControl}
                label={formatMessage(messages.confirmPassword)}
                name="confirmPassword"
                type={confirmpasstype}
              />
            </div>
            <div className={s.passwordTwo}>
              <span
                className={s.eyeStyle}
                onClick={this.confirmPasswordShowHide}
              >
                {confirmpasstype === 'input' ? (
                  <FontAwesome.FaEyeSlash className={s.profileIconSize} />
                ) : (
                  <FontAwesome.FaEye className={s.profileIconSize} />
                )}
              </span>
            </div>
          </div>
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <label className={s.labelText}>
            {formatMessage(messages.chooseCountry)}
            <span className={s.requiredStar}>{' *'} </span>
          </label>
          <CountryList
            className={cx(s.formControlSelect)}
            dialCode={false}
            getSelected={this.handleCountryChange}
            input={{
              name: 'countryCode',
              onChange: this.handleChange,
              value: countryCode,
            }}
            // onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Field
            className={s.formControlInput}
            component={this.renderFormControl}
            label={formatMessage(messages.editProfileZipCode)}
            name="zipCode"
            type="text"
          />
        </FormGroup>

        <FormGroup className={s.formGroup}>
          {/* <FormControl
            className={cx(s.formControlInput)}
            name="phoneNumber"
            onChange={this.handleChange}
            placeholder={formatMessage(messages.addPhoneNumber)}
            type="text"
            value={phoneNumber}
          /> */}
          <FormControl
            className={cx(s.formControlInput)}
            name="phoneNumber"
            onChange={this.handleChange}
            placeholder={formatMessage(messages.addPhoneNumber)}
            type="text"
            value={phoneNumber}
          />
        </FormGroup>

        <div>
          <Row className={s.spaceTop2}>
            <Col lg={3} md={3} sm={3} xs={3}>
              <ControlLabel className={s.noMargin}>
                {formatMessage(messages.gender)}
              </ControlLabel>
            </Col>
            <Col className={s.noPadding} lg={3} md={3} sm={3} xs={3}>
              <FormGroup className={s.formGroup}>
                <span className={cx(s.genderText)}>
                  <Field
                    className={s.radioBox}
                    component="input"
                    name="gender"
                    type="radio"
                    value="male"
                  />
                  <span className={cx(s.vtnMiddle, s.textPadding)}>
                    {formatMessage(messages.genderMale)}
                  </span>
                </span>
              </FormGroup>
            </Col>
            <Col className={s.noPadding} lg={3} md={4} sm={3} xs={3}>
              <FormGroup className={s.formGroup}>
                <span className={cx(s.genderText)}>
                  <Field
                    className={s.radioBox}
                    component="input"
                    name="gender"
                    type="radio"
                    value="female"
                  />
                  <span className={cx(s.vtnMiddle, s.textPadding)}>
                    {formatMessage(messages.genderFemale)}
                  </span>
                </span>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div>
          <Row className={s.spaceTop2}>
            <Col className={s.space1} lg={12} md={12} sm={12} xs={12}>
              <ControlLabel className={s.noMargin}>
                {formatMessage(messages.doYouIntend)}
              </ControlLabel>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Col className={s.noPadding} lg={3} md={3} sm={3} xs={3}>
                <FormGroup className={s.formGroup}>
                  <span className={cx(s.genderText)}>
                    <Field
                      className={s.radioBox}
                      component="input"
                      name="intend"
                      type="radio"
                      value="host"
                    />
                    <span className={cx(s.vtnMiddle, s.textPadding)}>
                      {formatMessage(messages.host)}
                    </span>
                  </span>
                </FormGroup>
              </Col>
              <Col className={s.noPadding} lg={3} md={3} sm={3} xs={3}>
                <FormGroup className={s.formGroup}>
                  <span className={cx(s.genderText)}>
                    <Field
                      className={s.radioBox}
                      component="input"
                      name="intend"
                      type="radio"
                      value="travel"
                    />
                    <span className={cx(s.vtnMiddle, s.textPadding)}>
                      {formatMessage(messages.travel)}
                    </span>
                  </span>
                </FormGroup>
              </Col>
              <Col className={s.noPadding} lg={3} md={3} sm={3} xs={3}>
                <FormGroup className={s.formGroup}>
                  <span className={cx(s.genderText)}>
                    <Field
                      className={s.radioBox}
                      component="input"
                      name="intend"
                      type="radio"
                      value="both"
                    />
                    <span className={cx(s.vtnMiddle, s.textPadding)}>
                      {formatMessage(messages.both)}
                    </span>
                  </span>
                </FormGroup>
              </Col>
            </Col>
          </Row>
        </div>

        <div>
          <Row className={s.spaceTop2}>
            <Col lg={12} md={12} sm={12} xs={12}>
              <ControlLabel>{formatMessage(messages.birthDay)}</ControlLabel>
            </Col>
            <Col lg={5} md={5} sm={5} xs={5}>
              <FormGroup>
                <Field component={this.renderFormControlSelect} name="month">
                  <option value="">{formatMessage(messages.month)}</option>
                  {dateOfBirthData.months.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item + 1}
                      </option>
                    );
                  })}
                </Field>
              </FormGroup>
            </Col>

            <Col className={s.noPadding} lg={3} md={3} sm={3} xs={3}>
              <FormGroup controlId="formControlsSelect">
                <Field component={this.renderFormControlSelect} name="day">
                  <option value="">{formatMessage(messages.day)}</option>
                  {dateOfBirthData.days.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Field>
              </FormGroup>
            </Col>

            <Col lg={4} md={4} sm={4} xs={4}>
              <FormGroup controlId="formControlsSelect">
                <Field component={this.renderFormControlSelect} name="year">
                  <option value="">{formatMessage(messages.year)}</option>
                  {dateOfBirthData.years.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Field>
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className={s.allMember}>
          <FormattedMessage {...messages.mustBe21Old} />
        </div>

        <FormGroup className={s.formGroup}>
          {/* <Button
            className={cx(s.button, s.btnPrimary)}
            bsSize="large"
            block type="submit"
            disabled={submitting}
          >
            {formatMessage(messages.signUp)}
          </Button> */}
          <Loader
            buttonType="submit"
            className={cx(s.button, s.btnPrimary, s.btnlarge)}
            disabled={submitting}
            label={formatMessage(messages.signUp)}
            show={loading}
            type="button"
          />
        </FormGroup>
      </form>
    );
  }
}

const registerForm = reduxForm({
  form: 'RegisterForm', // a unique name for this form
  validate,
  onSubmit: submit,
})(RegisterForm);

const selector = formValueSelector('RegisterForm');

const mapState = (state) => ({
  loading: state.loader.homeLoading,
  phoneNumber: selector(state, 'phoneNumber'),
});

const mapDispatch = {};

export default compose(
  injectIntl,
  connect(mapState, mapDispatch),
  graphql(
    gql`
      query getCountries {
        getCountries {
          id
          countryCode
          countryName
          isEnable
          dialCode
        }
      }
    `,
    {
      options: {
        ssr: false,
        fetchPolicy: 'network-only',
      },
    }
  )
)(registerForm);
