import React from 'react';

import PropTypes from 'prop-types';

import { sitename } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class RejectedStatusfromIDM extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const {
      content: { email, name, firstName },
    } = this.props;
    const userFirstName = firstName || name;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />

          <div>
            An user's details has failed the verification process. The following
            are the user details,
          </div>
          <div>Name : {userFirstName}</div>
          <div>Email : {email}</div>

          <div>Cheers!</div>
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default RejectedStatusfromIDM;
