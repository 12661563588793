import React, { Component } from 'react';

import { PropTypes } from 'prop-types';
import { Button } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';

class ButtonLoader extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    handleClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    spinnerColor: PropTypes.string,
  };

  static defaultProps = {
    label: 'Submit',
    show: false,
    type: 'button',
    spinnerColor: '#fff',
  };

  render() {
    const {
      label,
      show,
      type,
      handleClick,
      className,
      disabled,
      spinnerColor,
    } = this.props;
    let isDisabled = false;
    if (show || disabled) {
      isDisabled = true;
    }
    return (
      <Button
        className={className}
        disabled={isDisabled}
        onClick={handleClick}
        type={type}
      >
        {show && <MDSpinner singleColor={spinnerColor} size={18} />}
        &nbsp;{label}
      </Button>
    );
  }
}

export default ButtonLoader;
