import {
  ADD_PAYMENT_ERROR,
  ADD_PAYMENT_START,
  ADD_PAYMENT_SUCCESS,
  CONFIRM_DUBLICATE_LISTING_CLOSE,
  CONFIRM_DUBLICATE_LISTING_START,
  DECLINE_REASON_MODAL_CLOSE,
  DECLINE_REASON_MODAL_OPEN,
  HOST_DECLINE_MODAL_START,
  HOST_DECLINE_MODAL_SUCCESS,
  HOST_MODAL_START,
  HOST_MODAL_SUCCESS,
  PHOTOS_CONFIRM_DUBLICATE_LISTING_CLOSE,
  PHOTOS_CONFIRM_DUBLICATE_LISTING_START,
  PREPARING_DUPLICATE_LISTING_ERROR,
  PREPARING_DUPLICATE_LISTING_START,
  PREPARING_DUPLICATE_LISTING_SUCCESS,
  PREPARING_DUPLICATE_NOT_PHOTOS_LISTING_ERROR,
  PREPARING_DUPLICATE_NOT_PHOTOS_LISTING_START,
  PREPARING_DUPLICATE_NOT_PHOTOS_LISTING_SUCCESS,
  SEND_MESSAGE_ERROR,
  SEND_MESSAGE_START,
  SEND_MESSAGE_SUCCESS,
  SUBSCRIPTION_MODAL_CLOSE,
  SUBSCRIPTION_MODAL_START,
  VERIFICATION_MODAL_START,
  VERIFICATION_MODAL_SUCCESS,
} from '../constants';

export default function verificationModal(state = {}, action) {
  switch (action.type) {
    case VERIFICATION_MODAL_START:
      return {
        ...state,
        verificationData: action.verificationData,
      };

    case VERIFICATION_MODAL_SUCCESS:
      return {
        ...state,
        verificationData: action.verificationData,
      };

    case CONFIRM_DUBLICATE_LISTING_START:
      return {
        ...state,
        isConfirmation: action.isConfirmation,
      };

    case CONFIRM_DUBLICATE_LISTING_CLOSE:
      return {
        ...state,
        isConfirmation: action.isConfirmation,
      };

    case PHOTOS_CONFIRM_DUBLICATE_LISTING_START:
      return {
        ...state,
        isPhotosConfirm: action.isPhotosConfirm,
        isConfirmation: action.isConfirmation,
      };

    case PHOTOS_CONFIRM_DUBLICATE_LISTING_CLOSE:
      return {
        ...state,
        isPhotosConfirm: action.isPhotosConfirm,
      };

    case PREPARING_DUPLICATE_LISTING_START:
      return {
        ...state,
        isPreparingListing: action.payload.isPreparingListing,
      };

    case PREPARING_DUPLICATE_LISTING_SUCCESS:
      return {
        ...state,
        isPreparingListing: action.payload.isPreparingListing,
      };

    case PREPARING_DUPLICATE_LISTING_ERROR:
      return {
        ...state,
        isPreparingListing: action.payload.isPreparingListing,
      };

    case PREPARING_DUPLICATE_NOT_PHOTOS_LISTING_START:
      return {
        ...state,
        isPhotos: action.payload.isPhotos,
      };

    case PREPARING_DUPLICATE_NOT_PHOTOS_LISTING_SUCCESS:
      return {
        ...state,
        isPhotos: action.payload.isPhotos,
      };

    case PREPARING_DUPLICATE_NOT_PHOTOS_LISTING_ERROR:
      return {
        ...state,
        isPhotos: action.payload.isPhotos,
      };

    case HOST_DECLINE_MODAL_START:
      return {
        ...state,
        declineStatus: action.declineStatus,
        isDeclineStatus: action.isDeclineStatus,
      };

    case HOST_DECLINE_MODAL_SUCCESS:
      return {
        ...state,
        declineStatus: action.declineStatus,
      };

    case HOST_MODAL_START:
      return {
        ...state,
        isDeclineStatus: action.isDeclineStatus,
      };

    case HOST_MODAL_SUCCESS:
      return {
        ...state,
        isDeclineStatus: action.isDeclineStatus,
      };

    case ADD_PAYMENT_START:
      return {
        ...state,
        isLoader: action.isLoader,
      };

    case ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoader: action.isLoader,
      };

    case ADD_PAYMENT_ERROR:
      return {
        ...state,
        isLoader: action.isLoader,
      };

    case SEND_MESSAGE_START:
      return {
        ...state,
        sendMessageLoader: action.sendMessageLoader,
      };

    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMessageLoader: action.sendMessageLoader,
      };

    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        sendMessageLoader: action.sendMessageLoader,
      };

    case SUBSCRIPTION_MODAL_START:
      return {
        ...state,
        subscriptionStatus: action.subscriptionStatus,
      };

    case SUBSCRIPTION_MODAL_CLOSE:
      return {
        ...state,
        subscriptionStatus: action.subscriptionStatus,
      };

    case DECLINE_REASON_MODAL_OPEN:
      return {
        ...state,
        declineReasonModal: action.declineReasonModal,
      };

    case DECLINE_REASON_MODAL_CLOSE:
      return {
        ...state,
        declineReasonModal: action.declineReasonModal,
      };

    default:
      return state;
  }
}
