import {
  CONNECTION_SEARCH_ERROR,
  CONNECTION_SEARCH_START,
  CONNECTION_SEARCH_SUCCESS,
} from '../constants';

export default function connectionMember(state = {}, action) {
  switch (action.type) {
    case CONNECTION_SEARCH_START:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case CONNECTION_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        data: action.data,
      };

    case CONNECTION_SEARCH_ERROR:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    default:
      return state;
  }
}
