import React from 'react';

import UserLayout from '../../components/Layout/UserLayout';

import TransactionContainer from './TransactionContainer';

const title = 'Golightly | Transaction History';

export default {
  path: '/user/transaction',

  action({ store }) {
    /*  From Redux Store */
    const { isAuthenticated } = store.getState().runtime;

    if (!isAuthenticated) {
      return { redirect: '/login' };
    }

    return {
      title,
      component: (
        <UserLayout>
          <TransactionContainer mode="completed" />
        </UserLayout>
      ),
    };
  },
};
