import {
  ICON_SETTING_NEGATIVE_UPLOAD_ERROR,
  ICON_SETTING_NEGATIVE_UPLOAD_START,
  ICON_SETTING_NEGATIVE_UPLOAD_SUCCESS,
  ICON_SETTING_UPLOAD_ERROR,
  ICON_SETTING_UPLOAD_LOADER_START,
  ICON_SETTING_UPLOAD_START,
  ICON_SETTING_UPLOAD_SUCCESS,
  PICTURE_SETTING_UPLOAD_ERROR,
  PICTURE_SETTING_UPLOAD_LOADER_START,
  PICTURE_SETTING_UPLOAD_START,
  PICTURE_SETTING_UPLOAD_SUCCESS,
  REMOVE_PICTURE_SETTING_UPLOAD_SUCCESS,
} from '../../../constants';

export default function iconUploadLoader(state = {}, action) {
  switch (action.type) {
    case ICON_SETTING_UPLOAD_LOADER_START:
      return {
        ...state,
        iconUploadLoader: true,
      };

    case ICON_SETTING_UPLOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        iconUploadLoader: action.payload.iconUploadLoader,
      };

    case ICON_SETTING_UPLOAD_ERROR:
      return {
        ...state,
        data: action.payload.data,
        iconUploadLoader: action.payload.iconUploadLoader,
      };

    case ICON_SETTING_UPLOAD_START:
      return {
        ...state,
        iconUploadLoader: action.payload.iconUploadLoader,
      };

    case PICTURE_SETTING_UPLOAD_LOADER_START:
      return {
        ...state,
        profilePicUploadLoader: true,
      };
    case REMOVE_PICTURE_SETTING_UPLOAD_SUCCESS:
      return {
        ...state,
        profilePicUploadLoader: false,
      };
    case PICTURE_SETTING_UPLOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        profilePicUploadLoader: action.payload.profilePicUploadLoader,
        uploadFileName: action.payload.uploadFileName,
        isUpload: action.payload.isUpload,
      };

    case PICTURE_SETTING_UPLOAD_ERROR:
      return {
        ...state,
        data: action.payload.data,
        profilePicUploadLoader: action.payload.profilePicUploadLoader,
      };

    case PICTURE_SETTING_UPLOAD_START:
      return {
        ...state,
        profilePicUploadLoader: action.payload.profilePicUploadLoader,
      };

    case ICON_SETTING_NEGATIVE_UPLOAD_START:
      return {
        ...state,
        // iconUploadLoader: action.payload.iconUploadLoader,
        negativeIconUpLoader: action.payload.negativeIconUpLoader,
      };

    case ICON_SETTING_NEGATIVE_UPLOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        // iconUploadLoader: action.payload.iconUploadLoader,
        negativeIconUpLoader: action.payload.negativeIconUpLoader,
      };

    case ICON_SETTING_NEGATIVE_UPLOAD_ERROR:
      return {
        ...state,
        data: action.payload.data,
        // iconUploadLoader: action.payload.iconUploadLoader
        negativeIconUpLoader: action.payload.negativeIconUpLoader,
      };

    default:
      return state;
  }
}
