import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { Col, Panel, Row } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { onChangeListing } from '../../actions/Listing/onChangeListing';
import { payoutChangeListing } from '../../actions/Payout/payoutChangeListing';
import { backendUrl } from '../../config';
import messages from '../../locale/messages';
import CustomPagination from '../CustomPagination';
import Loader from '../Loader';

import CompletedTransactions from './Completed/CompletedTransactions';
import FutureTransactions from './Future/FutureTransactions';
import GrossEarnings from './GrossEarnings';
import Listings from './Listings';
import Payouts from './Payouts';
import TotalAmount from './TotalAmount';
import s from './Transaction.module.css';

class Transaction extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.func,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      getTransactionHistory: PropTypes.shape({
        count: PropTypes.number.isRequired,
        reservationData: PropTypes.arrayOf(
          PropTypes.shape({
            hostId: PropTypes.string.isRequired,
            checkIn: PropTypes.string.isRequired,
            checkOut: PropTypes.string.isRequired,
            confirmationCode: PropTypes.number.isRequired,
            listData: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            guestData: PropTypes.shape({
              firstName: PropTypes.string.isRequired,
            }),
            hostTransaction: PropTypes.shape({
              payoutId: PropTypes.number,
              payEmail: PropTypes.string,
              amount: PropTypes.number,
              currency: PropTypes.string,
              createdAt: PropTypes.string,
            }),
          })
        ),
      }),
    }).isRequired,
  };

  static defaultProps = {
    data: {
      loading: true,
      getTransactionHistory: {
        count: null,
        reservationData: [],
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 'completed',
      currentPage: 1,
      payoutId: null,
      listId: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.paginationData = this.paginationData.bind(this);
  }

  handleChange(page, payoutId, listId) {
    const {
      data: { refetch },
    } = this.props;
    const variables = {
      mode: page,
      currentPage: 1,
      payoutId,
      listId,
    };
    this.setState({ page, currentPage: 1, payoutId, listId });
    refetch(variables);
  }

  handleChangeFirst(page) {
    const {
      data: { refetch },
    } = this.props;
    const { onChangeListing, payoutChangeListing } = this.props;
    this.setState({ page, currentPage: 1, payoutId: null, listId: null });
    onChangeListing(null);
    payoutChangeListing(null);
    this.handleChange(page);
  }

  paginationData(currentPage) {
    const {
      data: { refetch },
    } = this.props;
    const variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
    window.scrollTo(0, 0);
  }

  render() {
    const {
      data: { loading, getTransactionHistory, refetch },
    } = this.props;
    const { page, currentPage, payoutId, listId } = this.state;
    const { formatMessage } = this.props.intl;
    let showListings;
    let showPayouts;
    let showTotal;
    let userId;
    const page1Active = page === 'completed' ? s.active : '';
    const page2Active = page === 'future' ? s.active : '';
    const page3Active = page === 'grossEarnings' ? s.active : '';
    if (page === 'completed' || page === 'grossEarnings') {
      showPayouts = true;
    }
    if (page === 'completed' || page === 'future') {
      showTotal = true;
    }

    if (
      !loading &&
      getTransactionHistory &&
      getTransactionHistory.reservationData
    ) {
      if (getTransactionHistory.reservationData.length > 0) {
        userId = getTransactionHistory.reservationData[0].hostId;
      }
    }

    return (
      <Panel
        className={cx('transactionPanel', s.panelHeader)}
        header={
          <ul className={cx('list-inline', s.noMargin)}>
            <li className={page1Active}>
              <a
                className={s.tabItem}
                onClick={() => this.handleChangeFirst('completed')}
              >
                <FormattedMessage {...messages.completedTransactions} />
              </a>
            </li>
            <li className={page2Active}>
              <a
                className={s.tabItem}
                onClick={() => this.handleChangeFirst('future')}
              >
                <FormattedMessage {...messages.futureTransactions} />
              </a>
            </li>
            <li className={page3Active}>
              <a
                className={s.tabItem}
                onClick={() => this.handleChangeFirst('grossEarnings')}
              >
                <FormattedMessage {...messages.grossEarnings} />
              </a>
            </li>
          </ul>
        }
      >
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {!loading &&
              showTotal &&
              getTransactionHistory &&
              getTransactionHistory.reservationData && (
                <TotalAmount
                  className={cx(s.space2, s.spaceTop1)}
                  data={getTransactionHistory.reservationData}
                  mode={page}
                />
              )}

            {!loading && showPayouts && (
              <div className={s.select}>
                <Payouts
                  className={cx(s.formWidth, s.formControlSelect, s.space1)}
                  defaultLabel={formatMessage(messages.allPayoutMethod)}
                  handleResults={this.handleChange}
                  listId={listId}
                  mode={page}
                  payoutId={payoutId}
                  refetch={refetch}
                />
              </div>
            )}
            {!loading && (
              <div className={s.select}>
                <Listings
                  className={cx(s.formWidth, s.formControlSelect, s.space1)}
                  handleResults={this.handleChange}
                  listId={listId}
                  mode={page}
                  payoutId={payoutId}
                  refetch={refetch}
                />
              </div>
            )}
            {!loading &&
              getTransactionHistory &&
              getTransactionHistory.count > 0 && (
                <div className={s.csvExport}>
                  <a
                    href={`${backendUrl}/export-transaction?type=${page}&userId=${userId}&listId=${listId}`}
                  >
                    <FormattedMessage {...messages.exportCSV} />
                  </a>
                </div>
              )}
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {loading && <Loader type="text" />}
            {!loading &&
              page === 'completed' &&
              getTransactionHistory &&
              getTransactionHistory.reservationData && (
                <CompletedTransactions
                  data={getTransactionHistory.reservationData}
                />
              )}

            {!loading &&
              page === 'future' &&
              getTransactionHistory &&
              getTransactionHistory.reservationData && (
                <FutureTransactions
                  data={getTransactionHistory.reservationData}
                />
              )}

            {!loading &&
              page === 'grossEarnings' &&
              getTransactionHistory &&
              getTransactionHistory.reservationData && (
                <GrossEarnings data={getTransactionHistory.reservationData} />
              )}
            {getTransactionHistory &&
            getTransactionHistory.count &&
            getTransactionHistory.count > 0 ? (
              <div>
                <CustomPagination
                  change={this.paginationData}
                  componentReference={page}
                  currentPage={currentPage}
                  defaultCurrent={1}
                  defaultPageSize={5}
                  paginationLabel={formatMessage(messages.transactions)}
                  total={getTransactionHistory.count}
                />
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Panel>
    );
  }
}

const mapState = (state) => ({
  base: state.currency.base,
  rates: state.currency.rates,
  toCurrency: state.currency.to,
});
const mapDispatch = {
  onChangeListing,
  payoutChangeListing,
};
export default compose(connect(mapState, mapDispatch), injectIntl)(Transaction);
