import {
  CHECK_AVAILABLE_DATES_ERROR,
  CHECK_AVAILABLE_DATES_START,
  CHECK_AVAILABLE_DATES_SUCCESS,
  CONTACT_HOST_CLOSE,
  CONTACT_HOST_OPEN,
  GET_CHECKIN_RULE_DAYS_END,
  GET_CHECKIN_RULE_DAYS_ERROR,
  GET_CHECKIN_RULE_DAYS_START,
  GET_FULL_LISTING_BLOCKED_DATES_ERROR,
  GET_FULL_LISTING_BLOCKED_DATES_START,
  GET_FULL_LISTING_BLOCKED_DATES_SUCCESS,
  GET_LISTING_BLOCKED_DATES_ERROR,
  GET_LISTING_BLOCKED_DATES_START,
  GET_LISTING_BLOCKED_DATES_SUCCESS,
  GET_LISTING_SPECIAL_PRICING_ERROR,
  GET_LISTING_SPECIAL_PRICING_START,
  GET_LISTING_SPECIAL_PRICING_SUCCESS,
  GET_LISTING_SPECIFIC_FIELDS_DATA_SUCCESS,
  GET_REVIEW_DATA_SUCCESS,
  HOSTFULLY_SYNC_ERROR,
  HOSTFULLY_SYNC_PROCESS,
  HOSTFULLY_SYNC_START,
  HOSTFULLY_SYNC_SUCCESS,
  IMAGE_LIGHTBOX_CLOSE,
  IMAGE_LIGHTBOX_OPEN,
  MAKE_SPECIAL_PRICE_CLOSE,
  MAKE_SPECIAL_PRICE_OPEN,
  RENTALS_UNITED_DATES_ERROR,
  RENTALS_UNITED_DATES_START,
  RENTALS_UNITED_DATES_SUCCESS,
  SAVE_BLOCKED_DATES,
  UPDATE_CHECKIN_RULE_END,
  UPDATE_CHECKIN_RULE_ERROR,
  UPDATE_CHECKIN_RULE_START,
} from '../constants';

export default function viewListing(state = {}, action) {
  switch (action.type) {
    case CHECK_AVAILABLE_DATES_START:
      return {
        ...state,
        isLoading: action.isLoading,
        maximumStay: action.payload.maximumStay,
        minimumStay: action.payload.minimumStay,
      };

    case CHECK_AVAILABLE_DATES_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        availability: action.availability,
        availabilityMessage: action.availabilityMessage,
        maximumStay: action.payload.maximumStay,
        minimumStay: action.payload.minimumStay,
        currentMinimumStay: action.payload.currentMinimumStay,
      };

    case CHECK_AVAILABLE_DATES_ERROR:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case GET_LISTING_SPECIFIC_FIELDS_DATA_SUCCESS:
      return {
        ...state,
        settingsData: action.settingsData,
      };

    case IMAGE_LIGHTBOX_OPEN:
      return {
        ...state,
        imageLightBox: action.imageLightBox,
        currentIndex: action.currentIndex,
        images: action.images,
      };

    case IMAGE_LIGHTBOX_CLOSE:
      return {
        ...state,
        imageLightBox: action.imageLightBox,
        images: [],
      };

    case CONTACT_HOST_OPEN:
      return {
        ...state,
        showContactHostModal: action.payload.showContactHostModal,
      };

    case CONTACT_HOST_CLOSE:
      return {
        ...state,
        showContactHostModal: action.payload.showContactHostModal,
      };

    case GET_LISTING_SPECIAL_PRICING_START:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        specialPricing: [],
      };

    case GET_LISTING_SPECIAL_PRICING_SUCCESS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        specialPricing: action.payload.specialPricing,
      };

    case GET_LISTING_SPECIAL_PRICING_ERROR:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        specialPricing: [],
      };

    case GET_REVIEW_DATA_SUCCESS:
      return {
        ...state,
        negativeReviewCount: action.payload.negativeReviewCount,
      };

    case SAVE_BLOCKED_DATES:
      return {
        ...state,
        blockedDates: action.payload.blockedDates,
      };
    case MAKE_SPECIAL_PRICE_OPEN:
      return {
        ...state,
        showSpecialPrice: action.payload.showSpecialPrice,
      };

    case MAKE_SPECIAL_PRICE_CLOSE:
      return {
        ...state,
        showSpecialPrice: action.payload.showSpecialPrice,
      };

    case HOSTFULLY_SYNC_SUCCESS:
      return {
        ...state,
        hostFullySyncOver: action.payload.hostFullySyncOver,
        hostFullySyncInprogress: action.payload.hostFullySyncInprogress,
      };

    case HOSTFULLY_SYNC_PROCESS:
      return {
        ...state,
        syncProgress: action.payload.progressValue,
      };

    case HOSTFULLY_SYNC_ERROR:
      return {
        ...state,
        hostFullySyncOver: action.payload.hostFullySyncOver,
        hostFullySyncInprogress: action.payload.hostFullySyncInprogress,
        hostFullySyncError: action.payload.hostFullySyncError,
      };

    case HOSTFULLY_SYNC_START:
      return {
        ...state,
        hostFullySyncInprogress: action.payload.hostFullySyncInprogress,
        hostFullyAgencyId: action.payload.hostFullyAgencyId,
        rentalsId: action.payload.rentalsId,
        nextPaxId: action.payload.nextPaxId,
      };

    case GET_LISTING_BLOCKED_DATES_START:
      return {
        ...state,
        isListBlocked: [],
      };

    case GET_LISTING_BLOCKED_DATES_SUCCESS:
      return {
        ...state,
        isListBlocked: action.payload.isListBlocked,
        userId: action.payload.userId,
      };

    case GET_LISTING_BLOCKED_DATES_ERROR:
      return {
        ...state,
        isListBlocked: [],
      };

    case GET_FULL_LISTING_BLOCKED_DATES_START:
      return {
        ...state,
        isFullDayBlock: [],
      };

    case GET_FULL_LISTING_BLOCKED_DATES_SUCCESS:
      return {
        ...state,
        isFullDayBlock: action.payload.isFullDayBlock,
      };

    case GET_FULL_LISTING_BLOCKED_DATES_ERROR:
      return {
        ...state,
        isFullDayBlock: [],
      };

    case RENTALS_UNITED_DATES_START:
      return {
        ...state,
        isMinStay: 0,
      };

    case RENTALS_UNITED_DATES_SUCCESS:
      return {
        ...state,
        isMinStay: action.payload.isMinStay,
      };

    case RENTALS_UNITED_DATES_ERROR:
      return {
        ...state,
        isMinStay: 0,
      };

    case GET_CHECKIN_RULE_DAYS_START:
      return {
        ...state,
        checkInDays: [],
      };

    case GET_CHECKIN_RULE_DAYS_END:
      return {
        ...state,
        checkInDays: action.payload && action.payload.checkInDays,
      };

    case GET_CHECKIN_RULE_DAYS_ERROR:
      return {
        ...state,
        checkInDays: [],
      };

    case UPDATE_CHECKIN_RULE_START:
      return {
        ...state,
        isCheckInDayLoading: true,
      };

    case UPDATE_CHECKIN_RULE_END:
      return {
        ...state,
        isCheckInDayLoading: false,
      };

    case UPDATE_CHECKIN_RULE_ERROR:
      return {
        ...state,
        isCheckInDayLoading: false,
      };

    default:
      return state;
  }
}
