import { gql } from 'react-apollo';

import {
  UPDATE_SOCIAL_RULES_ERROR,
  UPDATE_SOCIAL_RULES_START,
  UPDATE_SOCIAL_RULES_SUCCESS,
} from '../../constants';
import history from '../../core/history';
import { loadAccount } from '../account';
import { setLoaderComplete, setLoaderStart } from '../loader/loader';

const mutation = gql`
  mutation updateBlogRules {
    updateBlogRules {
      status
      errorMessage
    }
  }
`;

export const openSocialRulesModal = () => async (dispatch) => {
  dispatch(setLoaderStart('isSocialBlogRulesOpen'));
};

export const closeSocialRulesModal = () => async (dispatch) => {
  dispatch(setLoaderComplete('isSocialBlogRulesOpen'));
};

export const socialBlogRules = () => async (dispatch, getState, { client }) => {
  try {
    dispatch({ type: UPDATE_SOCIAL_RULES_START });
    const { data } = await client.mutate({
      mutation,
      fetchPolicy: 'network-only',
    });
    await dispatch(closeSocialRulesModal());
    await dispatch(loadAccount());
    dispatch({ type: UPDATE_SOCIAL_RULES_SUCCESS });
    history.push('/social');
  } catch (error) {
    dispatch({
      type: UPDATE_SOCIAL_RULES_ERROR,
      payload: {
        error,
      },
    });
    return false;
  }
  return true;
};
