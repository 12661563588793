import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import createRootReducer from '../reducers';

import createHelpers from './createHelpers';

export default function configureStore(initialState, config) {
  const helpers = createHelpers(config);
  const { apolloClient } = config;

  const middleware = [
    thunk.withExtraArgument(helpers),
    apolloClient.middleware(),
  ];

  const rootReducer = createRootReducer({
    apolloClient,
  });

  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
  );

  return store;
}
