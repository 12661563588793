import React from 'react';

import PropTypes from 'prop-types';

import { sitename } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import CurrencyView from '../modules/CurrencyView';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class ClaimSecurityFeeGuest extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      profileId: PropTypes.number,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const {
      content: {
        listTitle,
        hostName,
        guestName,
        city,
        zipcode,
        reservationId,
        reason,
        claimedFee,
      },
    } = this.props;
    const {
      content: { checkIn, checkOut, currency },
    } = this.props;
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi {guestName},</div>
          <EmptySpace height={20} />
          <div>
            Hope you enjoyed your stay at {listTitle}. The host has claimed{' '}
            <CurrencyView amount={claimedFee} currency={currency} /> for{' '}
            {reason} during your recent stay from {checkIn} to {checkOut}. The
            admin is reviewing this and you might be charged for the mentioned
            amount.
          </div>

          <EmptySpace height={30} />

          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default ClaimSecurityFeeGuest;
