import React from 'react';

import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class SyncProperty extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      receiverName: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
      senderName: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      threadId: PropTypes.number.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkout: PropTypes.string.isRequired,
      personCapacity: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const {
      content: { titles, firstName, email, platform, city, state, country },
    } = this.props;
    const listURL = `${url}/rooms/`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi Admin,</div>
                  <EmptySpace height={40} />
                  {firstName && <div>First Name: {firstName}</div>}
                  {email && <div>User Email Id: {email}</div>}
                  {platform && <div>Platform: {platform}</div>}
                  {/* {
                                        listId && <div>
                                            Title:{' '}<a href={listURL}>
                                                {title}
                                            </a>
                                        </div>
                                    } */}
                  {
                    <div>
                      Title:{' '}
                      {titles &&
                        titles.length > 0 &&
                        titles.map((item, key) => {
                          if (titles && titles.length === 1) {
                            return (
                              <span key={key}>
                                <a href={listURL + item.id}>{item.title} </a>
                              </span>
                            );
                          }
                          if (titles.length - 1 === key) {
                            return (
                              <span key={key}>
                                <a href={listURL + item.id}>{item.title} </a>
                              </span>
                            );
                          }
                          return (
                            <span key={key}>
                              <a href={listURL + item.id}>{item.title}, </a>
                            </span>
                          );
                        })}
                    </div>
                  }
                  {(city || state || country) && (
                    <div>
                      Location: {city && <span>{city}, </span>}
                      {state && <span>{state}, </span>}
                      {country && <span>{country}</span>}
                    </div>
                  )}
                  <EmptySpace height={40} />
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default SyncProperty;
