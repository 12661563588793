import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';

import '../rc-pagination-common.css';
import './index.css';

class CustomPagination extends Component {
  static propTypes = {
    total: PropTypes.number.isRequired,
    defaultCurrent: PropTypes.number.isRequired,
    defaultPageSize: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    paginationLabel: PropTypes.string,
  };

  static defaultProps = {
    paginationLabel: 'items',
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.renderShowTotal = this.renderShowTotal.bind(this);
  }

  handleChange(currentPage, size) {
    const { change } = this.props;
    change(currentPage);
  }

  renderShowTotal(total, range) {
    const { paginationLabel } = this.props;

    return (
      <div className="resultsCount">
        <span>{range[0]}</span>
        <span>&nbsp;–&nbsp;</span>
        <span>{range[1]}</span>
        <span>&nbsp;of&nbsp;</span>
        <span>{total}</span>
        <span>&nbsp;{paginationLabel}</span>
      </div>
    );
  }

  render() {
    const { total, defaultCurrent, defaultPageSize, currentPage } = this.props;

    return (
      <div className="spaceTop4">
        <Pagination
          className="ant-pagination"
          current={currentPage}
          defaultCurrent={defaultCurrent}
          defaultPageSize={defaultPageSize}
          locale="en_US"
          onChange={this.handleChange}
          showLessItems
          showTotal={(total, range) => this.renderShowTotal(total, range)}
          total={total}
        />
      </div>
    );
  }
}

export default CustomPagination;

// showTotal={(total, range) => this.renderShowTotal(total, range)}
