import {
  ADMIN_BED_TYPE_HOST_FULLY_SUCCESS,
  ADMIN_BED_TYPE_RENTALS_UNITED_SUCCESS,
} from '../../../constants';

export default function getBeds(state = {}, action) {
  switch (action.type) {
    case ADMIN_BED_TYPE_RENTALS_UNITED_SUCCESS:
      return {
        ...state,
        getRentalsUnited: action.payload.getRentalsUnited,
      };

    case ADMIN_BED_TYPE_HOST_FULLY_SUCCESS:
      return {
        ...state,
        getHostfully: action.payload.getHostfully,
      };

    default:
      return state;
  }
}
