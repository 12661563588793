export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeEachFirstLetter(string) {
  return string.toLowerCase().replace(/\b./g, (a) => a.toUpperCase());
}

export function findWordInHtml(
  value = '',
  countStart,
  countEnd = countStart + 50
) {
  const content = String(value);
  // let removeHtmlTagRegex = new RegExp(/<((?!<)(.|\n))*?\>/gi);
  const removeHtmlTagRegex = new RegExp(/(<([^>]+)>)/gi);
  const removeUnwantedSymbol = new RegExp(/&([a-z]+);/gi); // eg: &nbsp; or &lt;
  const unWantedRegex1 = new RegExp(/<.*/gi);
  let increamentCount = countStart;
  let start = 0;

  while (start < 20) {
    const prevCount = content
      .slice(0, increamentCount)
      .replace(removeHtmlTagRegex, '')
      .replace(removeUnwantedSymbol, ' ')
      .replace(unWantedRegex1, '').length;
    if (countEnd >= prevCount <= countStart) {
      const addCount =
        countStart - prevCount >= 0 ? countStart - prevCount : countStart;
      increamentCount += addCount;
      start += 1;
    }
  }

  const wordWithoutHtmlTag = content
    .replace(removeHtmlTagRegex, '')
    .replace(removeUnwantedSymbol, ' ')
    .replace(unWantedRegex1, '');
  const restArray = wordWithoutHtmlTag.slice(
    countStart,
    wordWithoutHtmlTag.length
  );

  return { wordsCount: increamentCount, restArray };
}

export const seperateHtmlTag = (value = '') => {
  const removeHtmlTagRegex = new RegExp(/(<([^>]+)>)/gi);
  const removeUnwantedSymbol = new RegExp(/&([a-z]+);/gi);
  return value
    .replace(removeHtmlTagRegex, '')
    .replace(removeUnwantedSymbol, ' ');
};

export const removeMentionedUserInPostContent = (str) => {
  if (typeof window !== 'undefined') {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    const elements = doc.getElementsByClassName('mention');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
    return doc.body && doc.body.innerHTML;
  }
};
