/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { gql } from 'react-apollo';
import { change } from 'redux-form';

import {
  SET_USER_DATA_ERROR,
  SET_USER_DATA_START,
  SET_USER_DATA_SUCCESS,
} from '../constants';
import history from '../core/history';
import { decrypt } from '../helpers/encryptStringHelper';

import { openVerifyModal } from './VerificationModal/verifyModal';
import { getCurrencyRates } from './getCurrencyRates';
import { setRuntimeVariable } from './runtime';

const query = gql`
  query userAccount {
    userAccount {
      id
      userId
      profileId
      firstName
      lastName
      displayName
      gender
      dateOfBirth
      email
      decryptEmail
      phoneNumber
      dialCode
      preferredLanguage
      preferredCurrency
      location
      info
      currentPosition
      education
      universityId
      companyName
      linkedinUrl
      createdAt
      picture
      country
      summary
      countryCode
      phoneCountryCode
      industry
      referralKey
      loginKey
      adminInviteLimit
      maxInvite
      travelCredit
      stillInvite
      totalInvites
      inviteCount
      inviteExpiry
      intend
      isProfileUpdated
      otherUniversityLabel
      otherUniversity
      secondaryEmail
      socialMediaUrl
      propertyManager
      isCompleted
      zipCode
      identityAppId
      userType
      hostFullyAgencyId
      rentalsUnitedId
      nextPaxId
      ownerRezId
      hostAwayId
      hostAwaySecret
      userAccess
      bookWithoutPay
      verification {
        id
        isEmailConfirmed
        isFacebookConnected
        isGoogleConnected
        isIdVerification
        isPhoneVerified
        emailVerificationCount
        isGovtProofVerification
        isLinkedinConnected
        isGovtProofVerificationImageCount
      }
      userData {
        id
        type
      }
      memberRecords {
        id
        isVerified
      }
      socialEnable
      socialNotification
      step1
      step2
      step3
      step4
      isDiamond
    }
  }
`;

export function loadAccount(
  loginScreen,
  refer,
  isVerifyModal = false,
  isInitial
) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: SET_USER_DATA_START,
      payload: {
        uploadFileName: '',
      },
    });

    try {
      // const { data } = await graphqlRequest(query);
      const { data } = await client.query({
        query,
        fetchPolicy: 'network-only',
      });
      const isAdmin = getState().runtime.isAdminAuthenticated;

      if (data && data.userAccount) {
        if (isInitial && data.userAccount.preferredCurrency) {
          await dispatch(getCurrencyRates(data.userAccount.preferredCurrency));
        }
        if (
          isVerifyModal &&
          data.userAccount.userType === 'memberForm' &&
          !data.userAccount.memberRecords.isVerified &&
          !data.userAccount.picture
        ) {
          dispatch(openVerifyModal());
        } else if (
          isVerifyModal &&
          (!data.userAccount.userType ||
            data.userAccount.userType === 'inviteForm') &&
          !data.userAccount.picture
        ) {
          dispatch(openVerifyModal());
        }
        const dateOfBirth = decrypt(data.userAccount.dateOfBirth);
        let updatedProfileData;
        if (dateOfBirth) {
          const dateOfBirthArray = dateOfBirth.split('-');
          const dateOfBirthObj = {
            month: Number(dateOfBirthArray[0] - 1),
            year: dateOfBirthArray[1],
            day: dateOfBirthArray[2],
          };

          const decryptedObject = {
            email: decrypt(data && data.userAccount && data.userAccount.email),
            phoneNumber: decrypt(
              data && data.userAccount && data.userAccount.phoneNumber
            ),
            secondaryEmail: decrypt(
              data && data.userAccount && data.userAccount.secondaryEmail
            ),
          };
          updatedProfileData = {
            ...data.userAccount,
            ...dateOfBirthObj,
            secEmail: decrypt(data.userAccount.secondaryEmail),
            isPhoneVerified: data.userAccount.verification.isPhoneVerified,
            ...decryptedObject,
          };
        } else {
          const decryptedObject = {
            email: decrypt(data.userAccount.email),
            phoneNumber: decrypt(data.userAccount.phoneNumber),
            secondaryEmail: decrypt(
              data && data.userAccount && data.userAccount.secondaryEmail
            ),
          };
          updatedProfileData = {
            ...data.userAccount,
            secEmail: decrypt(data.userAccount.secondaryEmail),
            isPhoneVerified: data.userAccount.verification.isPhoneVerified,
            ...decryptedObject,
          };
        }

        dispatch(
          change(
            'EditProfileForm',
            'isPhoneVerified',
            data.userAccount.verification.isPhoneVerified
          )
        );
        dispatch(
          change(
            'EditProfileFormStepOne',
            'isPhoneVerified',
            data.userAccount.verification.isPhoneVerified
          )
        );
        dispatch(
          change(
            'EditProfileFormStepTwo',
            'isPhoneVerified',
            data.userAccount.verification.isPhoneVerified
          )
        );
        dispatch(
          change(
            'EditProfileFormStepThree',
            'isPhoneVerified',
            data.userAccount.verification.isPhoneVerified
          )
        );

        dispatch({
          type: SET_USER_DATA_SUCCESS,
          updatedProfileData,
        });
        if (isAdmin) {
          dispatch(
            setRuntimeVariable({
              name: 'isCompletedProfile',
              value: true,
            })
          );

          dispatch(
            setRuntimeVariable({
              name: 'isProfileUpdated',
              value: true,
            })
          );
        } else {
          dispatch(
            setRuntimeVariable({
              name: 'isCompletedProfile',
              value: data.userAccount.isCompleted,
            })
          );

          dispatch(
            setRuntimeVariable({
              name: 'isProfileUpdated',
              value: data.userAccount.isProfileUpdated,
            })
          );
        }

        if (loginScreen) {
          if (
            updatedProfileData &&
            updatedProfileData.userId === 'c505e590-f3bc-11e9-bdc7-0913579b3abc'
          ) {
            history.push('/');
          }

          dispatch({
            type: SET_USER_DATA_SUCCESS,
            updatedProfileData,
          });
          if (isAdmin) {
            dispatch(
              setRuntimeVariable({
                name: 'isCompletedProfile',
                value: true,
              })
            );

            dispatch(
              setRuntimeVariable({
                name: 'isProfileUpdated',
                value: true,
              })
            );
          } else {
            dispatch(
              setRuntimeVariable({
                name: 'isCompletedProfile',
                value: data.userAccount.isCompleted,
              })
            );

            dispatch(
              setRuntimeVariable({
                name: 'isProfileUpdated',
                value: data.userAccount.isProfileUpdated,
              })
            );
          }
        }

        if (loginScreen) {
          if (
            updatedProfileData &&
            updatedProfileData.userId === 'c505e590-f3bc-11e9-bdc7-0913579b3abc'
          ) {
            history.push('/');
          } else if (refer) {
            history.push(`${refer}`);
          } else {
            history.push('/dashboard');
          }
        }
        dispatch(
          setRuntimeVariable({
            name: 'isAuthenticated',
            value: true,
          })
        );
      }
    } catch (error) {
      dispatch({
        type: SET_USER_DATA_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }

    return true;
  };
}
