import {
  SEND_NR_BOOKING_DATA_START,
  SEND_NR_BOOKING_DATA_SUCCESS,
} from '../constants';

const initialState = {
  prices: null,
  loading: false,
};

export default function naturalRetreats(state = initialState, action) {
  switch (action.type) {
    case SEND_NR_BOOKING_DATA_START:
      return {
        ...state,
        prices: null,
        loading: true,
      };
    case SEND_NR_BOOKING_DATA_SUCCESS:
      return {
        ...state,
        prices: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
