import React from 'react';

import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import history from '../../core/history';
import messages from '../../locale/messages';
import ForgotPassword from '../ForgotPassword';
import LoginModal from '../LoginModal';
import NavLink from '../NavLink';
import ReferralModal from '../ReferralModal';
import SignupModal from '../SignupModal';

import s from './NavigationBeforeLogin.module.css';

class NavigationBeforeLogin extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    setUserLogout: PropTypes.func,
    openLoginModal: PropTypes.func,
    openSignupModal: PropTypes.func,
  };

  goBecomeMember = () => {
    history.push('/become-a-member');
  };

  render() {
    const { openLoginModal } = this.props;
    let signUpMsg = false;
    return (
      <div>
        <LoginModal signUpMsg={signUpMsg} />
        <SignupModal />
        <ReferralModal />
        <ForgotPassword />
        <Nav pullRight>
          <NavLink className="visible-xs visible-sm visible-md" to="/">
            <FormattedMessage {...messages.home} />
          </NavLink>
          <NavLink to="/go-solo">
            <FormattedMessage {...messages.goSolo} />
          </NavLink>
          <NavLink to="/social">
            <FormattedMessage {...messages.goSocial} />
            {/* <sup className={s.newSup}>
              <FormattedMessage {...messages.beta} />
            </sup> */}
          </NavLink>
          <NavLink to="/about-us">
            <FormattedMessage {...messages.about} />
          </NavLink>
          <NavLink to="/whoCanJoin">
            <FormattedMessage {...messages.whoCanJoin} />
          </NavLink>
          <NavLink noLink onClick={openLoginModal} to="#">
            <FormattedMessage {...messages.login} />
          </NavLink>
          <NavLink noLink onClick={() => this.goBecomeMember()} to="#">
            <FormattedMessage {...messages.signup} />
          </NavLink>
        </Nav>
      </div>
    );
  }
}

const mapState = (state) => ({
  account: state.account.data,
  getBlog: state.currency.getBlog,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(NavigationBeforeLogin);
