import React from 'react';

import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class InviteEmailAdmin extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      link: PropTypes.string.isRequired,
      InviterName: PropTypes.string.isRequired,
      // name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'normal',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#ff5a5f',
      backgroundColor: '#ff5a5f',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const {
      content: {
        link,
        InviterName,
        referralKey,
        name,
        email,
        personalText,
        dateOfBirth,
        profileId,
        userEmail,
      },
    } = this.props;
    const {
      content: { emailList, phoneNumber },
    } = this.props;

    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };
    let actionURL = `${url}/users/show/${profileId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>
            <div>Hi Admin,</div>
          </div>
          <EmptySpace height={20} />
          <div>
            <a href={actionURL} style={linkText}>
              {InviterName}
              {userEmail ? ` (${userEmail})` : ''}
            </a>{' '}
            has invited {emailList && <span>{emailList}</span>}{' '}
            {phoneNumber && <span>{phoneNumber}</span>} to join Golightly
            {/* {phoneNumber && <span>{phoneNumber}</span>}  */}
            {personalText && (
              <div>with an additional message: {personalText}</div>
            )}
          </div>

          <EmptySpace height={30} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default InviteEmailAdmin;
