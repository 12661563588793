import {
  FETCH_SEARCH_RESULTS_ERROR,
  FETCH_SEARCH_RESULTS_START,
  FETCH_SEARCH_RESULTS_SUCCESS,
  LOADING_SEARCH_RESULTS,
} from '../constants';

export function getSearchResults(data) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: FETCH_SEARCH_RESULTS_START,
    });

    try {
      if (data) {
        // Dispatch a success action
        dispatch({
          type: FETCH_SEARCH_RESULTS_SUCCESS,
          payload: {
            data,
            isResultLoading: false,
            isBarLoading: false,
          },
        });
      } else {
        dispatch({
          type: FETCH_SEARCH_RESULTS_ERROR,
          payload: {
            isResultLoading: false,
            isBarLoading: false,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_SEARCH_RESULTS_ERROR,
        payload: {
          error,
          isResultLoading: false,
          isBarLoading: false,
        },
      });
      return false;
    }

    return true;
  };
}

export function loadingSearchResults() {
  return (dispatch) => {
    dispatch({
      type: LOADING_SEARCH_RESULTS,
      payload: {
        isBarLoading: true,
      },
    });
  };
}
