import React from 'react';

import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class RequestConnectionAdmin extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      profileId: PropTypes.number,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'normal',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const {
      content: {
        profileId,
        name,
        name2,
        connectionMessage,
        guestId,
        userEmail,
        loginEmail,
      },
    } = this.props;
    const hostProfileId = `${url}/users/show/${profileId}`;
    const guestProfileId = `${url}/users/show/${guestId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          <div>
            <a href={guestProfileId}>
              {name}
              {userEmail ? ` (${userEmail})` : ''}
            </a>{' '}
            has a new connection request from{' '}
            <a href={hostProfileId}>
              {name2}
              {loginEmail ? ` (${loginEmail})` : ''}
            </a>
            .
          </div>
          <EmptySpace height={10} />
          {connectionMessage && <div>Message: {connectionMessage}</div>}
          {connectionMessage && <EmptySpace height={10} />}
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default RequestConnectionAdmin;
