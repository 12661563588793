import messages from '../../locale/messages';

const validate = (values) => {
  const errors = {};

  if (!values.referralCode) {
    errors.referralCode = messages.referralCodeRequired;
  }
  return errors;
};

export default validate;
