import React from 'react';

import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class SocialBlogPostApproval extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const btnCenter = {
      textAlign: 'center',
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'normal',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const {
      content: { id, userName, profileId },
    } = this.props;

    const socialBlogUrl = `${url}/social/${id}`;
    const userProfileURL = `${url}/users/show/${profileId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          <div>
            <a href={userProfileURL}>{capitalizeFirstLetter(userName)}</a> has
            submitted a new post for your review. Please take the necessary
            action.
          </div>
          <EmptySpace height={20} />
          <div className={btnCenter}>
            <a href={socialBlogUrl} style={buttonStyle}>
              Accept or Decline
            </a>
          </div>
          <EmptySpace height={30} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default SocialBlogPostApproval;
