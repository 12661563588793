import {
  SEND_VERIFICATION_SMS_START,
  SEND_VERIFICATION_SMS_SUCCESS,
} from '../constants';

export default function phoneVerification(state = {}, action) {
  switch (action.type) {
    case SEND_VERIFICATION_SMS_START:
      return {
        ...state,
        dialCode: null,
        phoneNumber: null,
        verificationCode: null,
      };
    case SEND_VERIFICATION_SMS_SUCCESS:
      return {
        ...state,
        dialCode: action.payload.dialCode,
        phoneNumber: action.payload.phoneNumber,
        verificationCode: action.payload.verificationCode,
      };
    default:
      return state;
  }
}
