import React, { Component } from 'react';

import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import * as FontAwesome from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import messages from '../../locale/messages';
import NavLink from '../NavLink';

import s from './Message.module.css';

const UnreadThreadsQuery = loader('./getUnreadThreads.graphql');

class Message extends Component {
  static propTypes = {
    allUnreadThreadsCount: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      getUnreadCount: PropTypes.shape({
        total: PropTypes.number.isRequired,
      }),
    }),
  };

  static defaultProps = {
    allUnreadThreadsCount: {
      loading: true,
      getUnreadCount: {
        total: 0,
      },
    },
  };

  render() {
    const {
      allUnreadThreadsCount: { loading, getUnreadCount },
    } = this.props;
    let count = 0;
    if (!loading && getUnreadCount) {
      count = getUnreadCount.total ? getUnreadCount.total : 0;
    }
    const isNewMessage = count > 0;

    return (
      <NavLink to="/inbox">
        <span>
          <FormattedMessage {...messages.messages} />
        </span>
        {isNewMessage && <FontAwesome.FaCircle className={s.notification} />}
      </NavLink>
    );
  }
}

export default compose(
  graphql(UnreadThreadsQuery, {
    name: 'allUnreadThreadsCount',
    options: {
      ssr: false,
      pollInterval: 5000,
    },
  })
)(Message);
