import React from 'react';

import PropTypes from 'prop-types';

import { url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class SendGoSoloPaymentLink extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      guestName: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const {
      content: { link, title, fullDate, roomType, guestName, profileId },
    } = this.props;
    let actionURL = `${url}/users/show/${profileId}`;
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'normal',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };
    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };
    const textCenter = {
      textAlign: 'center',
    };

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>
            Hi{' '}
            <a href={actionURL} style={linkText}>
              {guestName}
            </a>
            ,
          </div>
          <EmptySpace height={20} />
          <div>
            Find the payment link for GoSolo {title} on {fullDate} staying in{' '}
            {roomType}
          </div>
          <EmptySpace height={20} />
          <div>Please make the payment to confirm your trip</div>
          <EmptySpace height={20} />
          <div style={textCenter}>
            <a href={link} style={buttonStyle}>
              Pay now
            </a>
          </div>
          <EmptySpace height={20} />
          <div>
            More information will be sent out to you shortly confirming further
            specific details and if you have any questions, please feel free to
            contact us!
          </div>
          <EmptySpace height={20} />
          <div>Thanks,</div>
          <div>The Golightly Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default SendGoSoloPaymentLink;
