import aboutUs from './aboutUs';
import addPayout from './addPayout';
import addPayoutFailure from './addPayoutFailure';
import admin from './admin';
import becomeAMember from './becomeAMember';
import becomeHost from './becomeHost';
import blog from './blog';
import blogExplorePage from './blogExplorePage';
import book from './book';
import cancel from './cancel';
import cancellationPolicies from './cancellationPolicies';
import changePassword from './changePassword';
import cities from './cities';
import concierge from './concierge';
import confirmEmail from './confirmEmail';
import confirmgender from './confirmgender';
import connectionUser from './connectionUser';
import connections from './connections';
import contact from './contact';
import dashboard from './dashboard';
import documentVerification from './documentVerification';
import editProfile from './editProfile';
import event from './event';
import exploreCities from './exploreCities';
import goHustleMyJobs from './goHustleMyJobs';
import goHustleUserPost from './goHustleUserPost';
import goSoloHome from './goSoloHome';
import goSoloPaymentSummary from './goSoloPaymentSummary';
import goSoloReceipt from './goSoloReceipt';
import goSoloRemainingPayment from './goSoloRemainingPayment';
import goSoloUserBooking from './goSoloUserBooking';
import goSoloViewTrip from './goSoloViewTrip';
import goSoloWaitingPayment from './goSoloWaitingPayment';
import home from './home';
import idProofType from './idProofType';
import idProofVerification from './idProofVerification';
import inbox from './inbox';
import interhomePaymentStatus from './interhomePaymentStatus';
import inviteform from './inviteform';
import itinerary from './itinerary';
import localSpotMember from './localSpotMember';
import login from './login';
import malegenderInfo from './malegenderInfo';
import manageListing from './manageListing';
import mapClustering from './mapClustering';
import pageOne from './membership/PageOne';
import pageTwo from './membership/PageTwo';
import notFound from './notFound';
import ownerrezPaymentStatus from './ownerrezPaymentStatus';
import passwordVerification from './passwordVerification';
import payment from './payment';
import payout from './payout';
import pressLogin from './pressLogin';
import profile from './profile';
import profilePhoto from './profilePhoto';
import receipt from './receipt';
import referralPage from './referralPage';
import register from './register';
import requestDelete from './requestDelete';
import reservation from './reservation';
import reviews from './reviews';
import search from './search';
import searchLanding from './searchLanding';
import securityDeposit from './securityDeposit';
import addBlogContent from './siteadmin/addBlogContent';
import addBlogDetails from './siteadmin/addBlogDetails';
import addEvent from './siteadmin/addEvent';
import addFAQ from './siteadmin/addFAQ';
import addHowToHostDetails from './siteadmin/addHowToHostDetails';
import addMemberPerks from './siteadmin/addMemberPerks';
import adminDashboard from './siteadmin/adminDashboard';
import adminLogin from './siteadmin/adminLogin';
import adminReviews from './siteadmin/adminReviews';
import adminRoles from './siteadmin/adminRoles';
import adminUser from './siteadmin/adminUser';
import bannerSettings from './siteadmin/bannerSettings';
import blogContentManagement from './siteadmin/blogContentManagement';
import blogManagement from './siteadmin/blogManagement';
import changeAdmin from './siteadmin/changeAdmin';
import changeCommonPassword from './siteadmin/changeCommonPassword';
import connectionMessage from './siteadmin/connectionMessage';
import currencies from './siteadmin/currencies';
import deletedListings from './siteadmin/deletedListings';
import deletedUsers from './siteadmin/deletedUsers';
import addDiamondPerks from './siteadmin/diamondPerks/addDiamondPerks';
import diamondOffers from './siteadmin/diamondPerks/diamondOffers';
import editDiamondPerks from './siteadmin/diamondPerks/editDiamondPerks';
import showDiamondPerks from './siteadmin/diamondPerks/showDiamondPerks';
import document from './siteadmin/document';
import editBlogContent from './siteadmin/editBlogContent';
import editBlogDetails from './siteadmin/editBlogDetails';
import editEvent from './siteadmin/editEvent';
import editFAQ from './siteadmin/editFAQ';
import editMemberPerks from './siteadmin/editMemberPerks';
import editReview from './siteadmin/editReview';
import editStaticPage from './siteadmin/editStaticPage';
import edituser from './siteadmin/edituser';
import events from './siteadmin/events';
import faq from './siteadmin/faq';
import footerBlock from './siteadmin/footerBlock';
import footerDetailsBlock from './siteadmin/footerDetailsBlock';
import footerSignupSettings from './siteadmin/footerSignupSettings';
import goHustleAppliedJobManagement from './siteadmin/goHustle/goHustleAppliedJobManagement';
import goHustleCommunityAdvice from './siteadmin/goHustle/goHustleCommunityAdvice';
import goHustleContent from './siteadmin/goHustle/goHustleContent';
import goHustleIndustry from './siteadmin/goHustle/goHustleIndustry';
import goHustleJobType from './siteadmin/goHustle/goHustleJobType';
import goHustlePostManagement from './siteadmin/goHustle/goHustlePostManagement';
import goHustleUserPostManagement from './siteadmin/goHustle/goHustleUserPostManagement';
import addTrip from './siteadmin/goSolo/addTrip';
import editTrip from './siteadmin/goSolo/editTrip';
import goSoloStay from './siteadmin/goSolo/goSoloStay';
import goSoloTrip from './siteadmin/goSolo/goSoloTrip';
import showBooking from './siteadmin/goSolo/showBooking';
import goSolo from './siteadmin/goSolo/showTrip';
import showUserRequest from './siteadmin/goSolo/showUserRequest';
import goSoloReviews from './siteadmin/goSoloReviews';
import happyRentalsBookings from './siteadmin/happyRentals/bookings';
import happyRentalsListings from './siteadmin/happyRentals/listings';
import homeBanner from './siteadmin/homeBanner';
import howToHostManagement from './siteadmin/howToHostManagement';
import ical from './siteadmin/ical';
import idProof from './siteadmin/idProof';
import imageBanner from './siteadmin/imageBanner';
import inquiry from './siteadmin/inquiry';
import hostAwayBookings from './siteadmin/integrationReports/hostAway/bookings';
import hostAway from './siteadmin/integrationReports/hostAway/listings';
import hostFullyBookings from './siteadmin/integrationReports/hostFully/bookings';
import hostfully from './siteadmin/integrationReports/hostFully/listings';
import nextPaxBookings from './siteadmin/integrationReports/nextPax/bookings';
import nextPax from './siteadmin/integrationReports/nextPax/listings';
import rentalsUnitedBookings from './siteadmin/integrationReports/rentalsUnited/bookings';
import rentalsUnited from './siteadmin/integrationReports/rentalsUnited/listings';
import rentalsUnitedRate from './siteadmin/integrationReports/rentalsUnited/rate';
import interhomeBookings from './siteadmin/interhome/bookings';
import interhomeListings from './siteadmin/interhome/listings';
import listSettings from './siteadmin/listSettings';
import listingPermission from './siteadmin/listingPermission';
import listings from './siteadmin/listings';
import localSpots from './siteadmin/localSpots';
import adminLogs from './siteadmin/logs';
import mainStaticPageManagement from './siteadmin/mainStaticPageManagement';
import manageBusinessTravelPicks from './siteadmin/manageBusinessTravelPicks';
import manageFamilyFriendlyPicks from './siteadmin/manageFamilyFriendlyPicks';
import manageLongTermStay from './siteadmin/manageLongTermStay';
import manageSoloTravelPicks from './siteadmin/manageSoloTravelPicks';
import manageTopPicks from './siteadmin/manageTopPicks';
import manageUniquePicks from './siteadmin/manageUniquePicks';
import memberPerks from './siteadmin/memberPerks';
import members from './siteadmin/members';
import messages from './siteadmin/messages';
import account from './siteadmin/naturalRetreats/account';
import allListings from './siteadmin/naturalRetreats/allListings';
import naturalRetreatsBookings from './siteadmin/naturalRetreats/bookings';
import logs from './siteadmin/naturalRetreats/logs';
import ownerRezBookings from './siteadmin/ownerRez/bookings';
import ownerRezListings from './siteadmin/ownerRez/listings';
import paymentSettings from './siteadmin/paymentSettings';
import pendingMembers from './siteadmin/pendingMembers';
import pressUsers from './siteadmin/pressUsers';
import privateMessages from './siteadmin/privateMessages';
import property from './siteadmin/property';
import propertyLogIn from './siteadmin/propertyLogIn';
import propertyManagerServiceFee from './siteadmin/propertyManagerServiceFee';
import reportUser from './siteadmin/reportUser';
import reservations from './siteadmin/reservations';
import searchSettings from './siteadmin/searchSettings';
import serviceFeesSettings from './siteadmin/serviceFeesSettings';
import siteSettings from './siteadmin/siteSettings';
import staticPage from './siteadmin/staticPage';
import subAdminDashboard from './siteadmin/subAdminDashboard';
import blockOneSubscription from './siteadmin/subscription/blockOneSubscription';
import blockThreeSubscription from './siteadmin/subscription/blockThreeSubscription';
import blockTwoSubscription from './siteadmin/subscription/blockTwoSubscription';
import showSubscription from './siteadmin/subscription/showSubscription';
import tagManagement from './siteadmin/tagManagement';
import travelCreditSettings from './siteadmin/travelCreditSettings';
import userBlogContentManagement from './siteadmin/userBlogContentManagement';
import userReviews from './siteadmin/userReviews';
import users from './siteadmin/users';
import viewEvent from './siteadmin/viewEvent';
import viewGoHustleExplorePage from './siteadmin/viewGoHustleExplorePage';
import viewReceipt from './siteadmin/viewReceipt';
import viewreservation from './siteadmin/viewreservation';
import adminWriteReview from './siteadmin/writeReview';
import about from './static/about';
import business from './static/business';
import careers from './static/careers';
import citizen from './static/citizen';
import contactUs from './static/contactUs';
import exploreMore from './static/exploreMore';
import guide from './static/guide';
import help from './static/help';
import hospitality from './static/hospitality';
import howToHost from './static/howToHost';
import policies from './static/policies';
import press from './static/press';
import privacy from './static/privacy';
import responsibleHosting from './static/responsible-hosting';
import termsService from './static/termsService';
import travelCredit from './static/travelCredit';
import travelOftenGolightly from './static/travelOftenGolightly';
import trustAndSafety from './static/trustAndSafety';
import whoCanJoin from './static/whoCanJoin';
import whyhost from './static/whyhost';
import test from './test';
import transaction from './transaction';
import trips from './trips';
import trustAndVerification from './trustAndVerification';
import userBlogExplorePage from './userBlogExplorePage';
import userGoSoloReviews from './userGoSoloReviews';
import viewBlogExplorePage from './viewBlogExplorePage';
import viewDiamondPerks from './viewDiamondPerks';
import viewFAQ from './viewFAQ';
import viewListing from './viewListing';
import viewMemberPerks from './viewMemberPerks';
import viewMessage from './viewMessage';
import warning from './warning';
import wishLists from './wishLists';
import writeGoSoloReview from './writeGoSoloReview';
import writeReview from './writeReview';

// The top-level (parent) route
export default {
  path: '/',

  /*  Keep in mind, routes are evaluated in order */
  children: [
    /*  About Us */
    aboutUs,
    confirmgender,
    malegenderInfo,
    confirmEmail,
    inviteform,
    home,
    contact,
    login,
    register,
    editProfile,
    admin,
    profile,
    becomeHost,
    viewListing,
    manageListing,
    search,
    profilePhoto,
    trustAndVerification,
    changePassword,
    dashboard,
    inbox,
    viewMessage,
    book,
    payout,
    addPayout,
    payment,
    itinerary,
    interhomePaymentStatus,
    receipt,
    reservation,
    trips,
    transaction,
    warning,
    cancel,
    cancellationPolicies,
    reviews,
    writeReview,
    passwordVerification,
    connections,
    connectionUser,
    addPayoutFailure,
    searchLanding,
    idProofType,
    blogExplorePage,
    userBlogExplorePage,
    requestDelete,

    /*  document upload */
    test,
    documentVerification,
    becomeAMember,
    idProofVerification,
    securityDeposit,
    cities,
    mapClustering,
    exploreCities,

    /*  Social-Media blog post page */
    viewBlogExplorePage,

    /*  Static Pages */
    about,
    privacy,
    careers,
    press,
    policies,
    help,
    whoCanJoin,
    travelOftenGolightly,
    contactUs,
    howToHost,
    termsService,
    trustAndSafety,
    travelCredit,
    citizen,
    business,
    guide,
    whyhost,
    responsibleHosting,
    hospitality,
    exploreMore,
    event,

    /*  Add Admin Panel Pages Here */
    adminDashboard,
    adminLogin,
    changeAdmin,
    edituser,
    users,
    members,
    siteSettings,
    listSettings,
    listings,
    currencies,
    paymentSettings,
    searchSettings,
    bannerSettings,
    imageBanner,
    reservations,
    viewReceipt,
    serviceFeesSettings,
    adminReviews,
    adminWriteReview,
    editReview,
    viewreservation,
    footerBlock,
    messages,
    privateMessages,
    reportUser,
    referralPage,
    travelCreditSettings,
    listingPermission,
    deletedUsers,
    manageTopPicks,
    homeBanner,
    property,
    pressUsers,
    propertyManagerServiceFee,
    manageSoloTravelPicks,
    manageBusinessTravelPicks,
    manageFamilyFriendlyPicks,
    manageUniquePicks,
    userReviews,
    changeCommonPassword,
    /*  Admin side Social-Media Blog post */
    addBlogContent,
    blogContentManagement,
    editBlogContent,
    userBlogContentManagement,
    /*  blog */
    blog,
    blogManagement,
    addBlogDetails,
    editBlogDetails,
    /*  Static location landing pages */
    staticPage,
    editStaticPage,
    /*  Main Static Management */
    mainStaticPageManagement,
    howToHostManagement,
    addHowToHostDetails,
    /*  Footer - Home Page Block */
    footerDetailsBlock,
    footerSignupSettings,
    /*  document view */
    document,
    idProof,
    /*  Login for property manager */
    propertyLogIn,
    /*  Manage long term stay */
    manageLongTermStay,
    /*  Connection */
    connectionMessage,
    /*  Members Perks */
    addMemberPerks,
    editMemberPerks,
    memberPerks,
    viewMemberPerks,
    /*  natural retreats */
    allListings,
    account,
    logs,
    naturalRetreatsBookings,
    /* interhome */
    interhomeBookings,
    interhomeListings,
    ownerrezPaymentStatus,
    /* happy rentals */
    happyRentalsListings,
    happyRentalsBookings,
    /*  FAQ */
    faq,
    addFAQ,
    editFAQ,
    viewFAQ,
    /*  Wish Lists */
    wishLists,
    /*  PressUser */
    pressLogin,
    /*  concierge */
    concierge,
    /*  Ical */
    ical,
    /*  Social blog event management */
    events,
    addEvent,
    editEvent,
    /*  Admin users and roles */
    adminRoles,
    adminUser,
    subAdminDashboard,
    /*  Tag management */
    tagManagement,
    /* HostAway */
    hostAway,
    hostAwayBookings,
    /*  Hostfully */
    hostfully,
    hostFullyBookings,
    /*  NextPax */
    nextPax,
    nextPaxBookings,
    /*  Rentals United */
    rentalsUnited,
    rentalsUnitedBookings,
    rentalsUnitedRate,
    /*  View event */
    viewEvent,
    pendingMembers,
    /* Admin Logs */
    adminLogs,
    /** Go Solo */
    goSolo,
    addTrip,
    editTrip,
    goSoloViewTrip,
    goSoloHome,
    goSoloPaymentSummary,
    showBooking,
    goSoloUserBooking,
    goSoloWaitingPayment,
    showSubscription,
    blockOneSubscription,
    blockTwoSubscription,
    blockThreeSubscription,
    inquiry,
    deletedListings,
    pageOne,
    pageTwo,
    showDiamondPerks,
    addDiamondPerks,
    editDiamondPerks,
    viewDiamondPerks,
    diamondOffers,
    localSpots,
    goSoloRemainingPayment,
    localSpotMember,
    ownerRezBookings,
    ownerRezListings,
    goSoloStay,
    goSoloTrip,
    showUserRequest,
    /** Go Hustle */
    goHustleIndustry,
    goHustleJobType,
    goHustleContent,
    goHustleCommunityAdvice,
    goHustlePostManagement,
    goHustleUserPost,
    goHustleUserPostManagement,
    goHustleMyJobs,
    viewGoHustleExplorePage,
    goSoloReceipt,
    goHustleAppliedJobManagement,
    /** GoSolo Review */
    writeGoSoloReview,
    goSoloReviews,
    userGoSoloReviews,
    // Wildcard routes, e.g. { path: '*', ... } (must go last)
    notFound,
  ],

  async action({ next }) {
    /*  Execute each child route until one of them return the result */
    const route = await next();
    /*  Provide default values for title, description etc. */
    route.title = `${route.title || 'Untitled Page'}`;
    route.description = route.description || '';
    return route;
  },
};
