import React, { Component } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Col, FormControl, Grid, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { closeSubscriptionModal } from '../../actions/VerificationModal/verifyModal';
import { backendUrl, sitename } from '../../config';
import { EMAIL_REGEX } from '../../constants/regex';
import fetch from '../../core/fetch';
import messages from '../../locale/messages';

import s from './SubscriptionModal.module.css';

// config

class SubscriptionModal extends Component {
  static propTypes = {
    userData: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      content: '',
      error: null,
      submitting: false,
    };
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl
          {...input}
          className={className}
          maxLength={50}
          placeholder={label}
          type={type}
        />
      </div>
    );
  };

  handleChange() {
    this.setState({
      error: null,
    });
  }

  async submitForm(e) {
    const { email, closeSubscriptionModal } = this.props;
    const { formatMessage } = this.props.intl;
    let error = null;
    let submitting = false;
    if (!email || email.toString().trim() === '') {
      error = {
        content: formatMessage(messages.required),
      };
    } else if (!EMAIL_REGEX.test(email)) {
      error = {
        content: formatMessage(messages.emailInvalid),
      };
    }

    submitting = !error;

    this.setState({
      submitting,
      error,
    });

    if (!error && submitting) {
      toastr.success('Subscribed!');
      closeSubscriptionModal();
      const { status, errorMessage } = await fetch(
        `${backendUrl}/getMailChimpSubscribe`,
        {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
          }),
          credentials: 'include',
        }
      );

      let resp1;
      if (status === 404) {
        resp1 = await fetch(`${backendUrl}/mailChimpSubscribe-footer`, {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            firstName: '',
            lastName: '',
            email,
          }),
          credentials: 'include',
        });
      }
      if (status === 200 || (resp1 && resp1.status === 200)) {
        const resp2 = await fetch(
          `${backendUrl}/mailChimpUpdateProfile-footer`,
          {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              firstName: '',
              lastName: '',
              email,
            }),
            credentials: 'include',
          }
        );
      }
      this.setState({ submitting: false, error });
      closeSubscriptionModal();
    }
  }

  render() {
    const { openSubscription, closeSubscriptionModal } = this.props;
    const { formatMessage } = this.props.intl;
    const { error, submitting } = this.state;
    return (
      <div>
        <Modal
          animation={false}
          className={cx('verificationModal', 'verifyModal')}
          onHide={closeSubscriptionModal}
          show={openSubscription}
        >
          <Modal.Header closeButton>
            <div className={cx(s.welcomeCenter, s.headingcolor)}>
              <FormattedMessage {...messages.welcomeTo} /> {sitename}!
            </div>
          </Modal.Header>
          <Modal.Body bsClass={s.logInModalBody}>
            <Grid fluid>
              <Row className={s.reverseFlex}>
                <Col
                  className={cx(s.dropDownSection)}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Field
                    className={s.noteInput}
                    component={this.renderFormControl}
                    label={formatMessage(messages.email)}
                    name="email"
                    onChange={this.handleChange}
                    placeholder={formatMessage(messages.email)}
                  />
                  <div>
                    {error && error.content && (
                      <span className={s.errorMessage}>{error.content}</span>
                    )}
                  </div>
                </Col>
                <Col
                  className={cx(s.dropDownSection)}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Button
                    bsSize="small"
                    className={cx(s.button, s.btnPrimaryBorder, s.btnSmall)}
                    disabled={submitting}
                    onClick={(e) => this.submitForm(e)}
                    type="submit"
                  >
                    <FormattedMessage {...messages.subscriptionTitle} />
                  </Button>
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const subscriptionModal = reduxForm({
  form: 'SubscriptionModal', // a unique name for this form
})(SubscriptionModal);

const selector = formValueSelector('SubscriptionModal'); // <-- same as form name

const mapState = (state) => ({
  openSubscription: state.verificationModal.subscriptionStatus,
  email: selector(state, 'email'),
});

const mapDispatch = {
  closeSubscriptionModal,
};

export default injectIntl(connect(mapState, mapDispatch)(subscriptionModal));
