import React from 'react';

import moment from 'moment';
import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import CurrencyView from '../modules/CurrencyView';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class BookingRequestHost extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      reservationId: PropTypes.number.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      listTitle: PropTypes.string.isRequired,
      basePrice: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      hostServiceFee: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const btnCenter = {
      textAlign: 'center',
    };

    const listPic = {
      borderRadius: '999px',
      margin: '0',
      padding: '0',
      lineHeight: '150%',
      borderSpacing: '0',
      width: '125px',
    };

    const textCenter = {
      textAlign: 'center',
    };

    const linkText = {
      color: '#77d1da',
      fontSize: '18px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const {
      content: {
        reservationId,
        confirmationCode,
        hostName,
        guestName,
        checkIn,
        checkOut,
        threadId,
      },
    } = this.props;
    const {
      content: {
        listTitle,
        basePrice,
        total,
        hostServiceFee,
        currency,
        hostListPic,
        listId,
      },
    } = this.props;
    const {
      content: { photoType },
    } = this.props;

    const checkInDate = checkIn
      ? moment(checkIn).format('ddd, MMM Do, YYYY')
      : '';
    const checkOutDate = checkOut
      ? moment(checkOut).format('ddd, MMM Do, YYYY')
      : '';
    // let actionURL = url + '/reservation/current';
    const actionURL = `${url}/message/${threadId}/host`;
    const subtotal = total - hostServiceFee;
    const listURL = `${url}/rooms/${listId}`;
    let imageURL;
    if (hostListPic) {
      imageURL =
        photoType === 'Golightly'
          ? hostListPic
          : `${url}/upload/x_medium_${hostListPic}`;
    }
    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi {hostName},</div>
                  <EmptySpace height={20} />
                  <div>
                    Great News! You have a new reservation from {guestName}.
                  </div>
                  <EmptySpace height={20} />
                  <a href={listURL} style={linkText}>
                    <div style={textCenter}>
                      {hostListPic && (
                        <img height={125} src={imageURL} style={listPic} />
                      )}
                      <div style={textCenter}>{listTitle}</div>
                    </div>
                  </a>
                  <EmptySpace height={20} />
                  <EmptySpace height={10} />
                  <div>
                    {guestName} would like to stay in the {listTitle} from{' '}
                    {checkInDate} to {checkOutDate}.
                  </div>
                  <EmptySpace height={10} />
                  <div>
                    Your estimated payment for this booking is{' '}
                    <CurrencyView amount={total} currency={currency} />.
                  </div>
                  <EmptySpace height={40} />
                  <div style={btnCenter}>
                    <a href={actionURL} style={buttonStyle}>
                      Accept or Decline
                    </a>
                  </div>
                  <EmptySpace height={40} />
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default injectIntl(BookingRequestHost);
