import React from 'react';

import { compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import messages from '../../locale/messages';
import Link from '../Link';

import s from './AccountSettingsSideMenu.module.css';

class AccountSettingsSideMenu extends React.Component {
  render() {
    const { isCoHost } = this.props;
    let isDisabled = false;

    if (isCoHost) {
      isDisabled = true;
    }
    const { account } = this.props;

    let disabled = false;

    if (account && account.userId === 'c505e590-f3bc-11e9-bdc7-0913579b3abc') {
      disabled = true;
    }

    return (
      <div>
        <ul className={s.listContainer}>
          {!isDisabled && (
            <li>
              <Link className={s.sideNavitem} to="/user/payout">
                <FormattedMessage {...messages.payoutPreferences} />
              </Link>
            </li>
          )}
          {!isDisabled && (
            <li>
              <Link className={s.sideNavitem} to="/user/transaction">
                <FormattedMessage {...messages.transactionHistory} />
              </Link>
            </li>
          )}
          <li />
          {!disabled && (
            <li>
              <Link className={s.sideNavitem} to="/users/security">
                <FormattedMessage {...messages.security} />
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

const mapState = (state) => ({
  isCoHost: state.runtime.isCoHost,
  account: state.account.data,
});

const mapDispatch = {};

export default compose(
  injectIntl,
  connect(mapState, mapDispatch)
)(AccountSettingsSideMenu);
