import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';

import { toggleOpen, toggleClose } from '../../actions/Menu/toggleControl';
import history from '../../core/history';
import Logo from '../Logo';
import Navigation from '../Navigation';
import SocialBlogRulesModal from '../SocialBlogRulesModal';
import Toaster from '../Toaster';

import s from './Header.module.css';
import HeaderLocationSearch from './HeaderLocationSearch';

class Header extends React.Component {
  static propTypes = {
    borderLess: PropTypes.bool,
    showMenu: PropTypes.bool,
    toggleOpen: PropTypes.func.isRequired,
    formatMessage: PropTypes.func,
  };

  static defaultProps = {
    borderLess: false,
    showMenu: false,
    searchDisablePages: [
      '/',
      '/home',
      '/s',
      // '/aboutus'
    ],
  };

  constructor(props) {
    super(props);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleDisableSearchPages = this.handleDisableSearchPages.bind(this);
  }

  handleMenu() {
    const { showMenu, toggleOpen, toggleClose } = this.props;
    if (showMenu) {
      toggleClose();
      document.body.classList.remove('menu-open');
    } else {
      toggleOpen();
      document.body.classList.add('menu-open');
    }
  }

  handleDisableSearchPages() {
    const { searchDisablePages } = this.props;
    let location = history.location ? history.location.pathname : null;
    let searchHide = false;
    if (location && searchDisablePages.length > 0) {
      searchHide = searchDisablePages.find((o) => location === o);
      searchHide = !!searchHide;
    }
    return searchHide;
  }

  render() {
    const {
      siteSettings,
      borderLess,
      showMenu,
      toggleOpen,
      isLocationShow,
    } = this.props;
    let borderClass;
    if (borderLess) {
      borderClass = s.rentAllHeaderBorderLess;
    }
    let location;
    if (history.location) {
      location = history.location.pathname;
    }

    return (
      <div className={cx(s.root, 'ipadMenuContainer')}>
        <Toaster />
        <LoadingBar />
        <div
          className={cx(
            s.container,
            'container',
            'aboutUsHeader',
            'searchHeaderFull'
          )}
        >
          <Navbar
            className={cx(
              s.rentAllHeader,
              //  { [s.fixedHeader]: location && location.includes('/cities/')},
              'rentAllHeader',
              s.paddingNone,
              'containerPadding'
            )}
            expanded={showMenu}
            fluid
            onToggle={this.handleMenu}
          >
            <Navbar.Header className={s.displayTable}>
              <Navbar.Brand className={cx('hidden-xs')}>
                <Logo className={cx(s.brand, s.brandImg)} link="/" />
              </Navbar.Brand>
              <Navbar.Toggle className={s.navBarToggle}>
                <div className={cx('menuToggle')}>
                  <input checked={showMenu} type="checkbox" />

                  <span />
                  <span />
                  <span />
                </div>
              </Navbar.Toggle>
              {/* <div className={cx ('visible-xs', 'visible-sm')}>
             <HeaderLocationSearch />
             </div> */}
            </Navbar.Header>
            <Navbar.Collapse className="ipadMenu">
              <Navigation />
              <SocialBlogRulesModal />
            </Navbar.Collapse>
            {!isLocationShow && (
              <Navbar.Form
                className={cx('hidden-xs', s.breakPoint, {
                  hide: this.handleDisableSearchPages(),
                })}
                pullLeft
              >
                <HeaderLocationSearch />
              </Navbar.Form>
            )}
          </Navbar>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  siteSettings: state.siteSettings.data,
  showMenu: state.toggle.showMenu,
});

const mapDispatch = {
  toggleOpen,
  toggleClose,
};

export default injectIntl(connect(mapState, mapDispatch)(Header));
