import { gql } from 'react-apollo';

import {
  CHANGE_PAYOUT_ERROR,
  CHANGE_PAYOUT_START,
  CHANGE_PAYOUT_SUCCESS,
} from '../../constants';

export function changePayout(payoutId, reservationId) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: CHANGE_PAYOUT_START,
    });

    try {
      const mutation = gql`
        mutation updatePayoutForReservation(
          $payoutId: Int!
          $reservationId: Int!
        ) {
          updatePayoutForReservation(
            payoutId: $payoutId
            reservationId: $reservationId
          ) {
            status
          }
        }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: {
          payoutId,
          reservationId,
        },
        // refetchQueries: [{ query: getPayoutsQuery }]
      });

      if (data && data.updatePayoutForReservation) {
        dispatch({
          type: CHANGE_PAYOUT_SUCCESS,
          payload: {
            status: data.updatePayoutForReservation.status,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: CHANGE_PAYOUT_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }

    return true;
  };
}
