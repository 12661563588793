import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import messages from '../../locale/messages';
import CurrencyConverter from '../CurrencyConverter';

class TotalAmount extends Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  render() {
    const { className, mode, data } = this.props;
    const { formatMessage } = this.props.intl;
    let label;
    let currency;
    let amount = 0;
    if (mode === 'completed') {
      label = formatMessage(messages.paidOut);
      if (data && data.length > 0) {
        data.forEach((item) => {
          amount += item.hostTransaction.amount;
          currency = item.hostTransaction.currency;
        });
      }
    } else {
      label = formatMessage(messages.pendingPayouts);
      if (data && data.length > 0) {
        data.forEach((item) => {
          amount +=
            Math.ceil(item.total) - Math.ceil(item.hostServiceFee.toFixed(2));
          currency = item.currency;
        });
      }
    }

    return (
      <h3 className={className}>
        <span>{label} : </span>
        <span>
          <CurrencyConverter amount={amount} from={currency} />
        </span>
      </h3>
    );
  }
}

export default injectIntl(TotalAmount);
