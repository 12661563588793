import { initialize } from 'redux-form';

import {
  CLOSE_WISH_LIST_GROUP_MODAL,
  CLOSE_WISH_LIST_MODAL,
  OPEN_WISH_LIST_GROUP_MODAL,
  OPEN_WISH_LIST_MODAL,
} from '../../constants';
import { openLoginModal } from '../modalActions';

export function openAddWishListGroupModal(initialData, formName) {
  return (dispatch, getState) => {
    // Reinitialize the form values
    dispatch(initialize(formName, initialData, true));

    dispatch({
      type: OPEN_WISH_LIST_GROUP_MODAL,
      wishListGroupModalOpen: true,
    });
  };
}

export function openEditWishListGroupModal(initialData) {
  return (dispatch, getState) => {
    // Reinitialize the form values
    dispatch(initialize('EditListSettingsForm', initialData, true));

    dispatch({
      type: OPEN_WISH_LIST_GROUP_MODAL,
      wishListGroupModalOpen: true,
    });
  };
}

export function closeWishListGroupModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_WISH_LIST_GROUP_MODAL,
      wishListGroupModalOpen: false,
    });
  };
}

export function openWishListModal(listId) {
  return (dispatch, getState) => {
    const { isAuthenticated } = getState().runtime;

    if (isAuthenticated) {
      dispatch({
        type: OPEN_WISH_LIST_MODAL,
        payload: {
          wishListModalOpen: true,
          listId,
        },
      });
    } else {
      dispatch(openLoginModal());
    }
  };
}

export function closeWishListModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_WISH_LIST_MODAL,
      payload: {
        wishListModalOpen: false,
      },
    });
  };
}
