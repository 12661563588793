import React from 'react';

import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class PrivateMessage extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      receiverName: PropTypes.string.isRequired,
      senderName: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const {
      content: { receiverName, senderName, message, profileId, threadId },
    } = this.props;
    // et profileURL = url + '/users/show/' + profileId;
    const profileURL = `${url}/message/${threadId}/private`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi {receiverName},</div>
          <EmptySpace height={20} />
          <div>{senderName} has sent you a new message through Golightly.</div>
          <EmptySpace height={20} />
          <div>Message:</div>
          <EmptySpace height={10} />
          <div dangerouslySetInnerHTML={{ __html: message }} />
          <EmptySpace height={30} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default PrivateMessage;
