import React from 'react';

import Oy from 'oy-vey';
import { IntlProvider } from 'react-intl';

import { backendUrl, emailConfig } from '../../config';
import fetch from '../fetch';

import EmailTemplate from './template/EmailTemplate';
import { getSubject } from './template/subjects';

const bothUserTemplate = ['eventConfirmed', 'forgotPasswordLink'];

export async function sendEmail(to, type, content) {
  const from = `${emailConfig.sender}<${emailConfig.senderEmail}>`;
  const subjectData = getSubject(type, content);
  let bcc;
  let isAdmin;

  const html = Oy.renderTemplate(
    <IntlProvider locale="en" textComponent="span">
      <EmailTemplate content={content} type={type} />
    </IntlProvider>,
    {
      title: subjectData.subject,
      previewText: subjectData.previewText,
    }
  );

  if (bothUserTemplate.includes(type)) {
    isAdmin = content.isAdmin;
  }

  const mailOptions = {
    from,
    to, // list of receivers
    bcc,
    subject: subjectData.subject, // Subject line
    mailTemplate: type,
    isAdmin,
    // text: textMessage, // plain text body
    html,
  };
  const resp = await fetch(`${backendUrl}/sendEmail`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ mailOptions }),
    credentials: 'include',
  });
  const { status, response } = await resp.json();
  return { status, response };
}
