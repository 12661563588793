import React from 'react';

import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class AdminListingApproved extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      listTitle: PropTypes.string.isRequired,
      hostName: PropTypes.string.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const {
      content: { listId, listTitle, hostName, city, zipcode },
    } = this.props;
    const link = `${url}/rooms/${listId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>
            <Table width="100%">
              <TBody>
                <TR>
                  <TD style={textStyle}>
                    <EmptySpace height={20} />
                    <div>Hi Admin,</div>
                    <EmptySpace height={20} />
                    <div>
                      <p>
                        We are pleased to inform that user {hostName} listing
                        for {listTitle} in {city} {zipcode} has been approved
                        and is now live for bookings.
                      </p>
                    </div>
                    <div>
                      <a href={link}>Check by link</a>
                    </div>
                    <EmptySpace height={40} />
                    <div>The {sitename} Team</div>
                  </TD>
                </TR>
              </TBody>
            </Table>
            <EmptySpace height={40} />
          </div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default AdminListingApproved;
