import React from 'react';

import { Img, Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';

import EmptySpace from './EmptySpace';

const Header = (props) => {
  const style = {
    color: props.color,
    fontWeight: 'bold',
    backgroundColor: '#F7F7F7',
    width: '100%',
  };

  return (
    <Table color={props.color} style={style} width="100%">
      <TBody>
        <TR>
          <TD>
            <Table width="100%">
              <TBody>
                <TR>
                  <TD
                    style={{
                      color: props.color,
                      fontFamily: 'Arial',
                      fontSize: '28px',
                      textAlign: 'center',
                    }}
                  >
                    <EmptySpace height={20} />
                    <Img
                      alt={sitename}
                      src={`${url}/email/logo.png`}
                      width={150}
                    />
                  </TD>
                </TR>
              </TBody>
            </Table>
          </TD>
        </TR>
      </TBody>
    </Table>
  );
};

Header.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Header;
