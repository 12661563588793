import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { setUserLogout } from '../../actions/logout';
import { openLoginModal, openSignupModal } from '../../actions/modalActions';
import NavigationAfterLogin from '../NavigationAfterLogin';
import NavigationBeforeLogin from '../NavigationBeforeLogin';

class Navigation extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    setUserLogout: PropTypes.func,
    openLoginModal: PropTypes.func,
    openSignupModal: PropTypes.func,
  };

  render() {
    const {
      className,
      isAuthenticated,
      isAdminAuthenticated,
      setUserLogout,
      openLoginModal,
      openSignupModal,
      account,
    } = this.props;
    let requestInvites = false;
    let adminInviteLimit;
    let maxInvite;
    let stillInvite;

    if (isAuthenticated === true && !isAdminAuthenticated) {
      if (account && account.profileId) {
        adminInviteLimit = account.adminInviteLimit;
        maxInvite = account.maxInvite;
        stillInvite = account.stillInvite;
      }
      if ((maxInvite === 0 && adminInviteLimit === 0) || stillInvite < 1) {
        requestInvites = false;
      } else {
        requestInvites = true;
      }
      return (
        <NavigationAfterLogin
          className={className}
          requestInvites={requestInvites}
          setUserLogout={setUserLogout}
        />
      );
    }
    return (
      <NavigationBeforeLogin
        className={className}
        openLoginModal={openLoginModal}
        openSignupModal={openSignupModal}
      />
    );
  }
}

const mapState = (state) => ({
  isAuthenticated: state.runtime.isAuthenticated,
  isAdminAuthenticated: state.runtime.isAdminAuthenticated,
  account: state.account.data,
});

const mapDispatch = {
  setUserLogout,
  openLoginModal,
  openSignupModal,
};
export default injectIntl(connect(mapState, mapDispatch)(Navigation));
