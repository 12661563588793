import React, { Component } from 'react';

import cx from 'classnames';
import { Button, Modal } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
  closeSocialRulesModal,
  socialBlogRules,
} from '../../actions/Modal/socialBlogRulesModal';
import { setPersonalizedValues } from '../../actions/personalized';
import GoLabel from '../../assets/go-header.png';
import history from '../../core/history';
import messages from '../../locale/messages';
import CustomCheckbox from '../CustomCheckbox';

import s from './SocialBlogRulesModal.module.css';

class SocialBlogRulesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async handleSubmit() {
    const { socialBlogRules } = this.props;
    await socialBlogRules();
  }

  handleClose() {
    const { closeSocialRulesModal, showRules } = this.props;
    if (window.location.pathname.match(/social/gi) && !showRules) {
      history.push('/');
    }
    closeSocialRulesModal();
  }

  componentWillUnmount() {
    const { setPersonalizedValues } = this.props;
    setPersonalizedValues({ name: 'showBlogRulesModal', value: false });
  }

  render() {
    const { isSocialBlogRulesOpen, showRules } = this.props;
    const { formatMessage } = this.props.intl;
    const { isChecked } = this.state;

    return (
      <div>
        <Modal
          animation={false}
          dialogClassName={cx('signupModal', 'postModelNew')}
          onHide={this.handleClose}
          show={isSocialBlogRulesOpen}
        >
          <Modal.Header closeButton>
            <Modal.Title className={s.modelTitle}>
              <span>
                {!showRules && `${formatMessage(messages.dashBoardHeader)} `}
                <img className={s.goLogo} src={GoLabel} width="40px" />
                {formatMessage(messages.blogRulesAndRegulation1)}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={s.socialRulesPadding}>
            <div>
              <p className={cx(s.descText, s.lineHeight)}>
                <span>
                  <img className={s.goLogo} src={GoLabel} width="40px" />
                  {formatMessage(messages.blogRulesAndRegulation2)}
                </span>
              </p>
              {!showRules && (
                <div>
                  <div>
                    <span className={cx(s.displayTableCell, s.checkBoxWidth)}>
                      <CustomCheckbox
                        checked={isChecked}
                        className="icheckbox_square-green"
                        name="isChecked"
                        onChange={(event) => {
                          this.setState({ isChecked: event });
                        }}
                        value={isChecked}
                      />
                    </span>
                    <span
                      className={cx(
                        s.checkBoxMarginLeft,
                        s.descText,
                        s.vtrMiddle,
                        s.displayTableCell
                      )}
                    >
                      {formatMessage(messages.blogTermsAndConditon)}
                    </span>
                  </div>
                  <div className={s.textAlignRight}>
                    <Button
                      className={cx(s.btnPrimaryBorder, s.spaceTop3)}
                      disabled={!isChecked}
                      onClick={this.handleSubmit}
                    >
                      {formatMessage(messages.confirmLabel)}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => ({
  isSocialBlogRulesOpen: state.loader.isSocialBlogRulesOpen,
  showRules: state.personalized.showBlogRulesModal,
});

const mapDispatch = {
  closeSocialRulesModal,
  socialBlogRules,
  setPersonalizedValues,
};

export default injectIntl(connect(mapState, mapDispatch)(SocialBlogRulesModal));
