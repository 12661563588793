import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { cloudFrontUrl } from '../../config';
import Link from '../Link';

import s from './Logo.module.css';

class Logo extends Component {
  static propTypes = {
    siteName: PropTypes.string.isRequired,
    logoImage: PropTypes.string,
    link: PropTypes.string,
    className: PropTypes.string,
    logoHeight: PropTypes.string,
    logoWidth: PropTypes.string,
  };

  static defaultProps = {
    siteName: null,
    logoImage: null,
    link: '/',
    logoHeight: '34',
    logoWidth: '34',
  };

  render() {
    const {
      siteName,
      logoImage,
      link,
      className,
      logoHeight,
      logoWidth,
    } = this.props;
    const image = logoImage?.includes('http')
      ? logoImage
      : `${cloudFrontUrl}/logo/${logoImage}`;
    return (
      <Link className={className} to={link}>
        {logoImage && <img height={logoHeight} src={image} width={logoWidth} />
        // logoImage && <img src={"/images/logo/golightly-logo.svg"} style={{ height: '60px', marginTop: '10px' }} />
        }
        {!logoImage && siteName && (
          <span className={s.logoColor}>{siteName}</span>
        )}
        {!logoImage && !siteName && (
          <span className={s.logoColor}>Site Name</span>
        )}
      </Link>
    );
  }
}

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  logoImage: state.siteSettings.data.Logo,
  logoHeight: state.siteSettings.data.logoHeight,
  logoWidth: state.siteSettings.data.logoWidth,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Logo);
