import postalCodes from 'postal-codes-js';

import { EMAIL_REGEX } from '../../constants/regex';
import { restrictionMessage } from '../../helpers/restrictionMessage';
import messages from '../../locale/messages';

const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = messages.firstNameRequired;
  } else if (values.firstName && values.firstName.toString().trim() === '') {
    errors.firstName = messages.required;
  } else if (values?.firstName && !/[a-zA-Z]+/g.test(values.firstName)) {
    errors.firstName = messages.validCharactersFirstName;
  }

  // else if (values.firstName) {
  //   let { isAirbnb } = restrictionMessage(values.firstName);
  //   errors.firstName = isAirbnb ? messages.invalidText : '';
  // }
  if (!values.loginReferralKey) {
    errors.loginReferralKey = messages.loginKeyRequired;
  }
  if (!values.lastName) {
    errors.lastName = messages.lastNameRequired;
  } else if (values.lastName && values.lastName.toString().trim() === '') {
    errors.lastName = messages.required;
  } else if (values?.lastName && !/[a-zA-Z]+/g.test(values.lastName)) {
    errors.lastName = messages.validCharactersLastName;
  }

  // else if (values.lastName) {
  //   let { isAirbnb } = restrictionMessage(values.lastName);
  //   errors.lastName = isAirbnb ? messages.invalidText : '';
  // }

  if (!values.email) {
    errors.email = messages.emailRequired;
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = messages.emailInvalid;
  }

  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  if (!values.password) {
    errors.password = messages.passwordRequired;
  } else if (values.password.length < 8) {
    errors.password = messages.passwordInvalid;
  } else if (!regex.test(values.password)) {
    errors.password = messages.passwordInvalidError;
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = messages.passwordError4;
  } else if (values.confirmPassword.length < 8) {
    errors.confirmPassword = messages.passwordInvalid;
  } else if (!regex.test(values.confirmPassword)) {
    errors.confirmPassword = messages.confirmPasswordError5;
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = messages.passwordMismatch;
  }

  if (!values.zipCode) {
    errors.zipCode = messages.profileZipCodeRequired;
  } else if (values.zipCode && values.zipCode.trim() === '') {
    errors.zipCode = messages.blankSpace;
  }

  const countryCode = ['AQ', 'TD', 'CD', 'CI', 'DJ', 'GA', 'GN', 'IR', 'MR'];

  if (values.zipCode && values.countryCode) {
    // let checkZipCode = postalCodes.validate(values.countryCode === 'UK' ? 'GBR' : values.countryCode, values.zipCode);
    const checkZipCode = postalCodes.validate(
      values.countryCode === 'UK'
        ? 'GBR'
        : values.countryCode === 'UAE'
        ? 'AE'
        : values.countryCode,
      values.zipCode
    );
    if (!countryCode.includes(values.countryCode) && checkZipCode !== true)
      errors.zipCode = messages.invalidZipCode;
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = messages.required;
  } else if (values.phoneNumber && values.phoneNumber.trim() === '') {
    errors.firstName = messages.blankSpace;
  } else if (Number.isNaN(values.phoneNumber)) {
    errors.phoneNumber = messages.phoneNumberInvalid;
  }

  return errors;
};

export default validate;
