import React from 'react';

import PropTypes from 'prop-types';

import { sitename } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import CurrencyView from '../modules/CurrencyView';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class ClaimSecurityFeeHost extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      profileId: PropTypes.number,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const {
      content: {
        listTitle,
        hostName,
        guestName,
        city,
        zipcode,
        reservationId,
        reason,
        claimedFee,
      },
    } = this.props;
    const {
      content: { checkIn, checkOut, currency },
    } = this.props;
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi {hostName},</div>
          <EmptySpace height={20} />
          <div>
            Your claim for{' '}
            <CurrencyView amount={claimedFee} currency={currency} /> due to{' '}
            {reason} by {guestName} during her stay from {checkIn} to {checkOut}{' '}
            at {listTitle} has been sent to the admin for review.
          </div>
          <EmptySpace height={30} />
          <div>
            The admin will get in touch at the earliest to resolve this.
          </div>
          <EmptySpace height={30} />

          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default ClaimSecurityFeeHost;
