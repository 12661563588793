import React from 'react';

import { Table, TBody, TD, TR } from 'oy-vey';

import { sitename, url } from '../../../config';

import defaultPic from './heart.png';

const Footer = (props) => {
  const style = {
    backgroundColor: '#f7f7f7',
  };

  const spaceStyle = {
    paddingBottom: '25px',
    paddingLeft: '5px',
    color: '#9ca299',
    fontSize: '14px',
    textAlign: 'center',
  };
  const imgStyle = {
    height: '24px',
    verticalAlign: 'middle',
  };

  return (
    <Table style={style} width="100%">
      <TBody>
        <TR>
          <TD>
            <div style={spaceStyle}>
              Sent with <img src={url + defaultPic} style={imgStyle} /> from{' '}
              {sitename}
            </div>
          </TD>
        </TR>
      </TBody>
    </Table>
  );
};

export default Footer;
