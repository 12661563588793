import { GET_STRIPESTATE_SUCCESS } from '../constants';

export default function stripeStates(state = {}, action) {
  switch (action.type) {
    case GET_STRIPESTATE_SUCCESS:
      return {
        ...state,
        availableStates: action.availableStates,
      };

    default:
      return state;
  }
}
