import React from 'react';

import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class AdminSocialBlogErrorMessage extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      postTitle: PropTypes.string.isRequired,
      postMessage: PropTypes.string,
      blogId: PropTypes.string,
      action: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const {
      content: { postTitle, postMessage, blogId, action },
    } = this.props;
    const blogPostLink = `${url}/social/${blogId}`;
    const postLink = blogId
      ? ` - ${(<a href={blogPostLink}>{blogPostLink}</a>)}`
      : '';

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi Admin,</div>
                  <EmptySpace height={20} />
                  <div>
                    The following prohibited message content was attempted to be{' '}
                    {action === 'comment' ? 'commented' : 'posted'} on{' '}
                    {postTitle} {blogId && ' - '}
                    {blogId && <a href={blogPostLink}>{blogPostLink}</a>}
                  </div>
                  <EmptySpace height={20} />
                  {postMessage && (
                    <div>
                      <div>Message:</div>
                      <EmptySpace height={10} />
                      <div dangerouslySetInnerHTML={{ __html: postMessage }} />
                    </div>
                  )}
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default AdminSocialBlogErrorMessage;
