import React from 'react';

import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class AdminListVerification extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      listTitle: PropTypes.string.isRequired,
      hostName: PropTypes.string.isRequired,
    }),
  };

  render() {
    const btnCenter = {
      textAlign: 'center',
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const {
      content: { listId, listTitle, hostName, type, city, zipcode },
    } = this.props;
    const messageURL = `${url}/become-a-host/${listId}/home`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>
            <Table width="100%">
              <TBody>
                <TR>
                  <TD style={textStyle}>
                    <EmptySpace height={20} />
                    <div>Hi {hostName},</div>
                    <EmptySpace height={20} />
                    {type === 'Approved' && (
                      <div>
                        <p>
                          We are pleased to inform you that your listing for{' '}
                          {listTitle} in {city} {zipcode} has been approved and
                          is now live for bookings.
                        </p>
                      </div>
                    )}
                    {type === 'Approved' && (
                      <div>
                        As an extra level of verification, we ask that all hosts
                        get their property badged if they can. This is done by a
                        physical or virtual walk through of the listed property.
                        See further details on our{' '}
                        <a href="https://www.wegolightly.com/page/golightly-property-badging">
                          Golightly Property Badging page.
                        </a>
                      </div>
                    )}
                    {type === 'Approved' && <br />}
                    {type === 'Approved' && (
                      <div>
                        Please contact{' '}
                        <a href="mailto:listings@wegolightly.com">
                          {' '}
                          listings@wegolightly.com{' '}
                        </a>{' '}
                        to arrange your Property badging and thank you for
                        listing!
                      </div>
                    )}
                    {type === 'Rejected' && (
                      <div>
                        <p>
                          We regret to inform you that your listing for{' '}
                          {listTitle} in {city} {zipcode} has not been approved
                          at this time. For further information please contact
                          us.
                        </p>
                        <EmptySpace height={20} />
                        <p>
                          Alternatively should you wish to resubmit this listing
                          with necessary changes please do so at the link below:
                        </p>
                        <EmptySpace height={20} />
                        <div style={btnCenter}>
                          <a href={messageURL} style={buttonStyle}>
                            Submit
                          </a>
                        </div>
                      </div>
                    )}

                    <EmptySpace height={40} />

                    <div>
                      Thanks, <br />
                      <EmptySpace height={10} />
                      The {sitename} Team
                    </div>
                  </TD>
                </TR>
              </TBody>
            </Table>
            <EmptySpace height={40} />
          </div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default AdminListVerification;
