import { SET_CURRENT_ROUTE } from '../constants';

export default function loader(state = {}, action) {
  switch (action.type) {
    case SET_CURRENT_ROUTE:
      return {
        ...state,
        route: action.payload.route,
      };

    default:
      return state;
  }
}
