import {
  GET_NATURAL_RETREATS_BOOKINGS,
  GET_NATURAL_RETREATS_BOOKINGS_START,
  GET_NATURAL_RETREATS_BOOKINGS_SUCCESS,
} from '../../constants';

const initialState = {
  loading: false,
  bookings: [],
};

export default function naturalRetreatsBookings(state = initialState, action) {
  switch (action.type) {
    case GET_NATURAL_RETREATS_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
        loading: false,
      };
    case GET_NATURAL_RETREATS_BOOKINGS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_NATURAL_RETREATS_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
