/* eslint-disable */
import React from 'react';

import PropTypes from 'prop-types';

import {
  golightlyDefault,
  lazyPictureUrl,
  cloudFrontUrl,
  backendUrl,
} from '../../config';
import LazyLoadImage from '../LazyLoadImage';

import s from './ListCoverPhoto.module.css';

class ListCoverPhoto extends React.Component {
  static propTypes = {
    coverPhoto: PropTypes.number,
    listPhotos: PropTypes.array,
    className: PropTypes.string,
    bgImage: PropTypes.bool,
  };

  static defaultProps = {
    bgImage: false,
    lazyLoad: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      photo: null,
      type: null,
      fallbackUrls: [],
      attempt: 0,
    };
    this.handleFallback = this.handleFallback.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { coverPhoto, listPhotos } = this.props;
    let activePhoto;
    let type;
    let fbUrl = [];
    if (listPhotos && listPhotos.length > 0) {
      activePhoto = listPhotos[0].name;
      type = listPhotos[0].type;
      if (coverPhoto && coverPhoto) {
        listPhotos.map((item) => {
          if (item.id === coverPhoto) {
            activePhoto = item.name;
            type = item.type;
          }
          fbUrl.push(item.name);
        });
      }
      this.setState({ photo: activePhoto, type, fallbackUrls: fbUrl });
    } else {
      this.setState({ photo: null, type: null });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { coverPhoto, listPhotos } = nextProps;
    let activePhoto;
    let type;
    if (listPhotos && listPhotos.length > 0) {
      activePhoto = listPhotos[0].name;
      type = listPhotos[0].type;
      if (coverPhoto && coverPhoto) {
        listPhotos.map((item) => {
          if (item.id === coverPhoto) {
            activePhoto = item.name;
            type = item.type;
          }
        });
      }
      this.setState({ photo: activePhoto, type });
    } else {
      this.setState({ photo: null, type: null });
    }
  }

  handleFallback = (e, imgSource, attempt) => {
    const hasUrl = imgSource.includes('http');
    let img = hasUrl ? imgSource : backendUrl + imgSource;
    this.setState({ attempt: this.state.attempt + 1 });
    if (attempt > 1 && attempt < this.state.fallbackUrls.length - 1) {
      img = this.state.fallbackUrls[this.state.attempt];
    } else if (attempt > 1 && this.state.fallbackUrls.length <= 1) {
      img = golightlyDefault;
    }
    e.target.onerror = null;
    //   e.target.src = `${backendUrl + imgSource}`;
    e.target.src = `${img}`;
  };

  render() {
    const { className, photoType, bgImage, srcPath, lazyLoad, isViewListing } = this.props;
    const { photo, type, fallbackUrls, attempt } = this.state;
    let path = '';
    let source;
    let placeholderSrc;
    const mediumNoImage = '/site-images/medium_no_image.png';
    const largeNoImage = '/site-images/large_no_image.jpeg';
    if (photo) {
      // source = photo + '-xx_large';
      source = photo;
      if (photoType) {
        // path = uploadPictureUrl + '/' + photoType + '_';
        path = `/upload/${photoType}_`; // path = uploadPictureUrl + '/' + photoType + '_';

        // path = srcPath ? srcPath : uploadPictureUrl;
      }
    } else if (photoType) {
      if (photoType === 'xx_large') {
        source = isViewListing ? golightlyDefault : largeNoImage;
      } else if (photoType === 'x_medium') {
        source = mediumNoImage;
      }
    } else {
      source = mediumNoImage;
    }

    source = type === 'Golightly' ? source : path + source;
    placeholderSrc = type === 'Golightly' ? source : lazyPictureUrl + source;
    const hasUrl = source.includes('http');
    const hasNoImage = source.includes('medium_no_image.png');

    if (bgImage && !this.props.children) {
      if (photo) {
        return (
          <LazyLoadImage
            // src={`${path}${source}`}
            // placeholderSrc={lazyPictureUrl + source}
            className={className}
            placeholderSrc={placeholderSrc}
            src={source}
            isViewListing
          />
        );
      }
      placeholderSrc = golightlyDefault;
      return (
        <div className={s.bannerImage}>
          <LazyLoadImage
            // src={`${path}${source}`}
            // placeholderSrc={lazyPictureUrl + source}
            className={className}
            placeholderSrc={placeholderSrc}
            src={source}
            isViewListing
          />
        </div>
      );
    }
    if (bgImage) {
      return (
        <div
          className={className}
          // style={{ backgroundImage: `url(${path}${source}), url(${golightlyDefault})` }}
          style={{
            backgroundImage: hasUrl
              ? `url("${source}")${fallbackUrls.length > 0 ? ',' : ''}${fallbackUrls?.slice(0, 2)?.map(q => ` url("${q}")`)}, url(${golightlyDefault})`
              : `url(${backendUrl}${source}), url(${cloudFrontUrl}${source}), url("${source}"), url(${golightlyDefault})`,
          }}
        >
          {this.props.children}
        </div>
      );
    }
    return (
      // <img src={path + source} className={className} />
      <img
        onError={(e) => this.handleFallback(e, source, attempt)}
        src={
          hasNoImage
            ? golightlyDefault
            : hasUrl
            ? source
            : backendUrl + source
        }
      />
    );
  }
}

export default ListCoverPhoto;
