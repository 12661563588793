import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class RemainderHostBooking extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      userName: PropTypes.string.isRequired,
      reporterName: PropTypes.string.isRequired,
      reportType: PropTypes.string.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const btnCenter = {
      textAlign: 'center',
    };

    const listPic = {
      borderRadius: '999px',
      margin: '0',
      padding: '0',
      lineHeight: '150%',
      borderSpacing: '0',
      width: '125px',
    };

    const {
      content: {
        guestFirstName,
        guestProfileId,
        checkIn,
        checkOut,
        hostFirstName,
        hostProfileId,
      },
    } = this.props;
    const {
      content: { listId, title, threadId, propertyImage, photoType },
    } = this.props;
    let imageURL;
    const hostProfile = `${url}/users/show/${hostProfileId}`;
    const guestProfile = `${url}/users/show/${guestProfileId}`;
    const listURL = `${url}/rooms/${listId}`;
    const checkInDate = checkIn
      ? moment(checkIn).format('ddd, MMM Do, YYYY')
      : '';
    const checkOutDate = checkOut
      ? moment(checkOut).format('ddd, MMM Do, YYYY')
      : '';
    const actionURL = `${url}/message/${threadId}/host`;
    if (propertyImage) {
      imageURL =
        photoType === 'Golightly'
          ? propertyImage
          : `${url}/upload/x_medium_${propertyImage}`;
    }
    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>
            Hi{' '}
            <a href={hostProfile} style={linkText}>
              {hostFirstName}
            </a>
            ,
          </div>
          <EmptySpace height={20} />
          <div>
            This is a reminder for an upcoming reservation for your listing{' '}
            <a href={listURL} style={linkText}>
              {title}
            </a>{' '}
            from {checkInDate} to {checkOutDate} by{' '}
            <a href={guestProfile} style={linkText}>
              {guestFirstName}
            </a>
            . Make sure you are prepared to welcome her and share any necessary
            check-in information ahead of time.
          </div>
          <EmptySpace height={20} />
          <div style={btnCenter}>
            {propertyImage && (
              <img height={125} src={imageURL} style={listPic} />
            )}
          </div>
          <EmptySpace height={40} />
          <div style={btnCenter}>
            <a href={actionURL} style={buttonStyle}>
              Message Guest
            </a>
          </div>
          <EmptySpace height={20} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default RemainderHostBooking;
