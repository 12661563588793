import {
  GET_HOME_BANNER_ERROR,
  GET_HOME_BANNER_START,
  GET_HOME_BANNER_SUCCESS,
} from '../../constants';

export default function homeBannerImages(state = {}, action) {
  switch (action.type) {
    case GET_HOME_BANNER_START:
      return {
        ...state,
      };

    case GET_HOME_BANNER_SUCCESS:
      return {
        ...state,
        data: action.data.getHomeBanner,
      };

    case GET_HOME_BANNER_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
}
