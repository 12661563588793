import { gql } from 'react-apollo';
import { hideLoading } from 'react-redux-loading-bar';
import { change } from 'redux-form';

import {
  EXPLORE_SEARCH_LISTING_RESULTS,
  EXPLORE_SEARCH_LISTING_SUCCESS,
  SEARCH_LISTING_ERROR,
  SEARCH_LISTING_START,
  SEARCH_LISTING_SUCCESS,
  UPDATE_MAP_DATA,
} from '../constants';

import { getSearchResults } from './getSearchResults';
import { setPersonalizedValues } from './personalized';

const query = gql`
  query(
    $personCapacity: Int
    $dates: String
    $currentPage: Int
    $geography: String
    $geoType: String
    $lat: Float
    $lng: Float
    $staticPage: String
    $womenCount: Int
    $menCount: Int
    $childCount: Int
    $sw_lat: Float
    $sw_lng: Float
    $ne_lat: Float
    $ne_lng: Float
    $searchByMap: Boolean
    $otherCurrentPage: Int
    $isExclusive: Boolean
    $bookingType: String
    $roomType: [Int]
    $amenities: [Int]
    $spaces: [Int]
    $houseRules: [Int]
    $priceRange: [Int]
    $familyFriendlyAmenities: [Int]
    $uniqueAmenities: [Int]
    $businessAmenities: [Int]
    $tripType: [Int]
    $universityId: String
    $bedrooms: Int
    $bathrooms: Int
    $beds: Int
    $industry: String
    $isLongerStay: Boolean
    $otherCount: Int
    $zoomLevel: Int
    $houseType: [Int]
    $priceNoLimit: Boolean
    $priceSortOrder: String
  ) {
    SearchListing(
      personCapacity: $personCapacity
      dates: $dates
      currentPage: $currentPage
      geography: $geography
      geoType: $geoType
      lat: $lat
      lng: $lng
      staticPage: $staticPage
      womenCount: $womenCount
      menCount: $menCount
      childCount: $childCount
      sw_lat: $sw_lat
      sw_lng: $sw_lng
      ne_lat: $ne_lat
      ne_lng: $ne_lng
      searchByMap: $searchByMap
      otherCurrentPage: $otherCurrentPage
      isExclusive: $isExclusive
      bookingType: $bookingType
      roomType: $roomType
      amenities: $amenities
      spaces: $spaces
      houseRules: $houseRules
      priceRange: $priceRange
      familyFriendlyAmenities: $familyFriendlyAmenities
      uniqueAmenities: $uniqueAmenities
      businessAmenities: $businessAmenities
      tripType: $tripType
      universityId: $universityId
      bedrooms: $bedrooms
      bathrooms: $bathrooms
      beds: $beds
      industry: $industry
      isLongerStay: $isLongerStay
      otherCount: $otherCount
      zoomLevel: $zoomLevel
      houseType: $houseType
      priceNoLimit: $priceNoLimit
      priceSortOrder: $priceSortOrder
    ) {
      industryName
      industryFilter
      industryFilterCount
      count
      results {
        id
        title
        city
        state
        country
        countryName
        personCapacity
        extraGuest
        extraBed
        lat
        lng
        beds
        bedrooms
        bookingType
        coverPhoto
        reviewsCount
        reviewsStarRating
        isExclusiveProp
        rentalsUnitedBedCount
        rentalUnitedId
        interhomeId
        hostFullyPropertyId
        ownerRezId
        naturalRetreatsId
        nextPaxId
        hostAwayId
        minPrice
        searchPrices {
          price
          month
          year
        }
        listPhotos {
          id
          name
          type
          status
        }
        listingData {
          defaultPrice
          basePrice
          currency
          isExclusive
          isLongerStay
          weeklyDiscount
          monthlyDiscount
          weekendPrice
        }
        settingsData {
          listsettings {
            id
            itemName
          }
        }
        wishListStatus
        isListOwner
      }
      otherResultsCount
      otherResults {
        id
        title
        city
        state
        country
        countryName
        personCapacity
        extraGuest
        extraBed
        lat
        lng
        beds
        bedrooms
        bookingType
        coverPhoto
        rentalsUnitedBedCount
        rentalUnitedId
        reviewsCount
        reviewsStarRating
        minPrice
        isExclusiveProp
        searchPrices {
          price
          month
          year
        }
        listPhotos {
          id
          name
          type
          status
        }
        listingData {
          defaultPrice
          basePrice
          currency
          isExclusive
          weeklyDiscount
          monthlyDiscount
          isLongerStay
        }
        settingsData {
          listsettings {
            id
            itemName
          }
        }
        wishListStatus
        isListOwner
      }
    }
  }
`;

const queryData = gql`
  query(
    $personCapacity: Int
    $dates: String
    $currentPage: Int
    $geography: String
    $geoType: String
    $lat: Float
    $lng: Float
    $staticPage: String
    $womenCount: Int
    $menCount: Int
    $childCount: Int
    $sw_lat: Float
    $sw_lng: Float
    $ne_lat: Float
    $ne_lng: Float
    $searchByMap: Boolean
    $otherCurrentPage: Int
    $isExclusive: Boolean
    $isLongerStay: Boolean
    $otherCount: Int
  ) {
    SearchListing(
      personCapacity: $personCapacity
      dates: $dates
      currentPage: $currentPage
      geography: $geography
      geoType: $geoType
      lat: $lat
      lng: $lng
      staticPage: $staticPage
      womenCount: $womenCount
      menCount: $menCount
      childCount: $childCount
      sw_lat: $sw_lat
      sw_lng: $sw_lng
      ne_lat: $ne_lat
      ne_lng: $ne_lng
      searchByMap: $searchByMap
      otherCurrentPage: $otherCurrentPage
      isExclusive: $isExclusive
      isLongerStay: $isLongerStay
      otherCount: $otherCount
    ) {
      industryName
      industryFilter
      industryFilterCount
      count
      otherResultsCount
      results {
        id
        title
        city
        state
        country
        countryName
        personCapacity
        extraGuest
        extraBed
        lat
        lng
        beds
        bedrooms
        bookingType
        coverPhoto
        reviewsCount
        reviewsStarRating
        isExclusiveProp
        city
        state
        country
        minPrice
        searchPrices {
          price
          month
          year
        }
        listPhotos {
          id
          name
          type
          status
        }
        listingData {
          defaultPrice
          basePrice
          currency
          isExclusive
          weeklyDiscount
          monthlyDiscount
        }
        settingsData {
          listsettings {
            id
            itemName
          }
        }
        wishListStatus
        isListOwner
      }
      otherResults {
        id
        title
        city
        state
        country
        countryName
        personCapacity
        extraGuest
        extraBed
        lat
        lng
        beds
        bedrooms
        bookingType
        coverPhoto
        rentalsUnitedBedCount
        rentalUnitedId
        reviewsCount
        reviewsStarRating
        isExclusiveProp
        searchPrices {
          price
          month
          year
        }
        listPhotos {
          id
          name
          type
          status
        }
        listingData {
          defaultPrice
          basePrice
          currency
          isExclusive
          weeklyDiscount
          monthlyDiscount
        }
        settingsData {
          listsettings {
            id
            itemName
          }
        }
        wishListStatus
        isListOwner
      }
    }
  }
`;

const exploreQuery = gql`
  query(
    $personCapacity: Int
    $dates: String
    $currentPage: Int
    $lat: Float
    $lng: Float
    $roomType: [Int]
    $bedrooms: Int
    $bathrooms: Int
    $beds: Int
    $amenities: [Int]
    $spaces: [Int]
    $houseRules: [Int]
    $priceRange: [Int]
    $priceNoLimit: Boolean
    $geography: String
    $bookingType: String
    $geoType: String
    $searchByMap: Boolean
    $sw_lat: Float
    $sw_lng: Float
    $ne_lat: Float
    $ne_lng: Float
    $industry: String
    $womenCount: Int
    $menCount: Int
    $childCount: Int
    $universityId: String
    $otherCurrentPage: Int
    $isExclusive: Boolean
    $familyFriendlyAmenities: [Int]
    $uniqueAmenities: [Int]
    $businessAmenities: [Int]
    $tripType: [Int]
    $staticPage: String
    $page: String
    $resultType: String
  ) {
    CitySearchListing(
      personCapacity: $personCapacity
      dates: $dates
      currentPage: $currentPage
      lat: $lat
      lng: $lng
      roomType: $roomType
      bedrooms: $bedrooms
      bathrooms: $bathrooms
      beds: $beds
      amenities: $amenities
      spaces: $spaces
      houseRules: $houseRules
      priceRange: $priceRange
      priceNoLimit: $priceNoLimit
      geography: $geography
      bookingType: $bookingType
      geoType: $geoType
      searchByMap: $searchByMap
      sw_lat: $sw_lat
      sw_lng: $sw_lng
      ne_lat: $ne_lat
      ne_lng: $ne_lng
      industry: $industry
      womenCount: $womenCount
      menCount: $menCount
      childCount: $childCount
      universityId: $universityId
      otherCurrentPage: $otherCurrentPage
      isExclusive: $isExclusive
      familyFriendlyAmenities: $familyFriendlyAmenities
      uniqueAmenities: $uniqueAmenities
      businessAmenities: $businessAmenities
      tripType: $tripType
      staticPage: $staticPage
      page: $page
      resultType: $resultType
    ) {
      count
      results {
        id
        title
        city
        state
        country
        countryName
        personCapacity
        extraGuest
        extraBed
        lat
        lng
        beds
        bedrooms
        coverPhoto
        bookingType
        reviewsCount
        reviewsStarRating
        isExclusiveProp
        searchPrices {
          price
          month
          year
        }
        listPhotos {
          id
          name
          type
          status
        }
        listingData {
          defaultPrice
          basePrice
          currency
          isExclusive
          dailyDiscount
          weeklyDiscount
          monthlyDiscount
        }
        settingsData {
          listsettings {
            id
            itemName
          }
        }
        wishListStatus
        isListOwner
      }
    }
  }
`;

const singleMapQuery = gql`
  query(
    $personCapacity: Int
    $currentPage: Int
    $geography: String
    $geoType: String
    $lat: Float
    $lng: Float
    $womenCount: Int
    $menCount: Int
    $childCount: Int
    $sw_lat: Float
    $sw_lng: Float
    $ne_lat: Float
    $ne_lng: Float
    $searchByMap: Boolean
    $resultType: String
    $otherCount: Int
  ) {
    ExploreMoreSearchListing(
      personCapacity: $personCapacity
      currentPage: $currentPage
      geography: $geography
      geoType: $geoType
      lat: $lat
      lng: $lng
      womenCount: $womenCount
      menCount: $menCount
      childCount: $childCount
      sw_lat: $sw_lat
      sw_lng: $sw_lng
      ne_lat: $ne_lat
      ne_lng: $ne_lng
      searchByMap: $searchByMap
      resultType: $resultType
      otherCount: $otherCount
    ) {
      count
      results {
        id
        lat
        lng
      }
    }
  }
`;

export function searchListing({
  staticPage,
  personCapacity,
  dates,
  geography,
  currentPage,
  geoType,
  lat,
  lng,
  womenCount,
  menCount,
  childCount,
  otherCurrentPage,
  bookingType,
  isExclusive,
  roomType,
  tripType,
  businessAmenities,
  familyFriendlyAmenities,
  uniqueAmenities,
  amenities,
  spaces,
  houseRules,
  priceRange,
  industry,
  university,
  universityId,
  beds,
  bedrooms,
  bathrooms,
  isLongerStay,
  priceRangeLabel,
  otherCount,
  houseType,
  priceSortOrder = '',
  isFilterLink,
}) {
  return async (dispatch, getState, { client }) => {
    dispatch({ type: SEARCH_LISTING_START });
    try {
      const { minPrice, maxPrice } =
        ((await getState().search) && getState().search.searchSettings) || {};
      let priceNoLimit = false;
      if (
        String(minPrice) &&
        maxPrice &&
        priceRange &&
        Array.isArray(priceRange)
      ) {
        priceNoLimit =
          (minPrice === priceRange[0] && maxPrice === priceRange[1]) ||
          (maxPrice === priceRange[0] && maxPrice === priceRange[1]) ||
          maxPrice === priceRange[1];
      }

      const { data } = await client.query({
        query,
        variables: {
          personCapacity,
          dates,
          currentPage,
          geography,
          geoType,
          lat,
          lng,
          staticPage,
          womenCount,
          menCount,
          childCount,
          otherCurrentPage,
          bookingType,
          isExclusive,
          roomType,
          tripType,
          businessAmenities,
          familyFriendlyAmenities,
          uniqueAmenities,
          amenities,
          spaces,
          houseRules,
          priceRange,
          industry,
          universityId,
          beds,
          bedrooms,
          bathrooms,
          isLongerStay,
          otherCount,
          houseType,
          priceNoLimit,
          priceSortOrder,
        },
        fetchPolicy: 'network-only',
      });
      if (data.SearchListing) {
        // dispatch(updateMapData({
        //   data: data?.SearchListing
        // }))
        dispatch({ type: SEARCH_LISTING_SUCCESS });
        // await dispatch(reset('SearchForm'));
        await dispatch(change('SearchForm', 'personCapacity', personCapacity));
        await dispatch(change('SearchForm', 'womenCount', womenCount));
        await dispatch(change('SearchForm', 'menCount', menCount));
        await dispatch(change('SearchForm', 'childCount', childCount));
        await dispatch(change('SearchForm', 'dates', dates));
        await dispatch(change('SearchForm', 'geography', geography));
        await dispatch(change('SearchForm', 'currentPage', currentPage));
        await dispatch(
          change('SearchForm', 'otherCurrentPage', otherCurrentPage)
        );
        await dispatch(change('SearchForm', 'geoType', geoType));
        await dispatch(change('SearchForm', 'lat', lat));
        await dispatch(change('SearchForm', 'lng', lng));
        await dispatch(change('SearchForm', 'searchByMap', true));
        await dispatch(change('SearchForm', 'initialLoad', true));
        await dispatch(change('SearchForm', 'markerHighlight', {}));
        await dispatch(change('SearchForm', 'showMap', true));
        await dispatch(change('SearchForm', 'bookingType', bookingType));
        await dispatch(change('SearchForm', 'isExclusive', isExclusive));
        await dispatch(change('SearchForm', 'roomType', roomType));
        await dispatch(change('SearchForm', 'tripType', tripType));
        await dispatch(
          change('SearchForm', 'businessAmenities', businessAmenities)
        );
        await dispatch(
          change(
            'SearchForm',
            'familyFriendlyAmenities',
            familyFriendlyAmenities
          )
        );
        await dispatch(
          change('SearchForm', 'uniqueAmenities', uniqueAmenities)
        );
        await dispatch(change('SearchForm', 'amenities', amenities));
        await dispatch(change('SearchForm', 'spaces', spaces));
        await dispatch(change('SearchForm', 'houseRules', houseRules));
        await dispatch(change('SearchForm', 'priceRange', priceRange));
        await dispatch(
          change('SearchForm', 'priceRangeLabel', priceRangeLabel)
        );
        await dispatch(change('SearchForm', 'industry', industry));
        await dispatch(change('SearchForm', 'university', university));
        await dispatch(change('SearchForm', 'universityId', universityId));
        await dispatch(change('SearchForm', 'beds', beds));
        await dispatch(change('SearchForm', 'bedrooms', bedrooms));
        await dispatch(change('SearchForm', 'bathrooms', bathrooms));
        await dispatch(change('SearchForm', 'isLongerStay', isLongerStay));
        await dispatch(change('SearchForm', 'otherCount', otherCount));
        await dispatch(change('SearchForm', 'houseType', houseType));
        await dispatch(change('SearchForm', 'priceSortOrder', priceSortOrder));
        await dispatch(change('SearchForm', 'priceNoLimit', priceNoLimit));

        if (isFilterLink) {
          await dispatch(change('SearchForm', 'sw_lat', null));
          await dispatch(change('SearchForm', 'sw_lng', null));
          await dispatch(change('SearchForm', 'ne_lat', null));
          await dispatch(change('SearchForm', 'ne_lng', null));
        }

        dispatch(getSearchResults(data.SearchListing));
        await dispatch(hideLoading());
      }
    } catch (error) {
      dispatch({
        type: SEARCH_LISTING_ERROR,
        payload: {
          error,
        },
      });
      dispatch(hideLoading());
      return false;
    }

    return true;
  };
}

export function dragSearchListing({
  staticPage,
  personCapacity,
  dates,
  geography,
  currentPage,
  geoType,
  lat,
  lng,
  womenCount,
  menCount,
  childCount,
  sw_lat,
  sw_lng,
  ne_lat,
  ne_lng,
  searchByMap,
  otherCurrentPage,
  otherCount,
}) {
  return async (dispatch, getState, { client }) => {
    dispatch({ type: SEARCH_LISTING_START });
    // dispatch(reset('SearchForm'));

    try {
      const { data } = await client.query({
        query: queryData,
        variables: {
          personCapacity,
          dates,
          currentPage,
          geography,
          geoType,
          lat,
          lng,
          staticPage,
          womenCount,
          menCount,
          childCount,
          sw_lat,
          sw_lng,
          ne_lat,
          ne_lng,
          searchByMap,
          otherCurrentPage,
          otherCount,
        },
        fetchPolicy: 'network-only',
      });
      if (data.SearchListing) {
        dispatch({ type: SEARCH_LISTING_SUCCESS });
        dispatch(getSearchResults(data.SearchListing));
      }
    } catch (error) {
      dispatch({
        type: SEARCH_LISTING_ERROR,
        payload: {
          error,
        },
      });
      dispatch(hideLoading());
      return false;
    }

    return true;
  };
}

export function expolreMapResults(
  lat,
  lng,
  sw_lat,
  sw_lng,
  ne_lat,
  ne_lng,
  searchByMap,
  resultType,
  currentPage,
  personCapacity
) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: EXPLORE_SEARCH_LISTING_RESULTS,
      payload: {
        isMapLoading: true,
      },
    });
    // dispatch(reset('SearchForm'));
    try {
      const isValue = {
        lat,
        lng,
        sw_lat,
        sw_lng,
        ne_lat,
        ne_lng,
        searchByMap,
        resultType,
        currentPage,
        personCapacity,
      };
      const { data } = await client.query({
        query: exploreQuery,
        // query: singleMapQuery,
        variables: {
          lat,
          lng,
          sw_lat,
          sw_lng,
          ne_lat,
          ne_lng,
          searchByMap,
          resultType,
          currentPage,
          personCapacity,
        },
        fetchPolicy: 'network-only',
      });

      if (data && data.CitySearchListing) {
        // if (data && data.ExploreMoreSearchListing) {
        dispatch({
          type: EXPLORE_SEARCH_LISTING_SUCCESS,
          payload: {
            data: data && data.CitySearchListing,
            isMapLoading: false,
            // mapData: data && data.CitySearchListing && data.CitySearchListing.worldMapData
          },
          // payload: {
          //   data: data && data.ExploreMoreSearchListing && data.ExploreMoreSearchListing.results,
          //   isMapLoading: false,
          //   mapData: data && data.ExploreMoreSearchListing && data.ExploreMoreSearchListing.results
          // }
        });
        // dispatch(change('citySearchForm', 'lat', parseFloat(lat)))
        // dispatch(change('citySearchForm', 'lng', parseFloat(lng)))
        // dispatch(change('citySearchForm', 'sw_lat', sw_lat))
        // dispatch(change('citySearchForm', 'sw_lng', sw_lng))
        // dispatch(change('citySearchForm', 'ne_lat', ne_lat))
        // dispatch(change('citySearchForm', 'ne_lng', ne_lng))
        // dispatch(change('citySearchForm', 'resultType', 'worldMap'))
        // dispatch(change('citySearchForm', 'searchByMap', true))
        // dispatch(change('SearchForm', 'showMap', true));
      }
    } catch (error) {
      dispatch({
        type: SEARCH_LISTING_ERROR,
        payload: {
          error,
        },
      });
      dispatch(hideLoading());
      return false;
    }

    return true;
  };
}

export function updateMapDataItems(data) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: UPDATE_MAP_DATA,
      payload: {
        ...data
      }
    })
  }
}
export function updateMapData({ data: results }) {
  return async (dispatch, getState, { client }) => {
    const bounds = new window.google.maps.LatLngBounds();

    try {
      const positions = [];
      if (Array.isArray(results) && results.length > 0)
        for (let i = 0; i < results?.length; i++) {
          const item = results[i];
          if (!item || !item?.listingData)
            continue;
          const data = {};
          data.lat = parseFloat(item.lat);
          data.lng = parseFloat(item.lng);
          data.position = new window.google.maps.LatLng(
            parseFloat(item.lat),
            parseFloat(item.lng)
          );
          data.id = item.id;
          data.basePrice = item.listingData.basePrice;
          data.defaultPrice = item.listingData.defaultPrice;
          data.currency = item.listingData.currency;
          data.title = item.title;
          data.beds = item.beds;
          data.bedrooms = item.bedrooms;
          data.personCapacity = item.personCapacity;
          data.roomType =
            item.settingsData &&
            item.settingsData.length > 0 &&
            item.settingsData[0].listsettings.itemName;
          data.coverPhoto = item.coverPhoto;
          data.searchPrices = item.searchPrices;
          data.listPhotos = item.listPhotos;
          data.bookingType = item.bookingType;
          data.reviewsCount = item.reviewsCount;
          data.reviewsStarRating = item.reviewsStarRating;
          data.wishListStatus = item.wishListStatus;
          data.isListOwner = item.isListOwner;
          data.extraBed = item.extraBed;
          data.isExclusive = item.isExclusiveProp;
          data.extraGuest = item.extraGuest;
          data.city = item.city;
          data.state = item.state;
          data.country = item.country;
          data.countryName = item.countryName;
          data.rentalsUnitedBedCount = item.rentalsUnitedBedCount;
          data.rentalUnitedId = item.rentalUnitedId;
          data.hovered = false;
          // if (hover) {
          //   data.zIndex = 1000;
          // } else {
          //   data.zIndex = 0;
          // }
          data.dailyDiscount =
            item && item.listingData && item.listingData.dailyDiscount;
          data.weeklyDiscount =
            item && item.listingData && item.listingData.weeklyDiscount;
          data.monthlyDiscount =
            item && item.listingData && item.listingData.monthlyDiscount;
          positions.push(data);
          bounds.extend(new window.google.maps.LatLng(item.lat, item.lng));

        };
      const personalized = getState().personalized;

      if (personalized?.bounds) {
        bounds.extend(new window.google.maps.LatLng(personalized?.bounds?.northeast?.lat, personalized?.bounds?.northeast?.lng))
        bounds.extend(new window.google.maps.LatLng(personalized?.bounds?.southwest?.lat, personalized?.bounds?.southwest?.lng))
      }
      let center = bounds.getCenter();

      dispatch({
        type: UPDATE_MAP_DATA,
        payload: {
          markers: positions,
          bounds,
          center
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
}