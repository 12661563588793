import {
  GENDER_STORE_ERROR,
  GENDER_STORE_START,
  GENDER_STORE_SUCCESS,
} from '../constants';

export default function storeGender(state = {}, action) {
  switch (action.type) {
    case GENDER_STORE_START:
      return {
        ...state,
        genderLoading: action.payload.genderLoading,
      };

    case GENDER_STORE_SUCCESS:
      return {
        ...state,
        genderLoading: action.payload.genderLoading,
      };

    case GENDER_STORE_ERROR:
      return {
        ...state,
        genderLoading: action.payload.genderLoading,
      };

    default:
      return state;
  }
}
