import React from 'react';

import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class ManualReviewStatus extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const {
      content: { email, name, firstName, profileId },
    } = this.props;
    const userFirstName = firstName || name;
    const profileURL = `${url}/users/show/${profileId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />

          <div>
            A user's documents have been flagged for manual review. The
            following are the user details:
          </div>

          <div>
            Name : <a href={profileURL}>{userFirstName}</a>
          </div>
          <div>Email : {email}</div>

          <div>Cheers!</div>
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default ManualReviewStatus;
