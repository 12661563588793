import {
  GET_MUTUAL_CONNECTION_API_ERROR,
  GET_MUTUAL_CONNECTION_API_START,
  GET_MUTUAL_CONNECTION_API_SUCCESS,
  SET_ADMIN_CHOSEN_USER_ID_SUCCESS,
  UPDATE_CONNECT_PROFILE_ERROR,
  UPDATE_CONNECT_PROFILE_START,
  UPDATE_CONNECT_PROFILE_SUCCESS,
  UPDATE_CONNECT_USER_ERROR,
  UPDATE_CONNECT_USER_START,
  UPDATE_CONNECT_USER_SUCCESS,
} from '../constants';

export default function connectUser(state = {}, action) {
  switch (action.type) {
    case UPDATE_CONNECT_USER_START:
      return {
        ...state,
        connectUpdateLoading: action.payload.connectUpdateLoading,
      };

    case UPDATE_CONNECT_USER_SUCCESS:
      return {
        ...state,
        connectUpdateLoading: action.payload.connectUpdateLoading,
      };

    case UPDATE_CONNECT_USER_ERROR:
      return {
        ...state,
        connectUpdateLoading: action.payload.connectUpdateLoading,
      };

    case GET_MUTUAL_CONNECTION_API_START:
      return {
        ...state,
        connectUpdateLoading: action.payload.connectUpdateLoading,
      };

    case GET_MUTUAL_CONNECTION_API_SUCCESS:
      return {
        ...state,
        connectUpdateLoading: action.payload.connectUpdateLoading,
      };

    case GET_MUTUAL_CONNECTION_API_ERROR:
      return {
        ...state,
        connectUpdateLoading: action.payload.connectUpdateLoading,
      };

    case SET_ADMIN_CHOSEN_USER_ID_SUCCESS:
      return {
        ...state,
        listUserId: action.payload.listUserId,
        userDetails: action.payload.userDetails,
      };

    case UPDATE_CONNECT_PROFILE_START:
      return {
        ...state,
        connectProfileLoading: action.payload.connectProfileLoading,
      };

    case UPDATE_CONNECT_PROFILE_SUCCESS:
      return {
        ...state,
        connectProfileLoading: action.payload.connectProfileLoading,
      };

    case UPDATE_CONNECT_PROFILE_ERROR:
      return {
        ...state,
        connectProfileLoading: action.payload.connectProfileLoading,
      };

    default:
      return state;
  }
}
