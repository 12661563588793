import { SubmissionError } from 'redux-form';

import { loadAccount } from '../../actions/account';
import { setLoaderComplete, setLoaderStart } from '../../actions/loader/loader';
import { closeLoginModal } from '../../actions/modalActions';
import { setRuntimeVariable } from '../../actions/runtime';
import { backendUrl } from '../../config';
import fetch from '../../core/fetch';
import { resetFormatReferURL } from '../../helpers/formatURL';
import messages from '../../locale/messages';

async function submit(values, dispatch) {
  const query = `query ($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      status
    }
  }`;

  const params = {
    email: values.email,
    password: values.password,
  };

  await dispatch(setLoaderStart('loginForm'));

  const resp = await fetch(`${backendUrl}/graphql`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables: params,
    }),
    credentials: 'include',
  });

  const { data } = await resp.json();

  if (data.userLogin.status === 'success') {
    await dispatch(closeLoginModal());
    const loginScreen = true;
    let { refer } = values;
    if (refer) refer = resetFormatReferURL(refer);
    dispatch(loadAccount(loginScreen, refer, true, true));
    dispatch(
      setRuntimeVariable({
        name: 'isAdminAuthenticated',
        value: false,
      })
    );
    await dispatch(setLoaderComplete('loginForm'));
  } else if (data.userLogin.status === 'admin login success') {
    await dispatch(closeLoginModal());
    const loginScreen = true;
    let { refer } = values;
    if (refer) refer = resetFormatReferURL(refer);
    await dispatch(loadAccount(loginScreen, refer, true, true));
    await dispatch(
      setRuntimeVariable({
        name: 'isAdmin',
        value: true,
      })
    );
    // history.push('/dashboard');
    await dispatch(setLoaderComplete('loginForm'));
  } else if (data.userLogin.status === 'secondary login success') {
    await dispatch(closeLoginModal());
    const loginScreen = true;
    let { refer } = values;
    if (refer) refer = resetFormatReferURL(refer);
    // dispatch(loadAccount(loginScreen, refer));
    await dispatch(loadAccount(loginScreen, refer, true, true));
    await dispatch(
      setRuntimeVariable({
        name: 'isCoHost',
        value: true,
      })
    );
    // history.push('/dashboard');
    await dispatch(setLoaderComplete('loginForm'));
  } else if (data.userLogin.status === 'email') {
    await dispatch(setLoaderComplete('loginForm'));
    throw new SubmissionError({ _error: messages.emailNotExists });
  } else if (data.userLogin.status === 'password') {
    await dispatch(setLoaderComplete('loginForm'));
    throw new SubmissionError({ _error: messages.passwordWrong });
  } else if (data.userLogin.status === 'loggedIn') {
    await dispatch(setLoaderComplete('loginForm'));
    throw new SubmissionError({ _error: messages.loggedIn });
  } else if (data.userLogin.status === 'adminLoggedIn') {
    await dispatch(setLoaderComplete('loginForm'));
    throw new SubmissionError({ _error: messages.adminLoggedIn });
  } else if (data.userLogin.status === 'memberNotVerified') {
    await dispatch(setLoaderComplete('loginForm'));
    throw new SubmissionError({ _error: messages.weAreSorry });
  } else {
    await dispatch(setLoaderComplete('loginForm'));
    throw new SubmissionError({ _error: messages.somethingWentWrong });
  }
  await dispatch(setLoaderComplete('loginForm'));
}

export default submit;
