import React from 'react';

import PropTypes from 'prop-types';

import { sitename } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class GuestTrip extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      profileId: PropTypes.number,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const {
      content: { listTitle, hostName, guestName, city, zipcode },
    } = this.props;
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi {guestName},</div>
          <EmptySpace height={20} />
          <div>
            We are happy to hear that you have checked in safely to {listTitle}{' '}
            in {city} {zipcode}
          </div>
          <EmptySpace height={20} />
          <div>Enjoy your stay!</div>
          <EmptySpace height={30} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default GuestTrip;
