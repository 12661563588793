import React from 'react';

import PropTypes from 'prop-types';

import { backendUrl, sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class DocumentUpload extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      verificationStatus: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const {
      content: { name, profileId, fileName, userId, userEmail },
    } = this.props;
    const imagePath = fileName.includes('http')
      ? fileName
      : `${backendUrl}/id-proof-image?fileName=${fileName}&userId=${userId}`;
    const userProfileId = `${url}/users/show/${profileId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          <div>
            <a href={userProfileId}>
              {name}
              {userEmail ? ` (${userEmail})` : ''}
            </a>{' '}
            has uploaded her ID for verification.
          </div>
          <EmptySpace height={20} />
          <div>
            <a href={imagePath}>Check uploaded document</a>
          </div>
          <EmptySpace height={30} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default DocumentUpload;
