import {
  GET_HAPPY_RENTALS_PRICES_START,
  GET_HAPPY_RENTALS_PRICES_SUCCESS,
} from '../constants';

const initialState = {
  prices: null,
  loading: false,
};

export default function happyRentals(state = initialState, action) {
  switch (action.type) {
    case GET_HAPPY_RENTALS_PRICES_START:
      return {
        ...state,
        prices: null,
        loading: true,
      };
    case GET_HAPPY_RENTALS_PRICES_SUCCESS:
      return {
        ...state,
        prices: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
