import { GET_UNIVERSITY_DATA_SUCCESS } from '../constants';

export default function getUniversities(state = {}, action) {
  switch (action.type) {
    case GET_UNIVERSITY_DATA_SUCCESS:
      return {
        ...state,
        data: action.universitySearch,
      };
    default:
      return state;
  }
}
