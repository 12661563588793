import { gql } from 'react-apollo';
import { change } from 'redux-form';

import {
  GET_LISTING_DETAILS_ERROR,
  GET_LISTING_DETAILS_START,
  GET_LISTING_DETAILS_SUCCESS,
  GET_LISTING_SUCCESS,
  SET_ADMIN_CHOSEN_USER_ID_ERROR,
  SET_ADMIN_CHOSEN_USER_ID_START,
  SET_ADMIN_CHOSEN_USER_ID_SUCCESS,
} from '../../constants';

const query = gql`
  query getStepTwo($listId: String!) {
    getStepTwo(listId: $listId) {
      id
      userId
      title
      description
      coverPhoto
      neighbourhood
      listPhotos {
        id
        name
        type
      }
    }
  }
`;

const userQuery = gql`
  query getUserdetails($profileId: Int) {
    getUserdetails(profileId: $profileId) {
      userId
      profileId
    }
  }
`;

const getListing = gql`
  query getListingData($id: Int) {
    getListingData(id: $id) {
      status
    }
  }
`;

export function getListingStepTwo(listId) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_LISTING_DETAILS_START,
      payload: {
        isLoading: true,
      },
    });

    try {
      // Send Request to get listing data
      const { data } = await client.query({
        query,
        variables: {
          listId,
        },
        fetchPolicy: 'network-only',
      });

      if (data && data.getStepTwo) {
        const isStepTwo = data && data.getStepTwo;

        dispatch({
          type: GET_LISTING_DETAILS_SUCCESS,
          payload: {
            // specialPricing: data.getSpecialPricing,
            stepTwoDetails: isStepTwo,
            isLoading: false,
          },
        });
      } else {
        dispatch({
          type: GET_LISTING_DETAILS_ERROR,
          payload: {
            isLoading: false,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: GET_LISTING_DETAILS_ERROR,
        payload: {
          error,
          isLoading: false,
        },
      });
      return false;
    }

    return true;
  };
}

export function setAdminListUserId(profileId) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: SET_ADMIN_CHOSEN_USER_ID_START,
    });

    try {
      // Send Request to get listing data
      const { data } = await client.query({
        query: userQuery,
        variables: {
          profileId,
        },
        fetchPolicy: 'network-only',
      });

      if (data && data.getUserdetails) {
        const isStepTwo = data && data.getUserdetails;
        const userId = data && data.getUserdetails.userId;

        dispatch({
          type: SET_ADMIN_CHOSEN_USER_ID_SUCCESS,
          payload: {
            userDetails: isStepTwo,
          },
        });
        if (userId) {
          await dispatch(change('ListPlaceStep1', 'userId', userId));
        }
      } else {
        dispatch({
          type: SET_ADMIN_CHOSEN_USER_ID_ERROR,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_ADMIN_CHOSEN_USER_ID_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }

    return true;
  };
}

export function getListingAuth(listId) {
  return async (dispatch, getState, { client }) => {
    try {
      const { data } = await client.query({
        query: getListing,
        variables: {
          id: listId,
        },
        fetchPolicy: 'network-only',
      });

      if (data && data.getListingData) {
        const isListing = data.getListingData.status === '400';
        dispatch({
          type: GET_LISTING_SUCCESS,
          payload: {
            isGetListing: isListing,
          },
        });
        return {
          status: isListing,
        };
      }
    } catch (error) {
      return false;
    }
    return true;
  };
}
