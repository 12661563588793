import React from 'react';

import PropTypes from 'prop-types';

import { sitename } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class FormalRegisterMemberEmail extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const {
      content: { email, name, firstName },
    } = this.props;
    const userFirstName = firstName || name;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi {userFirstName},</div>
          <EmptySpace height={20} />
          {/* {
                        firstName && <div>
                            Welcome to Golightly!
                    </div>
                    } */}
          {
            <div>
              Thanks so much for your application. Because we are a community
              based on trust and security, we thoroughly review all applications
              to vet every prospective member.
            </div>
          }
          <EmptySpace height={20} />
          <div>
            With all the positive press, we have experienced a tremendous influx
            of applications. Needless to say we're thrilled (and humbled) by the
            excitement and interest!
          </div>
          <EmptySpace height={20} />
          <div>
            Due to the overwhelming response, it might take us a few days to
            respond. We appreciate your patience as we review your application.
          </div>
          <EmptySpace height={30} />
          <div>Cheers!</div>
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default FormalRegisterMemberEmail;
