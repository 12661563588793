import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose, gql, graphql } from 'react-apollo';
import { FormControl } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { payoutChangeListing } from '../../actions/Payout/payoutChangeListing';
import { changePayout } from '../../actions/TransactionHistory/changePayout';
import messages from '../../locale/messages';
import Link from '../Link';

class Payouts extends Component {
  static propTypes = {
    className: PropTypes.string,
    formatMessage: PropTypes.func,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      getPayouts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          payEmail: PropTypes.string.isRequired,
        })
      ),
    }),
    refetch: PropTypes.func,
    changePayout: PropTypes.func.isRequired,
    type: PropTypes.string,
    reservationId: PropTypes.number,
    defaultLabel: PropTypes.string,
    defaultValue: PropTypes.number,
    enableAddPayout: PropTypes.bool,
  };

  static defaultProps = {
    defaultLabel: 'All payout method',
    enableAddPayout: false,
    data: {
      loading: true,
      getPayouts: [],
    },
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const {
      refetch,
      changePayout,
      type,
      reservationId,
      payoutChangeListing,
    } = this.props;
    const { mode, handleResults, listId } = this.props;
    if (type === 'change') {
      changePayout(e.target.value, reservationId);
    } else {
      const variables = {
        payoutId: e.target.value,
        currentPage: 1,
      };
      payoutChangeListing(e.target.value);
      // refetch(variables);
      handleResults(mode, e.target.value, listId);
    }
  }

  render() {
    const {
      className,
      data: { loading, getPayouts },
      defaultLabel,
      defaultValue,
      enableAddPayout,
    } = this.props;
    const { onChangePayoutId } = this.props;
    if (enableAddPayout) {
      if (!loading && (!getPayouts || getPayouts.length === 0)) {
        return (
          <Link to="/user/payout">
            <FormattedMessage {...messages.transactionsAddPayout} />
          </Link>
        );
      }
    }
    return (
      <FormControl
        className={className}
        componentClass="select"
        defaultValue={defaultValue}
        onChange={this.handleChange}
        value={onChangePayoutId}
      >
        <option value="0">{defaultLabel}</option>
        {!loading &&
          getPayouts &&
          getPayouts.map((item, index) => {
            return (
              <option key={index} value={item.id}>
                {item.payEmail}
              </option>
            );
          })}
      </FormControl>
    );
  }
}

const mapState = (state) => ({
  onChangePayoutId: state.payoutChangeListing.onChangePayoutId,
});

const mapDispatch = {
  changePayout,
  payoutChangeListing,
};

export default compose(
  connect(mapState, mapDispatch),
  graphql(
    gql`
      query getPayouts {
        getPayouts {
          id
          methodId
          paymentMethod {
            id
            name
          }
          userId
          payEmail
          address1
          address2
          city
          state
          country
          zipcode
          currency
          default
          createdAt
          status
        }
      }
    `,
    {
      options: {
        ssr: false,
      },
    }
  )
)(Payouts);
