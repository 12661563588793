import {
  SHOW_CONNECTION_SEARCH_FORM,
  SHOW_CONNECTION_SEARCH_RESULTS,
  SHOW_SEARCH_FORM,
  SHOW_SEARCH_RESULTS,
  SHOW_SEARCH_RESULTS_MAP,
} from '../constants';

export default function mobileSearch(state = {}, action) {
  switch (action.type) {
    case SHOW_SEARCH_RESULTS_MAP:
      return {
        ...state,
        data: {
          searchMap: action.payload.searchMap,
          searchResults: action.payload.searchResults,
          searchForm: action.payload.searchForm,
        },
      };

    case SHOW_SEARCH_RESULTS:
      return {
        ...state,
        data: {
          searchMap: action.payload.searchMap,
          searchResults: action.payload.searchResults,
          searchForm: action.payload.searchForm,
        },
      };

    case SHOW_SEARCH_FORM:
      return {
        ...state,
        data: {
          searchMap: action.payload.searchMap,
          searchResults: action.payload.searchResults,
          searchForm: action.payload.searchForm,
        },
      };

    case SHOW_CONNECTION_SEARCH_RESULTS:
      return {
        ...state,
        data: {
          connectionResult: action.payload.connectionResult,
          connectionForm: action.payload.connectionForm,
        },
      };

    case SHOW_CONNECTION_SEARCH_FORM:
      return {
        ...state,
        data: {
          connectionResult: action.payload.connectionResult,
          connectionForm: action.payload.connectionForm,
        },
      };

    default:
      return state;
  }
}
