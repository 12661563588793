import {
  DISPLAY_LISTING_IMAGES_SUCCESS,
  DISPLAY_LISTING_IMAGES_REMOVE,
  DISPLAY_LISTING_IMAGES_HIDE,
  DISPLAY_LISTING_IMAGES_DESCRIPTION,
  DISPLAY_LISTING_IMAGES_MODIFY,
  DELETE_UPLOADED_PHOTOS,
} from '../constants';

const initialState = {
  listingPhotos: [],
};

export default function listingPhotos(state = initialState, action) {
  switch (action.type) {
    case DISPLAY_LISTING_IMAGES_SUCCESS:
      return {
        ...state,
        listingPhotos: [...state.listingPhotos, action.payload.image],
      };
    case DISPLAY_LISTING_IMAGES_REMOVE: {
      let newListingPhotos = state.listingPhotos.filter(
        (value) => value.name !== action.payload.name
      );
      return {
        ...state,
        listingPhotos: newListingPhotos,
      };
    }
    case DISPLAY_LISTING_IMAGES_HIDE: {
      return {
        ...state,
        listingPhotos: state.listingPhotos.map((photo) =>
          photo.name === action.payload.name
            ? { ...photo, hide: !photo?.hide }
            : photo
        ),
      };
    }
    case DISPLAY_LISTING_IMAGES_DESCRIPTION: {
      return {
        ...state,
        listingPhotos: state.listingPhotos.map((photo) =>
          photo.name === action.payload.name
            ? { ...photo, photoDescription: action.payload.description }
            : photo
        ),
      };
    }
    case DISPLAY_LISTING_IMAGES_MODIFY: {
      const image = action.payload.image;
      return {
        ...state,
        listingPhotos: state.listingPhotos.map((photo) =>
          photo.name === image.name ? { ...image } : photo
        ),
      };
    }
    case DELETE_UPLOADED_PHOTOS:
      return {
        ...state,
        listingPhotos: [],
      };
    default:
      return state;
  }
}
