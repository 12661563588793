import React from 'react';

import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class GroupMessageAdminError extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      senderEmail: PropTypes.string.isRequired,
      senderName: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      senderProfileId: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const {
      content: { senderName, message, senderProfileId, senderEmail },
    } = this.props;

    const actionURL = `${url}/users/show/${senderProfileId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi Admin,</div>
                  <EmptySpace height={20} />
                  <div>
                    The following prohibited message content was attempted to be
                    sent from{' '}
                    <a href={actionURL} style={linkText}>
                      {senderName}
                      {senderEmail ? ` (${senderEmail})` : ''}
                    </a>
                  </div>
                  <EmptySpace height={20} />
                  <div>Message:</div>
                  <EmptySpace height={10} />
                  {message && (
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                  )}

                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default GroupMessageAdminError;
