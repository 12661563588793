import React, { Component } from 'react';

import moment from 'moment';
import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename } from '../../../config';
import Layout from '../layouts/Layout';
import CurrencyView from '../modules/CurrencyView';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class CancelledByGuest extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      listTitle: PropTypes.string.isRequired,
      payoutToHost: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const {
      content: {
        guestName,
        hostName,
        confirmationCode,
        checkIn,
        listTitle,
        payoutToHost,
        currency,
      },
    } = this.props;
    const checkInDate = checkIn
      ? moment(checkIn).format('ddd, MMM Do, YYYY')
      : '';
    const momentStartDate = moment(checkIn).startOf('day');
    const today = moment();
    const interval = momentStartDate.diff(today, 'days');
    let isPastDay = false;
    if (interval < 0) {
      isPastDay = true;
    }

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi {hostName},</div>
                  <EmptySpace height={20} />
                  <div>
                    We regret to inform you that your guest {guestName} has
                    cancelled reservation {confirmationCode} at {listTitle}{' '}
                    {isPastDay ? 'started' : 'starting'} on {checkInDate}.
                    {payoutToHost > 0 && (
                      <span>
                        {' '}
                        As per the cancellation policy, your payout will be
                        updated to{' '}
                        <CurrencyView
                          amount={payoutToHost}
                          currency={currency}
                        />
                        .
                      </span>
                    )}
                    {payoutToHost === 0 && (
                      <span>
                        {' '}
                        As per the cancellation policy, you will not receive any
                        payment for this reservation.
                      </span>
                    )}
                    <EmptySpace height={10} />
                    <p>
                      Your calendar has also been updated to show that the
                      previously booked dates are now available.
                    </p>
                  </div>
                  <EmptySpace height={20} />
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default CancelledByGuest;
