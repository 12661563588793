import { gql } from 'react-apollo';

import { USER_LOGOUT_START } from '../constants';

export function setUserLogout() {
  return async (dispatch, _getState, { client }) => {
    const query = gql`
      query {
        userLogout {
          status
        }
      }
    `;
    dispatch({
      type: USER_LOGOUT_START,
    });

    await client.query({
      query,
      fetchPolicy: 'network-only',
    });

    window.location.reload();

    return true;
  };
}
