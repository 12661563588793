import { GET_INDUSTRY_DATA_SUCCESS } from '../constants';

export default function getIndustries(state = {}, action) {
  switch (action.type) {
    case GET_INDUSTRY_DATA_SUCCESS:
      return {
        ...state,
        data: action.industrySearch,
      };
    default:
      return state;
  }
}
