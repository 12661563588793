import {
  CLOSE_BOOKING_MODAL,
  CLOSE_CONNECTION_MODAL,
  CLOSE_CONNECTION_NOTE_MODAL,
  CLOSE_EMAIL_MODAL,
  CLOSE_FORGOT_PASSWORD_MODAL,
  CLOSE_HOSTFULLY_AGENCY_MODAL,
  CLOSE_INPUT_PHONE_MODAL,
  CLOSE_LONGIN_MODAL,
  CLOSE_MESSAGE_MESSAGE_MODAL,
  CLOSE_MORE_FILTERS_MODAL,
  CLOSE_NEW_USER_INSERT_MODAL,
  CLOSE_PERSONAL_MODAL,
  CLOSE_PRESS_USER_MODAL,
  CLOSE_REFERRAL_INSERT_MODAL,
  CLOSE_REPORT_USER_MODAL,
  CLOSE_SERVICE_MODAL,
  CLOSE_SIGNUP_MODAL,
  CLOSE_SMS_VERIFICATION_MODAL,
  CLOSE_SOCIAL_SHARE_MODAL,
  CLOSE_THANK_YOU_MODAL,
  CLOSE_WISH_LIST_GROUP_MODAL,
  CLOSE_WISH_LIST_MODAL,
  MANAGE_SOCIAL_BLOG_SHARE_MODAL,
  MANAGE_SOCIAL_LIKE_MODAL,
  OPEN_BOOKING_MODAL,
  OPEN_CONNECTION_MODAL,
  OPEN_CONNECTION_NOTE_MODAL,
  OPEN_EMAIL_MODAL,
  OPEN_FORGOT_PASSWORD_MODAL,
  OPEN_HOSTFULLY_AGENCY_MODAL,
  OPEN_INPUT_PHONE_MODAL,
  OPEN_LONGIN_MODAL,
  OPEN_MESSAGE_MESSAGE_MODAL,
  OPEN_MORE_FILTERS_MODAL,
  OPEN_NEW_USER_INSERT_MODAL,
  OPEN_PERSONAL_MODAL,
  OPEN_PRESS_USER_MODAL,
  OPEN_REFERRAL_INSERT_MODAL,
  OPEN_REPORT_USER_MODAL,
  OPEN_SERVICE_MODAL,
  OPEN_SIGNUP_MODAL,
  OPEN_SMS_VERIFICATION_MODAL,
  OPEN_SOCIAL_SHARE_MODAL,
  OPEN_THANK_YOU_MODAL,
  OPEN_WISH_LIST_GROUP_MODAL,
  OPEN_WISH_LIST_MODAL,
  PHOTO_MODAL_HIDE,
  PHOTO_MODAL_SHOW,
  PROPERTY_STATUS_MODAL_START,
  PROPERTY_STATUS_MODAL_SUCCESS,
  QUOTE_MODAL_HIDE,
  QUOTE_MODAL_SHOW,
  SYNC_MANAGE_LISTING_PUBLISH_STATUS_ERROR,
  SYNC_MANAGE_LISTING_PUBLISH_STATUS_START,
  SYNC_MANAGE_LISTING_PUBLISH_STATUS_SUCCESS,
  OPEN_INVITE_EMAIL_MODAL,
  CLOSE_INVITE_EMAIL_MODAL,
  OPEN_LOCAL_SPOT_SHOW_MODAL,
  CLOSE_LOCAL_SPOT_SHOW_MODAL,
  UPDATE_CREDIT_FORM_MODAL,
  UPDATE_GOSOLO_USER_REQUEST_MODAL,
  UPDATE_USER_CONCIERGE_MODAL,
  UPDATE_GOHUSTLE_APPLY_MODAL,
  UPDATE_APPLIED_JOBS_MODAL,
} from '../constants';

export default function modalStatus(state = {}, action) {
  switch (action.type) {
    case OPEN_LONGIN_MODAL:
      return {
        ...state,
        isLoginModalOpen: action.isLoginModalOpen,
        isSignupModalOpen: action.isSignupModalOpen,
        isForgotPasswordOpen: action.isForgotPasswordOpen,
      };

    case CLOSE_LONGIN_MODAL:
      return {
        ...state,
        isLoginModalOpen: action.isLoginModalOpen,
      };

    case OPEN_SIGNUP_MODAL:
      return {
        ...state,
        isSignupModalOpen: action.isSignupModalOpen,
        isLoginModalOpen: action.isLoginModalOpen,
      };

    case CLOSE_SIGNUP_MODAL:
      return {
        ...state,
        isSignupModalOpen: action.isSignupModalOpen,
      };

    case OPEN_FORGOT_PASSWORD_MODAL:
      return {
        ...state,
        isForgotPasswordOpen: action.isForgotPasswordOpen,
        isLoginModalOpen: action.isLoginModalOpen,
      };

    case CLOSE_FORGOT_PASSWORD_MODAL:
      return {
        ...state,
        isForgotPasswordOpen: action.isForgotPasswordOpen,
      };

    case OPEN_WISH_LIST_GROUP_MODAL:
      return {
        ...state,
        wishListGroupModalOpen: true,
      };

    case CLOSE_WISH_LIST_GROUP_MODAL:
      return {
        ...state,
        wishListGroupModalOpen: false,
      };

    case OPEN_WISH_LIST_MODAL:
      return {
        ...state,
        wishListModalOpen: action.payload.wishListModalOpen,
        listId: action.payload.listId,
      };

    case CLOSE_WISH_LIST_MODAL:
      return {
        ...state,
        wishListModalOpen: false,
      };
    case OPEN_REPORT_USER_MODAL:
      return {
        ...state,
        isReportUserModalOpen: action.payload.isReportUserModalOpen,
      };
    case CLOSE_REPORT_USER_MODAL:
      return {
        ...state,
        isReportUserModalOpen: action.payload.isReportUserModalOpen,
      };
    case OPEN_THANK_YOU_MODAL:
      return {
        ...state,
        isThankYouModalOpen: action.payload.isThankYouModalOpen,
      };
    case CLOSE_THANK_YOU_MODAL:
      return {
        ...state,
        isThankYouModalOpen: action.payload.isThankYouModalOpen,
      };

    case OPEN_SMS_VERIFICATION_MODAL:
      return {
        ...state,
        smsVerificationModalOpen: action.payload.smsVerificationModalOpen,
        formType: action.payload.formType,
      };

    case CLOSE_SMS_VERIFICATION_MODAL:
      return {
        ...state,
        smsVerificationModalOpen: false,
        formType: undefined,
      };
    case OPEN_REFERRAL_INSERT_MODAL:
      return {
        ...state,
        referralFormModalOpen: action.payload.isReferralInsertOpen,
      };

    case CLOSE_REFERRAL_INSERT_MODAL:
      return {
        ...state,
        referralFormModalOpen: action.payload.isReferralInsertOpen,
      };

    case OPEN_NEW_USER_INSERT_MODAL:
      return {
        ...state,
        isNewUserInsertOpen: action.payload.isNewUserInsertOpen,
        isNewUserInsertOpenTrigger: action.payload.isNewUserInsertOpenTrigger,
      };

    case CLOSE_NEW_USER_INSERT_MODAL:
      return {
        ...state,
        isNewUserInsertOpen: action.payload.isNewUserInsertOpen,
      };

    case OPEN_BOOKING_MODAL:
      return {
        ...state,
        isBookModalOpen: action.isBookModalOpen,
      };

    case CLOSE_BOOKING_MODAL:
      return {
        ...state,
        isBookModalOpen: action.isBookModalOpen,
      };

    case OPEN_MESSAGE_MESSAGE_MODAL:
      return {
        ...state,
        isPrivateMessageModal: action.payload.isPrivateMessageModal,
      };

    case CLOSE_MESSAGE_MESSAGE_MODAL:
      return {
        ...state,
        isPrivateMessageModal: action.payload.isPrivateMessageModal,
      };

    case QUOTE_MODAL_SHOW:
      return {
        ...state,
        quoteModal: action.payload.quoteModal,
        reservationId: action.payload.reservationId,
        securityFee: action.payload.securityFee,
      };

    case QUOTE_MODAL_HIDE:
      return {
        ...state,
        quoteModal: action.payload.quoteModal,
      };
    case OPEN_PERSONAL_MODAL:
      return {
        ...state,
        isPersonalModalOpen: action.isPersonalModalOpen,
      };

    case CLOSE_PERSONAL_MODAL:
      return {
        ...state,
        isPersonalModalOpen: action.isPersonalModalOpen,
      };
    case OPEN_EMAIL_MODAL:
      return {
        ...state,
        isEmailModalOpen: action.isEmailModalOpen,
      };

    case CLOSE_EMAIL_MODAL:
      return {
        ...state,
        isEmailModalOpen: action.isEmailModalOpen,
      };

    case OPEN_SOCIAL_SHARE_MODAL:
      return {
        ...state,
        isSocialShareModal: action.payload.isSocialShareModal,
      };
    case CLOSE_SOCIAL_SHARE_MODAL:
      return {
        ...state,
        isSocialShareModal: action.payload.isSocialShareModal,
      };
    case OPEN_SERVICE_MODAL:
      return {
        ...state,
        isserviceModalOpen: action.payload.isserviceModalOpen,
      };
    case CLOSE_SERVICE_MODAL:
      return {
        ...state,
        isserviceModalOpen: action.payload.isserviceModalOpen,
      };
    case OPEN_PRESS_USER_MODAL:
      return {
        ...state,
        isPressUserModal: action.payload.isPressUserModal,
      };
    case CLOSE_PRESS_USER_MODAL:
      return {
        ...state,
        isPressUserModal: action.payload.isPressUserModal,
      };
    case OPEN_MORE_FILTERS_MODAL:
      return {
        ...state,
        isMoreFiltersModal: action.payload.isMoreFiltersModal,
      };
    case CLOSE_MORE_FILTERS_MODAL:
      return {
        ...state,
        isMoreFiltersModal: action.payload.isMoreFiltersModal,
      };
    case PHOTO_MODAL_SHOW:
      return {
        ...state,
        photoModel: action.payload.photoModel,
      };
    case PHOTO_MODAL_HIDE:
      return {
        ...state,
        photoModel: action.payload.photoModel,
      };
    case OPEN_CONNECTION_MODAL:
      return {
        ...state,
        isConnectionModalOpen: action.isConnectionModalOpen,
      };

    case CLOSE_CONNECTION_MODAL:
      return {
        ...state,
        isConnectionModalOpen: action.isConnectionModalOpen,
      };
    case OPEN_CONNECTION_NOTE_MODAL:
      return {
        ...state,
        isConnectionNoteModalOpen: action.isConnectionNoteModalOpen,
      };

    case CLOSE_CONNECTION_NOTE_MODAL:
      return {
        ...state,
        isConnectionNoteModalOpen: action.isConnectionNoteModalOpen,
      };

    case OPEN_INPUT_PHONE_MODAL:
      return {
        ...state,
        isInputPhoneModalOpen: action.isInputPhoneModalOpen,
      };

    case CLOSE_INPUT_PHONE_MODAL:
      return {
        ...state,
        isInputPhoneModalOpen: action.isInputPhoneModalOpen,
      };

    case OPEN_HOSTFULLY_AGENCY_MODAL:
      return {
        ...state,
        hostFullyModalStatus: action.hostFullyModalStatus,
      };

    case CLOSE_HOSTFULLY_AGENCY_MODAL:
      return {
        ...state,
        hostFullyModalStatus: action.hostFullyModalStatus,
      };

    case PROPERTY_STATUS_MODAL_START:
      return {
        ...state,
        propertyStatus: action.propertyStatus,
      };

    case PROPERTY_STATUS_MODAL_SUCCESS:
      return {
        ...state,
        propertyStatus: action.propertyStatus,
      };

    case SYNC_MANAGE_LISTING_PUBLISH_STATUS_START:
      return {
        ...state,
        isSyncListingStatus: action.payload.isSyncListingStatus,
      };

    case SYNC_MANAGE_LISTING_PUBLISH_STATUS_SUCCESS:
      return {
        ...state,
        isSyncListingStatus: action.payload.isSyncListingStatus,
      };

    case SYNC_MANAGE_LISTING_PUBLISH_STATUS_ERROR:
      return {
        ...state,
        isSyncListingStatus: action.payload.isSyncListingStatus,
      };

    case MANAGE_SOCIAL_BLOG_SHARE_MODAL:
      return {
        ...state,
        isBlogShareModalOpen: action.isBlogShareModalOpen,
        sharingBlogId: action.sharingBlogId,
      };

    case MANAGE_SOCIAL_LIKE_MODAL:
      return {
        ...state,
        isSocialLikeModalOpen: action.isSocialLikeModalOpen,
        blogId: action.blogId,
        likeCount: action.likeCount,
        commentLikeData: action.commentLikeData,
      };

    case OPEN_INVITE_EMAIL_MODAL:
      return {
        ...state,
        isEmailInviteOpen: action.isEmailInviteOpen,
      };

    case CLOSE_INVITE_EMAIL_MODAL:
      return {
        ...state,
        isEmailInviteOpen: action.isEmailInviteOpen,
      };

    case OPEN_LOCAL_SPOT_SHOW_MODAL:
      return {
        ...state,
        isLocalSpotShowOpen: action.isLocalSpotShowOpen,
      };

    case CLOSE_LOCAL_SPOT_SHOW_MODAL:
      return {
        ...state,
        isLocalSpotShowOpen: action.isLocalSpotShowOpen,
      };

    case UPDATE_CREDIT_FORM_MODAL:
      return {
        ...state,
        isCreditFormOpen: action.isCreditFormOpen,
      };

    case UPDATE_GOSOLO_USER_REQUEST_MODAL:
      return {
        ...state,
        isGoSoloUserRequestOpen: action.isGoSoloUserRequestOpen,
      };

    case UPDATE_USER_CONCIERGE_MODAL:
      return {
        ...state,
        isUserConciergeModalOpen: action.isUserConciergeModalOpen,
      };

    case UPDATE_GOHUSTLE_APPLY_MODAL:
      return {
        ...state,
        isGoHustleApplyModalOpen: action.isGoHustleApplyModalOpen,
      };

    case UPDATE_APPLIED_JOBS_MODAL:
      return {
        ...state,
        isAppliedJobsModalOpen: action.isAppliedJobsModalOpen,
      };

    default:
      return {
        ...state,
      };
  }
}
