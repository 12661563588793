import {
  INVITE_DETAILS_ERROR,
  INVITE_DETAILS_START,
  INVITE_DETAILS_SUCCESS,
  REFERRAL_KEY_DATA_ERROR,
  REFERRAL_KEY_DATA_START,
  REFERRAL_KEY_DATA_SUCCESS,
} from '../constants';

export default function referral(state = {}, action) {
  switch (action.type) {
    case INVITE_DETAILS_START:
      return {
        ...state,
        inviteLoading: action.payload.inviteLoading,
      };

    case INVITE_DETAILS_SUCCESS:
      return {
        ...state,
        inviteLoading: action.payload.inviteLoading,
      };

    case INVITE_DETAILS_ERROR:
      return {
        ...state,
        inviteLoading: action.payload.inviteLoading,
      };

    case REFERRAL_KEY_DATA_START:
      return {
        ...state,
        referralKeyValue: action.referralKeyValue,
      };

    case REFERRAL_KEY_DATA_SUCCESS:
      return {
        ...state,
        referralKeyValue: action.referralKeyValue,
      };

    case REFERRAL_KEY_DATA_ERROR:
      return {
        ...state,
        referralKeyValue: action.referralKeyValue,
      };

    default:
      return state;
  }
}
