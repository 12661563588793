import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../locale/messages';
import CurrencyConverter from '../../CurrencyConverter';

class CompletedTransactionPayout extends Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    data: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      payoutEmail: PropTypes.string.isRequired,
      formatMessage: PropTypes.func,
    }),
  };

  render() {
    const { className, data } = this.props;
    const date = data.createdAt
      ? moment(data.createdAt).format('DD-MM-YYYY')
      : '';
    const totalAmount = data.amount;

    return (
      <tr>
        <td className={className}>{date}</td>
        <td className={className}>
          <FormattedMessage {...messages.transactionPayout} />
        </td>
        <td className={className}>
          <FormattedMessage {...messages.transferTo} /> {data.payoutEmail}
        </td>
        <td className={className} />
        <td className={className}>
          <CurrencyConverter amount={totalAmount} from={data.currency} />
        </td>
      </tr>
    );
  }
}

export default CompletedTransactionPayout;
