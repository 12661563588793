import {
  DOCUMENT_PICTURE_LOADER_START,
  GET_LISTING_SUCCESS,
  PROFILE_PICTURE_LOADER_START,
  REMOVE_PROFILE_PICTURE_ERROR,
  REMOVE_PROFILE_PICTURE_SUCCESS,
  SET_USER_DATA_START,
  SET_USER_DATA_SUCCESS,
  UPLOAD_PROFILE_PICTURE_ERROR,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from '../constants';

export default function account(state = {}, action) {
  switch (action.type) {
    case SET_USER_DATA_START:
      return {
        ...state,
      };
    case SET_USER_DATA_SUCCESS:
      return {
        ...state,
        data: action.updatedProfileData,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        data: null,
      };

    case PROFILE_PICTURE_LOADER_START:
      return {
        ...state,
        profilePhotoLoading: action.payload.profilePhotoLoading,
      };
    case UPLOAD_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        profilePhotoLoading: action.payload.profilePhotoLoading,
        uploadFileName: action.payload.uploadFileName,
      };
    case UPLOAD_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        profilePhotoLoading: action.payload.profilePhotoLoading,
        uploadFileName: action.payload.uploadFileName,
      };
    case REMOVE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        profilePhotoLoading: action.payload.profilePhotoLoading,
      };
    case REMOVE_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        profilePhotoLoading: action.payload.profilePhotoLoading,
      };
    case DOCUMENT_PICTURE_LOADER_START:
      return {
        ...state,
        documentPhotoLoading: action.payload.documentPhotoLoading,
      };
    case GET_LISTING_SUCCESS:
      return {
        ...state,
        isGetListing: action.payload.isGetListing,
      };
    default:
      return state;
  }
}
