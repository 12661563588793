import React from 'react';

import PropTypes from 'prop-types';

import { backendUrl, sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class RequestConnection extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      profileId: PropTypes.number,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'normal',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const profileStyle = {
      display: 'inline-block',
      width: '23%',
      verticalAlign: 'top',
    };
    const profileTextStyle = {
      display: 'inline-block',
      width: '74%',
      verticalAlign: 'top',
      paddingLeft: '15px',
    };
    const profileTextTitleStyle = {
      fontSize: '18px',
      fontWeight: '700',
    };

    const {
      content: {
        profileId,
        name,
        name2,
        connectionMessage,
        profilePicture,
        profileCurrentPostion,
        profileLocation,
      },
    } = this.props;
    const actionURL = `${url}/users/show/${profileId}`;
    let profileImage = `${backendUrl}/images/avatar/medium_${profilePicture}`;

    if (profilePicture?.includes('http')) {
      profileImage = profilePicture;
    }

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>
            Hi {name},{' '}
            {connectionMessage || "I'd like to join your Golightly network."}
          </div>
          <EmptySpace height={20} />
          {profilePicture && (
            <div style={profileStyle}>
              <img height={125} src={profileImage} />
            </div>
          )}
          <div style={profileTextStyle}>
            <div style={profileTextTitleStyle}>
              {profileId === 2 ? `${name2} CEO, Golightly` : name2}
            </div>
            {profileCurrentPostion && profileId !== 2 && (
              <div>{profileCurrentPostion}</div>
            )}
            {profileLocation && <div>{profileLocation}</div>}
            <EmptySpace height={20} />
            <div>
              <a href={actionURL} style={buttonStyle}>
                Accept / Decline
              </a>
            </div>
            <EmptySpace height={20} />
            <div>
              Please click on the button above to either accept or decline her
              invitation to connect.
            </div>
          </div>
          <EmptySpace height={10} />
          <div>Please do not reply to this automatic email.</div>
          <EmptySpace height={30} />
          <div>Thanks,</div>
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default RequestConnection;
