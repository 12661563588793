import React from 'react';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class PostApplyUser extends React.Component {
  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };
    const {
      content: { userName, userEmail, userProfileId },
    } = this.props;

    const profileURL = `${url}/users/show/`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>
            {' '}
            Hi{' '}
            <a href={profileURL + userProfileId} style={linkText}>
              {userName}
              {userEmail ? ` (${userEmail})` : ''}
            </a>
          </div>
          <EmptySpace height={20} />
          <div>
            An email will be sent to you with more information. Thank you for
            applying for the job on the GoHustle platform
          </div>
          <EmptySpace height={20} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default PostApplyUser;
