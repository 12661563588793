import React from 'react';

import moment from 'moment';
import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import CurrencyView from '../modules/CurrencyView';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class InquiryForAdmin extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      receiverName: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
      senderName: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      threadId: PropTypes.number.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkout: PropTypes.string.isRequired,
      personCapacity: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const btnCenter = {
      textAlign: 'center',
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const {
      content: {
        receiverName,
        type,
        senderName,
        message,
        threadId,
        userEmail,
        hostEmail,
      },
    } = this.props;
    const {
      content: { checkIn, checkOut, personCapacity, status },
    } = this.props;
    const {
      content: {
        hostProfileId,
        guestProfileId,
        currency,
        offerPrice,
        listId,
        listTitle,
      },
    } = this.props;

    const checkInDate = checkIn
      ? moment(checkIn).format('ddd, MMM Do, YYYY')
      : '';
    const checkOutDate = checkOut
      ? moment(checkOut).format('ddd, MMM Do, YYYY')
      : '';
    const inquiryStatus = status === 'inquiry';
    const profileURL = `${url}/users/show/`;
    const listURL = `${url}/rooms/${listId}`;
    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi Admin,</div>
                  <EmptySpace height={20} />
                  <div>
                    <a href={profileURL + hostProfileId}>
                      {receiverName}
                      {hostEmail ? ` (${hostEmail})` : ''}
                    </a>{' '}
                    {inquiryStatus && <span>have a new inquiry from</span>}
                    {!inquiryStatus && (
                      <span>has a special price request from</span>
                    )}{' '}
                    <a href={profileURL + guestProfileId}>
                      {senderName}
                      {userEmail ? ` (${userEmail})` : ''}
                    </a>
                    .
                  </div>
                  <EmptySpace height={20} />
                  <div>Check In: {checkInDate}</div>
                  <EmptySpace height={20} />
                  <div>Check Out: {checkOutDate}</div>
                  <EmptySpace height={20} />
                  <div>Guests: {personCapacity}</div>
                  <EmptySpace height={20} />
                  <div>
                    Listing: <a href={listURL}>{listTitle}</a>
                  </div>
                  {!inquiryStatus && <EmptySpace height={20} />}
                  {!inquiryStatus && (
                    <div>
                      Offer Price Per Night:
                      <CurrencyView amount={offerPrice} currency={currency} />
                    </div>
                  )}

                  <EmptySpace height={20} />
                  <div>Message:</div>
                  <EmptySpace height={10} />
                  <div>{message}</div>
                  <EmptySpace height={40} />
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default InquiryForAdmin;
