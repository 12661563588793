import React from 'react';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class PostApplyAdmin extends React.Component {
  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const linkText = {
      color: '#80d8df',
      fontSize: '16px',
      textDecoration: 'none',
      cursor: 'pointer',
    };
    const btnCenter = {
      textAlign: 'center',
    };
    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };
    const {
      content: {
        userName,
        userProfileId,
        userEmail,
        jobType,
        postUserName,
        postUserProfileId,
        postUserEmail,
        jobLocation,
        isAdminPost,
        postId,
        companyName,
        isRemoteLocation,
      },
    } = this.props;

    const profileURL = `${url}/users/show/`;
    let actionURL = `${url}/hustle/${postId}`;
    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          {isAdminPost ? (
            <div>
              <a href={profileURL + userProfileId} style={linkText}>
                {userName}
                {userEmail ? ` (${userEmail})` : ''}
              </a>{' '}
              has applied for the position of {jobType} at {companyName}
              {!isRemoteLocation ? (
                <span>, {jobLocation} that you posted.</span>
              ) : (
                <span> that you posted.</span>
              )}
            </div>
          ) : (
            <div>
              <a href={profileURL + userProfileId} style={linkText}>
                {userName}
                {userEmail ? ` (${userEmail})` : ''}
              </a>{' '}
              has applied for the position of {jobType} at {companyName}
              {!isRemoteLocation ? (
                <span>, {jobLocation} posted by</span>
              ) : (
                <span> posted by</span>
              )}{' '}
              <a href={profileURL + postUserProfileId} style={linkText}>
                {postUserName}
                {postUserEmail ? ` (${postUserEmail})` : ''}
              </a>
            </div>
          )}
          <EmptySpace height={20} />
          <div style={btnCenter}>
            <a href={actionURL} style={buttonStyle}>
              View More
            </a>
          </div>
          <EmptySpace height={20} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default PostApplyAdmin;
