import {
  IDM_VERIFICATION_MODAL_START,
  IDM_VERIFICATION_MODAL_SUCCESS,
} from '../constants';

export default function idmModal(state = {}, action) {
  switch (action.type) {
    case IDM_VERIFICATION_MODAL_START:
      return {
        ...state,
        idmModalStatus: action.idmModalStatus,
      };

    case IDM_VERIFICATION_MODAL_SUCCESS:
      return {
        ...state,
        idmModalStatus: action.idmModalStatus,
      };

    default:
      return state;
  }
}
