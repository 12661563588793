import {
  FETCH_NEXTPAX_CALENDARS_START,
  FETCH_NEXTPAX_CALENDARS_SUCCESS,
  FETCH_NEXTPAX_PRICES_START,
  FETCH_NEXTPAX_PRICES_SUCCESS,
  GET_NEXTPAX_QUOTE_START,
  GET_NEXTPAX_QUOTE_SUCCESS,
} from '../constants';

export default function nextPax(state = {}, action) {
  switch (action.type) {
    case FETCH_NEXTPAX_PRICES_START:
      return {
        ...state,
        prices: null,
        loading: true,
      };

    case FETCH_NEXTPAX_PRICES_SUCCESS:
      return {
        ...state,
        prices: action.payload,
        loading: false,
      };

    case FETCH_NEXTPAX_CALENDARS_START:
      return {
        ...state,
        calendars: null,
        loadingCalendars: true,
      };

    case FETCH_NEXTPAX_CALENDARS_SUCCESS:
      return {
        ...state,
        calendars: action.payload,
        loadingCalendars: false,
      };

    case GET_NEXTPAX_QUOTE_START:
      return {
        ...state,
        quote: null,
        loading: true,
      };

    case GET_NEXTPAX_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
