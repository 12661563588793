import React from 'react';

import cx from 'classnames';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { Nav, NavDropdown } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { openSocialRulesModal } from '../../actions/Modal/socialBlogRulesModal';
import { setUserLogout } from '../../actions/logout';
import history from '../../core/history';
import messages from '../../locale/messages';
import Avatar from '../Avatar';
import GoSocial from '../GoSocial';
import Logout from '../Logout';
import MenuItemLink from '../MenuItemLink';
import Message from '../Message';
import NavLink from '../NavLink';
import WishListModal from '../WishListModal';

import s from './NavigationAfterLogin.module.css';

const CheckUserStatusQuery = loader('./getCheckUserStatus.graphql');

class NavigationAfterLogin extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    setUserLogout: PropTypes.func,
    formatMessage: PropTypes.func,
  };

  static defaultProps = {
    checkLoginUserExist: {
      loading: true,
      getCheckUserStatus: {
        userExistStatus: null,
      },
    },
  };

  constructor(props) {
    super(props);
    this.handleSocialBlogRules = this.handleSocialBlogRules.bind(this);
  }

  handleSocialBlogRules() {
    const {
      userData,
      openSocialRulesModal,
      isCompletedProfile,
      isProfileUpdated,
    } = this.props;

    if (
      userData &&
      isCompletedProfile &&
      isProfileUpdated &&
      userData.gender &&
      userData.gender === 'female' &&
      userData.verification &&
      userData.verification.isPhoneVerified &&
      !userData.socialEnable
    ) {
      openSocialRulesModal();
    } else if (
      userData &&
      isCompletedProfile &&
      isProfileUpdated &&
      userData.gender &&
      userData.gender === 'female' &&
      userData.verification &&
      userData.verification.isPhoneVerified &&
      userData.socialEnable
    ) {
      history.push('/social');
    }
  }

  render() {
    const {
      checkLoginUserExist: { loading, getCheckUserStatus },
      className,
      setUserLogout,
      wishListModal,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const {
      userData,
      requestInvites,
      isCoHost,
      isCompletedProfile,
      isProfileUpdated,
    } = this.props;
    let isVerified;
    let disabled = false;
    let isDisabled = false;

    if (userData) isVerified = userData.profileId;

    if (
      userData &&
      userData.userId === 'c505e590-f3bc-11e9-bdc7-0913579b3abc'
    ) {
      disabled = true;
    }
    if (isCoHost === true) {
      isDisabled = true;
    }

    if (!loading && getCheckUserStatus) {
      if (getCheckUserStatus.userExistStatus) {
        setUserLogout();
      }
    }

    return (
      <Nav pullRight>
        <NavLink className="visible-xs visible-sm visible-md" to="/">
          <FormattedMessage {...messages.home} />
        </NavLink>
        <NavLink
          to={
            userData && userData.isDiamond ? '/diamond-member' : '/membership'
          }
        >
          <FormattedMessage {...messages.diamondLabel} />
        </NavLink>
        {/* <NavLink to="/social">
          <FormattedMessage {...messages.goSocial} />
          <sup className={s.newSup}>
            <FormattedMessage {...messages.beta} />
          </sup>
        </NavLink> */}
        <NavLink to="/go-solo">
          <FormattedMessage {...messages.goSolo} />
        </NavLink>
        <NavLink
          className={cx('visible-xs visible-sm visible-md')}
          to="/go-solo-bookings"
        >
          <FormattedMessage {...messages.goSoloTrips} />
        </NavLink>
        <GoSocial />
        <NavLink className="visible-xs visible-sm visible-md" to="/about-us">
          <FormattedMessage {...messages.about} />
        </NavLink>

        {!disabled && (
          <NavLink to="/show/users">
            <FormattedMessage {...messages.members} />
          </NavLink>
        )}
        <NavLink className="visible-xs visible-sm visible-md" to="/dashboard">
          <FormattedMessage {...messages.dashboard} />
        </NavLink>

        <NavDropdown
          className={cx('hidden-xs hidden-sm hidden-md', 'dropdownHost')}
          eventKey={3}
          id="basic-nav-dropdown"
          noCaret
          title={formatMessage(messages.host)}
        >
          {!disabled && (
            <MenuItemLink to="/become-a-host?mode=new">
              <FormattedMessage {...messages.listYourSpace} />
            </MenuItemLink>
          )}
          {!disabled && (
            <MenuItemLink to="/rooms">
              <FormattedMessage {...messages.manageListing} />
            </MenuItemLink>
          )}
          <MenuItemLink to="/howtohost">
            <FormattedMessage {...messages.howToHost} />
          </MenuItemLink>
          <MenuItemLink to="/reservation/current">
            <FormattedMessage {...messages.yourReservations} />
          </MenuItemLink>
          {!isDisabled && (
            <MenuItemLink to="/user/transaction">
              <FormattedMessage {...messages.transactionHistory} />
            </MenuItemLink>
          )}
        </NavDropdown>
        {!disabled && (
          <NavLink
            className="visible-xs visible-sm visible-md"
            to={`/users/show/${isVerified}`}
          >
            <FormattedMessage {...messages.profile} />
          </NavLink>
        )}
        {/* { !isDisabled && <NavLink */}
        {!disabled && !isDisabled && (
          <NavLink
            className={cx('visible-xs visible-sm visible-md')}
            to="/user/payout"
          >
            <FormattedMessage {...messages.accountSettings} />
          </NavLink>
        )}
        {/* </NavLink> } */}
        {/* <NavLink to="/whoCanJoin" >
          <FormattedMessage {...messages.whoCanJoin} />
        </NavLink> */}
        {!disabled && (
          <NavLink to="/invite">
            {/* {requestInvites && <FormattedMessage {...messages.referFriends} />}
            {!requestInvites && (
              <FormattedMessage {...messages.requestInvitesFriend} />
            )} */}
            <FormattedMessage {...messages.referFriends} />
          </NavLink>
        )}
        <NavLink
          className={cx('visible-xs visible-sm visible-md', 'headerBorder')}
          to="/your-connections"
        >
          <FormattedMessage {...messages.yourConnections} />
        </NavLink>
        {/* <hr className={cx('visible-xs visible-sm visible-md', s.hrSection)} /> */}
        <NavLink
          className="visible-xs visible-sm visible-md"
          to="/trips/current"
        >
          <FormattedMessage {...messages.trips} />
        </NavLink>
        {!disabled && (
          <NavLink className="visible-xs visible-sm visible-md" to="/rooms">
            <FormattedMessage {...messages.host} />
          </NavLink>
        )}
        {!disabled && (
          <NavLink className="visible-xs visible-sm visible-md" to="/wishlists">
            <FormattedMessage {...messages.saved} />
          </NavLink>
        )}
        <Message />
        <Logout className={cx('visible-xs visible-sm visible-md')} />
        <NavDropdown
          className={cx('hidden-xs hidden-sm hidden-md')}
          id="basic-nav-dropdown"
          noCaret
          title={
            <Avatar
              className={s.userAvatar}
              daimondClass="diamondImageHeader"
              height={30}
              isDiamond={userData && userData.isDiamond}
              isUser
              type="small"
              width={30}
            />
          }
        >
          <MenuItemLink to="/dashboard">
            <FormattedMessage {...messages.dashboard} />
          </MenuItemLink>
          {!isDisabled && (
            <MenuItemLink to={`/users/show/${isVerified}`}>
              <FormattedMessage {...messages.viewProfile} />
            </MenuItemLink>
          )}
          {!disabled && (
            <MenuItemLink to="/invite">
              {requestInvites && (
                <FormattedMessage {...messages.referFriends} />
              )}
              {!requestInvites && (
                <FormattedMessage {...messages.requestInvitesFriend} />
              )}
            </MenuItemLink>
          )}
          <MenuItemLink to="/your-connections">
            <FormattedMessage {...messages.yourConnections} />
          </MenuItemLink>
          {!isDisabled && !disabled && (
            <MenuItemLink to="/user/payout">
              <FormattedMessage {...messages.accountSettings} />
            </MenuItemLink>
          )}
          {/* {!disabled && <MenuItemLink to="/user/payout">
            <FormattedMessage {...messages.accountSettings} />
          </MenuItemLink> } */}
          {
            // isProfileUpdated && isCompletedProfile &&
            <Logout />
          }
        </NavDropdown>
        {wishListModal && <WishListModal />}
      </Nav>
    );
  }
}

const mapState = (state) => ({
  wishListModal: state.modalStatus.wishListModalOpen,
  userData: state.account.data,
  isCoHost: state.runtime.isCoHost,
  isProfileUpdated: state.runtime.isProfileUpdated,
  isCompletedProfile: state.runtime.isCompletedProfile,
});

const mapDispatch = {
  setUserLogout,
  openSocialRulesModal,
};

export default compose(
  injectIntl,
  graphql(CheckUserStatusQuery, {
    name: 'checkLoginUserExist',
    options: {
      ssr: false,
      pollInterval: 15000,
    },
  }),
  connect(mapState, mapDispatch)
)(NavigationAfterLogin);
