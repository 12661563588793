import React from 'react';

import cx from 'classnames';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { setUserLogout } from '../../actions/logout';
import messages from '../../locale/messages';

class Logout extends React.Component {
  static propTypes = {};

  render() {
    const { className } = this.props;
    return (
      <li className={cx(className, 'ipadLogout')}>
        <form>
          <Button
            bsStyle="link"
            onClick={() => this.props.setUserLogout()}
            type="button"
          >
            <FormattedMessage {...messages.logout} />
          </Button>
        </form>
      </li>
    );
  }
}

export default connect(null, { setUserLogout })(Logout);
