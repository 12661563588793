import {
  CLOSE_ADMIN_ROLES_MODAL,
  CLOSE_ADMIN_USER_MODAL,
  CLOSE_LIST_SETTINGS_MODAL,
  CLOSE_MAXINVITE_SETTINGS_MODAL,
  CLOSE_TAG_MODAL,
  HOST_BANNER_UPLOAD_START,
  HOST_BANNER_UPLOAD_STOP,
  HOST_LOGO_UPLOAD_START,
  HOST_LOGO_UPLOAD_STOP,
  MEMBER_BANNER_UPLOAD_START,
  MEMBER_BANNER_UPLOAD_STOP,
  MEMBER_LOGO_UPLOAD_START,
  MEMBER_LOGO_UPLOAD_STOP,
  OPEN_ADMIN_ROLES_MODAL,
  OPEN_ADMIN_USER_MODAL,
  OPEN_LIST_SETTINGS_MODAL,
  OPEN_MAXINVITE_SETTINGS_MODAL,
  OPEN_TAG_MODAL,
  UPDATE_SUBSCRIPTION_FORM_MODAL,
  UPDATE_RESET_PASSWORD_MODAL,
  UPDATE_GO_SOLO_PRICE_SPLIT_UP_MODAL,
  UPDATE_GOSOLO_STAY_FORM_MODAL,
  UPDATE_GOSOLO_TRIP_TYPE_FORM_MODAL,
  UPDATE_USER_GOTEAM_MODAL,
  UPDATE_GOHUSTLE_INDUSTRY_FORM_MODAL,
  UPDATE_GOHUSTLE_JOB_TYPE_FORM_MODAL,
  UPDATE_GOHUSTLE_COMMUNITY_FORM_MODAL,
  UPDATE_GOHUSTLE_POST_FORM_MODAL,
  UPDATE_LIST_TRANSFER_MODAL,
} from '../../constants';

export default function adminModalStatus(state = {}, action) {
  switch (action.type) {
    case OPEN_LIST_SETTINGS_MODAL:
      return {
        ...state,
        listSettingsModal: action.listSettingsModal,
      };

    case CLOSE_LIST_SETTINGS_MODAL:
      return {
        ...state,
        listSettingsModal: action.listSettingsModal,
      };

    case OPEN_MAXINVITE_SETTINGS_MODAL:
      return {
        ...state,
        inviteSettingsModal: action.inviteSettingsModal,
      };

    case CLOSE_MAXINVITE_SETTINGS_MODAL:
      return {
        ...state,
        inviteSettingsModal: action.inviteSettingsModal,
      };

    case MEMBER_LOGO_UPLOAD_START:
      return {
        ...state,
        isLogoUpload: action.payload.isLogoUpload,
      };

    case MEMBER_LOGO_UPLOAD_STOP:
      return {
        ...state,
        isLogoUpload: action.payload.isLogoUpload,
      };

    case MEMBER_BANNER_UPLOAD_START:
      return {
        ...state,
        isBannerLogo: action.payload.isBannerLogo,
      };

    case MEMBER_BANNER_UPLOAD_STOP:
      return {
        ...state,
        isBannerLogo: action.payload.isBannerLogo,
      };

    case HOST_LOGO_UPLOAD_START:
      return {
        ...state,
        isLogoUpload: action.payload.isLogoUpload,
      };

    case HOST_LOGO_UPLOAD_STOP:
      return {
        ...state,
        isLogoUpload: action.payload.isLogoUpload,
      };

    case HOST_BANNER_UPLOAD_START:
      return {
        ...state,
        isBannerLogo: action.payload.isBannerLogo,
      };

    case HOST_BANNER_UPLOAD_STOP:
      return {
        ...state,
        isBannerLogo: action.payload.isBannerLogo,
      };

    case OPEN_ADMIN_USER_MODAL:
      return {
        ...state,
        adminUserModal: action.payload.adminUserModal,
        adminUserModalType: action.payload.adminUserModalType,
      };

    case CLOSE_ADMIN_USER_MODAL:
      return {
        ...state,
        adminUserModal: action.payload.adminUserModal,
        adminUserModalType: null,
      };

    case OPEN_ADMIN_ROLES_MODAL:
      return {
        ...state,
        adminRolesModal: action.payload.adminRolesModal,
        adminRolesModalType: action.payload.adminRolesModalType,
      };

    case CLOSE_ADMIN_ROLES_MODAL:
      return {
        ...state,
        adminRolesModal: action.payload.adminRolesModal,
        adminRolesModalType: null,
      };

    case OPEN_TAG_MODAL:
      return {
        ...state,
        tagModalStatus: action.payload.tagModalStatus,
      };

    case CLOSE_TAG_MODAL:
      return {
        ...state,
        tagModalStatus: action.payload.tagModalStatus,
      };

    case UPDATE_SUBSCRIPTION_FORM_MODAL:
      return {
        ...state,
        isSubscriptionFormOpen: action.payload.isSubscriptionFormOpen,
      };

    case UPDATE_RESET_PASSWORD_MODAL:
      return {
        ...state,
        isResetPasswordModalOpen: action.payload.isResetPasswordModalOpen,
      };

    case UPDATE_GO_SOLO_PRICE_SPLIT_UP_MODAL:
      return {
        ...state,
        isGoSoloPriceSplitUpOpen: action.payload.isGoSoloPriceSplitUpOpen,
      };

    case UPDATE_GOSOLO_STAY_FORM_MODAL:
      return {
        ...state,
        isGoSoloStayFormOpen: action.payload.isGoSoloStayFormOpen,
      };
    case UPDATE_GOSOLO_TRIP_TYPE_FORM_MODAL:
      return {
        ...state,
        isGoSoloTripFormOpen: action.payload.isGoSoloTripFormOpen,
      };
    case UPDATE_USER_GOTEAM_MODAL:
      return {
        ...state,
        isGoTeamModalFormOpen: action.payload.isGoTeamModalFormOpen,
      };
    case UPDATE_GOHUSTLE_INDUSTRY_FORM_MODAL:
      return {
        ...state,
        isGoHustleIndustryFormOpen: action.payload.isGoHustleIndustryFormOpen,
      };
    case UPDATE_GOHUSTLE_JOB_TYPE_FORM_MODAL:
      return {
        ...state,
        isGoHustleJobTypeFormOpen: action.payload.isGoHustleJobTypeFormOpen,
      };
    case UPDATE_GOHUSTLE_COMMUNITY_FORM_MODAL:
      return {
        ...state,
        isGoHustleCommunityFormOpen: action.payload.isGoHustleCommunityFormOpen,
      };
    case UPDATE_GOHUSTLE_POST_FORM_MODAL:
      return {
        ...state,
        isGoHustlePostModal: action.payload.isGoHustlePostModal,
      };
    case UPDATE_LIST_TRANSFER_MODAL:
      return {
        ...state,
        isListTransferModalOpen: action.payload.isListTransferModalOpen,
      };
    default:
      return {
        ...state,
      };
  }
}
