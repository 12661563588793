import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose, gql, graphql } from 'react-apollo';
import { FormControl } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { onChangeListing } from '../../actions/Listing/onChangeListing';
import messages from '../../locale/messages';

class Listings extends Component {
  static propTypes = {
    className: PropTypes.string,
    formatMessage: PropTypes.func,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      ManageListings: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
        })
      ),
    }),
    refetch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { refetch } = this.props;
    const { onChangeListing } = this.props;
    const { mode, handleResults, payoutId } = this.props;
    const variables = {
      listId: e.target.value,
    };
    onChangeListing(e.target.value);
    // refetch(variables);
    handleResults(mode, payoutId, e.target.value);
  }

  render() {
    const {
      className,
      data: { loading, ManageListings },
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { onChangeListingId } = this.props;
    return (
      <FormControl
        className={className}
        componentClass="select"
        onChange={this.handleChange}
        value={onChangeListingId}
      >
        <option value="0">{formatMessage(messages.allListings)}</option>
        {!loading &&
          ManageListings &&
          ManageListings.map((item, index) => {
            return (
              <option key={index} value={item.id}>
                {item.title}
              </option>
            );
          })}
      </FormControl>
    );
  }
}

const mapState = (state) => ({
  onChangeListingId: state.onChangeListing.onChangeListingId,
});
const mapDispatch = {
  onChangeListing,
};

export default compose(
  connect(mapState, mapDispatch),
  injectIntl,
  graphql(
    gql`
      {
        ManageListings {
          id
          title
        }
      }
    `,
    {
      options: {
        ssr: false,
      },
    }
  )
)(Listings);
