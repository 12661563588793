import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function phoneFormat(dialCode, number, countryCode) {
  let phoneNumber;
  let phoneFormatNumber;
  if (number && dialCode) {
    // phoneFormatNumber = parsePhoneNumberFromString('+12133734253')
    phoneFormatNumber = parsePhoneNumberFromString(dialCode + number);
    // phoneNumber = phoneFormatNumber.formatInternational();
    phoneNumber = phoneFormatNumber.formatInternational();
  }

  return phoneNumber;
}
