import {
  SEND_INTERHOME_BOOKING_DATA_START,
  SEND_INTERHOME_BOOKING_DATA_SUCCESS,
  SET_INTERHOME_RESERVATION_ID,
  SET_INTERHOME_PAYMENT_LOADING,
  GET_INTERHOME_BOOKINGS_BY_DATES_START,
  GET_INTERHOME_BOOKINGS_BY_DATES_SUCCESS,
  SET_INTERHOME_BOOKINGS,
  SET_INTERHOME_ADMIN_LISTINGS,
  SET_INTERHOME_ADMIN_LISTINGS_SUCCESS,
} from '../constants';

const initialState = {
  prices: null,
  reservationId: null,
  loading: false,
  interhomePaymentLoading: false,
  paymentSuccess: false,
  bookings: [],
  interhomeListings: null,
  interhomeListingsLoading: false,
};

export default function interhome(state = initialState, action) {
  switch (action.type) {
    case SET_INTERHOME_ADMIN_LISTINGS:
      return {
        ...state,
        interhomeListings: null,
        interhomeListingsLoading: true,
      };

    case SET_INTERHOME_ADMIN_LISTINGS_SUCCESS:
      return {
        ...state,
        interhomeListings: action.payload,
        interhomeListingsLoading: false,
      };

    case SEND_INTERHOME_BOOKING_DATA_START:
      return {
        ...state,
        prices: null,
        loading: true,
      };

    case SEND_INTERHOME_BOOKING_DATA_SUCCESS:
      return {
        ...state,
        prices: action.payload,
        loading: false,
      };

    case SET_INTERHOME_RESERVATION_ID: {
      return {
        ...state,
        reservationId: action.payload,
      };
    }

    case SET_INTERHOME_PAYMENT_LOADING: {
      return {
        ...state,
        paymentLoading: action.payload,
      };
    }

    case SET_INTERHOME_BOOKINGS: {
      return {
        ...state,
        bookings: action.payload,
        loading: false,
      };
    }

    case GET_INTERHOME_BOOKINGS_BY_DATES_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_INTERHOME_BOOKINGS_BY_DATES_SUCCESS: {
      return {
        ...state,
        bookings: action.payload,
        loading: false,
      };
    }

    default:
      return state;
  }
}
