import React, { Component } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Col, FormControl, FormGroup } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

// Internal Helpers
import messages from '../../locale/messages';

import s from './ReferralForm.module.css';
import submit from './submit';
import validate from './validate';

class ReferralForm extends Component {
  static propTypes = {
    formatMessage: PropTypes.func,
  };

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
    errorMessage,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
        {errorMessage && (
          <span className={s.errorMessage}>{formatMessage(errorMessage)}</span>
        )}
        <FormControl
          {...input}
          className={className}
          placeholder={label}
          type={type}
        />
      </div>
    );
  };

  render() {
    const { error, handleSubmit, submitting, dispatch } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <form onSubmit={handleSubmit(submit)}>
        <FormGroup className={cx(s.formGroup, s.spaceTop2, s.InputWidth)}>
          <Field
            className={s.formControlInput}
            component={this.renderFormControl}
            errorMessage={error}
            label={formatMessage(messages.referralCode)}
            name="referralCode"
            type="text"
          />
        </FormGroup>

        <FormGroup className={cx(s.formGroup, s.InputWidth)}>
          <Button
            block
            bsSize="large"
            className={cx(s.button, s.btnPrimary)}
            disabled={submitting}
            type="submit"
          >
            {formatMessage(messages.submit)}
          </Button>
        </FormGroup>
        <FormGroup className={cx(s.formGroup, s.formSection)}>
          <Col
            className={cx(s.noPadding, s.textAlignCenter)}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <a
              className={cx(s.modalCaptionLink, s.referalCodeBold)}
              href="/become-a-member"
            >
              <FormattedMessage {...messages.noInviteCode} />
            </a>
          </Col>
        </FormGroup>
      </form>
    );
  }
}

const referralForm = reduxForm({
  form: 'ReferralForm', // a unique name for this form
  validate,
  destroyOnUnmount: false,
})(ReferralForm);

const mapState = (state) => ({});

const mapDispatch = {};

export default injectIntl(connect(mapState, mapDispatch)(referralForm));
