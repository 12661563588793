import CryptoJS from 'crypto-js';

import { auth } from '../config';

export function encrypt(data) {
  if (data) {
    return (
      CryptoJS &&
      CryptoJS.AES &&
      CryptoJS.AES.encrypt(data, `${auth.jwt}`).toString()
    );
  }
  return null;
}

export function decrypt(data) {
  if (data) {
    const bytes = CryptoJS.AES.decrypt(data, `${auth.jwt}`);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return null;
}

export function encryptObject(data) {
  if (data) {
    return (
      CryptoJS &&
      CryptoJS.AES &&
      CryptoJS.AES.encrypt(JSON.stringify(data), `${auth.jwt}`).toString()
    );
  }
  return null;
}

export function decryptObject(data) {
  if (data) {
    const bytes =
      CryptoJS && CryptoJS.AES && CryptoJS.AES.decrypt(data, `${auth.jwt}`);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
}

// export function encryptObject(data) {
//     if (data) {
//         return CryptoJS.AES.encrypt(JSON.stringify(data), `${auth.jwt}`).toString();
//     } else {
//         return null;
//     }
// }

// export function decryptObject(data) {
//     if (data) {
//         let bytes = CryptoJS.AES.decrypt(data, `${auth.jwt}`);
//         let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//         return decryptedData;
//     } else {
//         return null;
//     }
// }
