import {
  GET_NEXTPAX_BOOKINGS,
  GET_NEXTPAX_BOOKINGS_START,
  GET_NEXTPAX_BOOKINGS_SUCCESS,
} from '../../constants';

const initialState = {
  loading: false,
  bookings: [],
};

export default function nextPaxBookings(state = initialState, action) {
  switch (action.type) {
    case GET_NEXTPAX_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
        loading: false,
      };
    case GET_NEXTPAX_BOOKINGS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_NEXTPAX_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
