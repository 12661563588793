import { GET_LOGS_START, GET_LOGS_SUCCESS } from '../../constants';

const initialState = {
  logs: [],
  loading: false,
};

export default function adminLogs(state = initialState, action) {
  switch (action.type) {
    case GET_LOGS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        logs: action.payload,
      };
    default:
      return state;
  }
}
