import { PRESS_DATA_SUCCESS } from '../constants';

export default function pressUserData(state = {}, action) {
  switch (action.type) {
    case PRESS_DATA_SUCCESS:
      return {
        ...state,
        email: action.email,
      };

    default:
      return state;
  }
}
