import React from 'react';

import cx from 'classnames';
import { compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import history from '../../core/history';
import messages from '../../locale/messages';
import Link from '../Link';

import s from './SubnavBar.module.css';

class MenuComponent extends React.Component {
  render() {
    const { accountData, isCoHost } = this.props;
    const { isAdminAuthenticated, isPropertyManager } = this.props;
    let location;
    let disabled = false;
    let isDisabled = false;
    let hideNavBar = false;
    if (this?.props?.currentRoute) {
      location = this.props.currentRoute;
    }

    if (
      accountData &&
      accountData.userId === 'c505e590-f3bc-11e9-bdc7-0913579b3abc'
    ) {
      disabled = true;
    }

    if (isCoHost === true) {
      isDisabled = true;
    }

    if (isAdminAuthenticated) {
      hideNavBar = true;
    }

    return (
      <div className={cx(s.progressContainer, 'hidden-xs', 'hidden-print')}>
        <ul className={cx(s.navList)}>
          {!isDisabled && !hideNavBar && (
            <li className={cx({ [s.active]: location === '/dashboard' })}>
              <Link className={s.navItem} to="/dashboard">
                <FormattedMessage {...messages.dashboard} />
              </Link>
            </li>
          )}

          {
            <li className={cx({ [s.active]: location === '/inbox' })}>
              <Link className={s.navItem} to="/inbox">
                <FormattedMessage {...messages.inbox} />
              </Link>
            </li>
          }

          {!disabled && (
            <li
              className={cx({
                [s.active]:
                  location === '/rooms' || location === '/reservation/current',
              })}
            >
              <Link className={s.navItem} to="/rooms">
                <FormattedMessage {...messages.yourListings} />
              </Link>
            </li>
          )}

          {
            <li className={cx({ [s.active]: location === '/trips/current' })}>
              <Link className={s.navItem} to="/trips/current">
                <FormattedMessage {...messages.yourTrips} />
              </Link>
            </li>
          }

          {!hideNavBar && (
            <li
              className={cx({ [s.active]: location === '/your-connections' })}
            >
              <Link className={s.navItem} to="/your-connections">
                <FormattedMessage {...messages.yourConnections} />
              </Link>
            </li>
          )}

          {!disabled && !isDisabled && !hideNavBar && (
            <li
              className={cx({
                [s.active]:
                  location === '/user/edit' ||
                  location === '/user/photo' ||
                  location === '/user/verification' ||
                  location === '/user/reviews' ||
                  location === '/user/gosolo-reviews',
              })}
            >
              <Link className={s.navItem} to="/user/edit">
                <FormattedMessage {...messages.profile} />
              </Link>
            </li>
          )}
          {!isDisabled && !hideNavBar && (
            <li
              className={cx({
                [s.active]:
                  location === '/user/payout' ||
                  location === '/user/transaction' ||
                  location === '/users/security',
              })}
            >
              <Link className={s.navItem} to="/user/payout">
                <FormattedMessage {...messages.account} />
              </Link>
            </li>
          )}
          {isDisabled && (
            <li className={cx({ [s.active]: location === '/users/security' })}>
              <Link className={s.navItem} to="/users/security">
                <FormattedMessage {...messages.account} />
              </Link>
            </li>
          )}

          {!hideNavBar && (
            <li className={cx({ [s.active]: location === '/wishlists' })}>
              <Link className={s.navItem} to="/wishlists">
                <FormattedMessage {...messages.saved} />
              </Link>
            </li>
          )}

          {
            <li
              className={cx({ [s.active]: location === '/go-solo-bookings' })}
            >
              <Link className={s.navItem} to="/go-solo-bookings">
                <FormattedMessage {...messages.goSolo} />
              </Link>
            </li>
          }
        </ul>
      </div>
    );
  }
}

const mapState = (state) => ({
  currentRoute: state.route.route,
  isCoHost: state.runtime.isCoHost,
  isAdminAuthenticated: state.runtime.isAdminAuthenticated,
  isPropertyManager: state.runtime.propertyManager,
});

const mapDispatch = {};

// export default injectIntl((connect(mapState, mapDispatch)(NavigationAfterLogin)));
export default compose(
  injectIntl,
  connect(mapState, mapDispatch)
)(MenuComponent);
// export default (MenuComponent);
