import React from 'react';

import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { profilePictureUrl, sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class CompletedReservationGuest extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      reservationId: PropTypes.number.isRequired,
      hostName: PropTypes.string.isRequired,
      hostLastName: PropTypes.string.isRequired,
      hostProfilePic: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '10px',
      textAlign: 'center',
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const bookingTitle = {
      paddingBottom: '20px',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '25px',
      margin: '0',
      padding: '0',
      textAlign: 'center',
    };

    const profilePic = {
      borderRadius: '999px',
      margin: '0',
      padding: '0',
      lineHeight: '150%',
      borderSpacing: '0',
      width: '125px',
    };

    const userName = {
      color: '#565a5c',
      fontSize: '26px',
      fontWeight: 'bold',
      paddingBottom: '5px',
    };

    const subTitle = {
      color: '#565a5c',
      fontSize: '18px',
      fontWeight: 'bold',
      paddingBottom: '5px',
    };

    const linkText = {
      color: '#77d1da',
      fontSize: '18px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const space = {
      paddingBottom: '20px',
    };
    const {
      content: { reservationId },
    } = this.props;
    const {
      content: { hostName, hostLastName, hostProfilePic },
    } = this.props;
    const messageURL = `${url}/review/write/${reservationId}`;
    let imageURL;
    if (hostProfilePic) {
      imageURL = `${profilePictureUrl}/medium_${hostProfilePic}`;
    }

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>
                    {hostProfilePic && (
                      <img height={125} src={imageURL} style={profilePic} />
                    )}
                  </div>
                  <EmptySpace height={20} />
                  <h1 style={bookingTitle}>
                    Tell {hostName} what you loved <br />
                    <span> and give her some tips for next time!</span>
                  </h1>
                  <EmptySpace height={20} />
                  <div>
                    You have just checked out, so now is the perfect time to
                    write your review.
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    Reviews are an important part of the {sitename} community.
                    Please take a moment to provide your host with some helpful
                    feedback - it'll only take a minute.
                  </div>
                  <EmptySpace height={50} />
                  <div>
                    <a href={messageURL} style={buttonStyle}>
                      Write a Review
                    </a>
                  </div>
                  <EmptySpace height={40} />
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={50} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default CompletedReservationGuest;
